import React, {useEffect, useState} from "react";

// reactstrap components
import {Card, CardHeader, Col, Container, Row,} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import ActivityList from "./component/activityList";
import ActivityCompanyList from "./component/activityCompanyList";
import PointSaveList from "./component/pointSaveList";
import LivingList from "./component/livingList";
import CarbonHistoryList from "./component/carbonHistoryList";
import carbonTabService from "./service/carbonTab.service";

function CarbonTabList() {
  const [data,setData] = useState({})

  const render = async () => {
    const res = await carbonTabService.detail();
    if (res.code === 200) {
      console.log(res);
      setData(res.data);
    }
  };

  useEffect(() => {
    render();
  }, []);

  return (
    <>
      <SimpleHeader name="Tables" parentName="Tables" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">탄소 탭 관리</h3>
                  </Col>
                </Row>
                <Row className="my-3">
                  <Col
                    className="border rounded mr-3 ml-3 p-4"
                    md="3"
                  >
                    <Row>
                      <Col md="auto">
                        <h4 className="mb-0">전체탄소 저감량</h4>
                      </Col>
                      <Col>
                        <h4 className="mb-0 text-right pt-6">{data?.totalCarbon} kg</h4>
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    className="border rounded mr-3 p-4"
                    md="3"
                  >
                    <Row>
                      <Col md="auto">
                        <h4 className="mb-0">걸음 기부 탄소 저감량</h4>
                      </Col>
                      <Col>
                        <h4 className="mb-0 text-right pt-6">{data?.stepReduction} kg</h4>
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    className="border rounded mr-3 p-4"
                    md="3"
                  >
                    <Row>
                      <Col md="auto">
                        <h4 className="mb-0">자원 리사이클링(탄소적립점)</h4>
                      </Col>
                      <Col>
                        <h4 className="mb-0 text-right pt-6">{data?.carbonReduction} kg</h4>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardHeader>
              <CarbonHistoryList/>
              <ActivityList/>
              <LivingList/>
              <PointSaveList/>
              <ActivityCompanyList/>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default CarbonTabList;
