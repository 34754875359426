import axios from "axios";
import api from "../../../config/api.routes";
import authHeader from "../../../services/auth-header";


const list = (data) => {
  let url = api().apiURL + `/sns/list?`;
  for (let query of Object.keys(data)) {
    if (data[query] === undefined || data[query] === '') {
      continue;
    }
    url += `${query}=${data[query]}&`;
  }
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.get(url, { headers: authHeader() })
      .then(res => res.data)
      .then(res => resolve(res))
      .catch(e => reject(e));
  });
};

const detail = (id) => {
  let url = api().apiURL + `/sns/detail?id=${id}`;
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.get(url, { headers: authHeader() })
      .then(res => res.data)
      .then(res => resolve(res))
      .catch(e => reject(e));
  });
};

const commentList = (id) => {
  let url = api().apiURL + `/snsComment/list?snsId=${id}`;
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.get(url, { headers: authHeader() })
      .then(res => res.data)
      .then(res => resolve(res))
      .catch(e => reject(e));
  });
};

const boardService = {
  list,
  detail,
  commentList
}

export default boardService;
