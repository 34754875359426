import React, {Fragment, useEffect} from "react";

// reactstrap components
import {
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import CounselingService from "../../../services/counseling.service";
import PaginationWrapper from "../components/pagination";
import {paginate} from "../../../utils";
import useHistoryState from "../../../common/useHistoryState";
import classnames from "classnames";
import {Link} from "react-router-dom";
import shopService from "./shop.service";
import {addWeeks} from "@fullcalendar/core";
import {shopCategory} from "./service/shop.category";

function ShopReviewList() {
  const [pageActive, setPageActive] = useHistoryState(1, 'pageActive');
  const [pageCount, setPageCount] = useHistoryState(1, 'pageCount');
  const [data, setData] = useHistoryState([], 'data');
  const [category, setCategory] = useHistoryState('전체', 'category');
  const [comment, setComment] = useHistoryState('전체', 'comment');
  const [searchCurrent, setSearchCurrent] = useHistoryState(false, 'searchCurrent');
  const [search, setSearch] = useHistoryState('', 'search');

  const render = async () => {
    const option = {
      category, search
    };
    if (comment !== '전체') {
      option.comment = comment === 'O';
    }
    const res = await shopService.reviewList(option);
    if (res.code === 200) {
      console.log(res);
      setPageCount(res.list.length);
      setData(paginate(res.list, pageActive, 10));
    }
  }

  useEffect(() => {
    render();
  }, [category, pageActive, search, comment]);

  return (
    <>
      <SimpleHeader name="Tables" parentName="Tables" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row className="py-3">
                  <Col xs="11">
                    <Form>
                      <Row>
                        <Col xs="3">
                          <label className=" form-control-label py-2">
                          </label>
                          <FormGroup>
                            <InputGroup
                              className={classnames("input-group-merge", {
                                focused: searchCurrent,
                              })}
                            >
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="fas fa-search" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="업체명, 아이디, 리뷰내용 으로 검색"
                                type="text"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                onFocus={(e) => setSearchCurrent(true)}
                                onBlur={(e) => setSearchCurrent(false)}
                              />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col xs="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="exampleFormControlSelect13"
                            >
                              카테고리
                            </label>
                            <Input
                              id="exampleFormControlSelect13"
                              type="select"
                              value={category}
                              onChange={(e) => setCategory(e.target.value)}
                            >
                              <option>전체</option>
                              {shopCategory.map((c, i) => {
                                return [
                                  <option key={i}>{c}</option>
                                ]
                              })}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col xs="2">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="comment"
                            >
                              사장님 답글
                            </label>
                            <Input
                              id="comment"
                              type="select"
                              value={comment}
                              onChange={(e) => setComment(e.target.value)}
                            >
                              <option>전체</option>
                              <option>O</option>
                              <option>X</option>
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">업체 리뷰 관리</h3>
                  </Col>
                </Row>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive striped>
                <thead className="thead-light">
                <tr>
                  <th className="text-center">리뷰 등록 날짜</th>
                  <th className="text-center">아이디</th>
                  <th className="text-center">카테고리</th>
                  <th className="text-center">업체명</th>
                  <th className="text-center">회원명</th>
                  <th className="text-center">리뷰 내용</th>
                  <th className="text-center">사장님 답글</th>
                  <th />
                </tr>
                </thead>
                <tbody>
                  {data?.map((item, i) => {
                  return [
                    <Fragment key={i}>
                      <tr>
                        <td className="text-center">
                          {item.createdAt}
                        </td>
                        <td className="text-center">
                          {item.user.loginId}
                        </td>
                        <td className="text-center">
                          {item.shop.category}
                        </td>
                        <td className="text-center">
                          {item.shop.name}
                        </td>
                        <td className="text-center">
                          {item.user.name}
                        </td>
                        <td className="text-center">
                          {item.content.length > 20 ? item.content.slice(0, 20) + '...': item.content}
                        </td>
                        <td className="text-center">
                          {item.comment ? 'O' : 'X'}
                        </td>
                        <td className="table-actions">
                          <Link className="table-action" to={'/admin/shopReviewDetail/' + item.id}>
                            <i id="tooltip209424781" className="fas fa-user-edit" />
                          </Link>
                          <UncontrolledTooltip delay={0} target="tooltip209424781">
                            상세 보기
                          </UncontrolledTooltip>
                        </td>
                      </tr>
                    </Fragment>
                  ];
                })}
                </tbody>
              </Table>

              <CardFooter className="py-4">
                <PaginationWrapper
                  isActive={pageActive}
                  pageNumber={pageCount}
                  callback={(page) => setPageActive(page)}
                  itemPerPage={10}
                />
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default ShopReviewList;
