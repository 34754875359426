import React, {Fragment, useEffect, useState} from "react";
// reactstrap components
import {Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Input, Modal, Row,} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import {useHistory} from "react-router-dom";
import useHistoryState from "../../../common/useHistoryState";
import moment from "moment";
import InputFile from "../components/InputFile";
import {FormControl, InputGroup} from "react-bootstrap";
import DaumPostcode from "react-daum-postcode";
import {useRecoilState, useRecoilValue, useResetRecoilState} from "recoil";
import qrDiscountService, {
  qrDiscountDeletedId,
  qrDiscountFilePreviewState,
  qrDiscountFileState, qrDiscountPreviewState,
  qrDiscountStoreState
} from "./service/qrDiscount.service";
import {
  companyStoreDeletedId,
  companyStorePreviewState,
  companyStoreState
} from "../companyStore/service/companyStore.service";
import InputFiles from "../components/InputFiles";
import qrCountService from "../qrCount/service/qrCount.service";
import shopService from "../shop/shop.service";

function QrDiscountForm({ match }) {
  const history = useHistory();
  const { id } = match.params;
  const [startDate, setStartDate] = useHistoryState(moment().format('YYYY-MM-DD'), 'startDate');
  const [endDate, setEndDate] = useHistoryState(moment().add(1, 'month').format('YYYY-MM-DD'), 'endDate');
  const [pageActive, setPageActive] = useHistoryState(1, 'pageActive');
  const [pageCount, setPageCount] = useHistoryState(1, 'pageCount');
  const [loading, setLoading] = useState(false);
  const [list, setList] = useHistoryState([], 'list');
  const [image, setImage] = useHistoryState([], 'image');
  const [checkList, setCheckList] = useState([]);
  const [checkOneList, setCheckOneList] = useState([]);
  const [companyList, setCompanyList] = useHistoryState([], 'companyList');
  const [defaultModal, setDefaultModal] = useState(false);

  const fileState = useRecoilValue(qrDiscountFileState);
  const [previewThumbURL, setPreviewThumbURL] = useRecoilState(qrDiscountFilePreviewState);
  const resetFileState = useResetRecoilState(qrDiscountFileState);
  const resetFilePreview = useResetRecoilState(qrDiscountFilePreviewState);

  const filesState = useRecoilValue(qrDiscountStoreState);
  const [previewsThumbURL, setPreviewsThumbURL] = useRecoilState(qrDiscountPreviewState);
  const resetFilesState = useResetRecoilState(qrDiscountStoreState);
  const resetFilesPreview = useResetRecoilState(qrDiscountPreviewState);
  const [carbonStore, setCarbonStore] = useState({
    category: '식당/음식점',
    point: '',
    qrName: ''
  })
  const {
    point,
    category,
    qrName,
  } = carbonStore
  const [data, setData] = useState({
    name: '',
    tel: '',
    managerTel: '',
    url: '',
    content: '',
    address: '',
    addressDetail: '',
    latitude: '',
    longitude: '',
  });
  const {
    name,
    tel,
    managerTel,
    url,
    content,
    address,
    addressDetail,
    latitude,
    longitude,
  } = data;

  const reset = () => {
    resetFileState();
    resetFilePreview();
    resetFilesState();
    resetFilesPreview()
  }

  const onChange = (e) => {
    let { value, name } = e.target;
    if (name === 'category' || name === 'qrName' || name === 'point'
    ) {
      setCarbonStore({
        ...carbonStore,
        [name]: value
      })
      // setData({
      //   ...data,
      //   [name]: Number(value.replace(/[^0-9]/g, '')),
      // })
    } else {
      setData({
        ...data,
        [name]: value
      });
    }
  };

  const fetchData = async () => {
    if(id) {
      const res = await qrDiscountService.detail(id);
      if (res.code === 200) {
        console.log(res);
        setData(res.store)
        setCarbonStore(res.store.carbonStore)
        setPreviewThumbURL(`https://d2twzv77g3c69u.cloudfront.net/fit-in/1000x700/${res.store.carbonStore.carbonStoreImages[0]?.name}`)
        setPreviewsThumbURL(res.store.images.map((v, i) => {
          return `https://d2twzv77g3c69u.cloudfront.net/fit-in/1000x700/${v}`
        }))
      }
    } else {

    }
  };

  useEffect(() => {
    if(id) {
      fetchData();
    } else {
      reset();
    }
  }, []);

  const submit = async () => {
    setLoading(true);
    try {
      await createOrUpdate();
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }

  const createOrUpdate = async () => {
    // if (!title) {
    //     alert('제목을 입력해주세요.');
    //     return;
    // }
    // if (!content) {
    //     alert('내용을 입력해주세요.');
    //     return;
    // }
    //
    const json = {
      ...data,
      category: '탄소할인점',
      carbonStore
    }

    console.log('json',json);

    let res;
    try {
      if (id) {
        let json = {
          category: '탄소할인점',
          address,
          addressDetail,
          carbonStore:{qrName, point: String(point), category},
          content,
          latitude,
          longitude,
          managerTel,
          name,
          tel,
          url,
        }
        res = await qrDiscountService.update(id, json);
        if (filesState.length > 0) {
          let form = new FormData();
          for (let f of filesState) {
            form.append('image', f);
          }
          await qrDiscountService.fileRegister(id, form);
        }
      } else {
        res = await qrDiscountService.register(json);
        if (filesState.length > 0) {
          let form = new FormData();
          for (let f of filesState) {
            form.append('image', f);
          }
          await qrDiscountService.fileRegister(res.store.id, form);
        }
      }
      if (res.code === 200) {
        alert(id ? '수정되었습니다.' : '등록되었습니다.');
        history.goBack();
        reset();
      }
    } catch (e) {
      // alert(JSON.stringify(e, null, 4));
      alert(e.response?.data?.message);
    }
    console.log('웹', res);
  };

  const remove = async () => {
    if (window.confirm('삭제하시겠습니까?')) {
      let res = await qrDiscountService.remove(id);
      console.log(res);
      if (res.code === 200) {
        alert('삭제되었습니다.')
        history.goBack();
      }
    }
  };

  const onChangeOpenPost = () => {
    setDefaultModal(true);
  };

  const onCompletePost = (input) => {
    let address = input.address;
    const geocoder = new window.daum.maps.services.Geocoder();
    geocoder.addressSearch(address, function (result, status) {
      // 정상적으로 검색이 완료됐으면
      if (status === window.daum.maps.services.Status.OK) {
        setData({
          ...data,
          address,
          // addressPreview: result[0].address.region_3depth_name,
          longitude: result[0].x,
          latitude: result[0].y
        });
      }
    })
    setDefaultModal(false);
  };

  if (loading) return <div>로딩중..</div>;
  return (
    <>
      {defaultModal  ? (
          <Fragment>
            <Modal
                className="modal-dialog-centered"
                isOpen={defaultModal}
                toggle={() => setDefaultModal(() => false)}
                fade={false}
            >
              <DaumPostcode autoClose onComplete={onCompletePost} className="post-code" />
            </Modal>

          </Fragment>
      ) : null}
      <SimpleHeader name="Form elements" parentName="Forms" />
      <Container className="mt--6" fluid>
        <Card className="mb-4">
          <CardHeader style={{ display: 'flex', justifyContent: 'space-between' }}>
            {id?
                <>
                  <h3 className="mt-2">탄소 할인점 등록,QR관리 상세</h3>
                  <div>
                    <Button
                      color="default"
                      type="button"
                      onClick={submit}
                    >
                      수정하기
                    </Button>
                    <Button
                      color="danger"
                      type="button"
                      onClick={remove}
                    >
                      삭제하기
                    </Button>
                  </div>
                </>
                :
                <>
                  <h3 className="mt-2">탄소 할인점 등록,QR관리 등록</h3>
                  <div>
                    <Button
                        color="default"
                        type="button"
                        onClick={submit}
                    >
                      <span className="btn-inner--icon mr-1">
                          <i className="fas fa-user-edit" />
                      </span>
                      등록하기
                    </Button>
                  </div>
                </>
            }
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    카테고리
                  </label>
                  <Input
                      type="select"
                      name="category"
                      value={category || ''}
                      onChange={onChange}
                  >
                    <option>식당/음식점</option>
                    <option>패션/미용</option>
                    <option>마트/할인점</option>
                    <option>숙박/서비스</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    상점명
                  </label>
                  <Input
                      placeholder="상점명을 입력해주세요."
                      type="text"
                      name="name"
                      value={name || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    QR생성명
                  </label>
                  <Input
                      placeholder="QR생성명을 입력해주세요."
                      type="text"
                      name="qrName"
                      value={qrName || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    차감포인트
                  </label>
                  <Input
                      placeholder="차감포인트를 입력해주세요."
                      type="text"
                      name="point"
                      value={point || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="mt-4">
                <InputFile
                    title={'생성 QR'}
                    fileState={qrDiscountFileState}
                    filePreviewState={qrDiscountFilePreviewState}
                />
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    주소 검색
                  </label>
                  <InputGroup className="mb-3">
                    <FormControl
                        placeholder="주소 입력"
                        aria-label="주소 입력"
                        aria-describedby="basic-addon2"
                        // name="address"
                        value={address || ''}
                        // onChange={onChange}
                        readOnly
                    />
                    <Button
                        className="ml-2"
                        variant="outline-secondary"
                        id="button-addon2"
                        color="primary"
                        onClick={() => onChangeOpenPost()}
                    >
                      주소 찾기
                    </Button>
                  </InputGroup>
                  <Input
                      placeholder="세부 주소 입력 ex) OO역 5번출구 도보 4분"
                      type="text"
                      name="addressDetail"
                      value={addressDetail || ''}
                      onChange={onChange}
                  />
                  <Input
                      className="mt-3"
                      placeholder=""
                      type="text"
                      name="latitude"
                      value={latitude || ''}
                      onChange={onChange}
                  />
                  <Input
                      className="mt-3"
                      placeholder=""
                      type="text"
                      name="longitude"
                      value={longitude || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    전화번호
                  </label>
                  <Input
                      placeholder="전화번호를 입력해주세요."
                      type="text"
                      name="tel"
                      value={tel || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    담당자 전화번호
                  </label>
                  <Input
                      placeholder="담당자 전화번호를 입력해주세요."
                      type="text"
                      name="managerTel"
                      value={managerTel || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    웹사이트
                  </label>
                  <Input
                      placeholder="웹사이트를 입력해주세요."
                      type="text"
                      name="url"
                      value={url || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    가게소개
                  </label>
                  <Input
                      placeholder="가게소개를 입력해주세요."
                      type="textarea"
                      rows="10"
                      name="content"
                      value={content || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <InputFiles
                title={'사진을 업로드하세요.(총3장)'}
                filesState={qrDiscountStoreState}
                filesPreviewState={qrDiscountPreviewState}
                deletedId={qrDiscountDeletedId}
                filesCount={3}
            />
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default QrDiscountForm;
