import React, {useEffect, useRef, useState, Fragment} from "react";
// reactstrap components
import {
  Button,
  Card,
  CardBody, CardFooter,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Modal,
  Row, Table,
  UncontrolledTooltip,
} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import {useHistory} from "react-router-dom";
import volunteerService from "./service/volunteer.service";
import useHistoryState from "../../../common/useHistoryState";
import moment from "moment";
import PaginationWrapper from "../components/pagination";
import * as dayjs from 'dayjs';

function VolunteerForm({ match }) {
  const history = useHistory();
  const { id } = match.params;
  const [pageActive, setPageActive] = useHistoryState(1, 'pageActive');
  const [pageCount, setPageCount] = useHistoryState(1, 'pageCount');
  const [loading, setLoading] = useState(false);
  const [list, setList] = useHistoryState([], 'list');
  const [data, setData] = useHistoryState([], 'data');
  const [image, setImage] = useHistoryState([], 'image');
  console.log(pageActive)

  const render = async () => {
    const res = await volunteerService.detail(id, { page: pageActive });
    if (res.code === 200) {
      console.log(res);
      setPageCount(res.serviceHistories.count);
      setList(res.serviceHistories.rows.map((v) => {
        const startSi = Number(v.startDate.split(':')[0])
        const startBun = Number(v.startDate.split(':')[1])
        const endSi = Number(v.endDate.split(':')[0])
        const endBun = Number(v.endDate.split(':')[1])
        const startTime = dayjs()
            .set('hour', startSi)
            .set('minute', startBun)
            .set('second', 0)
            .format('HH:mm:ss')
        const endTime = dayjs()
            .set('hour', endSi)
            .set('minute', endBun)
            .set('second', 0)
            .format('HH:mm:ss')
        const dateA = new Date('2022/06/01 ' + startTime);
        const dateB = new Date('2022/06/01 ' + endTime);
        let diffMSec;
        if(dateB > dateA) {
          diffMSec = dateB.getTime() - dateA.getTime();
          // diffMSec: minSec
        }
        if(dateA > dateB) {
          diffMSec = dateA.getTime() - dateB.getTime();
        }
        // const diffMSec = dateB.getTime() - dateA.getTime();
        const diffMin = diffMSec / (60 * 1000);
        console.log(`시간의 차이는 ${diffMin}분 입니다.`);
        console.log(`${dateA}, ${dateB}`);
        console.log((dateB - dateA) / (24 * 60 * 60 * 1000))
        return {
          confirm: v.confirm,
          createdAt: v.createdAt,
          endDate: v.endDate,
          id: v.id,
          startDate: v.startDate,
          totalTime: v.totalTime,
          user: v.user,
          userBirth: v.userBirth,
          userId: v.userId,
          userLoginId: v.userLoginId,
          userName: v.userName,
          userTel: v.userTel,
          time: diffMin,
        }
      }));
      setData(res.zone);
      setImage(res?.zone?.zonePhotos);
    }
  };

  useEffect(() => {
    render();
    console.log('123')
  }, [pageActive]);

  const statusChange = async (id, confirm) => {
    await volunteerService.updateService(id, confirm);
    render();
  };

  const remove = async (id) => {
    if (window.confirm('삭제하시겠습니까?')) {
      let res = await volunteerService.remove(id);
      console.log(res);
      if (res.code === 200) {
        render();
      }
    }
  };


  const down = () => {
    volunteerService.eachExcel(Number(id))
        .then((res) => {
          fileCreate(res);
        });
  }

  const fileCreate = (res) => {
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');
    const contentDisposition = res.headers['content-disposition']; // 파일 이름
    let fileName = 'report';
    if (contentDisposition) {
      const [ fileNameMatch ] = contentDisposition.split(';').filter(str => str.includes('filename'));
      if (fileNameMatch)
        [ , fileName ] = fileNameMatch.split('=');
    }
    link.href = url;
    link.setAttribute('download', `${fileName}.xlsx`);
    link.style.cssText = 'display:none';
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  if (loading) return <div>로딩중..</div>;
  return (
    <>
      <SimpleHeader name="Form elements" parentName="Forms" />
      <Container className="mt--6" fluid>
        <Card className="mb-4">
          <CardHeader style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h3 className="mt-2">봉사 상세</h3>
          </CardHeader>
          <CardBody>
            <Row>
              {image.map((v) => {
                  return <Col md="2" className="mr-4">
                    <img src={`https://d2twzv77g3c69u.cloudfront.net/fit-in/1000x700/${v.image}`} style={{ width: '280px', height: '280px' }}/>
                    <Button
                        className="btn-info mt-2"
                        // onClick={() => setDefaultModal(true)}
                        size="sm"
                    >
                      <span className="btn-inner--text">다운로드</span>
                    </Button>
                  </Col>
              })}
            </Row>
            <Row className="mt-5">
              <Col md="auto">
                <h3>센터명</h3>
              </Col>
              <Col md="2">
                <p>{data.centerName}</p>
              </Col>
              <Col md="auto">
                <h3>담당명칭</h3>
              </Col>
              <Col md="2">
                <p>{data.groupName}</p>
              </Col>
              <Col md="auto">
                <h3>봉사활동 명</h3>
              </Col>
              <Col md="2">
                <p>{data.zoneName}</p>
              </Col>
              <Col className="text-right mb-3">
                <Button color="primary" onClick={() => down()}>
                  엑셀 다운로드
                </Button>
              </Col>
            </Row>
            <Table className="align-items-center table-flush" responsive striped>
              <thead className="thead-light">
                <tr>
                  <th className="text-center">봉사 날짜</th>
                  <th className="text-center">회원번호</th>
                  <th className="text-center">회원명</th>
                  <th className="text-center">생년월일</th>
                  <th className="text-center">전화번호</th>
                  <th className="text-center">봉사 시작시간</th>
                  <th className="text-center">봉사 종료시간</th>
                  <th className="text-center">총 봉사시간</th>
                  <th className="text-center">관리</th>
                  <th className="text-center">체크</th>
                </tr>
              </thead>
              <tbody>
                {list?.map((item, i) => {
                  return [
                    <Fragment key={i}>
                      <tr>
                        <td className="text-center">
                          {moment(item.createdAt).format('YYYY-MM-DD')}
                        </td>
                        <td className="text-center">
                          {item.id}
                        </td>
                        <td className="text-center">
                          {item.userName}
                        </td>
                        <td className="text-center">
                          {item.userBirth}
                        </td>
                        <td className="text-center">
                          {item.userTel}
                        </td>
                        <td className="text-center">
                          {item.startDate}
                        </td>
                        <td className="text-center">
                          {item.endDate}
                        </td>
                        <td className="text-center">
                          {item.time} 분
                        </td>
                        <td className="text-center">
                          <Button
                              className="btn-outline-danger"
                              color="danger"
                              onClick={() => remove(item.id)}
                              size="sm"
                          >
                            <span className="btn-inner--text">삭제</span>
                          </Button>
                        </td>
                        <td className="text-center">
                          <label className="custom-toggle custom-toggle-success center">
                            <input
                                checked={item.confirm}
                                type="checkbox"
                                onChange={(e) => statusChange(item.id, !item.confirm)}
                            />
                            <span
                                className="custom-toggle-slider rounded-circle"
                                data-label-off="No"
                                data-label-on="Yes"
                            />
                          </label>
                        </td>
                      </tr>
                    </Fragment>
                  ];
                })}
              </tbody>
            </Table>

            <CardFooter className="py-4">
              <PaginationWrapper
                  isActive={pageActive}
                  pageNumber={pageCount}
                  callback={(page) => setPageActive(page)}
                  itemPerPage={10}
              />
            </CardFooter>
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default VolunteerForm;
