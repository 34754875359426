import React, {useEffect, useRef, useState, Fragment} from "react";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Modal,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import {Link, useHistory} from "react-router-dom";
import {FormControl, InputGroup} from "react-bootstrap";
import boardService from "./board.service";

function BoardDetail({ match, history }) {
  const router = useHistory();
  const { id } = match.params;
  const [data, setData] = useState(null);
  const [comment, setComment] = useState([]);
  const [loading, setLoading] = useState(false);

  const remove = () => {

  }

  const fetchData = async () => {
    setLoading(true);
    if (id) {
      const res = await boardService.detail(id);
      console.log(res);
      if (res.code === 200) {
        setData(res.data);
      }
      const response = await boardService.commentList(id);
      console.log(response);
      if (response.code === 200) {
        setComment(response.list);
      }
    }
    setLoading(false);
  };

  useEffect( () => {
    fetchData();
  }, []);

  if (loading) return <div>로딩중..</div>;
  return (
    <>
      <SimpleHeader name="Form elements" parentName="Forms" />
      <Container className="mt--6" fluid>
        <Card className="mb-4">
          <CardHeader>
            <Row>
              <Col xs="12">
                <h3 className="mb-0">포아브탭 게시글 관리 상세</h3>
              </Col>
              <Col className="text-right" xs="12">
                <Button
                  className="btn-round btn-icon"
                  color="danger"
                  onClick={remove}
                  size="sm"
                >
                  <span className="btn-inner--text">삭제하기</span>
                </Button>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="10">
                <Row>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example3cols1Input"
                      >
                        게시 날짜
                      </label>
                      <div className="form-group">
                        {data?.createdAt}
                      </div>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example3cols1Input"
                      >
                        아이디
                      </label>
                      <div className="form-group">
                        {data?.user.loginId}
                      </div>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example3cols1Input"
                      >
                        유저 이름
                      </label>
                      <div className="form-group">
                        {data?.user.name}
                      </div>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example3cols1Input"
                      >
                        카테고리
                      </label>
                      <div className="form-group">
                        {data?.category}
                      </div>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example3cols1Input"
                      >
                        가격
                      </label>
                      <div className="form-group">
                        {data?.price}
                      </div>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example3cols1Input"
                      >
                        좋아요
                      </label>
                      <div className="form-group">
                        {data?.wishCount}
                      </div>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example3cols1Input"
                      >
                        댓글
                      </label>
                      <div className="form-group">
                        {data?.commentCount}
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col md="6">
                <Row>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example3cols1Input"
                      >
                        제목
                      </label>
                      <div className="form-group">
                        {data?.title}
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col md="6">
                <Row>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example3cols1Input"
                      >
                        내용
                      </label>
                      <div className="form-group">
                        {data?.content}
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col md="6">
                <Row>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example3cols1Input"
                      >
                        사진
                      </label>
                      <div className="form-group">
                        {data?.images.map((i, index) => {
                          return (
                            <a
                              key={index}
                              className="avatar avatar-xl rounded ml-1 h-100"
                              href={i.name}
                              target="_blank"
                            >
                              <img src={i.name}/>
                            </a>
                          )
                        })}
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col md="12">
                <Row>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example3cols1Input"
                      >
                        댓글
                      </label>
                      <div className="form-group">
                        {comment.map((v, i) => {
                          return [
                            <Fragment key={i}>
                              <Row className="ml-2">
                                {v.depth > 1 && 'ㄴ '}{v.user.name} {v.createdAt}
                              </Row>
                              <Row className="ml-2 mb-2">
                                {v.content}
                              </Row>
                            </Fragment>
                          ];
                        })}
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>


          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default BoardDetail;
