import React from "react";

// reactstrap components
import {Card, CardHeader, Col, Container, Row,} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SettingList from "./component/settingList";
import HomeList from "./component/homeList";
import AllianceList from "./component/allianceList";
import AreaList from "./component/areaList";
import TabList from "./component/tabList";
import BottomList from "./component/bottomList";
import GuideList from "./component/guideList";
import GlobalList from "./component/globalList";
import MarketList from "./component/marketList";

function AdvertisementList() {
 
  return (
    <>
      <SimpleHeader name="Tables" parentName="Tables" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">광고 관리</h3>
                  </Col>
                </Row>
              </CardHeader>
              <BottomList/>
              <SettingList/>
              <AllianceList/>
              <HomeList/>
              <AreaList/>
              <TabList/>
              <GlobalList/>
              <MarketList/>
              {/*<GuideList/>*/}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default AdvertisementList;
