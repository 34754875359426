import React, {Fragment, useEffect} from "react";

// reactstrap components
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText, Media,
  Row,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import PaginationWrapper from "../components/pagination";
import {clickImg, paginate} from "../../../utils";
import useHistoryState from "../../../common/useHistoryState";
import classnames from "classnames";
import {Link} from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import challengeService from "./service/challenge.service";
import charityService from "../charity/service/charity.service";
import ReactDatetime from "react-datetime";
import AuthService from "../auth/auth.service";

function ChallengeMemDetail({ history, match }) {
  const { id } = match.params;
  const { name } = match.params;
  const user = typeof window !== 'undefined' ? AuthService.getCurrentUser() : null;
  const company = JSON.parse(localStorage.getItem('user'))?.companyId
  const companyName = JSON.parse(localStorage.getItem('user'))?.name
  const [category, setCategory] = useHistoryState('', 'category');
  const [pageActive, setPageActive] = useHistoryState(1, 'pageActive');
  const [pageCount, setPageCount] = useHistoryState(1, 'pageCount');
  const [data, setData] = useHistoryState([], 'data');
  const [searchCurrent, setSearchCurrent] = useHistoryState(false, 'searchCurrent');
  const [search, setSearch] = useHistoryState('', 'search');

  const render = async () => {
    let option = {category, search};
    if(user.role === 'center' || id) {
      option.companyId = company || id
    }
    try {
      const res = await challengeService.list(option);
      console.log('상점프로필 유저 관리', res);
      if (res.code === 200) {
        setPageCount(res.list.length);
        setData(paginate(res.list, pageActive, 10));
      }
    } catch (e) {
      alert(e.response?.data?.resultMsg);
    }
  };

  useEffect(() => {
    render();
  }, [ pageActive, pageCount, category, search ]);

  const statusChange = async (id, active) => {
    await challengeService.updateChallenge(id, {active});
    render();
  }

  return (
    <>
      <SimpleHeader name="Tables" parentName="Tables" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row className="py-1">
                  <Col xs="2">
                    <FormGroup>
                      <label className=" form-control-label">
                      </label>
                      <Input
                          id="category"
                          type="select"
                          value={category}
                          onChange={(e) => setCategory(e.target.value)}
                      >
                        <option>전체</option>
                        <option>봉사</option>
                        <option>생활</option>
                        <option>역량</option>
                        <option>건강</option>
                        <option>미션</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md="auto">
                    <label className=" form-control-label">
                    </label>
                    <FormGroup>
                      <InputGroup
                          className={classnames("input-group-merge", {
                            focused: searchCurrent,
                          })}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-search" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                            placeholder="챌린지명으로 검색"
                            type="text"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            onFocus={(e) => setSearchCurrent(true)}
                            onBlur={(e) => setSearchCurrent(false)}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
                {id &&
                  <Row className="py-3">
                    <Col xs="3">
                      {user.role === 'center'
                          ?
                          <h4 className="mb-0">
                            기업명: {companyName}
                          </h4>
                          :
                          <h4 className="mb-0">
                            기업명: {name}
                          </h4>
                      }
                    </Col>
                  </Row>
                }
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">
                      임직원 챌린지 {id? '관리':'리스트'}
                    </h3>
                  </Col>
                  {id &&
                      <Col className="text-right" xs="6">
                        {user.role === 'center'
                          ?
                            <Button
                                className="btn-round btn-icon"
                                color="default"
                                id="tooltip443412080"
                                onClick={(e) => history.push('/admin/challengeMemForm/' + company)}
                            >
                              <span className="btn-inner--text">등록하기</span>
                            </Button>
                          :
                            <Button
                                className="btn-round btn-icon"
                                color="default"
                                id="tooltip443412080"
                                onClick={(e) => history.push('/admin/challengeMemForm/' + id)}
                            >
                              <span className="btn-inner--text">등록하기</span>
                            </Button>
                        }
                      </Col>
                  }
                </Row>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive striped>
                <thead className="thead-light">
                <tr>
                  <th className="text-center">고유번호</th>
                  <th className="text-center">기업명</th>
                  <th className="text-center">카테고리</th>
                  <th className="text-center">챌린지명</th>
                  <th className="text-center">시작날짜</th>
                  <th className="text-center">종료날짜</th>
                  <th className="text-center">참여건</th>
                  <th className="text-center">댓글수</th>
                  <th className="text-center">활성화</th>
                  <th className="text-center">인증리스트</th>
                  <th className="text-center">상세</th>
                </tr>
                </thead>
                <tbody>
                  {data?.map((item, i) => {
                  return [
                    <Fragment key={uuidv4()}>
                      <tr>
                        <td className="text-center">
                          {item.id}
                        </td>
                        <td className="text-center">
                          {item.company? item.company : '-'}
                        </td>
                        <td className="text-center">
                          {item.category? item.category : '-'}
                        </td>
                        <td className="text-center">
                          {item.title}
                        </td>
                        <td className="text-center">
                          {item.startDate}
                        </td>
                        <td className="text-center">
                          {item.endDate}
                        </td>
                        <td className="text-center">
                          {item.join}
                        </td>
                        <td className="text-center">
                          {item.challengeUserCommentCount}
                        </td>
                        <td className="text-center">
                          <label className="custom-toggle custom-toggle-success center">
                            <input
                                checked={item.active}
                                type="checkbox"
                                onChange={(e) => statusChange(item.id, !item.active)}
                            />
                            <span
                                className="custom-toggle-slider rounded-circle"
                                data-label-off="No"
                                data-label-on="Yes"
                            />
                          </label>
                        </td>
                        <td className="text-center">
                          <Button
                              className="btn-outline-default"
                              color="danger"
                              onClick={() => history.push('/admin/certificateList/' + item.id)}
                              size="sm"
                          >
                            <span className="btn-inner--text">상세</span>
                          </Button>
                        </td>
                        <td className="text-center">
                          <Button
                              className="btn-outline-default"
                              color="danger"
                              onClick={() => history.push('/admin/challengeMemDetails/' + item.id + '/' + id)}
                              size="sm"
                          >
                            <span className="btn-inner--text">상세</span>
                          </Button>
                        </td>
                      </tr>
                    </Fragment>
                  ];
                })}
                </tbody>
              </Table>

              <CardFooter className="py-4">
                <PaginationWrapper
                  isActive={pageActive}
                  pageNumber={pageCount}
                  callback={(page) => setPageActive(page)}
                  itemPerPage={10}
                />
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default ChallengeMemDetail;
