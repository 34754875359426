import React, {Fragment, useEffect, useState} from "react";

// reactstrap components
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText, Modal,
  Row,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import CounselingService from "../../../services/counseling.service";
import PaginationWrapper from "../components/pagination";
import {numberWithCommas, paginate} from "../../../utils";
import useHistoryState from "../../../common/useHistoryState";
import classnames from "classnames";
import ReactDatetime from "react-datetime";
import moment from "moment";
import {Link} from "react-router-dom";
import inquiryService from "./service/inquiry.service";
import {useHistory} from "react-router";
import {shopCategory} from "../shop/service/shop.category";

function InquiryList() {
  const history = useHistory();
  const [pageActive, setPageActive] = useHistoryState(1, 'pageActive');
  const [pageCount, setPageCount] = useHistoryState(1, 'pageCount');
  const [data, setData] = useHistoryState([], 'data');
  const [category, setCategory] = useHistoryState('전체', 'category');
  const [startDate, setStartDate] = useHistoryState(moment().format('YYYY-MM-01'), 'startDate');
  const [endDate, setEndDate] = useHistoryState(moment().format('YYYY-MM-DD'), 'endDate');
  const [searchCurrent, setSearchCurrent] = useHistoryState(false, 'searchCurrent');
  const [search, setSearch] = useHistoryState('', 'search');
  const [defaultModal, setDefaultModal] = React.useState(false);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');

  const render = async () => {
    const res = await inquiryService.list({ page: pageActive, diff: '걸음', startDate, endDate });
    if (res.code === 200) {
      console.log(res);
      setPageCount(res.inquiries.count);
      setData(res.inquiries.rows);
    }
  };

  useEffect(() => {
    render();
  }, [category, pageActive, startDate, endDate, search]);

  return (
    <>
      <SimpleHeader name="Tables" parentName="Tables" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col xs="6">
                    <h3 className="mt-2">
                      문의 관리
                    </h3>
                  </Col>
                </Row>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive striped>
                <thead className="thead-light">
                <tr>
                  <th className="text-center">NO.</th>
                  <th className="text-center">제목</th>
                  <th className="text-center">내용</th>
                  <th className="text-center">문의날짜</th>
                  <th className="text-center">성명</th>
                  <th className="text-center">휴대폰번호</th>
                  <th className="text-center">이메일</th>
                  <th className="text-center">관리</th>
                </tr>
                </thead>
                <tbody>
                {data?.map((item, i) => {
                  return [
                    <Fragment key={i}>
                      <tr>
                        <td className="text-center">
                          {item.id}
                        </td>
                        <td className="text-center">
                          {item.title.length > 30 ? item.title.slice(0, 30) + '...' : item.title}
                        </td>
                        <td className="text-center">
                          {item.content.length > 50 ? item.content.slice(0, 50) + '...' : item.content}
                        </td>
                        <td className="text-center">
                          {moment(item.createdAt).format('YYYY.MM.DD HH:mm')}
                        </td>
                        <td className="text-center">
                          {item.user.name}
                        </td>
                        <td className="text-center">
                          {item.user.tel}
                        </td>
                        <td className="text-center">
                          {item.user.email}
                        </td>
                        <td className="text-center">
                          <Button
                              className="btn-outline-danger"
                              onClick={() => history.push('/admin/charityDetail/' + item.id)}
                              size="sm"
                          >
                            <span className="btn-inner--text">상세</span>
                          </Button>
                          <Button
                              className="btn-outline-default"
                              onClick={() => {
                                setDefaultModal(true)
                                setTitle(item.title)
                                setContent(item.content)
                              }}
                              size="sm"
                          >
                            <span className="btn-inner--text">상세</span>
                          </Button>
                        </td>
                      </tr>
                    </Fragment>
                  ];
                })}
                </tbody>
              </Table>

              <CardFooter className="py-4">
                <PaginationWrapper
                  isActive={pageActive}
                  pageNumber={pageCount}
                  callback={(page) => setPageActive(page)}
                  itemPerPage={10}
                />
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      <Modal
          className="modal-dialog-centered"
          isOpen={defaultModal}
          size="m"
          // toggle={() => setDefaultModal(false)} // 배경클릭시 창 닫힘
          fade={false}
          // style={{ minWidth: '40%' }}
      >
        <div className="modal-header">
          <h6 className="modal-title mt-1">
            문의 상세
          </h6>
          <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                setDefaultModal(false)
              }}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Row className="mt-2">
            <Col>
              <h4 className="text-muted mt-2">제목</h4>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <h4 className="">{title}</h4>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <h4 className="text-muted mt-2">내용</h4>
            </Col>
          </Row>
          <Row className="">
            <Col>
              <h4 className="mt-2">{content}</h4>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
}

export default InquiryList;
