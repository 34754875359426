import React, {Fragment, useEffect, useState} from "react";

// reactstrap components
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup, Input,
  InputGroup, InputGroupAddon, InputGroupText,
  Row,
  Table,
} from "reactstrap";
// layout for this page
// core components
import {useHistory} from "react-router";
import {numberWithCommas, telWithCommas} from "../../../utils";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import PaginationWrapper from "../components/pagination";
import useHistoryState from "../../../common/useHistoryState";
import moment from "moment";
import companyRankService from "./service/companyRank.service";
import companyService from "../company/service/company.service";
import ReactDatetime from "react-datetime";
import classnames from "classnames";
import AuthService from "../auth/auth.service";
import charityService from "../charity/service/charity.service";

function CompanyRankList() {
  const history = useHistory();
  const user = typeof window !== 'undefined' ? AuthService.getCurrentUser() : null;
  const [pageActive, setPageActive] = useHistoryState(1, 'pageActive');
  const [pageCount, setPageCount] = useHistoryState(1, 'pageCount');
  const [data, setData] = useHistoryState([], 'data');
  const [searchCurrent, setSearchCurrent] = useHistoryState(false, 'searchCurrent');
  const [search, setSearch] = useHistoryState('', 'search');
  const [startDate, setStartDate] = useHistoryState(moment().format('YYYY-MM-01'), 'startDate');
  const [endDate, setEndDate] = useHistoryState(moment().format('YYYY-MM-DD'), 'endDate');

  const render = async () => {
    const res = await companyRankService.list({ page: pageActive, search, startDate, endDate });
    if (res.code === 200) {
      console.log(res);
      setPageCount(res.list.count);
      setData(res.list.rows);
    }
  };

  useEffect(() => {
    render();
  }, [ pageActive, search, startDate, endDate ]);

  return (
    <>
      <SimpleHeader name="Tables" parentName="Tables" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row className="py-3">
                  <Col xs="3">
                    <label className=" form-control-label py-2">
                    </label>
                    <FormGroup>
                      <InputGroup
                          className={classnames("input-group-merge", {
                            focused: searchCurrent,
                          })}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-search" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                            placeholder="기업명으로 검색"
                            type="text"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            onFocus={(e) => setSearchCurrent(true)}
                            onBlur={(e) => setSearchCurrent(false)}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs="6">
                    <h3 className="mt-2">임직원 활동 리스트</h3>
                  </Col>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive striped>
                <thead className="thead-light">
                <tr>
                  <th className="text-center">기업명</th>
                  <th className="text-center">기업코드</th>
                  <th className="text-center">기부총합(걸음)</th>
                  <th className="text-center">걸음걸이 현황</th>
                  <th className="text-center">관리</th>
                </tr>
                </thead>
                <tbody>
                {data?.map((item, i) => {
                  return [
                    <Fragment key={i}>
                      <tr>
                        <td className="text-center">
                          {item.name}
                        </td>
                        <td className="text-center">
                          {item.code}
                        </td>
                        <td className="text-center">
                          {numberWithCommas(item.serviceStep)}
                        </td>
                        <td className="text-center">
                          {numberWithCommas(item.totalStep)}
                        </td>
                        <td className="text-center">
                          <Button
                              className="btn-outline-default"
                              onClick={() => history.push('/admin/company/detail/' + item.id)}
                              size="sm"
                          >
                            <span className="btn-inner--text">상세</span>
                          </Button>
                        </td>
                      </tr>
                    </Fragment>
                  ];
                })}
                </tbody>
              </Table>

              <CardFooter className="py-4">
                <PaginationWrapper
                  isActive={pageActive}
                  pageNumber={pageCount}
                  callback={(page) => setPageActive(page)}
                  itemPerPage={10}
                />
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default CompanyRankList;
