import axios from "axios";
import api from "../../../../config/api.routes";
import authHeader from "../../../../services/auth-header";
import {atom} from "recoil";

//file
export const companyStoreState = atom({
  key: 'companyStoreState',
  default: [],
});

export const companyStorePreviewState = atom({
  key: 'companyStorePreviewState',
  default: [],
});

export const companyStoreDeletedId = atom({
  key: 'companyStoreDeletedId',
  default: [],
});


const list = (data) => {
  let url = api().apiURL + `/carbonPointHistory/admin/list?`;
  for (let query of Object.keys(data)) {
    if (data[query] === undefined || data[query] === '') {
      continue;
    }
    url += `${query}=${data[query]}&`;
  }
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.get(url, { headers: authHeader() })
      .then(res => res.data)
      .then(res => resolve(res))
      .catch(e => reject(e));
  });
};

const remove = (id) => {
  let url = api().apiURL + `/carbonPointHistory/remove?id=${id}`;
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.delete(url, { headers: authHeader() })
        .then(res => res.data)
        .then(res => resolve(res))
        .catch(e => reject(e));
  });
};

const qrHistoryService = {
  list,
  remove,
}

export default qrHistoryService;
