import React, {Fragment, useEffect, useState} from "react";

// reactstrap components
import {
    Button,
    Card,
    CardFooter,
    CardHeader,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText, Modal,
    Row,
    Table, UncontrolledTooltip,
} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import PaginationWrapper from "../components/pagination";
import {paginate, telWithCommas} from "../../../utils";
import useHistoryState from "../../../common/useHistoryState";
import moment from "moment";
import {useHistory} from "react-router";
import serviceNewsService from "./service/serviceNews.service";
import classnames from "classnames";
import AuthService from "../auth/auth.service";
import {Link} from "react-router-dom";
import charityService from "../charity/service/charity.service";

function ServiceNewsList() {
    const user = typeof window !== 'undefined' ? AuthService.getCurrentUser() : null;
    const history = useHistory();
    const [pageActive, setPageActive] = useHistoryState(1, 'pageActive');
    const [pageCount, setPageCount] = useHistoryState(1, 'pageCount');
    const [data, setData] = useHistoryState([], 'data');
    const [diff, setDiff] = useHistoryState('', 'diff');
    const [searchCurrent, setSearchCurrent] = useHistoryState(false, 'searchCurrent');
    const [search, setSearch] = useHistoryState('', 'search');
    const [english, setEnglish] = useState('한글');

    // console.log('123',user)

    const render = () => {
        let option = { };
        if (english === '영문') {
            option.english = true;
        }
        if (search) {
            option.search = search;
        }
        if (pageCount) {
            option.page = pageActive;
        }
        if (diff) {
            option.diff = 'admin';
        }
        serviceNewsService.list(option)
            .then((res) => {
                if (res.code === 200) {
                    console.log(res);
                    setPageCount(res.serviceNewsList.count);
                    // setData(paginate(res.centers.rows, pageActive, 11));
                    setData(res.serviceNewsList.rows);
                }
            });
    };

    useEffect(() => {
        render();
    }, [search, pageActive, pageCount, english]);

    const remove = async (id) => {
        if (window.confirm('삭제하시겠습니까?')) {
            let res = await serviceNewsService.remove(id);
            console.log(res);
            if (res.code === 200) {
                alert('삭제되었습니다.')
                render();
            }
        }
    };

    return (
        <>
            <SimpleHeader name="Tables" parentName="Tables" />
            <Container className="mt--6" fluid>
                <Row>
                    <div className="col">
                        <Card>
                            <CardHeader className="border-0">
                                <Row>
                                    <Col xs="6">
                                        <h3 className="mt-2">
                                            사회 공헌 주요소식
                                        </h3>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col>
                                        <Button
                                            type="button"
                                            style={english === '한글' ?
                                                { backgroundColor: '#3CC900', color: 'white', border: 'none' }
                                                :
                                                { backgroundColor: '#5c5c5c', color: 'white', border: 'none' }
                                            }
                                            onClick={() => {
                                                setEnglish('한글')
                                            }}
                                        >
                                            한글
                                        </Button>
                                        <Button
                                            type="button"
                                            style={english === '영문' ?
                                                { backgroundColor: '#3CC900', color: 'white', border: 'none' }
                                                :
                                                { backgroundColor: '#5c5c5c', color: 'white', border: 'none' }
                                            }
                                            onClick={() => {
                                                setEnglish('영문')
                                            }}
                                        >
                                            영문
                                        </Button>
                                    </Col>
                                    {user?.role === 'center' ? null : (
                                        <Col className="text-right">
                                            <Button
                                                color="default"
                                                onClick={(e) => history.push('/admin/serviceNewsForm')}
                                            >
                                                등록하기
                                            </Button>
                                        </Col>
                                    )}
                                    {/*{user?.role === 'center' ? null : (*/}
                                    {/* )}*/}
                                </Row>
                            </CardHeader>

                            <Table className="align-items-center table-flush" responsive striped>
                                <thead className="thead-light">
                                    <tr>
                                        <th className="text-center">우선순위</th>
                                        <th className="text-center">제목</th>
                                        <th className="text-center">삭제</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item, i) => {
                                        return [
                                            <Fragment key={i}>
                                                <tr>
                                                    <td className="text-center">
                                                        {item.sortCode}
                                                    </td>
                                                    <td className="text-center">
                                                        {item.title}
                                                    </td>
                                                    <td className="text-center">
                                                        <Button
                                                            className="btn-outline-danger"
                                                            color="danger"
                                                            onClick={() => remove(item.id)}
                                                            size="sm"
                                                        >
                                                            <span className="btn-inner--text">삭제</span>
                                                        </Button>
                                                        <Button
                                                            className="btn-outline-default"
                                                            color="danger"
                                                            onClick={() => history.push('/admin/serviceNewsDetail/' + item.id)}
                                                            size="sm"
                                                        >
                                                            <span className="btn-inner--text">상세</span>
                                                        </Button>
                                                    </td>
                                                </tr>
                                            </Fragment>
                                        ];
                                    })}
                                </tbody>
                            </Table>

                            <CardFooter className="py-4">
                                <PaginationWrapper
                                    isActive={pageActive}
                                    pageNumber={pageCount}
                                    callback={(page) => setPageActive(page)}
                                    itemPerPage={10}
                                />
                            </CardFooter>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
}

export default ServiceNewsList;
