import React, {useEffect, useRef, useState, Fragment} from "react";
// reactstrap components
import {
  Button,
  Card,
  CardBody, CardFooter,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Modal,
  Row, Table,
  UncontrolledTooltip,
} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import {useHistory} from "react-router-dom";
import useHistoryState from "../../../common/useHistoryState";
import moment from "moment";
import PaginationWrapper from "../components/pagination";
import ReactDatetime from "react-datetime";
import InputFile from "../components/InputFile";
import {useRecoilState, useRecoilValue, useResetRecoilState} from "recoil";
import companyService from "../company/service/company.service";
import {FormControl, InputGroup} from "react-bootstrap";
import InputFiles from "../components/InputFiles";
import DaumPostcode from "react-daum-postcode";
import companyCenterService from "./service/companyCenter.service";
import AuthService from "../auth/auth.service";

function ManagerForm({ match }) {
  // const user = typeof window !== 'undefined' ? AuthService.getCurrentUser() : null;
  const history = useHistory();
  // const { id } = match.params;
  const [pageActive, setPageActive] = useHistoryState(1, 'pageActive');
  const [pageCount, setPageCount] = useHistoryState(1, 'pageCount');
  const [groupName, setGroupName] = useState('');
  const [managers, setManagers] = useState([]);
  // console.log(id)

  const createOrUpdate = async () => {

    const json = {
      groupName,
      // centerId: id
    }

    console.log('json',json);

    let res;
    try {
        res = await companyCenterService.groupRegister(json);
      if (res.resultMsg === "success") {
        alert('등록되었습니다.');
        history.goBack();
      }
    } catch (e) {
      // alert(JSON.stringify(e, null, 4));
      alert(e.response?.data?.message);
    }
    console.log('웹', res);
  };

  return (
    <>
      <SimpleHeader name="Form elements" parentName="Forms" />
      <Container className="mt--6" fluid>
        <Card className="mb-4">
          <CardHeader style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h3 className="mt-2">담당자 등록</h3>
            <div>
              <Button
                  color="default"
                  type="button"
                  onClick={createOrUpdate}
              >
                <span className="btn-inner--icon mr-1">
                    <i className="fas fa-user-edit" />
                </span>
                등록하기
              </Button>
            </div>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    담당자 직책
                  </label>
                  <Input
                      placeholder=""
                      type="text"
                      name="groupName"
                      value={groupName || ''}
                      onChange={(e) => setGroupName(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    담당자 이름
                  </label>
                  <Input
                      placeholder="*담당자 이름을 정확히 입력해주세요.(공백없이)"
                      type="text"
                      name="groupName"
                      value={groupName || ''}
                      onChange={(e) => setGroupName(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default ManagerForm;
