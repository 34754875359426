import React, {useRef} from "react";
import {Button, Col, FormGroup, Label, Row,} from "reactstrap";
import {useRecoilState, useSetRecoilState} from "recoil";
import {clickImg} from "../../../utils";
import AuthService from "../auth/auth.service";

function InputFile({ title, fileState, filePreviewState }) {
  const user = typeof window !== 'undefined' ? AuthService.getCurrentUser() : null;
  const setFile = useSetRecoilState(fileState);
  const [previewURL, setPreviewURL] = useRecoilState(filePreviewState);
  const fileRef = useRef();

  const handleFileOnChange = (event) => {
    //파일 불러오기
    event.preventDefault();
    let file = event.target.files[0];
    let reader = new FileReader();

    reader.onloadend = (e) => {
      setFile(file);
      setPreviewURL(reader.result);
    };
    if (file) reader.readAsDataURL(file);
  };


  const handleFileButtonClick = (e) => {
    //버튼 대신 클릭하기
    e.preventDefault();
    fileRef.current.click(); // file 불러오는 버튼을 대신 클릭함
  };

  return (
    <>
      <FormGroup>
          <Label
            className="form-control-label"
            htmlFor="file-input"
          >
            {title}
          </Label>
          <input
            ref={fileRef}
            hidden={true}
            id='file-input'
            type='file'
            onChange={handleFileOnChange}
          />
          {/*{user?.role === 'center' ? null : (*/}
            <Button
              className="ml-3"
              type="button"
              onClick={handleFileButtonClick}
              style={{ backgroundColor: '#2b7aaa', color: 'white', borderColor: '#2b7aaa' }}
            >
              첨부하기
            </Button>
          {/*)}*/}
      </FormGroup>

      <Row>
        <Col className="border mb-4" md="auto">
          {previewURL && (
            <a
              onClick={() => clickImg(previewURL)}
              style={{
                cursor: 'pointer'
              }}
            >
              <img src={previewURL} style={{ height: '150px', marginBottom: '25px', borderRadius: '5px' }}/>
            </a>
          )}
        </Col>
      </Row>
    </>
  );
}

export default InputFile;
