/*!

=========================================================
* NextJS Argon Dashboard PRO - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/nextjs-argon-dashboard-pro
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useEffect} from "react";
// reactstrap components
import {Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Input, Modal, Row,} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import QuestionService from "../../../services/questions.service";
import Choices from "./Choices";
import useHistoryState from "../../../common/useHistoryState";
import {useHistory} from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';

function QuestionForm({ match }) {
  const router = useHistory();
  const { id } = match.params;
  const [disease, setDisease] = useHistoryState('과로/뇌/심장', 'disease');
  const [depth, setDepth] = useHistoryState(1, 'depth');
  const [sortCode, setSortCode] = useHistoryState(null, 'sortCode');
  const [multipleCount, setMultipleCount] = useHistoryState(2, 'multipleCount');
  const [title, setTitle] = useHistoryState('', 'title');
  const [isMultiple, setIsMultiple] = useHistoryState(false, 'isMultiple');
  const [choiceId, setChoiceId] = useHistoryState(null, 'choiceId');
  const [choice, setChoice] = useHistoryState([], 'choice');
  const [questionId, setQuestionId] = useHistoryState(null, 'questionId');
  const [questions, setQuestions] = useHistoryState([], 'questions');
  const [choices, setChoices] = useHistoryState([], 'choices');
  const [defaultModal, setDefaultModal] = useHistoryState(false, 'defaultModal');

  const register = async () => {
    const data = {
      disease,
      depth,
      sortCode,
      title,
      isMultiple,
      choice,
      choiceId
    };

    if (!sortCode || sortCode < 1) {
      alert('우선순위를 입력하지 않았거나 잘못된 값입니다.');
      return;
    }

    if (title.length === 0) {
      alert('질문을 입력해주세요');
      return;
    }

    // if (depth === 2 && isMultiple) {
    //   alert('질문 구분이 2차일때 복수응답은 불가 합니다.');
    //   return;
    // }

    if (isMultiple) {
      if (multipleCount < 2) {
        alert('중복 갯수는 최소 2개 이상 입력 하셔야 합니다.');
        return;
      }

      if (multipleCount > choice.length) {
        alert('중복 갯수는 선택지 보다 낮게 입력하셔야 합니다.');
        return;
      }

      data.multipleCount = multipleCount;
    }

    if (depth === 2 && choiceId === null) {
      alert('선택지를 선택해주세요.');
      return;
    }

    for (let c of choice) {
      if (!c.content) {
        alert('항목 내용이 없습니다.');
        return;
      }

      // if (c.score < 0) {
      //   alert('항목 점수는 0점 이상으로 입력해주세요.');
      //   return;
      // }
    }

    try {
      let res;
      if (id) {
        console.log(id, data);
        res = await QuestionService.update(id, data);
      } else {
        res = await QuestionService.questionRegister(data);
      }
      if (res.data.statusCode === 200) {
        router.goBack();
      } else {
        alert('입력된 값을 확인해주세요.');
      }
    } catch (e) {
      alert('입력된 값을 확인해주세요.');
    }
  }

  async function fetchData() {
    if (id) {
      const res = await QuestionService.getQuestionDetail(id);
      console.log(res.data);
      const data = res.data.data;
      setDisease(() => data.disease);
      setDepth(() =>data.depth);
      if (data.depth === 2) {
        setChoiceId(() => data.choiceId);
        setQuestionId(() => data.questionId);
      }
      setSortCode(() => data.sortCode);
      setTitle(() => data.title);
      setIsMultiple(() => data.isMultiple);
      setChoice(() => data.choice);
      if (data.isMultiple) {
        setMultipleCount(() => data.multipleCount);
      }
    } else {
      setChoice(() => [{content: '', score: 0}]);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  async function listData() {
    if (depth === 2) {
      const { list } = await twoQuestionList();
      setQuestions(() => list);
    }
  }

  useEffect(() => {
    listData();
  }, [depth, disease]);

  async function findChoice() {
    if (questionId) {
      const cho = await questionFindChoice();
      setChoices(() => cho);
    } else {
      setChoices(() => []);
    }
  }

  useEffect( () => {
    findChoice();
  }, [questions, questionId]);

  const twoQuestionList = async () => {
    const quest = await QuestionService.getQuestions(disease);
    const data = quest.data;
    if (data.list.length > 0) {
      const list = data.list.filter(v => v.isMultiple === false);
      return {
        list
      }
    } else {
      return {
        list: []
      }
    }
  }

  const questionFindChoice = async () => {
    let quest = questions.find(q => q.id === questionId);
    if (quest) {
      return quest.choice;
    } else {
      return [];
    }
  }

  const choiceAdd = () => {
    setChoice(() => [...choice, { content: '', score: 0 }]);
  }

  const handleDeleteItem = (index) => {
    if (choice.length > 1) {
      setChoice(() => choice.filter((c, i) => i !== index));
    } else {
      alert('1개는 지울수 없습니다.');
    }
  }

  const removeItem = () => {
    QuestionService.getQuestionRemove(id)
      .then(res => res.data)
      .then(res => {
        if (res.statusCode === 200) {
          router.goBack();
        }
      });
  }

  return (
    <>
      <SimpleHeader name="Form elements" parentName="Forms" />
      <Container className="mt--6" fluid>
        <Card className="mb-4">
          <CardHeader>
            <Row>
              <Col xs="6">
                <h3 className="mb-0">질문관리 {id ? '상세' : '등록'}</h3>
              </Col>
              {id ? "" : (<Col className="text-right" xs="6">
                <Button
                  className="btn-round btn-icon"
                  color="primary"
                  onClick={register}
                  size="sm"
                >
                  <span className="btn-inner--text">등록하기</span>
                </Button>
              </Col>)}
              {id ? (<Col className="text-right" xs="6">
                <Button
                  className="btn-round btn-icon"
                  color="danger"
                  onClick={() => setDefaultModal(() => true)}
                  size="sm"
                >
                  <span className="btn-inner--text">삭제하기</span>
                </Button>
                <Modal
                  className="modal-dialog-centered"
                  isOpen={defaultModal}
                  toggle={() => setDefaultModal(() => false)}
                >
                  <div className="modal-header">
                    <h6 className="modal-title" id="modal-title-default">
                      질문 삭제하기
                    </h6>
                    <button
                      aria-label="Close"
                      className="close"
                      data-dismiss="modal"
                      type="button"
                      onClick={() => setDefaultModal(() => false)}
                    >
                      <span aria-hidden={true}>×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <p className="text-center">
                      정말 삭제하시겠습니까?
                    </p>
                    <p className="text-center">
                      삭제하면 복구 하실 수 없습니다.
                    </p>
                  </div>
                  <div className="modal-footer">
                    <Button
                      color="danger"
                      type="button"
                      onClick={removeItem}
                    >
                      삭제하기
                    </Button>
                    <Button
                      className="ml-auto"
                      color="link"
                      data-dismiss="modal"
                      type="button"
                      onClick={() => setDefaultModal(() => false)}
                    >
                      닫기
                    </Button>
                  </div>
                </Modal>
                <Button
                  className="btn-round btn-icon"
                  color="primary"
                  onClick={register}
                  size="sm"
                >
                  <span className="btn-inner--text">저장하기</span>
                </Button>
              </Col>) : ""}
            </Row>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    병명
                  </label>
                  <Input
                    type="select"
                    value={disease}
                    onChange={(e) => setDisease(() => e.target.value)}
                  >
                    <option>과로/뇌/심장</option>
                    <option>폐암 직업성 암</option>
                    <option>소음성 난청</option>
                    <option>진폐보상</option>
                    <option>진폐유족보상</option>
                    <option>근골격계질병</option>
                    <option>자살자해행위</option>
                    <option>업무상사고</option>
                    <option>만성폐쇠성폐질환 기타 폐질환</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    질문 구분
                  </label>
                  <div className="form-control-label">
                    <Button
                      color={depth === 1 ? "primary" : "secondary"}
                      type="button"
                      onClick={() => setDepth(() => 1)}
                    >
                      1차
                    </Button>
                    <Button
                      color={depth === 2 ? "primary" : "secondary"}
                      type="button"
                      onClick={() => setDepth(() => 2)}
                    >
                      2차
                    </Button>
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="3">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    질문 우선순위
                  </label>
                  <Input
                    type="number"
                    value={sortCode || ''}
                    onChange={(e) => setSortCode(() => Number(e.target.value))}
                  />
                </FormGroup>
              </Col>
            </Row>
            {depth === 2 ? (
              <Row>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                    >
                      질문
                    </label>
                    <Input
                      type="select"
                      onChange={(e) => setQuestionId(() => Number(e.target.value))}
                      value={questionId || ''}
                    >
                      <option value="">선택해주세요.</option>
                      {questions?.map((v, i) => {
                        return (
                          <option key={uuidv4()} value={v.id}>{v.title}</option>
                        )
                      })}
                    </Input>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                    >
                      선택지
                    </label>
                    <Input
                      type="select"
                      onChange={(e) => setChoiceId(() => Number(e.target.value))}
                      value={choiceId || ''}
                    >
                      <option value="">선택해주세요.</option>
                      {choices?.map((v, i) => {
                        return (
                          <option key={uuidv4()} value={v.id}>{v.content}</option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
            ) : ''}
            <Row>
              <Col md="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    질문
                  </label>
                  <Input
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(() => e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="2">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    선택지
                  </label>
                  <div className="custom-control custom-checkbox mb-3">
                    <input
                      className="custom-control-input"
                      type="checkbox"
                      id="customCheck1"
                      checked={isMultiple}
                      onChange={() => setIsMultiple(() => !isMultiple)}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customCheck1"
                    >
                      복수응답
                    </label>
                  </div>
                </FormGroup>
              </Col>
              {isMultiple ? (
                <Col md="2">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                    >
                      중복 허용 갯수
                    </label>
                    <Input
                      type="number"
                      size="sm"
                      value={multipleCount || 2}
                      onChange={(e) => setMultipleCount(() => Number(e.target.value))}
                    />
                  </FormGroup>
                </Col>
              ) : null}
            </Row>
            {choice?.map((item, i) => {
              return [
                <Choices key={uuidv4()} item={item} i={i} handleDeleteItem={(e) => handleDeleteItem(i)}/>
              ]
            })}
            <Row>
              <Col md="2">
                <Button
                  color="primary"
                  type="button"
                  onClick={choiceAdd}
                >
                  추가
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default QuestionForm;
