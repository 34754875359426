import React, {useEffect, useRef, useState, Fragment} from "react";
// reactstrap components
import {
  Button,
  Card,
  CardBody, CardFooter,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Modal,
  Row, Table,
  UncontrolledTooltip,
} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import {useHistory} from "react-router-dom";
import charityService, {
  charityDetailFilePreviewState,
  charityDetailFileState,
  charityFilePreviewState,
  charityFileState, englishDetailFilePreviewState, englishDetailFileState
} from "./service/charity.service";
import useHistoryState from "../../../common/useHistoryState";
import moment from "moment";
import PaginationWrapper from "../components/pagination";
import ReactDatetime from "react-datetime";
import InputFile from "../components/InputFile";
import {useRecoilState, useRecoilValue, useResetRecoilState} from "recoil";

function CharityForm({ match }) {
  const history = useHistory();
  const { id } = match.params;
  const [startDate, setStartDate] = useHistoryState(moment().format('YYYY-MM-DD'), 'startDate');
  const [endDate, setEndDate] = useHistoryState(moment().add(1, 'month').format('YYYY-MM-DD'), 'endDate');
  const [pageActive, setPageActive] = useHistoryState(1, 'pageActive');
  const [pageCount, setPageCount] = useHistoryState(1, 'pageCount');
  const [loading, setLoading] = useState(false);
  const [list, setList] = useHistoryState([], 'list');
  const [image, setImage] = useHistoryState([], 'image');
  const thumbFile = useRecoilValue(charityFileState);
  const [previewThumbURL, setPreviewThumbURL] = useRecoilState(charityFilePreviewState);
  const detailFile = useRecoilValue(charityDetailFileState);
  const [previewDetailURL, setPreviewDetailURL] = useRecoilState(charityDetailFilePreviewState);
  const englishFile = useRecoilValue(englishDetailFileState);
  const [englishDetailURL, setPreviewEnglishURL] = useRecoilState(englishDetailFilePreviewState);
  const resetThumbFile = useResetRecoilState(charityFileState);
  const resetDetailFile = useResetRecoilState(charityDetailFileState);
  const resetEnglishFile = useResetRecoilState(englishDetailFileState);
  const resetThumbFilePreview = useResetRecoilState(charityFilePreviewState);
  const resetDetailFilePreview = useResetRecoilState(charityDetailFilePreviewState);
  const resetEnglishFilePreview = useResetRecoilState(englishDetailFilePreviewState);
  const [data, setData] = useState({
    company: '',
    category: '아동-청소년',
    title: '',
    goalPrice: '',
    code: '',
    goal: '',
    sortCode: '',
    diff: '걸음',
    link: '',
    companyCode: '',
    companyName: '',
  });
  const {
    company,
    category,
    title,
    goalPrice,
    code,
    goal,
    sortCode,
    diff,
    link,
    companyCode,
    companyName,
  } = data;

  const reset = () => {
    resetThumbFile();
    resetDetailFile();
    resetEnglishFile();
    resetThumbFilePreview();
    resetDetailFilePreview()
    resetEnglishFilePreview()
  }

  const onChange = (e) => {
    let { value, name } = e.target;
    if (name === 'sortCode' ||
        name === 'goal' ||
        name === 'userCount'
    ) {
      setData({
        ...data,
        [name]: Number(value.replace(/[^0-9]/g, '')),
      })
    } else {
      setData({
        ...data,
        [name]: value
      });
    }
  };

  const fetchData = async () => {
    const res = await charityService.detail(id);
    if (res.code === 200) {
      console.log(res);
      setData({
        ...res.charity,
        sortCode: res.charity.sortCode === 99999? 0:res.charity.sortCode
      })
      if (res.charity.isAgree) {
        setCheckOneList(['확인'])
      } else {
        setCheckOneList(['미확인'])
      }
      setStartDate(res.charity.startDate)
      setEndDate(res.charity.endDate)
      if(res.charity.thumbnail) {
        setPreviewThumbURL(`https://d2twzv77g3c69u.cloudfront.net/fit-in/1000x700/${res.charity.thumbnail}`)
      }
      if(res.charity.image) {
        setPreviewDetailURL(`https://d2twzv77g3c69u.cloudfront.net/fit-in/1000x700/${res.charity.image}`)
      }
      if(res.charity.english) {
        setPreviewEnglishURL(`https://d2twzv77g3c69u.cloudfront.net/fit-in/1000x700/${res.charity.english}`)
      }
    }
  };

  useEffect(() => {
    if(id) {
      fetchData();
    } else {
      reset();
    }
  }, [pageActive]);

  const handleReactDatetimeChange = (who, date) => {
    if (
        startDate &&
        who === "endDate" &&
        new Date(startDate) > new Date(date.format('YYYY-MM-DD'))
    ) {
      setStartDate(date.format('YYYY-MM-DD'));
      setEndDate(date.format('YYYY-MM-DD'));
    } else if (
        endDate &&
        who === "startDate" &&
        new Date(endDate) < new Date(date.format('YYYY-MM-DD'))
    ) {
      setStartDate(date.format('YYYY-MM-DD'));
      setEndDate(date.format('YYYY-MM-DD'));
    } else {
      if (who === "startDate") {
        setStartDate(date.format('YYYY-MM-DD'));
      } else {
        setEndDate(date.format('YYYY-MM-DD'));
      }
    }
  };

  const getClassNameReactDatetimeDays = (date) => {
    if (startDate && endDate) {
    }
    if (startDate && endDate && startDate !== endDate) {
      if (
          new Date(endDate) > new Date(date.format('YYYY-MM-DD')) &&
          new Date(startDate) < new Date(date.format('YYYY-MM-DD'))
      ) {
        return " middle-date";
      }
      if (endDate === date.format('YYYY-MM-DD')) {
        return " end-date";
      }
      if (startDate === date.format('YYYY-MM-DD')) {
        return " start-date";
      }
    }
    return "";
  };

  const submit = async () => {
    setLoading(true);
    try {
      await createOrUpdate();
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }

  const createOrUpdate = async () => {
    // if (!title) {
    //     alert('제목을 입력해주세요.');
    //     return;
    // }
    // if (!content) {
    //     alert('내용을 입력해주세요.');
    //     return;
    // }
    //
    const json = {
      ...data,
      startDate,
      endDate,
      diff
    }

    // 우선순위
    if(sortCode === 0) {
      json.sortCode = 99999
    }

    // 개인정보동의 체크
    if(checkOneList[0] === '확인') {
      json.isAgree = true
    } else {
      json.isAgree = false
    }

    delete json.isAgreeHistory

    console.log('json',json);

    let res;
    try {
      if (id) {
        delete json.current
        delete json.thumbnail
        delete json.image
        delete json.dDay
        delete json.createdAt
        delete json.totalUserCount
        delete json.english
        delete json.id
        delete json.companyCode
        delete json.companyName
        delete json.totalCount
        res = await charityService.update(id, json);
      } else {
        delete json.companyCode
        delete json.companyName
        res = await charityService.register(json);
      }

      if (res.code === 200) {
        if (thumbFile) {
          let form = new FormData();
          console.log('file', thumbFile, typeof thumbFile);
          form.append('img', thumbFile);
          await charityService.fileRegister(res.charityId, form, 'thumbnail');
        }
        if (detailFile) {
          let form = new FormData();
          console.log('file', detailFile, typeof detailFile);
          form.append('img', detailFile);
          await charityService.fileRegister(res.charityId, form, 'image');
        }
        if (englishFile) {
          let form = new FormData();
          console.log('file', englishFile, typeof englishFile);
          form.append('img', englishFile);
          await charityService.fileRegister(res.charityId, form, 'english');
        }
        alert(id ? '수정되었습니다.' : '등록되었습니다.');
        history.goBack();
        reset();
      }
    } catch (e) {
      // alert(JSON.stringify(e, null, 4));
      alert(e.response?.data?.message);
    }
    console.log('웹', res);
  };

  const remove = async () => {
    if (window.confirm('삭제하시겠습니까?')) {
      let res = await charityService.removeCharity(id);
      console.log(res);
      if (res.code === 200) {
        alert('삭제되었습니다.')
        history.goBack();
      }
    }
  };

  const checks = ['확인', '미확인']
  const [checkOneList, setCheckOneList] = useState(['미확인']);

  const isCheckOne = (value, type, setType) => {
    const index = type.indexOf(value); // value 값의 위치를 찾음.
    if (index > -1) { //  값이 있는 경우, -1은 값이없음.
      setType([
        ...type.filter(v => v !== value)
      ]);
    } else {
      setType([
        value
      ]);
    }
  };

  if (loading) return <div>로딩중..</div>;
  return (
    <>
      <SimpleHeader name="Form elements" parentName="Forms" />
      <Container className="mt--6" fluid>
        <Card className="mb-4">
          <CardHeader style={{ display: 'flex', justifyContent: 'space-between' }}>
            {id?
                <>
                  <h3 className="mt-2">기부 상세</h3>
                  <div>
                    <Button
                      color="default"
                      type="button"
                      onClick={submit}
                    >
                      수정하기
                    </Button>
                    <Button
                      color="danger"
                      type="button"
                      onClick={remove}
                    >
                      삭제하기
                    </Button>
                  </div>
                </>
                :
                <>
                  <h3 className="mt-2">기부 등록</h3>
                  <div>
                    <Button
                        color="default"
                        type="button"
                        onClick={submit}
                    >
                      <span className="btn-inner--icon mr-1">
                          <i className="fas fa-user-edit" />
                      </span>
                      등록하기
                    </Button>
                  </div>
                </>
            }
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    개인정보동의 체크
                  </label>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              {checks.map((v, i) => {
                return <Col md="auto" key={i}>
                  <div className="custom-control custom-checkbox mb-4 mt--3">
                    <input
                        className="custom-control-input"
                        id={"customCheck" + i}
                        type="checkbox"
                        value={v}
                        onClick={() => {
                          isCheckOne(v, checkOneList, setCheckOneList)
                        }}
                        checked={checkOneList.indexOf(v) > -1}
                        readOnly
                    />
                    <label
                        className="custom-control-label"
                        htmlFor={"customCheck" + i}
                    >
                      {v}
                    </label>
                  </div>
                </Col>
              })}
            </Row>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    기부 업체명
                  </label>
                  <Input
                      placeholder="기부 업체명을 입력해주세요."
                      type="text"
                      name="company"
                      value={company || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    카테고리
                  </label>
                  <Input
                      type="select"
                      name="category"
                      value={category || ''}
                      onChange={onChange}
                  >
                    <option>아동-청소년</option>
                    <option>어르신</option>
                    <option>장애인</option>
                    <option>다문화</option>
                    <option>지구촌</option>
                    <option>가족-여성</option>
                    <option>시민사회</option>
                    <option>동물</option>
                    <option>환경</option>
                    <option>기타</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    우선순위
                  </label>
                  <Input
                      placeholder="제목을 입력해주세요."
                      type="text"
                      name="sortCode"
                      value={sortCode || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    제목
                  </label>
                  <Input
                      placeholder="제목을 입력해주세요."
                      type="textarea"
                      rows="5"
                      name="title"
                      value={title || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    기부유형
                  </label>
                  <Input
                      type="select"
                      name="diff"
                      value={diff || ''}
                      onChange={onChange}
                  >
                    <option>걸음</option>
                    <option>시간</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label className=" form-control-label">
                    시작날짜
                  </label>
                  <ReactDatetime
                      inputProps={{
                        placeholder: "",
                      }}
                      value={startDate}
                      locale={'ko'}
                      timeFormat={false}
                      dateFormat={'YYYY.MM.DD'}
                      onChange={(e) =>
                          handleReactDatetimeChange("startDate", e)
                      }
                      renderDay={(props, currentDate, selectedDate) => {
                        let classes = props.className;
                        classes += getClassNameReactDatetimeDays(
                            currentDate
                        );
                        return (
                            <td {...props} className={classes}>
                              {currentDate.date()}
                            </td>
                        );
                      }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label className=" form-control-label">
                    종료날짜
                  </label>
                  <ReactDatetime
                      inputProps={{
                        placeholder: "",
                      }}
                      value={endDate}
                      locale={'ko'}
                      timeFormat={false}
                      dateFormat={'YYYY.MM.DD'}
                      onChange={(e) =>
                          handleReactDatetimeChange("endDate", e)
                      }
                      renderDay={(props, currentDate, selectedDate) => {
                        let classes = props.className;
                        classes += getClassNameReactDatetimeDays(
                            currentDate
                        );
                        return (
                            <td {...props} className={classes}>
                              {currentDate.date()}
                            </td>
                        );
                      }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    목표
                  </label>
                  <Input
                      placeholder="시간 또는 걸음수"
                      type="text"
                      name="goal"
                      value={goal || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    목표금액
                  </label>
                  <Input
                      placeholder="목표금액을 입력해주세요."
                      type="text"
                      name="goalPrice"
                      value={goalPrice}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    유튜브 주소
                  </label>
                  <Input
                      placeholder="유튜브 주소를 입력해주세요."
                      type="text"
                      name="code"
                      value={code || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    관련 링크
                  </label>
                  <Input
                      placeholder="관련 링크를 입력해주세요."
                      type="text"
                      name="link"
                      value={link || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <InputFile
                    title={'대표 이미지 첨부 (720px x 755px)'}
                    fileState={charityFileState}
                    filePreviewState={charityFilePreviewState}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <InputFile
                    title={'상세 내용 이미지 첨부 (720px x 세로자유)'}
                    fileState={charityDetailFileState}
                    filePreviewState={charityDetailFilePreviewState}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <InputFile
                    title={'영문 상세 내용 이미지 첨부 (720px x 세로자유)'}
                    fileState={englishDetailFileState}
                    filePreviewState={englishDetailFilePreviewState}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default CharityForm;
