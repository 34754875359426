import React, {Fragment, useEffect, useState} from "react";

// reactstrap components
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText, Modal,
  Row,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import CounselingService from "../../../services/counseling.service";
import PaginationWrapper from "../components/pagination";
import {numberWithCommas, paginate} from "../../../utils";
import useHistoryState from "../../../common/useHistoryState";
import classnames from "classnames";
import ReactDatetime from "react-datetime";
import moment from "moment";
import {Link} from "react-router-dom";
import companyService from "./service/company.service";
import {useHistory} from "react-router";
import {shopCategory} from "../shop/service/shop.category";
import storeService from "../store/service/store.service";
import noticeService from "../notice/service/notice.service";

function CompanySubList({ match }) {
  const { id } = match.params;
  const { names } = match.params;
  const history = useHistory();
  const [pageActive, setPageActive] = useHistoryState(1, 'pageActive');
  const [pageCount, setPageCount] = useHistoryState(1, 'pageCount');
  const [name, setName] = useState('');
  const [ids, setIds] = useState('');
  const [data, setData] = useHistoryState([], 'data');
  const [defaultModal, setDefaultModal] = useHistoryState(false, 'defaultModal');

  const render = async () => {
    const res = await companyService.subList({ page: pageActive, companyId: Number(id), limit: 10 });
    if (res.code === 200) {
      console.log(res);
      setPageCount(res.list.count);
      setData(res.list.rows);
    }
  };

  useEffect(() => {
    render();
  }, [ pageActive ]);

  const submit = async () => {
    if (!name) {
      alert('지점명을 입력해주세요.');
      return;
    }

    const json = {
      name,
    }


    let res;
    try {
      if (ids) {
        res = await companyService.subUpdate(ids, json);
      } else {
        json.companyId = Number(id)
        res = await companyService.subRegister(json);
      }
      if (res.code === 200) {
        alert(id ? '수정되었습니다.' : '등록되었습니다.');
        setDefaultModal(false);
        setIds('');
        setName('');
        render();
      }
    } catch (e) {
      // alert(JSON.stringify(e, null, 4));
      alert(e.response?.data?.message);
    }
    console.log('웹', res);
  };

  const remove = async () => {
    if (window.confirm('삭제하시겠습니까?')) {
      let res = await companyService.subRemove(Number(ids));
      console.log(res);
      if (res.code === 200) {
        setDefaultModal(false);
        setIds('');
        setName('');
        render();
      }
    }
  };

  return (
    <>
      <SimpleHeader name="Tables" parentName="Tables" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col xs="6">
                    <h3 className="mt-2">
                      기업 관리 : {names}
                    </h3>
                  </Col>
                  <Col className="text-right" xs="6">
                    <Button
                        className="btn-round btn-icon"
                        color="default"
                        onClick={() => setDefaultModal(true)}
                    >
                      <span className="btn-inner--text">등록하기</span>
                    </Button>
                  </Col>
                </Row>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive striped>
                <thead className="thead-light">
                <tr>
                  <th className="text-center">등록 날짜</th>
                  <th className="text-center">지점명</th>
                  <th className="text-center">관리</th>
                </tr>
                </thead>
                <tbody>
                {data?.map((item, i) => {
                  return [
                    <Fragment key={i}>
                      <tr>
                        <td className="text-center">
                          {moment(item.createdAt).format('YYYY-MM-DD')}
                        </td>
                        <td className="text-center">
                          {item.name}
                        </td>
                        <td className="text-center">
                          <Button
                              className="btn-outline-default"
                              onClick={() => {
                                setDefaultModal(true)
                                setName(item.name)
                                setIds(item.id)
                              }}
                              size="sm"
                          >
                            <span className="btn-inner--text">상세</span>
                          </Button>
                        </td>
                      </tr>
                    </Fragment>
                  ];
                })}
                </tbody>
              </Table>

              <CardFooter className="py-4">
                <PaginationWrapper
                  isActive={pageActive}
                  pageNumber={pageCount}
                  callback={(page) => setPageActive(page)}
                  itemPerPage={10}
                />
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      <Modal
          className="modal-dialog-centered"
          isOpen={defaultModal}
          // toggle={() => setDefaultModal(() => false)}
          fade={false}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            지점 관리
          </h6>
          <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                setDefaultModal(false)
                setName("")
                setIds("")
              }}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <Col md="auto" className="mt-2">
              <h4>지점명</h4>
            </Col>
            <Col>
              <Input
                  placeholder=""
                  type="text"
                  name="name"
                  value={name || ''}
                  onChange={(e) => setName(e.target.value)}
              />
            </Col>
          </Row>
        </div>
        {ids
        ?
            <Row className="p-4">
              <Col className="text-right">
                <Button
                    color="default"
                    type="button"
                    onClick={submit}
                >
                  수정하기
                </Button>
                <Button
                    color="danger"
                    type="button"
                    onClick={remove}
                >
                  삭제하기
                </Button>
              </Col>
            </Row>
        :
            <Row className="p-4">
              <Col className="text-right">
                <Button
                    color="default"
                    type="button"
                    onClick={submit}
                >
                  등록하기
                </Button>
              </Col>
            </Row>
        }

      </Modal>
    </>
  );
}

export default CompanySubList;
