import React, {Fragment, useEffect, useState} from "react";

// reactstrap components
import {
    Button,
    Card,
    CardFooter,
    CardHeader,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row,
    Table,
    UncontrolledTooltip,
} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import CounselingService from "../../../services/counseling.service";
import PaginationWrapper from "../components/pagination";
import {paginate} from "../../../utils";
import useHistoryState from "../../../common/useHistoryState";
import classnames from "classnames";
import ReactDatetime from "react-datetime";
import moment from "moment";
import {Link} from "react-router-dom";
import {shopCategory} from "../shop/service/shop.category";
import {useHistory} from "react-router";
import donationService from "./donation.service";
import AuthService from "../auth/auth.service";

function DonationCompanyList({ match }) {
    const history = useHistory();
    const { id } = match.params;
    const [user, setUser] = useState(1);
    const [pageActive, setPageActive] = useHistoryState(1, 'pageActive');
    const [pageCount, setPageCount] = useHistoryState(1, 'pageCount');
    const [data, setData] = useHistoryState([], 'data');
    const [startDate, setStartDate] = useHistoryState(moment().add(-1, 'month').format('YYYY-MM-DD'), 'startDate');
    const [endDate, setEndDate] = useHistoryState(moment().format('YYYY-MM-DD'), 'endDate');
    const [searchCurrent, setSearchCurrent] = useHistoryState(false, 'searchCurrent');
    const [search, setSearch] = useHistoryState('', 'search');
    console.log(user);

    useEffect(() => {
        setUser(JSON.parse(localStorage.getItem('user')).name)
    }, []);

    const handleReactDatetimeChange = (who, date) => {
        if (
            startDate &&
            who === "endDate" &&
            new Date(startDate) > new Date(date.format('YYYY-MM-DD'))
        ) {
            setStartDate(date.format('YYYY-MM-DD'));
            setEndDate(date.format('YYYY-MM-DD'));
        } else if (
            endDate &&
            who === "startDate" &&
            new Date(endDate) < new Date(date.format('YYYY-MM-DD'))
        ) {
            setStartDate(date.format('YYYY-MM-DD'));
            setEndDate(date.format('YYYY-MM-DD'));
        } else {
            if (who === "startDate") {
                setStartDate(date.format('YYYY-MM-DD'));
            } else {
                setEndDate(date.format('YYYY-MM-DD'));
            }
        }
    };

    const getClassNameReactDatetimeDays = (date) => {
        if (startDate && endDate) {
        }
        if (startDate && endDate && startDate !== endDate) {
            if (
                new Date(endDate) > new Date(date.format('YYYY-MM-DD')) &&
                new Date(startDate) < new Date(date.format('YYYY-MM-DD'))
            ) {
                return " middle-date";
            }
            if (endDate === date.format('YYYY-MM-DD')) {
                return " end-date";
            }
            if (startDate === date.format('YYYY-MM-DD')) {
                return " start-date";
            }
        }
        return "";
    };

    const render = () => {
        let option = { charityId: id };
        if (startDate && endDate) {
            option = {
                ...option,
                startDate,
                endDate
            }
        }
        if (search) {
            option = {
                ...option,
                search
            }
        }
        donationService.charityHistoryDetail(option)
            .then((res) => {
                if (res.code === 200) {
                    console.log(res);
                    setPageCount(res.list.rows.length);
                    setData(paginate(res.list.rows, pageActive, 10));
                }
            });
    }

    const down = () => {
        let option = { charityId: id };
        if (startDate && endDate) {
            option = {
                ...option,
                startDate,
                endDate
            }
        }
        if (search) {
            option = {
                ...option,
                search
            }
        }
        option.excel = 'download';
        donationService.charityHistoryDetailDown(option)
          .then((res) => {
              fileCreate(res);
          });
    }

    const fileCreate = (res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        const contentDisposition = res.headers['content-disposition']; // 파일 이름
        let fileName = 'report';
        if (contentDisposition) {
            const [ fileNameMatch ] = contentDisposition.split(';').filter(str => str.includes('filename'));
            if (fileNameMatch)
                [ , fileName ] = fileNameMatch.split('=');
        }
        link.href = url;
        link.setAttribute('download', `${fileName}.xlsx`);
        link.style.cssText = 'display:none';
        document.body.appendChild(link);
        link.click();
        link.remove();
    }

    useEffect(() => {
        render();
        setUser(JSON.parse(localStorage.getItem('user')).name)
    }, [pageActive, startDate, endDate, search]);

    useEffect(() => {
        setPageActive(1);
    }, [search]);

    const statusChange = async (id, active) => {
        await donationService.update(id, {active});
        render();
    }

    return (
        <>
            <SimpleHeader name="Tables" parentName="Tables" />
            <Container className="mt--6" fluid>
                <Row>
                    <div className="col">
                        <Card>
                            <CardHeader className="border-0">
                                <Row className="py-3">
                                    <Col xs="11">
                                        <Form>
                                            <Row>
                                                <Col xs="3">
                                                    <label className=" form-control-label py-2">
                                                    </label>
                                                    <FormGroup>
                                                        <InputGroup
                                                            className={classnames("input-group-merge", {
                                                                focused: searchCurrent,
                                                            })}
                                                        >
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText>
                                                                    <i className="fas fa-search" />
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input
                                                                placeholder="이름, ID로 검색"
                                                                type="text"
                                                                value={search}
                                                                onChange={(e) => setSearch(e.target.value)}
                                                                onFocus={(e) => setSearchCurrent(true)}
                                                                onBlur={(e) => setSearchCurrent(false)}
                                                            />
                                                        </InputGroup>
                                                    </FormGroup>
                                                </Col>
                                                <Col xs="2">
                                                    <label className=" form-control-label">
                                                        날짜
                                                    </label>
                                                    <FormGroup>
                                                        <ReactDatetime
                                                            inputProps={{
                                                                placeholder: "",
                                                            }}
                                                            value={startDate}
                                                            locale={'ko'}
                                                            timeFormat={false}
                                                            dateFormat={'YYYY-MM-DD'}
                                                            onChange={(e) =>
                                                                handleReactDatetimeChange("startDate", e)
                                                            }
                                                            renderDay={(props, currentDate, selectedDate) => {
                                                                let classes = props.className;
                                                                classes += getClassNameReactDatetimeDays(
                                                                    currentDate
                                                                );
                                                                return (
                                                                    <td {...props} className={classes}>
                                                                        {currentDate.date()}
                                                                    </td>
                                                                );
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col xs="2">
                                                    <FormGroup>
                                                        <label className=" form-control-label py-2">
                                                        </label>
                                                        <ReactDatetime
                                                            inputProps={{
                                                                placeholder: "",
                                                            }}
                                                            value={endDate}
                                                            locale={'ko'}
                                                            timeFormat={false}
                                                            dateFormat={'YYYY-MM-DD'}
                                                            onChange={(e) =>
                                                                handleReactDatetimeChange("endDate", e)
                                                            }
                                                            renderDay={(props, currentDate, selectedDate) => {
                                                                let classes = props.className;
                                                                classes += getClassNameReactDatetimeDays(
                                                                    currentDate
                                                                );
                                                                return (
                                                                    <td {...props} className={classes}>
                                                                        {currentDate.date()}
                                                                    </td>
                                                                );
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="6">
                                        <h3 className="mt-2">기부 관리 내역</h3>
                                    </Col>
                                    {JSON.parse(localStorage.getItem('user')).name === '한국장학재단' ?
                                        <Col className="text-right">
                                            {/*<Button color="primary" onClick={(e) => down()}>*/}
                                            {/*    엑셀 다운로드*/}
                                            {/*</Button>*/}
                                        </Col>
                                        :
                                        <Col className="text-right">
                                            <Button color="primary" onClick={(e) => down()}>
                                              엑셀 다운로드
                                            </Button>
                                            {/*<Button color="default" onClick={(e) => history.push('/admin/donationForm')}>*/}
                                            {/*    등록하기*/}
                                            {/*</Button>*/}
                                        </Col>
                                    }
                                </Row>
                            </CardHeader>

                            <Table className="align-items-center table-flush" responsive striped>
                                <thead className="thead-light">
                                    <tr>
                                        <th className="text-center">기부 날짜</th>
                                        <th className="text-center">아이디</th>
                                        <th className="text-center">회원명</th>
                                        <th className="text-center">생년월일</th>
                                        <th className="text-center">휴대폰번호</th>
                                        <th className="text-center">기부내역</th>
                                        <th className="text-center">총 누적 기부내역</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {data?.map((item, i) => {
                                    return [
                                        <Fragment key={i}>
                                            <tr>
                                                <td className="text-center">
                                                    {item.createdAt}
                                                </td>
                                                <td className="text-center">
                                                    {item.user.loginId}
                                                </td>
                                                <td className="text-center">
                                                    {item.user.name}
                                                </td>
                                                <td className="text-center">
                                                    {item.user.birth}
                                                </td>
                                                <td className="text-center">
                                                    {item.user.tel}
                                                </td>
                                                <td className="text-center">
                                                    {item.count}
                                                </td>
                                                <td className="text-center">
                                                    {item.totalCount}
                                                </td>
                                            </tr>
                                        </Fragment>
                                    ];
                                })}
                                </tbody>
                            </Table>

                            <CardFooter className="py-4">
                                <PaginationWrapper
                                    isActive={pageActive}
                                    pageNumber={pageCount}
                                    callback={(page) => setPageActive(page)}
                                    itemPerPage={10}
                                />
                            </CardFooter>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
}

export default DonationCompanyList;
