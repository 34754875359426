import React, {Fragment, useEffect, useState} from "react";

// reactstrap components
import {
    Button,
    Card,
    CardFooter,
    CardHeader,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText, Modal,
    Row,
    Table,
    UncontrolledTooltip,
} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import PaginationWrapper from "../components/pagination";
import useHistoryState from "../../../common/useHistoryState";
import classnames from "classnames";
import moment from "moment";
import {useHistory} from "react-router";
import AuthService from "../auth/auth.service";
import pollutionService from "./service/pollution.service";
import MapPop from "./mapPop";

function PollutionList() {
    const user = typeof window !== 'undefined' ? AuthService.getCurrentUser() : null;
    const company = JSON.parse(localStorage.getItem('user'))?.companyId
    const history = useHistory();
    const [pageActive, setPageActive] = useHistoryState(1, 'pageActive');
    const [pageCount, setPageCount] = useHistoryState(1, 'pageCount');
    const [data, setData] = useHistoryState([], 'data');
    const [rankData, setRankData] = useState([]);
    const [startDate, setStartDate] = useHistoryState(moment().format('YYYY-MM-01'), 'startDate');
    const [endDate, setEndDate] = useHistoryState(moment().format('YYYY-MM-DD'), 'endDate');
    const [searchCurrent, setSearchCurrent] = useHistoryState(false, 'searchCurrent');
    const [search, setSearch] = useHistoryState('', 'search');
    const [diff, setDiff] = useState('');
    const [ids, setIds] = useState();
    const [defaultModal, setDefaultModal] = useState(false);

    const render = async () => {
        let option = {page: pageActive, search, limit: 10};
        if(user.role === 'center') {
            option.companyId = company
        }
        const res = await pollutionService.list(option);
        if (res.code === 200) {
            console.log(res);
            setPageCount(res.list?.length);
            setData(res.list);
        }
    };

    useEffect(() => {
        render();
    }, [pageActive, startDate, endDate, search]);

    return (
        <>

            <SimpleHeader name="Tables" parentName="Tables" />
            <Container className="mt--6" fluid>
                <Row>
                    <div className="col">
                        <Card>
                            <CardHeader className="border-0">
                                <Row className="py-3">
                                    <Col xs="11">
                                        <Form>
                                            <Row>
                                                <Col xs="3">
                                                    <label className=" form-control-label py-2">
                                                    </label>
                                                    <FormGroup>
                                                        <InputGroup
                                                            className={classnames("input-group-merge", {
                                                                focused: searchCurrent,
                                                            })}
                                                        >
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText>
                                                                    <i className="fas fa-search" />
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input
                                                                placeholder="캠페인명으로 검색"
                                                                type="text"
                                                                value={search}
                                                                onChange={(e) => setSearch(e.target.value)}
                                                                onFocus={(e) => setSearchCurrent(true)}
                                                                onBlur={(e) => setSearchCurrent(false)}
                                                            />
                                                        </InputGroup>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="6">
                                        <h3 className="mt-2">악취오염 캠페인</h3>
                                    </Col>
                                    {user?.role === 'center' ? null : (
                                        <Col className="text-right">
                                            <Button color="default" onClick={(e) => history.push('/admin/pollutionForm')}>
                                                등록하기
                                            </Button>
                                        </Col>
                                    )}
                                </Row>
                            </CardHeader>

                            <Table className="align-items-center table-flush" responsive striped>
                                <thead className="thead-light">
                                <tr>
                                    <th className="text-center">No.</th>
                                    <th className="text-center">활동코드</th>
                                    <th className="text-center">관리자 공유 기업코드</th>
                                    <th className="text-center">활동명</th>
                                    <th className="text-center">기업활동기간</th>
                                    <th className="text-center">미션내용</th>
                                    <th className="text-center">미션링크</th>
                                    <th className="text-center">지도내역</th>
                                    <th className="text-center">내역</th>
                                    <th className="text-center">관리</th>
                                </tr>
                                </thead>
                                <tbody>
                                {data?.map((item, i) => {
                                    return [
                                        <Fragment key={i}>
                                            <tr>
                                                <td className="text-center">
                                                    {item.id}
                                                </td>
                                                <td className="text-center">
                                                    {item.code}
                                                </td>
                                                <td className="text-center">
                                                    {item?.companyCode}
                                                </td>
                                                <td className="text-center">
                                                    {item?.title}
                                                </td>
                                                <td className="text-center">
                                                    {item?.startDate} ~ {item?.endDate}
                                                </td>
                                                <td className="text-center">
                                                    {item.mission?.length > 30
                                                        ? item.mission.substring(0, 30) + " ...."
                                                        : item.mission
                                                    }
                                                </td>
                                                <td className="text-center">
                                                    {item?.link? item?.link : '-'}
                                                </td>
                                                <td className="text-center">
                                                    <Button
                                                        className="btn-outline-default"
                                                        onClick={() => {
                                                            setDefaultModal(true)
                                                            setIds(item.id)
                                                        }}
                                                        size="sm"
                                                    >
                                                        <span className="btn-inner--text">보기</span>
                                                    </Button>
                                                </td>
                                                <td className="text-center">
                                                    <Button
                                                        className="btn-outline-default"
                                                        onClick={() => {
                                                            history.push('/admin/pollutionHistory/' + item.id + '/' + item.title)}
                                                        }
                                                        size="sm"
                                                    >
                                                        <span className="btn-inner--text">보기</span>
                                                    </Button>
                                                </td>
                                                <td className="text-center">
                                                    <Button
                                                        className="btn-outline-default"
                                                        onClick={() => history.push('/admin/pollutionDetail/' + item.id)}
                                                        size="sm"
                                                    >
                                                        <span className="btn-inner--text">상세</span>
                                                    </Button>
                                                </td>
                                            </tr>
                                        </Fragment>
                                    ];
                                })}
                                </tbody>
                            </Table>

                            <CardFooter className="py-4">
                                <PaginationWrapper
                                    isActive={pageActive}
                                    pageNumber={pageCount}
                                    callback={(page) => setPageActive(page)}
                                    itemPerPage={10}
                                />
                            </CardFooter>
                        </Card>
                    </div>
                </Row>
            </Container>
            <Modal
                className="modal-dialog-centered"
                isOpen={defaultModal}
                style={{ minWidth: '80%' }}
                fade={false}
            >
                <div className="modal-header">
                    <h6 className="modal-title mt-2">
                        지도내역
                    </h6>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => {
                            setDefaultModal(false)
                        }}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <MapPop
                        id={ids}
                    />
                </div>
            </Modal>
        </>
    );
}

export default PollutionList;
