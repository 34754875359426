/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Alternative from "views/pages/dashboards/Alternative.js";
import Buttons from "views/pages/components/Buttons.js";
import Calendar from "views/pages/Calendar.js";
import Cards from "views/pages/components/Cards.js";
import Charts from "views/pages/Charts.js";
import Components from "views/pages/forms/Components.js";
import Dashboard from "views/pages/dashboards/Dashboard.js";
import Elements from "views/pages/forms/Elements.js";
import Grid from "views/pages/components/Grid.js";
import Icons from "views/pages/components/Icons.js";
import Lock from "views/pages/examples/Lock.js";
import Login from "views/pages/auth/Login.js";
import Notifications from "views/pages/components/Notifications.js";
import Pricing from "views/pages/examples/Pricing.js";
import Profile from "views/pages/examples/Profile.js";
import ReactBSTables from "views/pages/tables/ReactBSTables.js";
import Register from "views/pages/examples/Register.js";
import RTLSupport from "views/pages/examples/RTLSupport.js";
import Sortable from "views/pages/tables/Sortable.js";
import Tables from "views/pages/tables/Tables.js";
import Timeline from "views/pages/examples/Timeline.js";
import Typography from "views/pages/components/Typography.js";
import Validation from "views/pages/forms/Validation.js";
import Widgets from "views/pages/Widgets.js";
import QuestionForm from "./views/pages/questions/Form.js";
import ShopUserList from "./views/pages/shop/shopUserList";
import ShopCouponList from "./views/pages/shop/shopCouponList";
import ShopReviewList from "./views/pages/shop/shopReviewList";
import RegionAdvertList from "./views/pages/regionAdvert/regionAdvertList";
import RegionAdvertPaymentList from "./views/pages/regionAdvert/regionAdvertPaymentList";
import BoardList from "./views/pages/board/boardList";
import ShopForm from "./views/pages/shop/shopForm";
import ShopCouponFrom from "./views/pages/shop/shopCouponForm";
import ShopReviewDetail from "./views/pages/shop/shopReviewDetail";
import BoardDetail from "./views/pages/board/boardDetail";
import ShopMenu from "./views/pages/shop/shopMenu";
import ShopMenuForm from "./views/pages/shop/shopMenuForm";
import DonationList from "./views/pages/donation/donationList";
import DonationForm from "./views/pages/donation/donationForm";
import DonationCompanyList from "./views/pages/donation/donationCompanyList";
import UserList from "./views/pages/user/userList";
import StepList from "./views/pages/user/stepList";
import StepForm from "./views/pages/user/stepForm";
import ChallengeMem from "./views/pages/carbon/challengeMem";
import PloggingList from "./views/pages/plogging/ploggingList";
import CenterList from "./views/pages/center/centerList";
import CenterForm from "./views/pages/center/centerForm";
import VolunteerList from "./views/pages/volunteer/volunteerList";
import VolunteerForm from "./views/pages/volunteer/volunteerForm";
import CharityList from "./views/pages/charity/charityList";
import CharityForm from "./views/pages/charity/charityForm";
import CharityRankList from "./views/pages/charityRank/charityRankList";
import SnsList from "./views/pages/sns/snsList";
import ServiceNewsList from "./views/pages/serviceNews/serviceNewsList";
import StoreList from "./views/pages/store/storeList";
import FoavNewsList from "./views/pages/foavNews/foavNewsList";
import StaffList from "./views/pages/staff/staffList";
import OrderList from "./views/pages/order/orderList";
import AllianceList from "./views/pages/alliance/allianceList";
import AdvertisementList from "./views/pages/advertisments/advertisementList";
import NoticeList from "./views/pages/notice/noticeList";
import InquiryList from "./views/pages/inquiry/inquiryList";
import ReviewList from "./views/pages/review/reviewList";
import DeviceList from "./views/pages/device/deviceList";
import CompanyList from "./views/pages/company/companyList";
import CompanyStoreList from "./views/pages/companyStore/companyStoreList";
import CompanyCenterList from "./views/pages/companyCenter/companyCenterList";
import CompanyCharityList from "./views/pages/companyCharity/companyCharityList";
import CompanyRankList from "./views/pages/companyRank/companyRankList";
import CarbonTabList from "./views/pages/carbonTab/carbonTabList";
import UserForm from "./views/pages/user/userForm";
import CharityHistoryList from "./views/pages/charity/charityHistoryList";
import ServiceNewsForm from "./views/pages/serviceNews/serviceNewsForm";
import FoavNewsForm from "./views/pages/foavNews/foavNewsForm";
import StaffForm from "./views/pages/staff/staffForm";
import AllianceForm from "./views/pages/alliance/allianceForm";
import ActivityForm from "./views/pages/carbonTab/component/activityForm";
import LivingForm from "./views/pages/carbonTab/component/livingForm";
import ActivityCompanyForm from "./views/pages/carbonTab/component/activityCompanyForm";
import ChallengeMemDetail from "./views/pages/carbon/challengeMemDetail";
import ChallengeMemForm from "./views/pages/carbon/challengeMemForm";
import CertificateList from "./views/pages/carbon/certificateList";
import CompanyForm from "./views/pages/company/companyForm";
import CompanyStoreForm from "./views/pages/companyStore/companyStoreForm";
import QrCountList from "./views/pages/qrCount/qrCountList";
import QrDiscountList from "./views/pages/qrDiscount/qrDiscountList";
import QrCountForm from "./views/pages/qrCount/qrCountForm";
import QrDiscountForm from "./views/pages/qrDiscount/qrDiscountForm";
import QrHistoryList from "./views/pages/qrHistory/qrHistoryList";
import AdvertisementForm from "./views/pages/advertisments/advertisementForm";
import TogetherChallenge from "./views/pages/togetherChallenge/togetherChallenge";
import CommentList from "./views/pages/carbon/commentList";
import TogetherChallengeForm from "./views/pages/togetherChallenge/togetherChallengeForm";
import ChallengeRank from "./views/pages/challengeRank/challengeRank";
import RegionAdvertForm from "./views/pages/regionAdvert/regionAdvertForm";
import Push from "./views/pages/push/List";
import NoticeForm from "./views/pages/notice/noticeForm";
import CompanyRankDetail from "./views/pages/companyRank/companyRankDetail";
import CompanyCenterForm from "./views/pages/companyCenter/companyCenterForm";
import CompanyDetail from "./views/pages/companyCenter/companyDetail";
import GroupForm from "./views/pages/companyCenter/groupForm";
import ChallengeMemList from "./views/pages/carbon/challengeMemList";
import CenterDetail from "./views/pages/center/centerDetail";
import CompanySubList from "./views/pages/company/companySubList";
import CompanyGroupList from "./views/pages/companyGroup/companyGroupList";
import CompanyGroupForm from "./views/pages/companyGroup/companyGroupForm";
import PloggingCenter from "./views/pages/plogging/ploggingCenter";
import PloggingCenterDetail from "./views/pages/plogging/ploggingCenterDetail";
import PloggingCompany from "./views/pages/plogging/ploggingCompany";
import PollutionList from "./views/pages/pollution/pollutionList";
import PollutionForm from "./views/pages/pollution/pollutionForm";
import EcoFriendsList from "./views/pages/ecoFriends/ecoFriendsList";
import EcoFriendsForm from "./views/pages/ecoFriends/ecoFriendsForm";
import EcoFriendsHistoryList from "./views/pages/ecoFriendsHistory/ecoFriendsHistoryList";
import PollutionHistoryList from "./views/pages/pollution/pollutionHistoryList";
import QnaCategoryList from "./views/pages/qna/qnaCategoryList";
import QnaList from "./views/pages/qna/qnaList";
import QnaForm from "./views/pages/qna/qnaForm";
import MarketList from "./views/pages/market/marketList";
import MarketForm from "./views/pages/market/marketForm";
import SharedPloggingMgmt from "./views/pages/plogging/sharedPloggingMgmt";
import SharedPloggingCalcMgmt from "./views/pages/plogging/sharedPloggingCalcMgmt";
import SharedPloggingCalcMgmtPloggingList from "./views/pages/plogging/sharedPloggingCalcMgmtPloggingList";
import SharedPloggingPaymentMgmt from "./views/pages/plogging/sharedPloggingPaymentMgmt";

const company = JSON.parse(localStorage.getItem('user'))?.companyId
// console.log(company)

let routes = [
  {
    path: "/dashboard",
    name: "홈",
    icon: "",
    layout: "/admin",
    component: Dashboard,
  },
  {
    path: "/login",
    redirect: true,
    component: Login,
    layout: "/auth",
  },

  {
    path: "/userList",
    name: "유저 관리",
    icon: "",
    layout: "/admin",
    component: UserList,
  },
  {
    path: "/userForm",
    layout: "/admin",
    redirect: true,
    component: UserForm,
  },
  {
    path: "/userDetail/:id",
    layout: "/admin",
    redirect: true,
    component: UserForm,
  },
  {
    path: "/stepList/:id",
    layout: "/admin",
    redirect: true,
    component: StepList,
  },
  {
    path: "/stepForm",
    layout: "/admin",
    redirect: true,
    component: StepForm,
  },
  {
    path: "/stepDetail/:id",
    layout: "/admin",
    redirect: true,
    component: StepForm,
  },

  {
    path: "/charityList",
    name: "기부 관리",
    icon: "",
    layout: "/admin",
    component: CharityList,
  },
  {
    path: "/charityForm",
    layout: "/admin",
    redirect: true,
    component: CharityForm,
  },
  {
    path: "/charityDetail/:id",
    layout: "/admin",
    redirect: true,
    component: CharityForm,
  },
  {
    path: "/charityHistory/:id",
    layout: "/admin",
    redirect: true,
    component: CharityHistoryList,
  },
  {
    path: "/charityRankList",
    name: "기부랭킹 리스트",
    icon: "",
    layout: "/admin",
    component: CharityRankList,
  },
  {
    path: "/inquiryList",
    name: "문의하기",
    icon: "",
    layout: "/admin",
    component: InquiryList,
  },
  {
    path: "/pushList",
    name: "푸시알림",
    icon: "",
    layout: "/admin",
    component: Push,
  },
  {
    path: "/stepList/:id",
    layout: "/admin",
    redirect: true,
    component: StepList,
  },
  {
    path: "/stepForm",
    layout: "/admin",
    redirect: true,
    component: StepForm,
  },
  {
    path: "/stepDetail/:id",
    layout: "/admin",
    redirect: true,
    component: StepForm,
  },
  {
    path: "/deviceList",
    name: "중복 로그인 관리",
    icon: "",
    layout: "/admin",
    component: DeviceList,
  },
  {
    collapse: true,
    name: "자원봉사센터 관리",
    icon: "ni ni-align text-white",
    state: "centerState",
    views: [
      {
        path: "/centerList",
        name: "봉사센터 관리",
        miniName: "봉",
        icon: "ml-4",
        layout: "/admin",
        component: CenterList,
      },
      {
        path: "/centerForm",
        layout: "/admin",
        redirect: true,
        component: CenterForm,
      },
      {
        path: "/centerDetail/:id",
        layout: "/admin",
        redirect: true,
        component: CenterDetail,
      },
      {
        path: "/volunteerList",
        name: "봉사 관리",
        miniName: "봉",
        icon: "ml-4",
        layout: "/admin",
        component: VolunteerList,
      },
      {
        path: "/volunteerDetail/:id",
        layout: "/admin",
        redirect: true,
        component: VolunteerForm,
      },
      {
        path: "/ploggingList/:centerId/:companyId",
        name: "인증 플로깅 개별 리스트",
        miniName: "플",
        icon: "ml-4",
        layout: "/admin",
        component: PloggingList,
      },
      {
        path: "/ploggingCompany",
        name: "인증 플로깅 기업전달 리스트",
        miniName: "플",
        icon: "ml-4",
        layout: "/admin",
        component: PloggingCompany,
      },
      // {
      //   path: "/ploggingCenter/detail/:id/:name",
      //   layout: "/admin",
      //   redirect: true,
      //   component: PloggingCenterDetail,
      // },
      {
        path: "/ploggingCenter",
        name: "인증 플로깅 센터전달 리스트",
        miniName: "플",
        icon: "ml-4",
        layout: "/admin",
        component: PloggingCenter,
      },
      {
        path: "/sharingPlogging",
        name: "나눔플로깅 관리",
        miniName: "나",
        icon: "ml-4",
        layout: "/admin",
        component: SharedPloggingMgmt,
      },
      {
        path: "/sharedPloggingCalcMgmt",
        name: "나눔플로깅 리스트 및 정산관리",
        miniName: "나",
        icon: "ml-4",
        layout: "/admin",
        component: SharedPloggingCalcMgmt,
      },
      {
        path: "/sharedPloggingCalcMgmtPlogging/:sharingPloggingId/:centerName",
        layout: "/admin",
        redirect: true,
        component: SharedPloggingCalcMgmtPloggingList,
      },
      {
        path: "/sharePloggingPayment",
        name: "나눔플로깅 결제관리",
        miniName: "나",
        icon: "ml-4",
        layout: "/admin",
        component: SharedPloggingPaymentMgmt,
      },
    ],
  },
  {
    collapse: true,
    name: "탄소 e음",
    icon: "ni ni-align text-white",
    state: "carbon",
    views: [
      {
        path: "/carbonTabList",
        name: "탄소 탭 관리",
        miniName: "탭",
        icon: "ml-4",
        component: CarbonTabList,
        layout: "/admin",
      },
      {
        path: "/activityForm",
        layout: "/admin",
        redirect: true,
        component: ActivityForm,
      },
      {
        path: "/activityDetail/:id",
        layout: "/admin",
        redirect: true,
        component: ActivityForm,
      },
      {
        path: "/livingForm",
        layout: "/admin",
        redirect: true,
        component: LivingForm,
      },
      {
        path: "/livingDetail/:id",
        layout: "/admin",
        redirect: true,
        component: LivingForm,
      },
      {
        path: "/activityCompanyForm",
        layout: "/admin",
        redirect: true,
        component: ActivityCompanyForm,
      },
      {
        path: "/activityCompanyDetail/:id",
        layout: "/admin",
        redirect: true,
        component: ActivityCompanyForm,
      },
      {
        path: "/qrCountList",
        name: "탄소 적립점 등록, QR 관리",
        miniName: "적",
        icon: "ml-4",
        component: QrCountList,
        layout: "/admin",
      },
      {
        path: "/qrCountDetail/:id",
        layout: "/admin",
        redirect: true,
        component: QrCountForm,
      },
      {
        path: "/qrCountForm",
        layout: "/admin",
        redirect: true,
        component: QrCountForm,
      },
      {
        path: "/qrDiscountList",
        name: "탄소 할인점 등록, QR 관리",
        miniName: "할",
        icon: "ml-4",
        component: QrDiscountList,
        layout: "/admin",
      },
      {
        path: "/qrDiscountDetail/:id",
        layout: "/admin",
        redirect: true,
        component: QrDiscountForm,
      },
      {
        path: "/qrDiscountForm",
        layout: "/admin",
        redirect: true,
        component: QrDiscountForm,
      },
      {
        path: "/qrHistoryList",
        name: "QR 인증 참여 실적리스트",
        miniName: "내",
        icon: "ml-4",
        component: QrHistoryList,
        layout: "/admin",
      },
      {
        path: "/challengeTogether",
        name: "함께하는 챌린지 관리",
        miniName: "함",
        icon: "ml-4",
        component: TogetherChallenge,
        layout: "/admin",
      },
      {
        path: "/togetherChallengeForm",
        layout: "/admin",
        redirect: true,
        component: TogetherChallengeForm,
      },
      {
        path: "/togetherChallengeDetail/:id",
        layout: "/admin",
        redirect: true,
        component: TogetherChallengeForm,
      },
      {
        path: "/challengeRank",
        name: "챌린지 랭킹 리스트",
        miniName: "함",
        icon: "ml-4",
        component: ChallengeRank,
        layout: "/admin",
      },
      // {
      //   path: "/UserPoint",
      //   name: "유저 포인트 적립/이력",
      //   miniName: "함",
      //   icon: "ml-4",
      //   component: UserPoint,
      //   layout: "/admin",
      // },
      // {
      //   path: "/shopCouponList",
      //   name: "업체 쿠폰 관리",
      //   miniName: "쿠",
      //   icon: "ml-4",
      //   component: QrDiscountList,
      //   layout: "/admin",
      // },
      // {
      //   path: "/shopReviewList",
      //   name: "업체 리뷰 관리",
      //   miniName: "리",
      //   icon: "ml-4",
      //   component: ShopReviewList,
      //   layout: "/admin",
      // },
    ],
  },
  {
    collapse: true,
    name: "기업 메뉴",
    icon: "ni ni-align text-white",
    state: "company",
    views: [
      {
        path: "/challengeList",
        name: "임직원 챌린지 리스트",
        miniName: "임",
        icon: "ml-4",
        component: ChallengeMemList,
        layout: "/admin",
      },
      {
        path: "/challengeMem",
        name: "임직원 챌린지 관리",
        miniName: "임",
        icon: "ml-4",
        component: ChallengeMem,
        layout: "/admin",
      },
      {
        path: "/challengeMemDetails/:id/:comId",
        layout: "/admin",
        redirect: true,
        component: ChallengeMemForm,
      },
      {
        path: "/challengeMemForm/:comId",
        layout: "/admin",
        redirect: true,
        component: ChallengeMemForm,
      },
      {
        path: "/certificateList/:id",
        layout: "/admin",
        redirect: true,
        component: CertificateList,
      },
      {
        path: "/commentList/:id",
        layout: "/admin",
        redirect: true,
        component: CommentList,
      },
      {
        path: "/challengeMemDetail/:id/:name",
        layout: "/admin",
        redirect: true,
        component: ChallengeMemDetail,
      },
      {
        path: "/company/rankList",
        name: "임직원 활동 리스트",
        miniName: "",
        icon: "ml-4",
        component: CompanyRankList,
        layout: "/admin",
      },
      {
        path: "/company/detail/:id",
        layout: "/admin",
        redirect: true,
        component: CompanyRankDetail,
      },
      {
        path: "/company/rankList",
        name: "기업관리자 카테고리 관리",
        miniName: "",
        icon: "ml-4",
        component: CompanyRankList,
        layout: "/admin",
      },
      {
        path: "/company/centerList",
        name: "기업 ADMIN 관리",
        miniName: "",
        icon: "ml-4",
        component: CompanyCenterList,
        layout: "/admin",
      },
      {
        path: "/companyCenter/form",
        layout: "/admin",
        redirect: true,
        component: CompanyCenterForm,
      },
      {
        path: "/companyCenter/detail/:id",
        layout: "/admin",
        redirect: true,
        component: CompanyDetail,
      },
      {
        path: "/companyCenter/groupForm/:id",
        layout: "/admin",
        redirect: true,
        component: GroupForm,
      },
      {
        path: "/companyCenter/groupDetail/:id/:ids",
        layout: "/admin",
        redirect: true,
        component: GroupForm,
      },
      {
        path: "/company/charityList",
        name: "기업 센터 봉사 관리",
        miniName: "",
        icon: "ml-4",
        component: CompanyCharityList,
        layout: "/admin",
      },
      {
        path: "/companyList",
        name: "기업코드 개설관리",
        miniName: "",
        icon: "ml-4",
        component: CompanyList,
        layout: "/admin",
      },
      {
        path: "/companySubList/:id/:names",
        layout: "/admin",
        redirect: true,
        component: CompanySubList,
      },
      {
        path: "/companyDetail/:id",
        layout: "/admin",
        redirect: true,
        component: CompanyForm,
      },
      {
        path: "/companyForm",
        layout: "/admin",
        redirect: true,
        component: CompanyForm,
      },
      {
        path: "/companyGroupList",
        name: "참여그룹 걷기 현황",
        miniName: "",
        icon: "ml-4",
        component: CompanyGroupList,
        layout: "/admin",
      },
      {
        path: "/companyGroupForm",
        layout: "/admin",
        redirect: true,
        component: CompanyGroupForm,
      },
      {
        path: "/companyGroupDetail/:id",
        layout: "/admin",
        redirect: true,
        component: CompanyGroupForm,
      },
      {
        path: "/company/storeList",
        name: "기업 가맹점 관리",
        miniName: "",
        icon: "ml-4",
        component: CompanyStoreList,
        layout: "/admin",
      },
      {
        path: "/company/storeForm",
        layout: "/admin",
        redirect: true,
        component: CompanyStoreForm,
      },
      {
        path: "/company/storeDetail/:id",
        layout: "/admin",
        redirect: true,
        component: CompanyStoreForm,
      },
      {
        path: "/donation",
        name: "기업 기부 관리",
        miniName: "",
        icon: "ml-4",
        component: DonationList,
        layout: "/admin",
      },
      {
        path: "/donationCharityDetail/:id",
        layout: "/admin",
        redirect: true,
        component: DonationCompanyList,
      },
      {
        path: "/donationForm",
        layout: "/admin",
        redirect: true,
        component: DonationForm,
      },
      {
        path: "/donationDetail/:id",
        layout: "/admin",
        redirect: true,
        component: DonationForm,
      },
    ],
  },
  {
    path: "/couponList/:id",
    layout: "/admin",
    redirect: true,
    component: ShopCouponList,
  },
  {
    path: "/shopRegister",
    layout: "/admin",
    redirect: true,
    component: ShopForm,
  },
  {
    path: "/shopCouponRegister",
    layout: "/admin",
    redirect: true,
    component: ShopCouponFrom,
  },
  {
    path: "/shopCouponDetail/:id",
    layout: "/admin",
    redirect: true,
    component: ShopCouponFrom,
  },
  {
    path: "/shopReviewDetail/:id",
    layout: "/admin",
    redirect: true,
    component: ShopReviewDetail,
  },
  {
    path: "/shopDetail/:id",
    layout: "/admin",
    redirect: true,
    component: ShopForm,
  },
  {
    path: "/shopMenu/:id",
    layout: "/admin",
    redirect: true,
    component: ShopMenu,
  },
  {
    path: "/shopMenuRegister/:id",
    layout: "/admin",
    redirect: true,
    component: ShopMenuForm,
  },
  {
    path: "/shopMenuDetail",
    layout: "/admin",
    redirect: true,
    component: ShopMenuForm,
  },
  {
    path: "/snsDetail/:id",
    layout: "/admin",
    redirect: true,
    component: BoardDetail,
  },
  {
    path: "/question",
    layout: "/admin",
    redirect: true,
    component: QuestionForm,
  },
  {
    collapse: true,
    name: "상점프로필 유저 관리",
    icon: "ni ni-align text-white",
    state: "storeProfileState",
    views: [
      {
        path: "/shopUserList",
        name: "상점프로필 유저 관리",
        miniName: "유",
        icon: "ml-4",
        component: ShopUserList,
        layout: "/admin",
      },
      {
        path: "/shopCouponList",
        name: "업체 쿠폰 관리",
        miniName: "쿠",
        icon: "ml-4",
        component: ShopCouponList,
        layout: "/admin",
      },
      {
        path: "/shopReviewList",
        name: "업체 리뷰 관리",
        miniName: "리",
        icon: "ml-4",
        component: ShopReviewList,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "기업 ESG",
    icon: "ni ni-align text-white",
    state: "storeEsgState",
    views: [
      {
        path: "/pollutionList",
        name: "악취오염 캠페인",
        miniName: "악",
        icon: "ml-4",
        component: PollutionList,
        layout: "/admin",
      },
      {
        path: "/pollutionHistory/:id/:title",
        layout: "/admin",
        redirect: true,
        component: PollutionHistoryList,
      },
      {
        path: "/pollutionForm",
        layout: "/admin",
        redirect: true,
        component: PollutionForm,
      },
      {
        path: "/pollutionDetail/:id",
        layout: "/admin",
        redirect: true,
        component: PollutionForm,
      },
    ],
  },
  {
    collapse: true,
    name: "에코프렌즈",
    icon: "ni ni-align text-white",
    state: "ecoState",
    views: [
      {
        path: "/ecoFriendsList",
        name: "에코프렌즈 지점관리",
        miniName: "에",
        icon: "ml-4",
        component: EcoFriendsList,
        layout: "/admin",
      },
      {
        path: "/ecoFriendsForm",
        layout: "/admin",
        redirect: true,
        component: EcoFriendsForm,
      },
      {
        path: "/ecoFriendsDetail/:id",
        layout: "/admin",
        redirect: true,
        component: EcoFriendsForm,
      },
      {
        path: "/ecoFriendsHistoryList",
        name: "에코프렌즈 관리",
        miniName: "에",
        icon: "ml-4",
        component: EcoFriendsHistoryList,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "지역 광고 관리",
    icon: "ni ni-align text-white",
    state: "bannerState",
    views: [
      {
        path: "/regionAdvertList",
        name: "지역 광고 관리",
        miniName: "광",
        icon: "ml-4",
        component: RegionAdvertList,
        layout: "/admin",
      },
      {
        path: "/regionAdvertDetail/:id",
        layout: "/admin",
        redirect: true,
        component: RegionAdvertForm,
      },
      {
        path: "/regionAdvertPaymentList",
        name: "지역 광고 결제 관리",
        miniName: "결",
        icon: "ml-4",
        component: RegionAdvertPaymentList,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "공지 및 소식관리",
    icon: "ni ni-align text-white",
    state: "noticeState",
    views: [
      {
        path: "/serviceNewsList",
        name: "사회 공헌 주요소식",
        miniName: "사",
        icon: "ml-4",
        layout: "/admin",
        component: ServiceNewsList,
      },
      {
        path: "/serviceNewsForm",
        layout: "/admin",
        redirect: true,
        component: ServiceNewsForm,
      },
      {
        path: "/serviceNewsDetail/:id",
        layout: "/admin",
        redirect: true,
        component: ServiceNewsForm,
      },
      {
        path: "/newsList",
        name: "포아브 소식",
        miniName: "포",
        icon: "ml-4",
        layout: "/admin",
        component: FoavNewsList,
      },
      {
        path: "/newsForm",
        layout: "/admin",
        redirect: true,
        component: FoavNewsForm,
      },
      {
        path: "/newsDetail/:id",
        layout: "/admin",
        redirect: true,
        component: FoavNewsForm,
      },
      {
        path: "/advertisementList",
        name: "광고 관리",
        miniName: "광",
        icon: "ml-4",
        layout: "/admin",
        component: AdvertisementList,
      },
      {
        path: "/globalDetail/:id",
        layout: "/admin",
        redirect: true,
        component: AdvertisementForm,
      },
      {
        path: "/marketDetail/:id",
        layout: "/admin",
        redirect: true,
        component: AdvertisementForm,
      },
      {
        path: "/homeDetail/:id",
        layout: "/admin",
        redirect: true,
        component: AdvertisementForm,
      },
      {
        path: "/carbonDetail/:id",
        layout: "/admin",
        redirect: true,
        component: AdvertisementForm,
      },
      {
        path: "/areaDetail/:id",
        layout: "/admin",
        redirect: true,
        component: AdvertisementForm,
      },
      {
        path: "/settingDetail/:id",
        layout: "/admin",
        redirect: true,
        component: AdvertisementForm,
      },
      {
        path: "/bottomDetail/:id",
        layout: "/admin",
        redirect: true,
        component: AdvertisementForm,
      },
      {
        path: "/allianceDetail/:id",
        layout: "/admin",
        redirect: true,
        component: AdvertisementForm,
      },
      {
        path: "/bannerBottom",
        layout: "/admin",
        redirect: true,
        component: AdvertisementForm,
      },
      {
        path: "/bannerSetting",
        layout: "/admin",
        redirect: true,
        component: AdvertisementForm,
      },
      {
        path: "/bannerAlliance",
        layout: "/admin",
        redirect: true,
        component: AdvertisementForm,
      },
      {
        path: "/bannerHome",
        layout: "/admin",
        redirect: true,
        component: AdvertisementForm,
      },
      {
        path: "/bannerArea",
        layout: "/admin",
        redirect: true,
        component: AdvertisementForm,
      },
      {
        path: "/bannerCarbon",
        layout: "/admin",
        redirect: true,
        component: AdvertisementForm,
      },
      {
        path: "/bannerGlobal",
        layout: "/admin",
        redirect: true,
        component: AdvertisementForm,
      },
      {
        path: "/bannerMarket",
        layout: "/admin",
        redirect: true,
        component: AdvertisementForm,
      },
      {
        path: "/noticeList",
        name: "공지사항",
        miniName: "공",
        icon: "ml-4",
        layout: "/admin",
        component: NoticeList,
      },
      {
        path: "/noticeForm",
        layout: "/admin",
        redirect: true,
        component: NoticeForm,
      },
      {
        path: "/noticeDetail/:id",
        layout: "/admin",
        redirect: true,
        component: NoticeForm,
      },
    ],
  },
  {
    collapse: true,
    name: "Q&A 관리",
    icon: "ni ni-align text-white",
    state: "qna",
    views: [
      {
        path: "/qnaCategoryList",
        name: "카테고리 관리",
        miniName: "C",
        icon: "ml-4",
        layout: "/admin",
        component: QnaCategoryList,
      },
      {
        path: "/qnaList",
        name: "Q&A",
        miniName: "Q",
        icon: "ml-4",
        layout: "/admin",
        component: QnaList,
      },
      {
        path: "/qnaForm",
        layout: "/admin",
        redirect: true,
        component: QnaForm,
      },
      {
        path: "/qnaDetail/:id",
        layout: "/admin",
        redirect: true,
        component: QnaForm,
      },
    ],
  },

  {
    path: "/marketList",
    name: "로컬 이음 마켓",
    icon: "",
    layout: "/admin",
    component: MarketList,
  },
  {
    path: "/marketRegister",
    layout: "/admin",
    redirect: true,
    component: MarketForm,
  },
  {
    path: "/marketsDetail/:id",
    layout: "/admin",
    redirect: true,
    component: MarketForm,
  },


  {
    collapse: true,
    name: "비활성화 메뉴 모음",
    icon: "ni ni-align text-white",
    state: "disabledState",
    views: [
      {
        path: "/snsList",
        name: "SNS",
        miniName: "S",
        icon: "ml-4",
        layout: "/admin",
        component: SnsList,
      },
      {
        path: "/storeList",
        name: "가맹점 관리",
        miniName: "가",
        icon: "ml-4",
        layout: "/admin",
        component: StoreList,
      },
      {
        path: "/allianceList",
        name: "법인제휴 관리",
        miniName: "법",
        icon: "ml-4",
        layout: "/admin",
        component: AllianceList,
      },
      {
        path: "/allianceForm",
        layout: "/admin",
        redirect: true,
        component: AllianceForm,
      },
      {
        path: "/alliance/detail/:id",
        layout: "/admin",
        redirect: true,
        component: AllianceForm,
      },
      {
        path: "/staffList",
        name: "직원 관리",
        miniName: "직",
        icon: "ml-4",
        layout: "/admin",
        component: StaffList,
      },
      {
        path: "/staffForm",
        layout: "/admin",
        redirect: true,
        component: StaffForm,
      },
      {
        path: "/staffDetail/:id",
        layout: "/admin",
        redirect: true,
        component: StaffForm,
      },
      {
        path: "/orderList",
        name: "주문 관리",
        miniName: "주",
        icon: "ml-4",
        layout: "/admin",
        component: OrderList,
      },
      {
        path: "/reviewList",
        name: "업체 리뷰 관리",
        miniName: "업",
        icon: "ml-4",
        layout: "/admin",
        component: ReviewList,
      },
      {
        path: "/tabList",
        name: "포아브탭 게시글 관리",
        miniName: "포",
        icon: "ml-4",
        layout: "/admin",
        component: BoardList,
      },
    ],
  },
];

if (process.env.NODE_ENV === 'development') {
  routes = [
    ...routes,
    {
      collapse: true,
      name: "Dashboards",
      icon: "ni ni-shop text-primary",
      state: "dashboardsCollapse",
      views: [
        {
          path: "/dashboard",
          name: "Dashboard",
          miniName: "D",
          component: Dashboard,
          layout: "/admin",
        },
        {
          path: "/alternative-dashboard",
          name: "Alternative",
          miniName: "A",
          component: Alternative,
          layout: "/admin",
        },
      ],
    },
    {
      collapse: true,
      name: "Examples",
      icon: "ni ni-ungroup text-orange",
      state: "examplesCollapse",
      views: [
        {
          path: "/pricing",
          name: "Pricing",
          miniName: "P",
          component: Pricing,
          layout: "/auth",
        },
        {
          path: "/login",
          name: "Login",
          miniName: "L",
          component: Login,
          layout: "/auth",
        },
        {
          path: "/register",
          name: "Register",
          miniName: "R",
          component: Register,
          layout: "/auth",
        },
        {
          path: "/lock",
          name: "Lock",
          miniName: "L",
          component: Lock,
          layout: "/auth",
        },
        {
          path: "/timeline",
          name: "Timeline",
          miniName: "T",
          component: Timeline,
          layout: "/admin",
        },
        {
          path: "/profile",
          name: "Profile",
          miniName: "P",
          component: Profile,
          layout: "/admin",
        },
        {
          path: "/rtl-support",
          name: "RTL Support",
          miniName: "RS",
          component: RTLSupport,
          layout: "/rtl",
        },
      ],
    },
    {
      collapse: true,
      name: "Components",
      icon: "ni ni-ui-04 text-info",
      state: "componentsCollapse",
      views: [
        {
          path: "/buttons",
          name: "Buttons",
          miniName: "B",
          component: Buttons,
          layout: "/admin",
        },
        {
          path: "/cards",
          name: "Cards",
          miniName: "C",
          component: Cards,
          layout: "/admin",
        },
        {
          path: "/grid",
          name: "Grid",
          miniName: "G",
          component: Grid,
          layout: "/admin",
        },
        {
          path: "/notifications",
          name: "Notifications",
          miniName: "N",
          component: Notifications,
          layout: "/admin",
        },
        {
          path: "/icons",
          name: "Icons",
          miniName: "I",
          component: Icons,
          layout: "/admin",
        },
        {
          path: "/typography",
          name: "Typography",
          miniName: "T",
          component: Typography,
          layout: "/admin",
        },
        {
          collapse: true,
          name: "Multi Level",
          miniName: "M",
          state: "multiCollapse",
          views: [
            {
              path: "#pablo",
              name: "Third level menu",
              component: () => {},
              layout: "/",
            },
            {
              path: "#pablo",
              name: "Just another link",
              component: () => {},
              layout: "/",
            },
            {
              path: "#pablo",
              name: "One last link",
              component: () => {},
              layout: "/",
            },
          ],
        },
      ],
    },
    {
      collapse: true,
      name: "Forms",
      icon: "ni ni-single-copy-04 text-pink",
      state: "formsCollapse",
      views: [
        {
          path: "/elements",
          name: "Elements",
          miniName: "E",
          component: Elements,
          layout: "/admin",
        },
        {
          path: "/components",
          name: "Components",
          miniName: "C",
          component: Components,
          layout: "/admin",
        },
        {
          path: "/validation",
          name: "Validation",
          miniName: "V",
          component: Validation,
          layout: "/admin",
        },
      ],
    },
    {
      collapse: true,
      name: "Tables",
      icon: "ni ni-align-left-2 text-default",
      state: "tablesCollapse",
      views: [
        {
          path: "/tables",
          name: "Tables",
          miniName: "T",
          component: Tables,
          layout: "/admin",
        },
        {
          path: "/sortable",
          name: "Sortable",
          miniName: "S",
          component: Sortable,
          layout: "/admin",
        },
        {
          path: "/react-bs-table",
          name: "React BS Tables",
          miniName: "RBT",
          component: ReactBSTables,
          layout: "/admin",
        },
      ],
    },
    {
      collapse: true,
      name: "Maps",
      icon: "ni ni-map-big text-primary",
      state: "mapsCollapse",
      views: [
        {
          path: "/google",
          name: "Google",
          miniName: "G",
          layout: "/admin",
        },
        {
          path: "/vector",
          name: "Vector",
          miniName: "V",
          layout: "/admin",
        },
      ],
    },
    {
      path: "/widgets",
      name: "Widgets",
      icon: "ni ni-archive-2 text-green",
      component: Widgets,
      layout: "/admin",
    },
    {
      path: "/charts",
      name: "Charts",
      icon: "ni ni-chart-pie-35 text-info",
      component: Charts,
      layout: "/admin",
    },
    {
      path: "/calendar",
      name: "Calendar",
      icon: "ni ni-calendar-grid-58 text-red",
      component: Calendar,
      layout: "/admin",
    },
  ]
}

export default routes;
