import React, {useEffect, useState} from "react";
// reactstrap components
import {Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Input, Row,} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import {useHistory} from "react-router-dom";

import moment from "moment";
import {useRecoilState, useRecoilValue, useResetRecoilState} from "recoil";
import useHistoryState from "../../../../common/useHistoryState";
import livingService, {
  companyDetailFilePreviewState,
  companyDetailFileState,
  companyThumbFilePreviewState,
  companyThumbFileState,
  livingDetailFilePreviewState,
  livingDetailFileState,
  livingThumbFilePreviewState,
  livingThumbFileState
} from "../service/carbonTab.service";
import InputFile from "../../components/InputFile";

function ActivityCompanyForm({ match }) {
  const history = useHistory();
  const { id } = match.params;
  const [loading, setLoading] = useState(false);
  const thumbFile = useRecoilValue(companyThumbFileState);
  const [previewThumbURL, setPreviewThumbURL] = useRecoilState(companyThumbFilePreviewState);
  const detailFile = useRecoilValue(companyDetailFileState);
  const [previewThumbURL2, setPreviewThumbURL2] = useRecoilState(companyDetailFilePreviewState);
  const resetThumbFile = useResetRecoilState(companyThumbFileState);
  const resetDetailFilePreview = useResetRecoilState(companyThumbFilePreviewState);
  const resetThumbFile2 = useResetRecoilState(companyDetailFileState);
  const resetDetailFilePreview2 = useResetRecoilState(companyDetailFilePreviewState);
  const [data, setData] = useState({
    link: '',
    content: '',
    title: '',
    sortCode: '',
    youtube: '',
  });
  const {
    link,
    content,
    title,
    sortCode,
    youtube,
  } = data;

  const reset = () => {
    resetThumbFile();
    resetThumbFile2();
    resetDetailFilePreview()
    resetDetailFilePreview2()
  }

  const onChange = (e) => {
    let { value, name } = e.target;
    if (name === 'sortCode'
    ) {
      setData({
        ...data,
        [name]: Number(value.replace(/[^0-9]/g, '')),
      })
    } else {
      setData({
        ...data,
        [name]: value
      });
    }
  };

  const submit = async () => {
    setLoading(true);
    try {
      await createOrUpdate();
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }

  const createOrUpdate = async () => {
    if (!title) {
        alert('제목을 입력해주세요.');
        return;
    }
    if (!content) {
        alert('내용을 입력해주세요.');
        return;
    }

    const json = {
      link,
      content,
      title,
      sortCode,
      youtube,
    }

    console.log('json',json);

    let res;
    try {
      if (id) {
        res = await livingService.companyUpdate(id, json);
      } else {
        res = await livingService.companyRegister(json);
      }
      if (res.resultMsg === "success") {
        if (thumbFile) {
          let form = new FormData();
          console.log('image', thumbFile, typeof thumbFile);
          form.append('image', thumbFile);
          await livingService.companyFileRegister(res.data.companyIntroductionId, form);
        }
        if (detailFile) {
          let form = new FormData();
          console.log('image', detailFile, typeof detailFile);
          form.append('image', detailFile);
          await livingService.companyFileRegister2(res.data.companyIntroductionId, form);
        }
        alert(id ? '수정되었습니다.' : '등록되었습니다.');
        history.goBack();
        reset();
      }
    } catch (e) {
      // alert(JSON.stringify(e, null, 4));
      alert(e.response?.data?.message);
    }
    console.log('웹', res);
  };

  const fetchData = async () => {
    const res = await livingService.companyDetail(id);
    if (res.code === 200) {
      console.log(res);
      setData(res.data)
      if(res.data.thumbnail) {
        setPreviewThumbURL(`https://d2twzv77g3c69u.cloudfront.net/fit-in/1000x700/${res.data.thumbnail}`)
      }
      if(res.data.images) {
        setPreviewThumbURL2(`https://d2twzv77g3c69u.cloudfront.net/fit-in/1000x700/${res.data.images[0]}`)
      }
    }
  };

  useEffect(() => {
    if(id) {
      fetchData();
    } else {
      reset();
    }
  }, []);

  const remove = async () => {
    if (window.confirm('삭제하시겠습니까?')) {
      let res = await livingService.companyRemove(id);
      console.log(res);
      if (res.code === 200) {
        alert('삭제되었습니다.')
        history.goBack();
      }
    }
  };

  if (loading) return <div>로딩중..</div>;
  return (
    <>
      <SimpleHeader name="Form elements" parentName="Forms" />
      <Container className="mt--6" fluid>
        <Card className="mb-4">
          <CardHeader style={{ display: 'flex', justifyContent: 'space-between' }}>
            {id?
                <>
                  <h3 className="mt-2">활동기업 상세</h3>
                  <div>
                    <Button
                      color="default"
                      type="button"
                      onClick={submit}
                    >
                      수정하기
                    </Button>
                    <Button
                      color="danger"
                      type="button"
                      onClick={remove}
                    >
                      삭제하기
                    </Button>
                  </div>
                </>
                :
                <>
                  <h3 className="mt-2">활동기업 등록</h3>
                  <div>
                    <Button
                        color="default"
                        type="button"
                        onClick={submit}
                    >
                      <span className="btn-inner--icon mr-1">
                          <i className="fas fa-user-edit" />
                      </span>
                      등록하기
                    </Button>
                  </div>
                </>
            }
          </CardHeader>
          <CardBody>
            <Row>
              <Col>
                <InputFile
                    title={'메인 썸네일'}
                    fileState={companyThumbFileState}
                    filePreviewState={companyThumbFilePreviewState}
                />
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    우선순위
                  </label>
                  <Input
                      placeholder="우선순위를 입력해주세요."
                      type="text"
                      name="sortCode"
                      value={sortCode || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    제목
                  </label>
                  <Input
                      placeholder="제목을 입력해주세요."
                      type="text"
                      name="title"
                      value={title || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    내용
                  </label>
                  <Input
                      placeholder="내용을 입력해주세요."
                      type="textarea"
                      rows="10"
                      name="content"
                      value={content || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    URL 링크(선택)
                  </label>
                  <Input
                      placeholder="URL을 입력해주세요."
                      type="text"
                      name="link"
                      value={link || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    유튜브 링크(선택)
                  </label>
                  <Input
                      placeholder="URL을 입력해주세요."
                      type="text"
                      name="youtube"
                      value={youtube || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <InputFile
                    title={'상세 이미지'}
                    fileState={companyDetailFileState}
                    filePreviewState={companyDetailFilePreviewState}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default ActivityCompanyForm;
