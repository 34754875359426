import React, {Fragment, useEffect, useState} from "react";

// reactstrap components
import {
    Button,
    Card,
    CardFooter,
    CardHeader,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText, Modal,
    Row,
    Table,
    UncontrolledTooltip,
} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import CounselingService from "../../../services/counseling.service";
import PaginationWrapper from "../components/pagination";
import {numberWithCommas, paginate} from "../../../utils";
import useHistoryState from "../../../common/useHistoryState";
import classnames from "classnames";
import ReactDatetime from "react-datetime";
import moment from "moment";
import companyGroupService from "./service/companyGroup.service";
import {useHistory} from "react-router";
import AuthService from "../auth/auth.service";

function CompanyGroupList() {
  const user = typeof window !== 'undefined' ? AuthService.getCurrentUser() : null;
  const history = useHistory();
  const [pageActive, setPageActive] = useHistoryState(1, 'pageActive');
  const [pageCount, setPageCount] = useHistoryState(1, 'pageCount');
  const [data, setData] = useHistoryState([], 'data');
  const [rankData, setRankData] = useState([]);
  const [startDate, setStartDate] = useHistoryState(moment().format('YYYY-MM-01'), 'startDate');
  const [endDate, setEndDate] = useHistoryState(moment().format('YYYY-MM-DD'), 'endDate');
  const [searchCurrent, setSearchCurrent] = useHistoryState(false, 'searchCurrent');
  const [search, setSearch] = useHistoryState('', 'search');
  const [diff, setDiff] = useState('');
  const [tempId, setTempId] = useState('');
  const [defaultModal, setDefaultModal] = useState(false);

  const render = async () => {
    const res = await companyGroupService.list({ limit: 11, page: pageActive, search });
    if (res.code === 200) {
      console.log(res);
      setPageCount(res.list?.count);
      setData(res.list?.rows);
    }
  };

  useEffect(() => {
    render();
  }, [pageActive, startDate, endDate, search]);

    const handleReactDatetimeChange = (who, date) => {
        if (
            startDate &&
            who === "endDate" &&
            new Date(startDate) > new Date(date.format('YYYY-MM-DD'))
        ) {
            setStartDate(date.format('YYYY-MM-DD'));
            setEndDate(date.format('YYYY-MM-DD'));
        } else if (
            endDate &&
            who === "startDate" &&
            new Date(endDate) < new Date(date.format('YYYY-MM-DD'))
        ) {
            setStartDate(date.format('YYYY-MM-DD'));
            setEndDate(date.format('YYYY-MM-DD'));
        } else {
            if (who === "startDate") {
                setStartDate(date.format('YYYY-MM-DD'));
            } else {
                setEndDate(date.format('YYYY-MM-DD'));
            }
        }
    };

    const getClassNameReactDatetimeDays = (date) => {
        if (startDate && endDate) {
        }
        if (startDate && endDate && startDate !== endDate) {
            if (
                new Date(endDate) > new Date(date.format('YYYY-MM-DD')) &&
                new Date(startDate) < new Date(date.format('YYYY-MM-DD'))
            ) {
                return " middle-date";
            }
            if (endDate === date.format('YYYY-MM-DD')) {
                return " end-date";
            }
            if (startDate === date.format('YYYY-MM-DD')) {
                return " start-date";
            }
        }
        return "";
    };

    const fetchData = async () => {
        let res;
        if(diff === '그룹') {
            res = await companyGroupService.detailGroup(Number(tempId));
        }
        if(diff === '부서') {
            res = await companyGroupService.detailSub(Number(tempId));
        }
        if(diff === '전체 걸음') {
            res = await companyGroupService.detailPer(Number(tempId));
        }
        if (res?.code === 200) {
            console.log(res);
            setRankData(res.data)
        }
    };

    useEffect(() => {
        fetchData();
    }, [ tempId, startDate, endDate ]);

    return (
    <>
      <SimpleHeader name="Tables" parentName="Tables" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row className="py-3">
                    <Col xs="11">
                        <Form>
                            <Row>
                                <Col xs="3">
                                    <label className=" form-control-label py-2">
                                    </label>
                                    <FormGroup>
                                        <InputGroup
                                            className={classnames("input-group-merge", {
                                                focused: searchCurrent,
                                            })}
                                        >
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="fas fa-search" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                placeholder="기업명, 기업코드로 검색"
                                                type="text"
                                                value={search}
                                                onChange={(e) => setSearch(e.target.value)}
                                                onFocus={(e) => setSearchCurrent(true)}
                                                onBlur={(e) => setSearchCurrent(false)}
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col xs="2">
                                    <label className=" form-control-label py-2">
                                    </label>
                                    <FormGroup>
                                        <ReactDatetime
                                            inputProps={{
                                                placeholder: "",
                                            }}
                                            value={startDate}
                                            locale={'ko'}
                                            timeFormat={false}
                                            dateFormat={'YYYY-MM-DD'}
                                            onChange={(e) =>
                                                handleReactDatetimeChange("startDate", e)
                                            }
                                            renderDay={(props, currentDate, selectedDate) => {
                                                let classes = props.className;
                                                classes += getClassNameReactDatetimeDays(
                                                    currentDate
                                                );
                                                return (
                                                    <td {...props} className={classes}>
                                                        {currentDate.date()}
                                                    </td>
                                                );
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs="2">
                                    <FormGroup>
                                        <label className=" form-control-label py-2">
                                        </label>
                                        <ReactDatetime
                                            inputProps={{
                                                placeholder: "",
                                            }}
                                            value={endDate}
                                            locale={'ko'}
                                            timeFormat={false}
                                            dateFormat={'YYYY-MM-DD'}
                                            onChange={(e) =>
                                                handleReactDatetimeChange("endDate", e)
                                            }
                                            renderDay={(props, currentDate, selectedDate) => {
                                                let classes = props.className;
                                                classes += getClassNameReactDatetimeDays(
                                                    currentDate
                                                );
                                                return (
                                                    <td {...props} className={classes}>
                                                        {currentDate.date()}
                                                    </td>
                                                );
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                <Row>
                  <Col xs="6">
                    <h3 className="mt-2">참여그룹 걷기 현황</h3>
                  </Col>
                  {user?.role === 'center' ? null : (
                    <Col className="text-right">
                        <Button color="default" onClick={(e) => history.push('/admin/companyGroupForm')}>
                            등록하기
                        </Button>
                    </Col>
                  )}
                </Row>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive striped>
                <thead className="thead-light">
                <tr>
                  <th className="text-center">No.</th>
                  <th className="text-center">기업명</th>
                  <th className="text-center">기업코드</th>
                  <th className="text-center">그룹순위</th>
                  <th className="text-center">부서순위</th>
                  <th className="text-center">전체걸음순위</th>
                  <th className="text-center">상세보기</th>
                </tr>
                </thead>
                <tbody>
                {data?.map((item, i) => {
                  return [
                    <Fragment key={i}>
                      <tr>
                        <td className="text-center">
                          {item.id}
                        </td>
                        <td className="text-center">
                          {item.name}
                        </td>
                        <td className="text-center">
                          {item?.code}
                        </td>
                          <td className="text-center">
                              <Button
                                  className="btn-outline-default"
                                  onClick={() => {
                                      setDiff('그룹')
                                      setDefaultModal(true)
                                      setTempId(item.companyId)
                                  }}
                                  size="sm"
                              >
                                  <span className="btn-inner--text">보기</span>
                              </Button>
                          </td>
                          <td className="text-center">
                              <Button
                                  className="btn-outline-default"
                                  onClick={() => {
                                      setDiff('부서')
                                      setDefaultModal(true)
                                      setTempId(item.companyId)
                                  }}
                                  size="sm"
                              >
                                  <span className="btn-inner--text">보기</span>
                              </Button>
                          </td>
                        <td className="text-center">
                          <Button
                              className="btn-outline-default"
                              onClick={() => {
                                  setDiff('전체 걸음')
                                  setDefaultModal(true)
                                  setTempId(item.companyId)
                              }}
                              size="sm"
                          >
                            <span className="btn-inner--text">보기</span>
                          </Button>
                        </td>
                          <td className="text-center">
                          <Button
                              className="btn-outline-default"
                              onClick={() => history.push('/admin/companyGroupDetail/' + item.id)}
                              size="sm"
                          >
                            <span className="btn-inner--text">상세</span>
                          </Button>
                        </td>
                      </tr>
                    </Fragment>
                  ];
                })}
                </tbody>
              </Table>

              <CardFooter className="py-4">
                <PaginationWrapper
                  isActive={pageActive}
                  pageNumber={pageCount}
                  callback={(page) => setPageActive(page)}
                  itemPerPage={10}
                />
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
        <Modal
            className="modal-dialog-centered"
            isOpen={defaultModal}
            size="lg"
            // toggle={() => setDefaultModal(false)} // 배경클릭시 창 닫힘
            fade={false}
        >
            <div className="modal-header">
                <h6 className="modal-title mt-2">
                    {diff} 순위 보기
                </h6>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => {
                        setDefaultModal(false)
                    }}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>
            <div className="modal-body">
                {diff === '그룹' &&
                    <>
                    <Row className="mb-2">
                        <Col md="3" className="text-center font-weight-bold">랭킹</Col>
                        <Col md="6" className="font-weight-bold">기업명</Col>
                        <Col md="3" className="font-weight-bold">달성률</Col>
                    </Row>
                    {rankData?.map((v) => {
                        return (
                            <Row className="mt-1">
                                <Col md="3" className="text-center">
                                    {v.rank}
                                </Col>
                                <Col md="6">{v.companyName}</Col>
                                <Col md="3">{v.per}%</Col>
                            </Row>
                        )
                    })}
                    </>
                }
                {diff === '부서' &&
                    <>
                    <Row className="mb-2">
                        <Col md="3" className="text-center font-weight-bold">랭킹</Col>
                        <Col md="6" className="font-weight-bold">기업명-부서</Col>
                        <Col md="3" className="font-weight-bold">달성률</Col>
                    </Row>
                    {rankData?.map((v) => {
                        return (
                            <Row className="mt-1">
                                <Col md="3" className="text-center">
                                    {v.rank}
                                </Col>
                                <Col md="6">{v.companyName}<br/>{v.companySub}</Col>
                                <Col md="3">{v.per}%</Col>
                            </Row>
                        )
                    })}
                    </>
                }
                {diff === '전체 걸음' &&
                    <>
                    <Row className="mb-2">
                        <Col md="2" className="text-center font-weight-bold">랭킹</Col>
                        <Col md="2" className="font-weight-bold">이름</Col>
                        <Col md="4" className="font-weight-bold">기업명</Col>
                        <Col md="2" className="font-weight-bold">걸음총합</Col>
                        <Col md="2" className="font-weight-bold">달성률</Col>
                    </Row>
                    {rankData?.map((v) => {
                        return (
                            <Row className="mt-1">
                                <Col md="2" className="text-center">
                                    {v.rank}
                                </Col>
                                <Col md="2">{v.userName}</Col>
                                <Col md="4">{v.companyName}<br/>{v.companySub}</Col>
                                <Col md="2">{v.steps}</Col>
                                <Col md="2">{v.per}%</Col>
                            </Row>
                        )
                    })}
                    </>
                }
            </div>
        </Modal>
    </>
  );
}

export default CompanyGroupList;
