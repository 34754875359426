import React, {useEffect, useState} from "react";
// reactstrap components
import {Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Input, Row,} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import {useHistory} from "react-router-dom";
import useHistoryState from "../../../common/useHistoryState";
import moment from "moment";
import ReactDatetime from "react-datetime";
import companyService from "../company/service/company.service";
import ecoFriendsService from "./service/ecoFriends.service";
import dayjs from "dayjs";

function EcoFriendsForm({ match }) {
  const { id } = match.params;
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useHistoryState(moment().format('YYYY-MM-01'), 'startDate');
  const [endDate, setEndDate] = useHistoryState(moment().format('YYYY-MM-DD'), 'endDate');
  const [data, setData] = useState({
    name: '',
  });

  const {
    name,
  } = data;
  console.log(data)

  const onChange = (e) => {
    let { value, name } = e.target;
    if (name === 'managerTel'
    ) {
      setData({
        ...data,
        [name]: Number(value.replace(/[^0-9]/g, '')),
      })
    } else {
      setData({
        ...data,
        [name]: value
      });
    }
  };

  const submit = async () => {
    setLoading(true);
    try {
      await createOrUpdate();
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }

  const createOrUpdate = async () => {
    if (!name) {
      return alert('지점명을 입력해주세요.');
    }

    const json = {
      ...data,
    }

    if(id) {
      delete json.id;
      delete json.active;
      delete json.createdAt;
      delete json.updatedAt;
      delete json.deletedAt;
    }

    console.log('json',json);

    let res;
    try {
      if (id) {
        res = await ecoFriendsService.update(id, json);
      } else {
        res = await ecoFriendsService.register(json);
      }
      if (res.resultMsg === "success") {
        alert(id ? '수정되었습니다.' : '등록되었습니다.');
        history.goBack();
      }
    } catch (e) {
      alert(JSON.stringify(e, null, 4));
      alert(e.response?.data?.message);
    }
    console.log('웹', res);
  };

  const fetchData = async () => {
    const res = await ecoFriendsService.detail(id);
    if (res.code === 200) {
      console.log(res);
      setData(res.data)
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const remove = async () => {
    if (window.confirm('삭제하시겠습니까?')) {
      let res = await ecoFriendsService.remove(id);
      console.log(res);
      if (res.code === 200) {
        history.goBack();
      }
    }
  };

  if (loading) return <div>로딩중..</div>;
  return (
    <>
      <SimpleHeader name="Form elements" parentName="Forms" />
      <Container className="mt--6" fluid>
        <Card className="mb-4">
          <CardHeader style={{ display: 'flex', justifyContent: 'space-between' }}>
            {id?
                <>
                  <h3 className="mt-2">에코프렌즈 지점 상세</h3>
                  <div>
                    <Button
                      color="default"
                      type="button"
                      onClick={submit}
                    >
                      수정하기
                    </Button>
                    <Button
                      color="danger"
                      type="button"
                      onClick={remove}
                    >
                      삭제하기
                    </Button>
                  </div>
                </>
                :
                <>
                  <h3 className="mt-2">에코프렌즈 지점 등록</h3>
                  <div>
                    <Button
                        color="default"
                        type="button"
                        onClick={submit}
                    >
                      <span className="btn-inner--icon mr-1">
                          <i className="fas fa-user-edit" />
                      </span>
                      등록하기
                    </Button>
                  </div>
                </>
            }
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="2">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    지점명
                  </label>
                  <Input
                      placeholder="지점명을 입력해주세요."
                      type="text"
                      name="name"
                      value={name || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default EcoFriendsForm;
