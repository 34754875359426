import React, {Fragment, useEffect} from "react";

// reactstrap components
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import CounselingService from "../../../services/counseling.service";
import PaginationWrapper from "../components/pagination";
import {numberWithCommas, paginate} from "../../../utils";
import useHistoryState from "../../../common/useHistoryState";
import classnames from "classnames";
import ReactDatetime from "react-datetime";
import moment from "moment";
import {Link} from "react-router-dom";
import deviceService from "./service/device.service";
import {useHistory} from "react-router";
import {shopCategory} from "../shop/service/shop.category";

function DeviceList() {
  const history = useHistory();
  const [pageActive, setPageActive] = useHistoryState(1, 'pageActive');
  const [pageCount, setPageCount] = useHistoryState(1, 'pageCount');
  const [data, setData] = useHistoryState([], 'data');
  const [category, setCategory] = useHistoryState('전체', 'category');
  const [startDate, setStartDate] = useHistoryState(moment().format('YYYY-MM-01'), 'startDate');
  const [endDate, setEndDate] = useHistoryState(moment().format('YYYY-MM-DD'), 'endDate');
  const [searchCurrent, setSearchCurrent] = useHistoryState(false, 'searchCurrent');
  const [search, setSearch] = useHistoryState('', 'search');

  const render = async () => {
    const res = await deviceService.list({ page: pageActive });
    if (res.code === 200) {
      console.log(res);
      setPageCount(res.deviceList.count);
      setData(res.deviceList.rows);
    }
  };

  useEffect(() => {
    render();
  }, [ pageActive ]);

  return (
    <>
      <SimpleHeader name="Tables" parentName="Tables" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col xs="6">
                    <h3 className="mt-2">
                      문의 관리
                    </h3>
                  </Col>
                </Row>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive striped>
                <thead className="thead-light">
                <tr>
                  <th className="text-center">이름</th>
                  <th className="text-center">전화번호</th>
                  <th className="text-center">키 값</th>
                  <th className="text-center">시간</th>
                </tr>
                </thead>
                <tbody>
                {data?.map((item, i) => {
                  return [
                    <Fragment key={i}>
                      <tr>
                        <td className="text-center">
                          {item.user?.name}
                        </td>
                        <td className="text-center">
                          {item.user?.tel}
                        </td>
                        <td className="text-center">
                          {item.deviceKey}
                        </td>
                        <td className="text-center">
                          {item.createdAt}
                        </td>
                      </tr>
                    </Fragment>
                  ];
                })}
                </tbody>
              </Table>

              <CardFooter className="py-4">
                <PaginationWrapper
                  isActive={pageActive}
                  pageNumber={pageCount}
                  callback={(page) => setPageActive(page)}
                  itemPerPage={10}
                />
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default DeviceList;
