import React, {Fragment, useEffect, useState} from "react";
// reactstrap components
import {Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Input, Row, Table,} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import {useHistory} from "react-router-dom";

import useHistoryState from "../../../common/useHistoryState";
import moment from "moment";
import InputFile from "../components/InputFile";
import {useRecoilState, useRecoilValue, useResetRecoilState} from "recoil";
import companyService, {
  companyLogoFilePreviewState,
  companyLogoFileState,
  etcFilePreviewState,
  etcFileState, foavTabFilePreviewState,
  foavTabFileState, logo1FilePreviewState,
  logo1FileState, logo2FilePreviewState,
  logo2FileState, nanumTabFilePreviewState,
  nanumTabFileState, storeDetailFilePreviewState,
  storeDetailFileState
} from "./service/company.service";

function CompanyForm({ match }) {
  const history = useHistory();
  const { id } = match.params;
  const [startDate, setStartDate] = useHistoryState(moment().format('YYYY-MM-DD'), 'startDate');
  const [endDate, setEndDate] = useHistoryState(moment().add(1, 'month').format('YYYY-MM-DD'), 'endDate');
  const [loading, setLoading] = useState(false);
  // 파일 Data
  const mainLogo = useRecoilValue(companyLogoFileState);
  const nanumTab = useRecoilValue(nanumTabFileState);
  const foavTab = useRecoilValue(foavTabFileState);
  const storeDetail = useRecoilValue(storeDetailFileState);
  const logo1 = useRecoilValue(logo1FileState);
  const logo2 = useRecoilValue(logo2FileState);
  const etc = useRecoilValue(etcFileState);
  // 파일 Preview
  const [mainLogoPreviewThumbURL, setMainLogoPreviewThumbURL] = useRecoilState(companyLogoFilePreviewState);
  const [nanumTabPreviewThumbURL, setNanumTabPreviewThumbURL] = useRecoilState(nanumTabFilePreviewState);
  const [foavTabPreviewThumbURL, setFoavTabPreviewThumbURL] = useRecoilState(foavTabFilePreviewState);
  const [storeDetailPreviewThumbURL, setStoreDetailPreviewThumbURL] = useRecoilState(storeDetailFilePreviewState);
  const [logo1PreviewThumbURL, setLogo1PreviewThumbURL] = useRecoilState(logo1FilePreviewState);
  const [logo2PreviewThumbURL, setLogo2PreviewThumbURL] = useRecoilState(logo2FilePreviewState);
  const [etcPreviewThumbURL, setEtcPreviewThumbURL] = useRecoilState(etcFilePreviewState);
  // 파일 reset Data
  const resetMainLogoFile = useResetRecoilState(companyLogoFileState);
  const resetNanumTabFile = useResetRecoilState(nanumTabFileState);
  const resetFoavTabFile = useResetRecoilState(foavTabFileState);
  const resetStoreDetailFile = useResetRecoilState(storeDetailFileState);
  const resetLogo1File = useResetRecoilState(logo1FileState);
  const resetLogo2File = useResetRecoilState(logo2FileState);
  const resetEtcFile = useResetRecoilState(etcFileState);
  // 파일 reset Preview
  const resetCompanyLogoFilePreview = useResetRecoilState(companyLogoFilePreviewState);
  const resetNanumTabFilePreview = useResetRecoilState(nanumTabFilePreviewState);
  const resetFoavTabFilePreview = useResetRecoilState(foavTabFilePreviewState);
  const resetStoreDetailFilePreview = useResetRecoilState(storeDetailFilePreviewState);
  const resetLogo1FilePreview = useResetRecoilState(logo1FilePreviewState);
  const resetLogo2FilePreview = useResetRecoilState(logo2FilePreviewState);
  const resetEtcFilePreview = useResetRecoilState(etcFilePreviewState);

  const [subName, setSubName] = useState('');
  const [data, setData] = useState({
    diff: '',
    code: '',
    name: '',
    isShareBanner: true,
    idFoavBanner: true,
  });
  const {
    diff,
    code,
    name,
    isShareBanner,
    idFoavBanner,
  } = data;

  const reset = () => {
    resetMainLogoFile();
    resetNanumTabFile();
    resetFoavTabFile();
    resetStoreDetailFile()
    resetLogo1File()
    resetLogo2File()
    resetEtcFile()
    resetCompanyLogoFilePreview()
    resetNanumTabFilePreview()
    resetFoavTabFilePreview()
    resetStoreDetailFilePreview()
    resetLogo1FilePreview()
    resetLogo2FilePreview()
    resetEtcFilePreview()
  }

  const onChange = (e) => {
    let { value, name } = e.target;
    if (name === 'sortCode'
    ) {
      setData({
        ...data,
        [name]: Number(value.replace(/[^0-9]/g, '')),
      })
    } else {
      setData({
        ...data,
        [name]: value
      });
    }
  };

  const submit = async () => {
    setLoading(true);
    try {
      await createOrUpdate();
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }

  const createOrUpdate = async () => {
    // if (!title) {
    //     alert('제목을 입력해주세요.');
    //     return;
    // }
    // if (!content) {
    //     alert('내용을 입력해주세요.');
    //     return;
    // }

    const json = {
      ...data,
    }

    delete json.id
    delete json.createdAt
    delete json.shareBanner
    delete json.foavBanner
    delete json.companyBanner
    delete json.logo1
    delete json.logo2
    delete json.discountCard
    delete json.mainLogo
    delete json.companySub

    console.log('json',json);

    let res;
    try {
      if (id) {
        res = await companyService.update(id, json);
        if (res.resultMsg === "success") {
          if (mainLogo) {
            let form = new FormData();
            console.log('image', mainLogo, typeof mainLogo);
            form.append('image', mainLogo);
            await companyService.fileRegister(id, form, 'mainLogo');
          }
          if (nanumTab) {
            let form = new FormData();
            console.log('image', nanumTab, typeof nanumTab);
            form.append('image', nanumTab);
            await companyService.fileRegister(id, form, 'shareBanner');
          }
          if (foavTab) {
            let form = new FormData();
            console.log('image', foavTab, typeof foavTab);
            form.append('image', foavTab);
            await companyService.fileRegister(id, form, 'foavBanner');
          }
          if (storeDetail) {
            let form = new FormData();
            console.log('image', storeDetail, typeof storeDetail);
            form.append('image', storeDetail);
            await companyService.fileRegister(id, form, 'companyBanner');
          }
          if (logo1) {
            let form = new FormData();
            console.log('image', logo1, typeof logo1);
            form.append('image', logo1);
            await companyService.fileRegister(id, form, 'logo1');
          }
          if (logo2) {
            let form = new FormData();
            console.log('image', logo2, typeof logo2);
            form.append('image', logo2);
            await companyService.fileRegister(id, form, 'logo2');
          }
          if (etc) {
            let form = new FormData();
            console.log('image', etc, typeof etc);
            form.append('image', etc);
            await companyService.fileRegister(id, form, 'discountCard');
          }
          alert(id ? '수정되었습니다.' : '등록되었습니다.');
          history.goBack();
          reset();
        }
      } else {
        delete json.isShareBanner
        delete json.idFoavBanner
        res = await companyService.register(json);
        if (res.resultMsg === "success") {
          if (mainLogo) {
            let form = new FormData();
            console.log('image', mainLogo, typeof mainLogo);
            form.append('image', mainLogo);
            await companyService.fileRegister(res.data.companyId, form, 'mainLogo');
          }
          if (nanumTab) {
            let form = new FormData();
            console.log('image', nanumTab, typeof nanumTab);
            form.append('image', nanumTab);
            await companyService.fileRegister(res.data.companyId, form, 'shareBanner');
          }
          if (foavTab) {
            let form = new FormData();
            console.log('image', foavTab, typeof foavTab);
            form.append('image', foavTab);
            await companyService.fileRegister(res.data.companyId, form, 'foavBanner');
          }
          if (storeDetail) {
            let form = new FormData();
            console.log('image', storeDetail, typeof storeDetail);
            form.append('image', storeDetail);
            await companyService.fileRegister(res.data.companyId, form, 'companyBanner');
          }
          if (logo1) {
            let form = new FormData();
            console.log('image', logo1, typeof logo1);
            form.append('image', logo1);
            await companyService.fileRegister(res.data.companyId, form, 'logo1');
          }
          if (logo2) {
            let form = new FormData();
            console.log('image', logo2, typeof logo2);
            form.append('image', logo2);
            await companyService.fileRegister(res.data.companyId, form, 'logo2');
          }
          if (etc) {
            let form = new FormData();
            console.log('image', etc, typeof etc);
            form.append('image', etc);
            await companyService.fileRegister(res.data.companyId, form, 'discountCard');
          }
          alert(id ? '수정되었습니다.' : '등록되었습니다.');
          history.goBack();
          reset();
        }
      }
    } catch (e) {
      // alert(JSON.stringify(e, null, 4));
      alert(e.response?.data?.message);
    }
    console.log('웹', res);
  };

  const fetchData = async () => {
    const res = await companyService.detail(id);
    if (res.code === 200) {
      console.log(res);
      setData(res.data)
      setStartDate(res.data.startDate)
      setEndDate(res.data.endDate)
      if(res.data.mainLogo) {
        setMainLogoPreviewThumbURL(`https://d2twzv77g3c69u.cloudfront.net/fit-in/1000x700/${res.data.mainLogo}`)
      }
      if(res.data.shareBanner) {
        setNanumTabPreviewThumbURL(`https://d2twzv77g3c69u.cloudfront.net/fit-in/1000x700/${res.data.shareBanner}`)
      }
      if(res.data.foavBanner) {
        setFoavTabPreviewThumbURL(`https://d2twzv77g3c69u.cloudfront.net/fit-in/1000x700/${res.data.foavBanner}`)
      }
      if(res.data.companyBanner) {
        setStoreDetailPreviewThumbURL(`https://d2twzv77g3c69u.cloudfront.net/fit-in/1000x700/${res.data.companyBanner}`)
      }
      if(res.data.logo1) {
        setLogo1PreviewThumbURL(`https://d2twzv77g3c69u.cloudfront.net/fit-in/1000x700/${res.data.logo1}`)
      }
      if(res.data.logo2) {
        setLogo2PreviewThumbURL(`https://d2twzv77g3c69u.cloudfront.net/fit-in/1000x700/${res.data.logo2}`)
      }
      if(res.data.discountCard) {
        setEtcPreviewThumbURL(`https://d2twzv77g3c69u.cloudfront.net/fit-in/1000x700/${res.data.discountCard}`)
      }
    }
  };

  useEffect(() => {
    if(id) {
      fetchData();
    } else {
      reset();
    }
  }, []);

  const remove = async () => {
    if (window.confirm('삭제하시겠습니까?')) {
      let res = await companyService.remove(Number(id));
      console.log(res);
      if (res.code === 200) {
        history.goBack();
      }
    }
  };

  if (loading) return <div>로딩중..</div>;
  return (
    <>
      <SimpleHeader name="Form elements" parentName="Forms" />
      <Container className="mt--6" fluid>
        <Card className="mb-4">
          <CardHeader style={{ display: 'flex', justifyContent: 'space-between' }}>
            {id?
              <>
                <h3 className="mt-2">기업 상세</h3>
                <div>
                  <Button
                    color="default"
                    type="button"
                    onClick={submit}
                  >
                    수정하기
                  </Button>
                  <Button
                    color="danger"
                    type="button"
                    onClick={remove}
                  >
                    삭제하기
                  </Button>
                </div>
              </>
              :
              <>
                <h3 className="mt-2">기업 등록</h3>
                <div>
                  <Button
                      color="default"
                      type="button"
                      onClick={submit}
                  >
                    <span className="btn-inner--icon mr-1">
                        <i className="fas fa-user-edit" />
                    </span>
                    등록하기
                  </Button>
                </div>
              </>
            }
          </CardHeader>
          <CardBody>
            <Row>
              <Col>
                <h3>상점 구분</h3>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    상점 구분 명
                  </label>
                  <Input
                      placeholder="상점구분 명을 입력해주세요."
                      type="text"
                      name="diff"
                      value={diff || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    기업 코드 명
                  </label>
                  <Input
                      placeholder="기업코드 명을 입력해주세요."
                      type="text"
                      name="code"
                      value={code || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    기업 명
                  </label>
                  <Input
                      placeholder="기업 명을 입력해주세요."
                      type="text"
                      name="name"
                      value={name || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col>
                <InputFile
                    title={'기업 로고 등록'}
                    fileState={companyLogoFileState}
                    filePreviewState={companyLogoFilePreviewState}
                />
              </Col>
            </Row>
            <Row className="mt-5">
              <Col>
                <h3>기업 랭킹, 거리순 배너</h3>
              </Col>
            </Row>
            <Row>
              <Col>
                <InputFile
                    title={'나눔탭(기부랭킹) 배너 등록 (가로 1200px x 세로 170px 비율 기본)'}
                    fileState={nanumTabFileState}
                    filePreviewState={nanumTabFilePreviewState}
                />
              </Col>
            </Row>
            <Row className="">
              <Col md="auto">
                <h5>나눔탭(기부랭킹) 배너 활성화/비활성화</h5>
              </Col>
              <Col md="auto" className="mt--1">
                <label className="custom-toggle custom-toggle-success center">
                  <input
                      checked={isShareBanner}
                      type="checkbox"
                      onChange={(e) => setData({...data, isShareBanner: !isShareBanner})}
                  />
                  <span
                      className="custom-toggle-slider rounded-circle"
                      data-label-off="No"
                      data-label-on="Yes"
                  />
                </label>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <InputFile
                    title={'포아브탭(거리순보기) 배너 등록 (가로 720px x 세로 102px 비율 기본)'}
                    fileState={foavTabFileState}
                    filePreviewState={foavTabFilePreviewState}
                />
              </Col>
            </Row>
            <Row>
              <Col md="auto" className="">
                <h5>포아브탭 배너 활성화/비활성화</h5>
              </Col>
              <Col md="auto" className="mt--1">
                <label className="custom-toggle custom-toggle-success center">
                  <input
                      checked={idFoavBanner}
                      type="checkbox"
                      onChange={(e) => setData({...data, idFoavBanner: !idFoavBanner})}
                  />
                  <span
                      className="custom-toggle-slider rounded-circle"
                      data-label-off="No"
                      data-label-on="Yes"
                  />
                </label>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col>
                <h3>기업 제휴 배너</h3>
              </Col>
            </Row>
            <Row>
              <Col>
                <InputFile
                    title={'업체 상세정보 배너 등록(가로 720px x 세로 102px 배율 기본)'}
                    fileState={storeDetailFileState}
                    filePreviewState={storeDetailFilePreviewState}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <InputFile
                    title={'로고1 추가 (별점 위)'}
                    fileState={logo1FileState}
                    filePreviewState={logo1FilePreviewState}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <InputFile
                    title={'로고2 추가 (별점 왼쪽)'}
                    fileState={logo2FileState}
                    filePreviewState={logo2FilePreviewState}
                />
              </Col>
            </Row>
            <Row className="mt-5">
              <Col>
                <h3>기타</h3>
              </Col>
            </Row>
            <Row>
              <Col>
                <InputFile
                    title={'할인 카드 이미지'}
                    fileState={etcFileState}
                    filePreviewState={etcFilePreviewState}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default CompanyForm;
