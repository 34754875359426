const api = () => {
  let apiURL = 'https://api.foav.co.kr';
  let url = 'https://api.foav.co.kr/docs';

  const routes = {
    apiURL,
    LOGIN: `${apiURL}/admin/login`,

    QUESTIONS_LIST: `${apiURL}/question/list`,
    QUESTIONS_DETAIL: `${apiURL}/question/detail`,
    QUESTIONS_REGISTER: `${apiURL}/question/register`,
    QUESTIONS_UPDATE: `${apiURL}/question/update`,
    QUESTIONS_REMOVE: `${apiURL}/question/remove`,

    RESULT_LIST: `${apiURL}/result/list`,
    RESULT_DETAIL: `${apiURL}/result/detail`,
    RESULT_REGISTER: `${apiURL}/result/register`,
    RESULT_REMOVE: `${apiURL}/result/remove`,
    RESULT_UPDATE: `${apiURL}/result/update`,

    COUNSELING_LIST: `${apiURL}/counseling/list`,
    COUNSELING_DETAIL: `${apiURL}/counseling/detail`,
    COUNSELING_REMOVE: `${apiURL}/counseling/remove`,
    COUNSELING_UPDATE: `${apiURL}/counseling/update`,

    PUSH_LIST: `${apiURL}/notificationLog/list`,
    PUSH_SEND: `${apiURL}/notification/send`,

    ADVERTISEMENT_LIST: `${apiURL}/advertisement/list`,
    ADVERTISEMENT_DETAIL: `${apiURL}/advertisement/detail`,
    ADVERTISEMENT_REGISTER: `${apiURL}/advertisement/register`,
    ADVERTISEMENT_UPDATE: `${apiURL}/advertisement/update`,
    ADVERTISEMENT_REMOVE: `${apiURL}/advertisement/remove`,
    ADVERTISEMENT_FILE_REGISTER: `${apiURL}/advertisement/file/register`,
    ADVERTISEMENT_FILES_REGISTER: `${apiURL}/advertisementImage/register`,
    ADVERTISEMENT_FILES_REMOVE: `${apiURL}/advertisementImage/remove`,

    VISITORS_STATISTICS: `${apiURL}/visitors/statistics`,
  };

  return routes;
};

export default api;
