import React, {Fragment, useEffect, useState} from "react";

// reactstrap components
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import CounselingService from "../../../services/counseling.service";
import PaginationWrapper from "../components/pagination";
import {numberWithCommas, paginate} from "../../../utils";
import useHistoryState from "../../../common/useHistoryState";
import classnames from "classnames";
import ReactDatetime from "react-datetime";
import moment from "moment";
import {Link} from "react-router-dom";
import charityRankService from "./service/charityRank.service";
import {useHistory} from "react-router";
import {shopCategory} from "../shop/service/shop.category";

function CharityRankList() {
  const history = useHistory();
  const [pageActive, setPageActive] = useHistoryState(1, 'pageActive');
  const [pageCount, setPageCount] = useHistoryState(1, 'pageCount');
  const [data, setData] = useHistoryState([], 'data');
  const [category, setCategory] = useHistoryState('전체', 'category');
  const [startDate, setStartDate] = useHistoryState(moment().format('YYYY-MM-01'), 'startDate');
  const [endDate, setEndDate] = useHistoryState(moment().format('YYYY-MM-DD'), 'endDate');
  const [searchCurrent, setSearchCurrent] = useHistoryState(false, 'searchCurrent');
  const [search, setSearch] = useHistoryState('', 'search');
  const [diff, setDiff] = useState('걸음');

  const render = async () => {
    const res = await charityRankService.list({ page: pageActive, startDate, endDate, diff });
    if (res.code === 200) {
      console.log(res);
      setPageCount(res.list.count);
      setData(res.list.rows);
    }
  };

  useEffect(() => {
    render();
  }, [category, pageActive, startDate, endDate, search, diff]);

  const handleReactDatetimeChange = (who, date) => {
    if (
        startDate &&
        who === "endDate" &&
        new Date(startDate) > new Date(date.format('YYYY-MM-DD'))
    ) {
      setStartDate(date.format('YYYY-MM-DD'));
      setEndDate(date.format('YYYY-MM-DD'));
    } else if (
        endDate &&
        who === "startDate" &&
        new Date(endDate) < new Date(date.format('YYYY-MM-DD'))
    ) {
      setStartDate(date.format('YYYY-MM-DD'));
      setEndDate(date.format('YYYY-MM-DD'));
    } else {
      if (who === "startDate") {
        setStartDate(date.format('YYYY-MM-DD'));
      } else {
        setEndDate(date.format('YYYY-MM-DD'));
      }
    }
  };

  const getClassNameReactDatetimeDays = (date) => {
    if (startDate && endDate) {
    }
    if (startDate && endDate && startDate !== endDate) {
      if (
          new Date(endDate) > new Date(date.format('YYYY-MM-DD')) &&
          new Date(startDate) < new Date(date.format('YYYY-MM-DD'))
      ) {
        return " middle-date";
      }
      if (endDate === date.format('YYYY-MM-DD')) {
        return " end-date";
      }
      if (startDate === date.format('YYYY-MM-DD')) {
        return " start-date";
      }
    }
    return "";
  };

  const buttonColor = (color) => {
    const on = '#3CC900';
    const off = '#5c5c5c';
    return {
      backgroundColor: diff === '걸음'? on: off,
      color: 'white',
      border: 'none'
    };
  };


  return (
    <>
      <SimpleHeader name="Tables" parentName="Tables" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row className="py-3">
                  <Col xs="11">
                    <Form>
                      <Row>
                        <Col xs="2">
                          <label className=" form-control-label">
                            날짜 조회
                          </label>
                          <FormGroup>
                            <ReactDatetime
                                inputProps={{
                                  placeholder: "",
                                }}
                                value={startDate}
                                locale={'ko'}
                                timeFormat={false}
                                dateFormat={'YYYY-MM-DD'}
                                onChange={(e) =>
                                    handleReactDatetimeChange("startDate", e)
                                }
                                renderDay={(props, currentDate, selectedDate) => {
                                  let classes = props.className;
                                  classes += getClassNameReactDatetimeDays(
                                      currentDate
                                  );
                                  return (
                                      <td {...props} className={classes}>
                                        {currentDate.date()}
                                      </td>
                                  );
                                }}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs="2">
                          <FormGroup>
                            <label className=" form-control-label py-2">
                            </label>
                            <ReactDatetime
                                inputProps={{
                                  placeholder: "",
                                }}
                                value={endDate}
                                locale={'ko'}
                                timeFormat={false}
                                dateFormat={'YYYY-MM-DD'}
                                onChange={(e) =>
                                    handleReactDatetimeChange("endDate", e)
                                }
                                renderDay={(props, currentDate, selectedDate) => {
                                  let classes = props.className;
                                  classes += getClassNameReactDatetimeDays(
                                      currentDate
                                  );
                                  return (
                                      <td {...props} className={classes}>
                                        {currentDate.date()}
                                      </td>
                                  );
                                }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
                <Row>
                  <Col xs="6">
                    <h3 className="mt-2">기부 랭킹 리스트</h3>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button
                        type="button"
                        style={diff === '걸음' ?
                            { backgroundColor: '#3CC900', color: 'white', border: 'none' }
                            :
                            { backgroundColor: '#5c5c5c', color: 'white', border: 'none' }
                    }
                        onClick={() => {
                          setDiff('걸음')
                        }}
                    >
                      걸음 랭킹
                    </Button>
                    <Button
                        type="button"
                        style={diff === '시간' ?
                            { backgroundColor: '#3CC900', color: 'white', border: 'none' }
                            :
                            { backgroundColor: '#5c5c5c', color: 'white', border: 'none' }
                        }
                        onClick={() => {
                          setDiff('시간')
                        }}
                    >
                      시간 랭킹
                    </Button>
                  </Col>
                </Row>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive striped>
                <thead className="thead-light">
                <tr>
                  <th className="text-center">랭킹</th>
                  <th className="text-center">이름</th>
                  <th className="text-center">아이디</th>
                  <th className="text-center">전화번호</th>
                  <th className="text-center">기부총합(걸음)</th>
                  <th className="text-center">기부총합(시간)</th>
                </tr>
                </thead>
                <tbody>
                {data?.map((item, i) => {
                  return [
                    <Fragment key={i}>
                      <tr>
                        <td className="text-center">
                          {item.rank}
                        </td>
                        <td className="text-center">
                          {item.user.name}
                        </td>
                        <td className="text-center">
                          {item.user.loginId}
                        </td>
                        <td className="text-center">
                          {item.user.tel}
                        </td>
                        <td className="text-center">
                          {item.sumCount} {item.diff}
                        </td>
                        <td className="text-center">
                          {/*{numberWithCommas(item.goal)}*/}
                        </td>
                      </tr>
                    </Fragment>
                  ];
                })}
                </tbody>
              </Table>

              <CardFooter className="py-4">
                <PaginationWrapper
                  isActive={pageActive}
                  pageNumber={pageCount}
                  callback={(page) => setPageActive(page)}
                  itemPerPage={10}
                />
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default CharityRankList;
