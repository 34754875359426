import React, {Fragment, useEffect, useRef, useState} from "react";
// reactstrap components
import {Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Input, Modal, Row,} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import {useHistory} from "react-router-dom";
import InputFile from "../components/InputFile";
import {
  companyDetailFilePreviewState,
  companyDetailFileState, companyThumbFilePreviewState,
  companyThumbFileState
} from "../carbonTab/service/carbonTab.service";
import {useRecoilState, useRecoilValue, useResetRecoilState} from "recoil";
import advertisementService, {
  bottomFilePreviewState,
  bottomFileState,
  detailFilePreviewState, detailFileState
} from "./service/advertisement.service";

function AdvertisementForm({ match, history }) {
  const { id } = match.params;

  console.log('===> Current id=', id);

  const isBottom = history.location.pathname === '/admin/bannerBottom'
  const isSetting = history.location.pathname === '/admin/bannerSetting'
  const isAlliance = history.location.pathname === '/admin/bannerAlliance'
  const isHome = history.location.pathname === '/admin/bannerHome'
  const isArea = history.location.pathname === '/admin/bannerArea'
  const isCarbon = history.location.pathname === '/admin/bannerCarbon'
  const isGlobal = history.location.pathname === '/admin/bannerGlobal'
  const isMarket = history.location.pathname === '/admin/bannerMarket'
  const isBottomDetail = history.location.pathname === `/admin/bottomDetail/${id}`
  const isSettingDetail = history.location.pathname === `/admin/settingDetail/${id}`
  const isAllianceDetail = history.location.pathname === `/admin/allianceDetail/${id}`
  const isHomeDetail = history.location.pathname === `/admin/homeDetail/${id}`
  const isAreaDetail = history.location.pathname === `/admin/areaDetail/${id}`
  const isCarbonDetail = history.location.pathname === `/admin/carbonDetail/${id}`
  const isGlobalDetail = history.location.pathname === `/admin/globalDetail/${id}`
  const isMarketDetail = history.location.pathname === `/admin/marketDetail/${id}`
  const router = useHistory();
  const [loading, setLoading] = useState(false);
  const [diff, setDiff] = useState('url');
  const [url, setUrl] = useState('');
  const fileBottom = useRecoilValue(bottomFileState);
  const [previewURLBottom, setPreviewURLBottom] = useRecoilState(bottomFilePreviewState);
  const fileDetail = useRecoilValue(detailFileState);
  const [previewURLDetail, setPreviewURLDetail] = useRecoilState(detailFilePreviewState);
  const resetThumbFile = useResetRecoilState(bottomFileState);
  const resetDetailFile = useResetRecoilState(detailFileState);
  const resetThumbPreview = useResetRecoilState(bottomFilePreviewState);
  const resetDetailPreview = useResetRecoilState(detailFilePreviewState);
  const [inputs, setInputs] = useState({
    company: '',
    area: ''
  });

  const { company, area } = inputs;
  console.log(inputs, diff)

  const onChange = (e) => {
    let { value, name } = e.target;
    if (name === 'sortCode') {
      value = Number(value);
    }
    setInputs({
      ...inputs,
      [name]: value
    });
  };

  const fetchData = async () => {
    if(id) {
      const res = await advertisementService.detail(id);
      console.log(res);
      if(res.code === 200) {
        setInputs(res.advertisement)
        if(res.advertisement.image.length > 7) {
          setPreviewURLDetail(res.advertisement.image)
          setDiff('이미지')
        } else {
          setDiff('url')
          setUrl(res.advertisement.url)
        }
        if(res.advertisement.company) {
          setInputs({ ...inputs, company: res.advertisement.company })
        }
        if(res.advertisement.thumbnail){
          setPreviewURLBottom(res.advertisement.thumbnail)
        }
      }
    } else {
      resetThumbFile();
      resetDetailFile();
      resetThumbPreview();
      resetDetailPreview();
    }
  };

  useEffect( () => {
    fetchData();
  }, []);

  const submit = async () => {
    if (company.length === 0) {
      alert('제목을 입력해주세요.');
      return;
    }

    // if ((diff === 'url' || diff === 'url+이미지') && url.length === 0) {
    //   alert('url 을 입력해주세요.');
    //   return;
    // }

    const data = {
      company,
      url,
      location: isBottom && 'bottom' || isSetting && 'setting' ||
          isAlliance && 'alliance' || isHome && 'home' ||
          isArea && 'area' || isCarbon && 'carbon' || isGlobal && 'english' || isMarket && 'market',
    };

    if(diff === '이미지') {
      delete data.url
    }

    if(isArea) {
      data.area = area
    }

    let res;
    if (id) {
      delete data.location
      if(isAreaDetail) {
        data.area = area
      }
      res = await advertisementService.update(id, data);
    } else {
      res = await advertisementService.register(data);
    }
    console.log(res);
    if (res.resultMsg === 'success') {
      if (fileBottom) {
        let form = new FormData();
        form.append('thumb', fileBottom);
        await advertisementService.fileRegister(id? id:Number(res.advertisement.id), form, 'thumb');
      }
      if (fileDetail && diff === '이미지') {
        let form = new FormData();
        form.append('img', fileDetail);
        await advertisementService.fileRegister(id? id:Number(res.advertisement.id), form, 'img');
      }
      alert(id ? '수정되었습니다.' : '등록되었습니다.');
      history.goBack();
      resetThumbFile();
      resetDetailFile();
      resetThumbPreview();
      resetDetailPreview();
    }
  }
  console.log(diff)
  if (loading) return <div>로딩중..</div>;
  return (
    <>
      <SimpleHeader name="Form elements" parentName="Forms" />
      <Container className="mt--6" fluid>
        <Card className="mb-4">
          <CardHeader style={{ display: 'flex', justifyContent: 'space-between' }}>
              {id?
                  <>
                    <h3 className="mt-2">{isAreaDetail && '지역 ' || isBottomDetail && '배너 ' || isSettingDetail && '설정 ' || isAllianceDetail && '법인제휴 ' || isHomeDetail && '홈 ' || isCarbonDetail && '탄소탭 '
                        || isGlobalDetail && '글로벌-탄소이음 ' || isMarket && '마켓'}
                      광고 상세</h3>
                    <div>
                      <Button
                          color="default"
                          type="button"
                          onClick={submit}
                      >
                        수정하기
                      </Button>
                      {/*<Button*/}
                      {/*    color="danger"*/}
                      {/*    type="button"*/}
                      {/*    // onClick={remove}*/}
                      {/*>*/}
                      {/*  삭제하기*/}
                      {/*</Button>*/}
                    </div>
                  </>
                  :
                  <>
                    <h3 className="mt-2">{isArea && '지역 ' || isBottom && '배너 ' || isSetting && '설정 ' || isAlliance && '법인제휴 ' || isHome && '홈 ' || isCarbon && '탄소탭 '
                        || isGlobal && '글로벌-탄소이음 ' || isMarket && '마켓 '}
                      광고 등록</h3>
                    <div>
                      <Button
                          color="default"
                          type="button"
                          onClick={submit}
                      >
                      <span className="btn-inner--icon mr-1">
                          <i className="fas fa-user-edit" />
                      </span>
                        등록하기
                      </Button>
                    </div>
                  </>
              }
          </CardHeader>
          <CardBody>
            {!isCarbon &&
              <Row>
                <Col className="pt-3">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="check1"
                    >
                      구분
                    </label>
                    <Row className="pl-3 pt-2">
                      <Col xs="1" className="custom-control custom-radio mb-3">
                        <input
                          className="custom-control-input"
                          id="customRadio5"
                          name="diff"
                          type="radio"
                          value='url'
                          checked={diff === 'url'}
                          onChange={(e) => setDiff('url')}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customRadio5"
                        >
                          url
                        </label>
                      </Col>
                      <Col xs="1" className="custom-control custom-radio mb-3">
                        <input
                          className="custom-control-input"
                          defaultChecked
                          id="customRadio6"
                          name="diff"
                          type="radio"
                          value='이미지'
                          checked={diff === '이미지'}
                          onChange={(e) => setDiff('이미지')}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customRadio6"
                        >
                          이미지
                        </label>
                      </Col>
                    </Row>
                  </FormGroup>
                </Col>
              </Row>
            }
            <Row>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="titleInput1"
                  >
                    {isCarbon && isGlobal ? '광고명':'업체명'}
                  </label>
                  <Input
                    id="titleInput1"
                    placeholder="입력해주세요"
                    name="company"
                    type="text"
                    value={company || ''}
                    onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            {isArea &&
              <Row>
                <Col md="4">
                  <FormGroup>
                    <label
                        className="form-control-label"
                        htmlFor="titleInput2"
                    >
                      지역명
                    </label>
                    <Input
                        id="titleInput2"
                        placeholder="지역명을 입력해주세요"
                        name="area"
                        type="text"
                        value={area || ''}
                        onChange={onChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
            }
            {isAreaDetail &&
              <Row>
                <Col md="4">
                  <FormGroup>
                    <label
                        className="form-control-label"
                        htmlFor="titleInput2"
                    >
                      지역명
                    </label>
                    <Input
                        id="titleInput2"
                        placeholder="지역명을 입력해주세요"
                        name="area"
                        type="text"
                        value={area || ''}
                        onChange={onChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
            }
            <Row>
              <Col>
                <InputFile
                    title={'대표 썸내일 첨부(720 x 340px)'}
                    fileState={bottomFileState}
                    filePreviewState={bottomFilePreviewState}
                />
              </Col>
            </Row>
            {diff === 'url'
              ?
                <Row>
                  <Col md="4">
                    <FormGroup>
                      <label
                          className="form-control-label"
                          htmlFor="titleInput1"
                      >
                        url
                      </label>
                      <Input
                          id="titleInput1"
                          placeholder="url을 입력해주세요"
                          name="url"
                          type="text"
                          value={url || ''}
                          onChange={(e) => setUrl(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              :
                <Row>
                  <Col>
                    <InputFile
                        title={'상세 이미지(가로 최소 720px)'}
                        fileState={detailFileState}
                        filePreviewState={detailFilePreviewState}
                    />
                  </Col>
                </Row>
            }
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default AdvertisementForm;
