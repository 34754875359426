import React, {useEffect, useRef, useState, Fragment} from "react";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Modal,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import {Link, useHistory} from "react-router-dom";
import {FormControl, InputGroup} from "react-bootstrap";
import DaumPostcode from "react-daum-postcode";
import shopService from "./shop.service";
import {shopCategory} from "./service/shop.category";
import {clickImg} from "../../../utils";
import advertisementService from "../advertisments/service/advertisement.service";

function ShopFrom({ match }) {
  const history = useHistory();
  const { id } = match.params;
  const [data, setData] = useState(null);
  const [defaultModal, setDefaultModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loginId, setLoginId] = useState('');

  const [inputs, setInputs] = useState({
    userId: 0,
    name: '',
    category: '카페',
    intro: '',
    homepage: undefined,
    tel: '',
    time: '',
    address: '',
    addressDetail: '',
    latitude: '',
    longitude: '',
    mapLink: '',
  });

  const { userId, name, address, addressDetail, category, intro, homepage, latitude, tel, time, longitude, mapLink } = inputs;

  const onChange = (e) => {
    let { value, name } = e.target;
    if (name === 'loginId') {
      setLoginId(value);
    } else {
      setInputs({
        ...inputs,
        [name]: value
      });
    }
  };

  const [file, setFile] = useState('');
  const [previewURL, setPreviewURL] = useState('');
  const [preview, setPreview] = useState(null);
  const fileRef = useRef();

  const [files, setFiles] = useState([]);
  const [previewURLs, setPreviewURLs] = useState([]);
  const [previews, setPreviews] = useState(null);
  const [deleteFilesId, setDeleteFilesId] = useState([]);
  const filesRef = useRef();

  const fetchData = async () => {
    setLoading(true);
    if (id) {
      const res = await shopService.detail(id);
      console.log(res);
      if (res.code === 200) {
        setData(res.data);
        if (res.data.thumbnail) {
          setPreviewURL(res.data.thumbnail);
          setPreview(
            <img
              className='img_preview ml-3'
              style={{ width: '250px', height: '250px' }}
              src={res.data.thumbnail}
            />
          );
        }
        if (res.data.images.length > 0) {
          setPreviewURLs(res.data.images);
        }
      }
      setInputs({
        userId: res.data.userId,
        name: res.data.name,
        category: res.data.category,
        intro: res.data.intro,
        homepage: res.data.homepage,
        tel: res.data.tel,
        time: res.data.time,
        address: res.data.address,
        addressDetail: res.data.addressDetail,
        latitude: res.data.latitude,
        longitude: res.data.longitude,
        mapLink: res.data.mapLink,
      });
    }
    setLoading(false);
  };

  useEffect( () => {
    fetchData();
  }, []);

  useEffect(() => {
    if (previews) {
      setPreviewURLs([...previewURLs, previews]);
    }
    return () => {};
  }, [previews]);

  //파일 불러오기
  const handleFileOnChanges = (e) => {
    e.preventDefault();
    let tempFiles = e.target.files;
    if (((tempFiles && tempFiles.length) + files.length) > 3) {
      alert('파일은 최대 3개만 등록 가능합니다.');
      return;
    }
    const filesArr = Array.prototype.slice.call(tempFiles);
    for (let file of filesArr) {
      files.push(file);
      let reader = new FileReader();
      reader.onload = (e) => {
        setPreviews(reader.result);
      };
      if (file) reader.readAsDataURL(file);
    }
  };

  const deleteFile = (eventParam, orderParam) => {
    const find = previewURLs.find((v, i) => i === orderParam);
    if (find.id) {
      setDeleteFilesId([
        ...deleteFilesId,
        previewURLs.find((v, i) => i === orderParam).id
      ]);
    }
    setFiles(files.filter((v, i) => i !== orderParam));
    setPreviewURLs(previewURLs.filter((v, i) => i !== orderParam));
  }

  const handleFileButtonClicks = (e) => {
    //버튼 대신 클릭하기
    e.preventDefault();
    filesRef.current.click(); // file 불러오는 버튼을 대신 클릭함
  };

  const handleFileOnChange = (event) => {
    //파일 불러오기
    event.preventDefault();
    let file = event.target.files[0];
    let reader = new FileReader();

    reader.onloadend = (e) => {
      setFile(file);
      setPreviewURL(reader.result);
    };
    if (file) reader.readAsDataURL(file);
  };

  const handleFileButtonClick = (e) => {
    //버튼 대신 클릭하기
    e.preventDefault();
    fileRef.current.click(); // file 불러오는 버튼을 대신 클릭함
  };

  useEffect(() => {
    if (file !== '')
      //처음 파일 등록하지 않았을 때를 방지
      setPreview(
        <img
          className='img_preview ml-3'
          style={{ width: '250px', height: '250px' }}
          src={previewURL}
        />
      );
    return () => {};
  }, [previewURL]);

  const removeItem = () => {
    advertisementService.remove(id)
      .then(res => res.data)
      .then(res => {
        if (res.statusCode === 200) {
          history.goBack();
        }
      });
  }

  const submit = async (e) => {
    setLoading(true);
    try {
      await createOrUpdate(e);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }

  const createOrUpdate = async (e) => {
    if (!userId || userId < 1) {
        alert('아이디를 조회해주세요.');
        return;
      }

    if (name.length === 0) {
      alert('업체명을 입력해주세요.');
      return;
    }

    if (intro.length === 0) {
      alert('문구를 입력해주세요.');
      return;
    }

    if (!file && !previewURL) {
      alert('대표 이미지를 등록해주세요.');
      return;
    }

    if (files.length === 0 && previewURLs.length === 0) {
      alert('업체 이미지를 등록해주세요.');
      return;
    }

    if (tel.length === 0) {
      alert('업체 전화번호를 입력해주세요.');
      return;
    }

    if (time.length === 0) {
      alert('영업시간을 입력해주세요.');
      return;
    }

    if (address.length === 0) {
      alert('주소를 입력해주세요.');
      return;
    }

    if (addressDetail.length === 0) {
      alert('주소를 입력해주세요.');
      return;
    }

    const data = {
      name, address, addressDetail, category, intro, latitude, tel, time, longitude, homepage, mapLink
    };

    let res;
    if (id) {
      res = await shopService.update(id, data);
    } else {
      data.userId = userId;
      res = await shopService.register(data);
    }
    console.log(res);
    if (res.code === 200) {
      const shopId = res.data?.shopId || id;
      if (file) {
        let form = new FormData();
        form.append('image', file);
        await shopService.fileRegister(shopId, form);
      }
      if (deleteFilesId.length > 0) {
        await shopService.filesRemove(deleteFilesId.join(','));
      }
      if (files.length > 0) {
        let form = new FormData();
        for (let f of files) {
          form.append('image', f);
        }
        await shopService.filesRegister(shopId, form);
      }
      alert(`${id ? '수정' : '등록'}되었습니다.`);
      history.push('/admin/shopUserList');
    }
  }

  const onChangeOpenPost = () => {
    setDefaultModal(true);
  };

  const onCompletePost = (data) => {
    let address = data.address;
    const geocoder = new window.daum.maps.services.Geocoder();
    geocoder.addressSearch(address, function (result, status) {
      // 정상적으로 검색이 완료됐으면
      if (status === window.daum.maps.services.Status.OK) {
        setInputs({
          ...inputs,
          address,
          // addressPreview: result[0].address.region_3depth_name,
          longitude: result[0].x,
          latitude: result[0].y
        });
      }
    })
    setDefaultModal(false);
  };

  // const postCodeStyle = {
  //   display: 'block',
  //   position: 'relative',
  //   top: '0%',
  //   width: '400px',
  //   height: '400px',
  //   padding: '7px',
  // };

  const loginIdCheck = async () => {
    const data = await shopService.loginIdCheck({ loginId });
    if (data.code === 200 && !data.user.shop) {
      alert('정상적으로 조회 되었습니다.');
      setInputs({
        ...inputs,
        userId: data.user.id
      })
    } else {
      alert('없는 유저 이거나 이미 등록된 아이디 입니다.');
    }
  }

  const remove = async () => {
    if (window.confirm('삭제하시겠습니까?')) {
      let res = await shopService.remove(id);
      console.log(res);
      if (res.code === 200) {
        history.goBack();
      }
    }
  }

  if (loading) return <div>로딩중..</div>;
  return (
    <>
      {defaultModal  ? (
        <Fragment>
          <Modal
            className="modal-dialog-centered"
            isOpen={defaultModal}
            toggle={() => setDefaultModal(() => false)}
            fade={false}
          >
            <DaumPostcode autoClose onComplete={onCompletePost} className="post-code" />
          </Modal>

        </Fragment>
      ) : null}
      <SimpleHeader name="Form elements" parentName="Forms" />
      <Container className="mt--6" fluid>
        <Card className="mb-4">
          <CardHeader>
            <Row>
              <Col xs="11">
                <h3 className="mb-0">상점프로필 {id ? '상세' : '등록'}</h3>
              </Col>
              {
                id ?
                  (<Col className="text-right" xs="12">
                    <Button
                      className="btn-round btn-icon"
                      color="primary"
                      onClick={submit}
                      size="sm"
                    >
                      <span className="btn-inner--text">저장하기</span>
                    </Button>
                    <Button
                      className="btn-round btn-icon"
                      color="danger"
                      onClick={remove}
                      size="sm"
                    >
                      <span className="btn-inner--text">삭제하기</span>
                    </Button>
                  </Col>) :
                  <Button
                    className="btn-round btn-icon"
                    color="primary"
                    onClick={submit}
                    size="md"
                  >
                    <span className="btn-inner--text">등록하기</span>
                  </Button>
              }
            </Row>
          </CardHeader>
          <CardBody>
            {id ? <Row>
              <Col xs="2">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example4cols1Input"
                  >
                    로그인아이디
                  </label>
                  <div>
                    {data?.loginId}
                  </div>
                </FormGroup>
              </Col>
            </Row> : null}
            {!id ? <Row>
              <Col md="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="titleInput1"
                  >
                    아이디 <span className='text-red'>(필수)</span>
                  </label>
                  <div className="form-inline">
                    {/*<FormGroup className="mb-lg-6 mr-sm-2 mb-sm-0">*/}
                    {/*  <Input type="password" name="password" id="examplePassword" placeholder="don't tell!" />*/}
                    {/*</FormGroup>*/}
                    {/*<Button>조회하기</Button>*/}
                    <Input
                      id="titleInput1"
                      placeholder="아이디"
                      name="loginId"
                      type="text"
                      value={loginId || ''}
                      onChange={onChange}
                    />
                    <Button
                      id="titleInput1"
                      className="ml-2"
                      color="primary"
                      type="button"
                      onClick={() => loginIdCheck()}
                    >
                      조회하기
                    </Button>
                  </div>
                </FormGroup>
              </Col>
            </Row> : null}
            <Row>
              <Col md="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    카테고리 선택 <span className='text-red'>(필수)</span>
                  </label>
                  <Input
                    id="example3cols1Input"
                    type="select"
                    value={category || ''}
                    onChange={(e) => setInputs({ ...inputs, category: e.target.value})}
                  >
                    {shopCategory.map((c, i) => {
                      return [
                        <option key={i}>{c}</option>
                      ]
                    })}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    업체명 <span className='text-red'>(필수)</span>
                  </label>
                  <Input
                    placeholder="사업자등록증 상의 상호와 동일해야 합니다."
                    type="text"
                    name="name"
                    value={name || ''}
                    onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    업체를 나타낼 수 있는 감각적 문구 <span className='text-red'>(필수)</span>
                  </label>
                  <Input
                    placeholder="50자 이내"
                    type="textarea"
                    rows="5"
                    name="intro"
                    value={intro || ''}
                    onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col xs="12">
                <label
                  className="form-control-label"
                  htmlFor="example4cols2Input"
                >
                  업체 대표 사진 <span className='text-red'>(필수)</span>
                  <p className="text-muted text-sm">대표사진은 리스트의 메인 사진으로 등록됩니다.</p>
                </label>
                <input
                  ref={fileRef}
                  hidden={true}
                  id='file'
                  type='file'
                  onChange={handleFileOnChange}
                />
                <Button
                  className="ml-4 mt--4"
                  color="primary"
                  size="md"
                  type="button"
                  onClick={handleFileButtonClick}
                >
                  첨부하기
                </Button>
                <div>
                  {previewURL && (
                    <img
                      style={{
                        height: '150px',
                        borderRadius: '5px',
                        marginRight: '20px',
                        cursor: 'pointer',
                      }}
                      src={previewURL}
                      onClick={() => clickImg(previewURL)}
                    />
                    // <a
                    //   className="avatar avatar-xl rounded"
                    //   style={{ cursor: 'pointer'}}
                    //   onClick={() => clickImg(previewURL)}
                    // >
                    //   <img src={previewURL}/>
                    // </a>
                  )}
                </div>
              </Col>

            </Row>
            <Row className="mt-2">
              <Col xs="12">
                <label
                  className="form-control-label"
                  htmlFor="example4cols2Input"
                >
                  업체사진 <span className='text-red'>(1장 필수, 총 3장까지 가능)</span>
                  <p className="text-muted text-sm">업체 소개 페이지에서 보여집니다.</p>
                </label>
                <input
                  ref={filesRef}
                  hidden={true}
                  id='file'
                  type='file'
                  multiple
                  onChange={handleFileOnChanges}
                />
                <Button
                  className="ml-4 mt--4"
                  color="primary"
                  size="md"
                  type="button"
                  onClick={handleFileButtonClicks}
                >
                  첨부하기
                </Button>
                <div>
                  {previewURLs.length > 0 && (
                    previewURLs.map((item, i) => {
                      return [
                        <Fragment key={i}>
                          <a
                            style={{
                              position: 'relative',
                              height: '150px',
                              marginRight: '15px',
                            }}
                          >
                            <img
                              style={{
                                height: '150px',
                                borderRadius: '5px',
                                marginRight: '20px',
                                cursor: 'pointer',
                              }}
                              src={item.name ? item.name : item}
                              onClick={() => clickImg(item.name ? item.name : item)}
                            />
                            <div
                              style={{
                                position: 'absolute',
                                top: -73,
                                right: 7,
                              }}
                            >
                              <span
                                style={{
                                  backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                  borderRadius: '100%',
                                  padding: '1px 10px',
                                  cursor: 'pointer',
                                }}
                                onClick={(e) => deleteFile(e, i)}
                              >
                                  X
                              </span>
                            </div>
                          </a>
                        </Fragment>
                      ];
                    }))
                  }
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="6" className="mt-4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    홈페이지
                    <p className="text-muted text-sm">가게 홍보 영상 및 홈페이지가 있는경우 URL을 입력해주세요</p>
                  </label>
                  <Input
                    className="mt--3"
                    type="text"
                    name="homepage"
                    value={homepage || ''}
                    onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    지도 링크
                  </label>
                  <Input
                    type="text"
                    name="mapLink"
                    value={mapLink || ''}
                    onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    업체 전화번호 <span className='text-red'>(필수)</span>
                  </label>
                  <Input
                    placeholder="- 없이 입력"
                    type="text"
                    name="tel"
                    value={tel || ''}
                    onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    영업시간 <span className='text-red'>(필수)</span>
                  </label>
                  <Input
                    placeholder="ex) 평일 10시~20시, 주말 11시~20시, 24시 운영 등"
                    type="text"
                    name="time"
                    value={time || ''}
                    onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    위치 <span className='text-red'>(필수)</span>
                  </label>
                  <InputGroup className="mb-3">
                    <FormControl
                      placeholder="주소 입력"
                      aria-label="주소 입력"
                      aria-describedby="basic-addon2"소
                      value={address || ''}
                      readOnly
                    />
                    <Button
                      className="ml-2"
                      variant="outline-secondary"
                      id="button-addon2"
                      color="primary"
                      onClick={() => onChangeOpenPost()}
                    >
                      주소 찾기
                    </Button>
                  </InputGroup>
                  <Input
                    placeholder="세부 주소 입력 ex) OO역 5번출구 도보 4분"
                    type="text"
                    name="addressDetail"
                    value={addressDetail || ''}
                    onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>

          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default ShopFrom;
