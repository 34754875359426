import axios from "axios";
import api from "../../../../config/api.routes";
import authHeader from "../../../../services/auth-header";
import {atom} from "recoil";

//file
export const groupThumbFileState = atom({
  key: 'groupThumbFileState',
  default: '',
});

export const groupThumbFilePreviewState = atom({
  key: 'groupThumbFilePreviewState',
  default: '',
});

export const charityDetailFileState = atom({
  key: 'charityDetailFileState',
  default: '',
});

export const charityDetailFilePreviewState = atom({
  key: 'charityDetailFilePreviewState',
  default: '',
});

const list = (data) => {
  let url = api().apiURL + `/companyGroup/list?`;
  for (let query of Object.keys(data)) {
    if (data[query] === undefined || data[query] === '') {
      continue;
    }
    url += `${query}=${data[query]}&`;
  }
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.get(url, { headers: authHeader() })
      .then(res => res.data)
      .then(res => resolve(res))
      .catch(e => reject(e));
  });
};

const register = (data) => {
  let url = api().apiURL + `/companyGroup/register?`;
  console.log('서비스',url,data);
  return new Promise((resolve, reject) => {
    axios.post(url, data, { headers: authHeader() })
        .then(res => res.data)
        .then(res => resolve(res))
        .catch(e => reject(e));
  });
};

const detail = (id) => {
  let url = api().apiURL + `/companyGroup/detail?id=${id}`;
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.get(url, { headers: authHeader() })
      .then(res => res.data)
      .then(res => resolve(res))
      .catch(e => reject(e));
  });
};

const detailGroup = (id, startDate, endDate) => {
  let url = api().apiURL + `/companyGroup/groupRank?id=${id}`;
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.get(url, { headers: authHeader() })
      .then(res => res.data)
      .then(res => resolve(res))
      .catch(e => reject(e));
  });
};

const detailSub = (id, startDate, endDate) => {
  let url = api().apiURL + `/companyGroup/subRank?id=${id}`;
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.get(url, { headers: authHeader() })
        .then(res => res.data)
        .then(res => resolve(res))
        .catch(e => reject(e));
  });
};

const detailPer = (id, startDate, endDate) => {
  let url = api().apiURL + `/companyGroup/perRank?id=${id}`;
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.get(url, { headers: authHeader() })
        .then(res => res.data)
        .then(res => resolve(res))
        .catch(e => reject(e));
  });
};

const update = (id, data) => {
  let url = api().apiURL + `/companyGroup/update?id=${id}`;
  console.log('수정',url);
  return new Promise((resolve, reject) => {
    axios.put(url, data, { headers: authHeader() })
        .then(res => res.data)
        .then(res => resolve(res))
        .catch(e => reject(e));
  });
};

const remove = (id) => {
  let url = api().apiURL + `/companyGroup/remove?id=${id}`;
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.delete(url, { headers: authHeader() })
        .then(res => res.data)
        .then(res => resolve(res))
        .catch(e => reject(e));
  });
};

const fileRegister = (id, data) => {
  console.log(id, data)
  let url = api().apiURL + `/companyGroup/file/register?companyGroupId=${id}`;
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.post(url, data, { headers: authHeader() })
        .then(res => res.data)
        .then(res => resolve(res))
        .catch(e => reject(e));
  });
};

const fileRemove = (id) => {
  let url = api().apiURL + `/companyGroup/file/remove?id=${id}`;
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.delete(url, { headers: authHeader() })
        .then(res => res.data)
        .then(res => resolve(res))
        .catch(e => reject(e));
  });
};

const companyGroupService = {
  list,
  register,
  detail,
  update,
  remove,
  detailGroup,
  detailSub,
  detailPer,
  fileRegister,
  fileRemove,
}

export default companyGroupService;
