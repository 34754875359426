//file
import {atom} from "recoil";
import axios from "axios";
import api from "../../../../config/api.routes";
import authHeader from "../../../../services/auth-header";

export const detailFileState = atom({
    key: 'detailFileState',
    default: '',
});

export const detailFilePreviewState = atom({
    key: 'detailFilePreviewState',
    default: '',
});

export const thumbnailFileState = atom({
    key: 'thumbnailFileState',
    default: '',
});

export const thumbnailFilePreviewState = atom({
    key: 'thumbnailFilePreviewState',
    default: '',
});

const list = (data) => {
    let url = api().apiURL + `/sharingPlogging/list?`;

    if (!!data) {
        for (let query of Object.keys(data)) {
            if (data[query] === undefined || data[query] === '') {
                continue;
            }
            url += `${query}=${data[query]}&`;
        }
    }
    console.log(url);
    return new Promise((resolve, reject) => {
        axios.get(url, {headers: authHeader()})
            .then(res => res.data)
            .then(res => resolve(res))
            .catch(e => reject(e));
    });
};

const rank = (data) => {
    let url = api().apiURL + `/plogging/rank?`;
    for (let query of Object.keys(data)) {
        if (data[query] === undefined || data[query] === '') {
            continue;
        }
        url += `${query}=${data[query]}&`;
    }
    console.log(url);
    return new Promise((resolve, reject) => {
        axios.get(url, {headers: authHeader()})
            .then(res => res.data)
            .then(res => resolve(res))
            .catch(e => reject(e));
    });
};

const update = (id, data) => {
    let url = api().apiURL + `/sharingPlogging/update?id=${id}`;
    console.log('수정', url);
    return new Promise((resolve, reject) => {
        axios.put(url, data, {headers: authHeader()})
            .then(res => res.data)
            .then(res => resolve(res))
            .catch(e => reject(e));
    });
};

const register = (data) => {
    let url = api().apiURL + `/sharingPlogging/register`;
    console.log(url, data);
    return new Promise((resolve, reject) => {
        axios.post(url, data, {headers: authHeader()})
            .then(res => res.data)
            .then(res => {
                if (res['code'] === 203) {
                    alert('존재하지 않는 아이디입니다.');
                }
                resolve(res);
            })
            .catch(e => {
                console.log(1234);
                console.log(e);
                reject(e);
            });
    });
};

const remove = (id) => {
    let url = api().apiURL + `/sharingPlogging/remove?id=${id}`;
    console.log(url);
    return new Promise((resolve, reject) => {
        axios.delete(url, {headers: authHeader()})
            .then(res => res.data)
            .then(res => resolve(res))
            .catch(e => reject(e));
    });
};

const fileRegister = (id, data) => {
    let url = api().apiURL + `/sharingPlogging/file/register`;
    console.log(url);
    return new Promise((resolve, reject) => {
        axios.post(url, data, {
            headers: authHeader(), params: {
                sharingPloggingId: id,
            }
        })
            .then(res => res.data)
            .then(res => resolve(res))
            .catch(e => reject(e));
    });
};

const imageRegister = (id, data) => {
    let url = api().apiURL + `/sharingPlogging/image/register`;
    console.log(url);
    return new Promise((resolve, reject) => {
        axios.post(url, data, {
            headers: authHeader(), params: {
                sharingPloggingId: id,
            }
        })
            .then(res => res.data)
            .then(res => resolve(res))
            .catch(e => reject(e));
    });
};


const ploggingService = {
    list,
    rank,
    update,
    fileRegister,
    imageRegister,
    register,
    remove,
}

export default ploggingService;
