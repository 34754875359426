import React, {Fragment, useEffect, useState} from "react";

// reactstrap components
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup, Input,
  InputGroup, InputGroupAddon, InputGroupText,
  Row,
  Table,
} from "reactstrap";
// layout for this page
// core components
import {useHistory} from "react-router";
import {numberWithCommas, telWithCommas} from "../../../utils";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import PaginationWrapper from "../components/pagination";
import useHistoryState from "../../../common/useHistoryState";
import moment from "moment";
import companyRankService from "./service/companyRank.service";
import companyService from "../company/service/company.service";
import ReactDatetime from "react-datetime";
import classnames from "classnames";
import AuthService from "../auth/auth.service";
import serviceNewsService from "../serviceNews/service/serviceNews.service";
import xlsx from "xlsx";

function CompanyRankDetail({ match }) {
  const { id } = match.params;
  const history = useHistory();
  const center = JSON.parse(localStorage.getItem('user'))?.centerId
  const user = typeof window !== 'undefined' ? AuthService.getCurrentUser() : null;
  const company = JSON.parse(localStorage.getItem('user'))?.companyId
  const [pageActive, setPageActive] = useHistoryState(1, 'pageActive');
  const [pageCount, setPageCount] = useHistoryState(1, 'pageCount');
  const [statics, setStatics] = useHistoryState(1, 'statics');
  const [data, setData] = useHistoryState([], 'data');
  const [sort, setSort] = useHistoryState('', 'sort');
  const [startDate, setStartDate] = useHistoryState(moment().format('YYYY-MM-01'), 'startDate');
  const [endDate, setEndDate] = useHistoryState(moment().format('YYYY-MM-DD'), 'endDate');
  const [searchCurrent, setSearchCurrent] = useHistoryState(false, 'searchCurrent');
  const [search, setSearch] = useHistoryState('', 'search');

  const render = async () => {
    const res = await companyRankService.detail({
      limit: 15, page: pageActive, sort, companyId: user.role === 'center'? company : Number(id), startDate, endDate, search
    });
    if (res.code === 200) {
      console.log(res.list);
      setPageCount(res.list.count);
      setData(res.list.rows);
      setStatics(res.list.data);
    }
  };

  useEffect(() => {
    render();
  }, [ pageActive, sort, startDate, endDate, search ]);

  const handleReactDatetimeChange = (who, date) => {
    if (
        startDate &&
        who === "endDate" &&
        new Date(startDate) > new Date(date.format('YYYY-MM-DD'))
    ) {
      setStartDate(date.format('YYYY-MM-DD'));
      setEndDate(date.format('YYYY-MM-DD'));
    } else if (
        endDate &&
        who === "startDate" &&
        new Date(endDate) < new Date(date.format('YYYY-MM-DD'))
    ) {
      setStartDate(date.format('YYYY-MM-DD'));
      setEndDate(date.format('YYYY-MM-DD'));
    } else {
      if (who === "startDate") {
        setStartDate(date.format('YYYY-MM-DD'));
      } else {
        setEndDate(date.format('YYYY-MM-DD'));
      }
    }
  };

  const getClassNameReactDatetimeDays = (date) => {
    if (startDate && endDate) {
    }
    if (startDate && endDate && startDate !== endDate) {
      if (
          new Date(endDate) > new Date(date.format('YYYY-MM-DD')) &&
          new Date(startDate) < new Date(date.format('YYYY-MM-DD'))
      ) {
        return " middle-date";
      }
      if (endDate === date.format('YYYY-MM-DD')) {
        return " end-date";
      }
      if (startDate === date.format('YYYY-MM-DD')) {
        return " start-date";
      }
    }
    return "";
  };

  const down = () => {
    companyRankService.excelDown({ excel: 'download', page: pageActive, limit: 15, companyId: user.role === 'center'? company : Number(id), startDate, endDate, sort })
        .then((res) => {
          fileCreate(res);
        });
  }

  const downDaily = () => {
    companyRankService.excelDown({ excel: 'download', page: pageActive, limit: 15, companyId: user.role === 'center'? company : Number(id), startDate, endDate, sort, diff: '일별' })
        .then((res) => {
          fileCreate(res);
        });
  }

  const fileCreate = (res) => {
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');
    const contentDisposition = res.headers['content-disposition']; // 파일 이름
    let fileName = 'report';
    if (contentDisposition) {
      const [ fileNameMatch ] = contentDisposition.split(';').filter(str => str.includes('filename'));
      if (fileNameMatch)
        [ , fileName ] = fileNameMatch.split('=');
    }
    link.href = url;
    link.setAttribute('download', `${fileName}.xlsx`);
    link.style.cssText = 'display:none';
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  const remove = async (id) => {
    if (window.confirm('삭제하시겠습니까?')) {
      let res = await companyRankService.remove(id);
      console.log(res);
      if (res.code === 200) {
        render();
      }
    }
  };

  return (
    <>
      <SimpleHeader name="Tables" parentName="Tables" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row className="py-3">
                  <Col md="auto">
                    <ReactDatetime
                        inputProps={{
                          placeholder: "",
                        }}
                        value={startDate}
                        locale={'ko'}
                        timeFormat={false}
                        dateFormat={'YYYY-MM-DD'}
                        onChange={(e) =>
                            handleReactDatetimeChange("startDate", e)
                        }
                        renderDay={(props, currentDate, selectedDate) => {
                          let classes = props.className;
                          classes += getClassNameReactDatetimeDays(
                              currentDate
                          );
                          return (
                              <td {...props} className={classes}>
                                {currentDate.date()}
                              </td>
                          );
                        }}
                    />
                  </Col>
                  <Col md="auto">
                    <ReactDatetime
                        inputProps={{
                          placeholder: "",
                        }}
                        value={endDate}
                        locale={'ko'}
                        timeFormat={false}
                        dateFormat={'YYYY-MM-DD'}
                        onChange={(e) =>
                            handleReactDatetimeChange("endDate", e)
                        }
                        renderDay={(props, currentDate, selectedDate) => {
                          let classes = props.className;
                          classes += getClassNameReactDatetimeDays(
                              currentDate
                          );
                          return (
                              <td {...props} className={classes}>
                                {currentDate.date()}
                              </td>
                          );
                        }}
                    />
                  </Col>
                  <Col md="auto">
                    <Input
                        type="select"
                        value={sort}
                        onChange={(e) => setSort(e.target.value)}
                    >
                      <option>전체</option>
                      <option>걸음</option>
                      <option>탄소저감량</option>
                      <option>탄소e음포인트</option>
                    </Input>
                  </Col>
                  <Col xs="2">
                    <FormGroup>
                      <InputGroup
                          className={classnames("input-group-merge", {
                            focused: searchCurrent,
                          })}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-search" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                            // placeholder="제목으로 검색"
                            type="text"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            onFocus={(e) => setSearchCurrent(true)}
                            onBlur={(e) => setSearchCurrent(false)}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md="auto" className="mr-5">
                    기업명 <span className="text-black-50 font">{statics.name}</span>
                  </Col>
                  <Col md="auto" className="mr-5">
                    기업코드 <span className="text-black-50">{statics.code}</span>
                  </Col>
                  <Col md="auto" className="mr-5">
                    기부총합(걸음) <span className="text-black-50">{numberWithCommas(statics?.serviceStep)}</span>
                  </Col>
                  <Col md="auto" className="mr-5">
                    걸음걸이 현황 <span className="text-black-50">{numberWithCommas(statics?.totalStep)}</span>
                  </Col>
                  <Col md="auto" className="mr-5">
                    탄소e음포인트 <span className="text-black-50">{numberWithCommas(statics?.challengePoint)}</span>
                  </Col>
                  <Col md="auto" className="mr-5">
                    탄소저감량 <span className="text-black-50">{numberWithCommas(statics?.carbon)}</span>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col>
                    <h3 className="mt-2">임직원 활동 리스트</h3>
                  </Col>
                  <Col className="text-right" md="auto">
                    <Button
                        color="success"
                        type="button"
                        onClick={() => downDaily()}
                    >
                      일별 걸음 다운
                    </Button>
                    <Button
                        color="primary"
                        type="button"
                        onClick={() => down()}
                    >
                      엑셀 다운로드
                    </Button>
                  </Col>
                </Row>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive striped>
                <thead className="thead-light">
                <tr>
                  <th className="text-center">랭킹</th>
                  <th className="text-center">직번</th>
                  <th className="text-center">회원명</th>
                  <th className="text-center">아이디</th>
                  <th className="text-center">탄소 e음 챌린지<br/>(임직원 챌린지)</th>
                  <th className="text-center">탄소저감량</th>
                  <th className="text-center">전화번호</th>
                  <th className="text-center">기부총합(걸음)</th>
                  <th className="text-center">걸음걸이 현황</th>
                  <th className="text-center">상태 메세지</th>
                  <th className="text-center">기업유저삭제</th>
                </tr>
                </thead>
                <tbody>
                {data?.map((item, i) => {
                  return [
                    <Fragment key={i}>
                      <tr>
                        <td className="text-center">
                          {item.rank}
                        </td>
                        <td className="text-center">
                          {item.staffNumber? item.staffNumber:'-'}
                        </td>
                        <td className="text-center">
                          {item.name}
                        </td>
                        <td className="text-center">
                          {item.loginId}
                        </td>
                        <td className="text-center">
                          {item.challengePoint}
                        </td>
                        <td className="text-center">
                          {item.carbon}
                        </td>
                        <td className="text-center">
                          {item.tel}
                        </td>
                        <td className="text-center">
                          {numberWithCommas(item.serviceStep)}
                        </td>
                        <td className="text-center">
                          {numberWithCommas(item.totalStep)}
                        </td>
                        <td className="text-center">
                          {item.stateMessage? item.stateMessage:'-'}
                        </td>
                        <td className="text-center">
                          <Button
                              className="btn-outline-danger"
                              onClick={() => remove(item.id)}
                              size="sm"
                          >
                            <span className="btn-inner--text">강제추방</span>
                          </Button>
                        </td>
                      </tr>
                    </Fragment>
                  ];
                })}
                </tbody>
              </Table>

              <CardFooter className="py-4">
                <PaginationWrapper
                  isActive={pageActive}
                  pageNumber={pageCount}
                  callback={(page) => setPageActive(page)}
                  itemPerPage={10}
                />
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default CompanyRankDetail;
