import React, {Fragment, useEffect, useState} from "react";

// reactstrap components
import {Button, Card, CardFooter, CardHeader, Col, Container, Input, Row, Table,} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import {useHistory} from "react-router-dom";
import useHistoryState from "../../../../common/useHistoryState";
import PaginationWrapper from "../../components/pagination";
import carbonTabService from "../service/carbonTab.service";
import advertisementService from "../../advertisments/service/advertisement.service";
import ploggingService from "../../plogging/service/plogging.service";

function CarbonHistoryList() {
  const router =  useHistory();
  const [pageActive, setPageActive] = useHistoryState(1, 'pageActive');
  const [pageCount, setPageCount] = useHistoryState(1, 'pageCount');
  const [data, setData] = useHistoryState([], 'data');
  const [kg, setKg] = useState('');

  const render = async () => {
    const res = await carbonTabService.historyList({ page: pageActive, diff: '저감량' });
    if (res.code === 200) {
      console.log(res);
      setPageCount(res.list.rows.length);
      setData(res.list.rows);
    }
  };

  useEffect(() => {
    render();
  }, [pageActive]);

  const submit = async () => {
    let res;
    res = await carbonTabService.register({ kg: Number(kg) });
    if (res.code === 200) {
      alert('보정량이 추가되었습니다.');
      render();
    }
  };

  const remove = (id) => {
    if (window.confirm('삭제하시겠습니까?')) {
      carbonTabService.remove(Number(id))
          .then(res => res.data)
          .then(res => {
            alert('삭제되었습니다.')
            render();
          })
    }
  };

  return (
    <>
      <SimpleHeader name="Tables" parentName="Tables" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col xs="6"/>
                  <Col className="text-right" xs="6">
                  </Col>
                </Row>
                <Row>
                  <Col xs="10">
                    <h4 className="mb-0">(엠텍)알류미늄 수거 탄소 저감량</h4>
                  </Col>
                  <Col className="text-right">
                    <Row>
                      <Col className="text-right">
                        <Input
                          placeholder="보정량 추가"
                          type="text"
                          name=""
                          onChange={(e) => setKg(e.target.value)}
                        >
                        </Input>
                      </Col>
                      <Col>
                        <Button
                          className="btn-round btn-icon"
                          color="info"
                          id="tooltip443412080"
                          onClick={(e) => {
                            submit();
                          }}
                        >
                          <span className="btn-inner--text">추가하기</span>
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive striped>
                <thead className="thead-light">
                  <tr>
                    <th className="text-center">등록날짜</th>
                    <th className="text-center">구분</th>
                    <th className="text-center">보정량 추가</th>
                    <th className="text-center">관리</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((item, i) => {
                    return [
                      <Fragment key={i}>
                        <tr>
                          <td className="text-center">
                            {item.createdAt}
                          </td>
                          <td className="text-center">
                            {item.diff}
                          </td>
                          <td className="text-center">
                            {item.carbonReduction}kg
                          </td>
                          <td className="text-center">
                            <Button
                                className="btn-outline-danger"
                                onClick={() => remove(item.id)}
                                size="sm"
                            >
                              <span className="btn-inner--text">삭제</span>
                            </Button>
                          </td>
                        </tr>
                      </Fragment>
                    ];
                  })}
                </tbody>
              </Table>

              <CardFooter className="py-4">
                <PaginationWrapper
                  isActive={pageActive}
                  pageNumber={pageCount}
                  callback={(page) => setPageActive(page)}
                  itemPerPage={10}
                />
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default CarbonHistoryList;
