//file
import axios from "axios";
import api from "../../../../config/api.routes";
import authHeader from "../../../../services/auth-header";


const list = (data) => {
    let url = api().apiURL + `/pay/list?`;
    console.log(url);

    if (!!data) {
        for (let query of Object.keys(data)) {
            if (data[query] === undefined || data[query] === '') {
                continue;
            }
            url += `${query}=${data[query]}&`;
        }
    }
    return new Promise((resolve, reject) => {
        axios.get(url, {headers: authHeader()})
            .then(res => res.data)
            .then(res => resolve(res))
            .catch(e => reject(e));
    });
};


const payService = {
    list,
}

export default payService;
