//file
import {atom} from "recoil";
import axios from "axios";
import api from "../../../../config/api.routes";
import authHeader from "../../../../services/auth-header";

export const detailFileState = atom({
    key: 'detailFileState',
    default: '',
});

export const detailFilePreviewState = atom({
    key: 'detailFilePreviewState',
    default: '',
});

export const thumbnailFileState = atom({
    key: 'thumbnailFileState',
    default: '',
});

export const thumbnailFilePreviewState = atom({
    key: 'thumbnailFilePreviewState',
    default: '',
});

const list = (data) => {
    let url = api().apiURL + `/ecoFriendsHistory/list?`;
    for (let query of Object.keys(data)) {
        if (data[query] === undefined || data[query] === '') {
            continue;
        }
        url += `${query}=${data[query]}&`;
    }
    console.log(url);
    return new Promise((resolve, reject) => {
        axios.get(url, { headers: authHeader() })
          .then(res => res.data)
          .then(res => resolve(res))
          .catch(e => reject(e));
    });
};

const register = (data) => {
    let url = api().apiURL + `/ecoFriendsHistory/register`;
    console.log(url,data);
    return new Promise((resolve, reject) => {
        axios.post(url, data, { headers: authHeader() })
            .then(res => res.data)
            .then(res => resolve(res))
            .catch(e => reject(e));
    });
};

const detail = (id) => {
    let url = api().apiURL + `/ecoFriendsHistory/detail?id=${id}`;
    console.log(url);
    return new Promise((resolve, reject) => {
        axios.get(url, { headers: authHeader() })
            .then(res => res.data)
            .then(res => resolve(res))
            .catch(e => reject(e));
    });
};

const update = (id, data) => {
    let url = api().apiURL + `/ecoFriendsHistory/update?id=${id}`;
    console.log('수정',url);
    return new Promise((resolve, reject) => {
        axios.put(url, data, { headers: authHeader() })
            .then(res => res.data)
            .then(res => resolve(res))
            .catch(e => reject(e));
    });
};

const remove = (id) => {
    let url = api().apiURL + `/ecoFriendsHistory/remove?id=${id}`;
    console.log(url);
    return new Promise((resolve, reject) => {
        axios.delete(url, { headers: authHeader() })
            .then(res => res.data)
            .then(res => resolve(res))
            .catch(e => reject(e));
    });
};


const ecoFriendsHistoryService = {
    list,
    register,
    detail,
    update,
    remove,
}

export default ecoFriendsHistoryService;
