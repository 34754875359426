import React, {Fragment, useEffect, useState} from "react";

// reactstrap components
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText, Modal,
  Row,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import CounselingService from "../../../services/counseling.service";
import PaginationWrapper from "../components/pagination";
import {numberWithCommas, paginate} from "../../../utils";
import useHistoryState from "../../../common/useHistoryState";
import classnames from "classnames";
import ReactDatetime from "react-datetime";
import moment from "moment";
import {Link} from "react-router-dom";
import qnaService from "./service/qna.service";
import {useHistory} from "react-router";
import {shopCategory} from "../shop/service/shop.category";
import challengeService from "../carbon/service/challenge.service";
import QuestionService from "../../../services/questions.service";
import dayjs from "dayjs";

function QnaCategoryList() {
  const history = useHistory();
  const [pageActive, setPageActive] = useHistoryState(1, 'pageActive');
  const [pageCount, setPageCount] = useHistoryState(1, 'pageCount');
  const [data, setData] = useHistoryState([], 'data');
  const [defaultModal, setDefaultModal] = useState(false);
  const [name, setName] = useState('');
  const [id, setId] = useState();

  const render = async () => {
    let option = { };
    const res = await qnaService.categoryList(option);
    if (res.code === 200) {
      console.log(res);
      setPageCount(res.list.length);
      setData(res.list);
    }
  };

  useEffect(() => {
    render();
  }, [pageActive]);

  const remove = async () => {
    if (window.confirm('삭제하시겠습니까?')) {
      let res = await qnaService.categoryRemove(id);
      if (res.code === 200) {
        setDefaultModal(false)
        setName('')
        setId()
        render();
      }
    }
  };

  const submit = async () => {
    try {
      let res;
      if (id) {
        res = await qnaService.categoryUpdate(id, {name});
      } else {
        res = await qnaService.categoryRegister({name});
      }
      if (res.code === 200) {
        setDefaultModal(false)
        setName('')
        setId()
        render();
      }
    } catch (e) {
    }
  };

  return (
    <>
      <SimpleHeader name="Tables" parentName="Tables" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col xs="6">
                    <h3 className="mt-2">
                      카테고리 관리
                    </h3>
                  </Col>
                  <Col className="text-right">
                    <Button
                        color="default"
                        onClick={() => setDefaultModal(() => true)}
                    >
                      등록하기
                    </Button>
                  </Col>
                </Row>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive striped>
                <thead className="thead-light">
                  <tr>
                    <th className="text-center">No.</th>
                    <th className="text-center">등록날짜</th>
                    <th className="text-center">카테고리</th>
                    <th className="text-center">상세</th>
                  </tr>
                </thead>
                <tbody>
                {data?.map((item, i) => {
                  return [
                    <Fragment key={i}>
                      <tr>
                        <td className="text-center">
                          {item.id}
                        </td>
                        <td className="text-center">
                          {dayjs(item.createdAt).format('YYYY-MM-DD')}
                        </td>
                        <td className="text-center">
                          {item.name}
                        </td>
                        <td className="text-center">
                          <Button
                              className="btn-outline-default"
                              onClick={() => {
                                setId(item.id)
                                setName(item.name)
                                setDefaultModal(() => true)
                              }}
                              size="sm"
                          >
                            <span className="btn-inner--text">상세</span>
                          </Button>
                        </td>
                      </tr>
                    </Fragment>
                  ];
                })}
                </tbody>
              </Table>

              <CardFooter className="py-4">
                <PaginationWrapper
                  isActive={pageActive}
                  pageNumber={pageCount}
                  callback={(page) => setPageActive(page)}
                  itemPerPage={10}
                />
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      <Modal
          className="modal-dialog-centered"
          isOpen={defaultModal}
          toggle={() => setDefaultModal(() => false)}
          fade={false}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            카테고리 {id? '수정':'등록'}
          </h6>
          <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setDefaultModal(() => false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <Col>
              <FormGroup>
                <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                >
                  카테고리
                </label>
                <Input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
        </div>
        {id
            ?
            <div className="modal-footer">
              <Button
                  color="warning"
                  type="button"
                  onClick={submit}
              >
                수정하기
              </Button>
              <Button
                  color="danger"
                  type="button"
                  onClick={remove}
              >
                삭제하기
              </Button>
            </div>
          :
            <div className="modal-footer">
              <Button
                  color="default"
                  type="button"
                  onClick={submit}
              >
                등록하기
              </Button>
            </div>
        }
      </Modal>
    </>
  );
}

export default QnaCategoryList;
