import React, {Fragment, useEffect, useState} from "react";

// reactstrap components
import {
    Button,
    Card,
    CardFooter,
    CardHeader,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row,
    Table,
    UncontrolledTooltip,
} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import CounselingService from "../../../services/counseling.service";
import PaginationWrapper from "../components/pagination";
import {paginate} from "../../../utils";
import useHistoryState from "../../../common/useHistoryState";
import classnames from "classnames";
import ReactDatetime from "react-datetime";
import moment from "moment";
import {Link} from "react-router-dom";
import {shopCategory} from "../shop/service/shop.category";
import {useHistory} from "react-router";
import AuthService from "../auth/auth.service";
import charityService from "./service/charity.service";

function CharityHistoryList({ match }) {
    const history = useHistory();
    const { id } = match.params;
    const [pageActive, setPageActive] = useHistoryState(1, 'pageActive');
    const [pageCount, setPageCount] = useHistoryState(1, 'pageCount');
    const [data, setData] = useHistoryState([], 'data');
    const [startDate, setStartDate] = useHistoryState(moment().format('YYYY-01-01'), 'startDate');
    const [endDate, setEndDate] = useHistoryState(moment().format('YYYY-MM-DD'), 'endDate');
    const [searchCurrent, setSearchCurrent] = useHistoryState(false, 'searchCurrent');
    const [search, setSearch] = useHistoryState('', 'search');

    const handleReactDatetimeChange = (who, date) => {
        if (
            startDate &&
            who === "endDate" &&
            new Date(startDate) > new Date(date.format('YYYY-MM-DD'))
        ) {
            setStartDate(date.format('YYYY-MM-DD'));
            setEndDate(date.format('YYYY-MM-DD'));
        } else if (
            endDate &&
            who === "startDate" &&
            new Date(endDate) < new Date(date.format('YYYY-MM-DD'))
        ) {
            setStartDate(date.format('YYYY-MM-DD'));
            setEndDate(date.format('YYYY-MM-DD'));
        } else {
            if (who === "startDate") {
                setStartDate(date.format('YYYY-MM-DD'));
            } else {
                setEndDate(date.format('YYYY-MM-DD'));
            }
        }
    };

    const getClassNameReactDatetimeDays = (date) => {
        if (startDate && endDate) {
        }
        if (startDate && endDate && startDate !== endDate) {
            if (
                new Date(endDate) > new Date(date.format('YYYY-MM-DD')) &&
                new Date(startDate) < new Date(date.format('YYYY-MM-DD'))
            ) {
                return " middle-date";
            }
            if (endDate === date.format('YYYY-MM-DD')) {
                return " end-date";
            }
            if (startDate === date.format('YYYY-MM-DD')) {
                return " start-date";
            }
        }
        return "";
    };

    const render = () => {
        charityService.historyList({ charityId: id, page: pageActive, startDate, endDate })
            .then((res) => {
                if (res.code === 200) {
                    console.log(res);
                    setPageCount(res.list.count);
                    setData(res.list.rows);
                }
            });
    }

    useEffect(() => {
        render();
    }, [startDate, endDate, pageActive])

    const down = () => {
        charityService.charityHistoryDetailDown({ charityId: id, startDate, endDate, excel: 'download' })
          .then((res) => {
              fileCreate(res);
          });
    }

    const fileCreate = (res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        const contentDisposition = res.headers['content-disposition']; // 파일 이름
        let fileName = 'report';
        if (contentDisposition) {
            const [ fileNameMatch ] = contentDisposition.split(';').filter(str => str.includes('filename'));
            if (fileNameMatch)
                [ , fileName ] = fileNameMatch.split('=');
        }
        link.href = url;
        link.setAttribute('download', `${fileName}.xlsx`);
        link.style.cssText = 'display:none';
        document.body.appendChild(link);
        link.click();
        link.remove();
    }

    return (
        <>
            <SimpleHeader name="Tables" parentName="Tables" />
            <Container className="mt--6" fluid>
                <Row>
                    <div className="col">
                        <Card>
                            <CardHeader className="border-0">
                                <Row className="py-3">
                                    <Col xs="11">
                                        <Form>
                                            <Row>
                                                <Col xs="2">
                                                    <label className=" form-control-label">
                                                        날짜 조회
                                                    </label>
                                                    <FormGroup>
                                                        <ReactDatetime
                                                            inputProps={{
                                                                placeholder: "",
                                                            }}
                                                            value={startDate}
                                                            locale={'ko'}
                                                            timeFormat={false}
                                                            dateFormat={'YYYY-MM-DD'}
                                                            onChange={(e) =>
                                                                handleReactDatetimeChange("startDate", e)
                                                            }
                                                            renderDay={(props, currentDate, selectedDate) => {
                                                                let classes = props.className;
                                                                classes += getClassNameReactDatetimeDays(
                                                                    currentDate
                                                                );
                                                                return (
                                                                    <td {...props} className={classes}>
                                                                        {currentDate.date()}
                                                                    </td>
                                                                );
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col xs="2">
                                                    <FormGroup>
                                                        <label className=" form-control-label py-2">
                                                        </label>
                                                        <ReactDatetime
                                                            inputProps={{
                                                                placeholder: "",
                                                            }}
                                                            value={endDate}
                                                            locale={'ko'}
                                                            timeFormat={false}
                                                            dateFormat={'YYYY-MM-DD'}
                                                            onChange={(e) =>
                                                                handleReactDatetimeChange("endDate", e)
                                                            }
                                                            renderDay={(props, currentDate, selectedDate) => {
                                                                let classes = props.className;
                                                                classes += getClassNameReactDatetimeDays(
                                                                    currentDate
                                                                );
                                                                return (
                                                                    <td {...props} className={classes}>
                                                                        {currentDate.date()}
                                                                    </td>
                                                                );
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="6">
                                        <h3 className="mt-2">기부내역 상세</h3>
                                    </Col>
                                    <Col className="text-right">
                                        <Button color="primary" onClick={(e) => down()}>
                                          엑셀 다운로드
                                        </Button>
                                    </Col>
                                </Row>
                            </CardHeader>

                            <Table className="align-items-center table-flush" responsive striped>
                                <thead className="thead-light">
                                    <tr>
                                        <th className="text-center">유저 ID</th>
                                        <th className="text-center">이름</th>
                                        <th className="text-center">기부 날짜</th>
                                        <th className="text-center">기부 수단</th>
                                        <th className="text-center">기부 상황</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {data?.map((item, i) => {
                                    return [
                                        <Fragment key={i}>
                                            <tr>
                                                <td className="text-center">
                                                    {item.user.id}
                                                </td>
                                                <td className="text-center">
                                                    {item.user.name}
                                                </td>
                                                <td className="text-center">
                                                    {item.createdAt}
                                                </td>
                                                <td className="text-center">
                                                    {item.diff}
                                                </td>
                                                <td className="text-center">
                                                    {item.count}걸음
                                                </td>
                                            </tr>
                                        </Fragment>
                                    ];
                                })}
                                </tbody>
                            </Table>

                            <CardFooter className="py-4">
                                <PaginationWrapper
                                    isActive={pageActive}
                                    pageNumber={pageCount}
                                    callback={(page) => setPageActive(page)}
                                    itemPerPage={10}
                                />
                            </CardFooter>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
}

export default CharityHistoryList;
