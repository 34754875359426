import React, {useEffect, useRef, useState, Fragment} from "react";
// reactstrap components
import {
  Button,
  Card,
  CardBody, CardFooter,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input, InputGroupAddon, InputGroupText,
  Label,
  Modal,
  Row, Table,
  UncontrolledTooltip,
} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import {useHistory} from "react-router-dom";
import useHistoryState from "../../../common/useHistoryState";
import moment from "moment";
import PaginationWrapper from "../components/pagination";
import ReactDatetime from "react-datetime";
import InputFile from "../components/InputFile";
import {useRecoilState, useRecoilValue, useResetRecoilState} from "recoil";
import companyService from "../company/service/company.service";
import {FormControl, InputGroup} from "react-bootstrap";
import InputFiles from "../components/InputFiles";
import DaumPostcode from "react-daum-postcode";
import companyCenterService from "./service/companyCenter.service";
import classnames from "classnames";
import AuthService from "../auth/auth.service";
import xlsx from "xlsx";

function CompanyDetail({ match }) {
  const user = typeof window !== 'undefined' ? AuthService.getCurrentUser() : null;
  const center = JSON.parse(localStorage.getItem('user'))?.centerId;
  const company = JSON.parse(localStorage.getItem('user'))?.companyId;
  const history = useHistory();
  const { id } = match.params;
  const [list, setList] = useHistoryState([], 'list');
  const [pageActive, setPageActive] = useHistoryState(1, 'pageActive');
  const [pageCount, setPageCount] = useHistoryState(1, 'pageCount');
  const [loading, setLoading] = useState(false);
  const [checkList, setCheckList] = useState([]);
  const [checkOneList, setCheckOneList] = useState([]);
  const [companyList, setCompanyList] = useHistoryState([], 'companyList');
  const [companyId, setCompanyId] = useState(117);
  const [clazz, setClazz] = useState([]);
  const [data, setData] = useState({
    loginId: '',
    password: '',
    name: '',
    tel: '',
    nation: '',
  });
  const {
    loginId,
    password,
    name,
    tel,
    nation,
  } = data;

  const onChange = (e) => {
    let { value, name } = e.target;
    if (name === 'managerTel'
    ) {
      setData({
        ...data,
        [name]: Number(value.replace(/[^0-9]/g, '')),
      })
    } else {
      setData({
        ...data,
        [name]: value
      });
    }
  };

  const fetchData = async () => {
    const res = await companyCenterService.detail(user?.role === 'center'? center:id);
    if (res.code === 200) {
      console.log(res);
      setData(res.centers[0])
      setClazz(res.centers[0]?.serviceGroups)
    }
  };
  console.log(clazz)

  const companyRender = async () => {
    const res = await companyService.list({});
    if (res.code === 200) {
      console.log(res);
      setCompanyList(res.list);
    }
  };

  useEffect(() => {
    companyRender();
    if(id) {
      fetchData();
    }
  }, []);

  const submit = async () => {
    setLoading(true);
    try {
      await createOrUpdate();
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }

  const createOrUpdate = async () => {

    const json = {
      ...data,
      companyId: Number(companyId),
    }

    console.log('json',json);

    let res;
    try {
      if (id) {
        res = await companyCenterService.update(id, json);
      }
      if (res.statusCode === 200) {
        alert(id ? '수정되었습니다.' : '등록되었습니다.');
        history.goBack();
      }
    } catch (e) {
      // alert(JSON.stringify(e, null, 4));
      alert(e.response?.data?.message);
    }
    console.log('웹', res);
  };

  const remove = async (id) => {
    if (window.confirm('삭제하시겠습니까?')) {
      let res = await companyCenterService.remove(id);
      console.log(res);
      if (res.code === 200) {
        alert('삭제되었습니다.')
        fetchData();
      }
    }
  };

  const excelDownload = () => {
    const workSheet = xlsx.utils.json_to_sheet(clazz);
    workSheet['!autofilter'] = { ref: 'A1:R11' };

    const workBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(workBook, workSheet, 'sheet title');
    xlsx.writeFile(workBook, 'report.xlsx');
  };

  if (loading) return <div>로딩중..</div>;
  return (
    <>
      <SimpleHeader name="Form elements" parentName="Forms" />
      <Container className="mt--6" fluid>
        <Card className="mb-4">
          <CardHeader style={{ display: 'flex', justifyContent: 'space-between' }}>
            {id &&
              <>{center
                  ? <h3 className="mt-2">봉사 담당자 관리</h3>
                  : <h3 className="mt-2">기업 센터 상세</h3>
                }
                <div>
                  <Button
                    color="default"
                    type="button"
                    onClick={submit}
                  >
                    수정하기
                  </Button>
                  <Button
                    color="danger"
                    type="button"
                    onClick={remove}
                  >
                    삭제하기
                  </Button>
                </div>
              </>
            }
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="3">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    센터명
                  </label>
                  <Input
                      placeholder="국가를 입력해주세요."
                      type="text"
                      name="name"
                      value={name || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    아이디
                  </label>
                  <Input
                      type="text"
                      name="loginId"
                      value={loginId || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    비밀번호
                  </label>
                  <Input
                      placeholder="비밀번호는 8 ~ 13자, 영문 또는 영문 + 숫자 + 특수문자($@$!%*#?&.-) 입니다."
                      type="text"
                      name="password"
                      value={password || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    전화번호
                  </label>
                  <Input
                      type="text"
                      name="tel"
                      value={tel || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <div className="col">
                <Card>
                  <CardHeader className="border-0">
                    <Row>
                      <Col xs="6">
                      </Col>
                        <Col className="text-right">
                          <Button
                              color="default"
                              onClick={() => excelDownload()}
                          >
                            엑셀 다운
                          </Button>
                          {user.role === 'center'
                              ?
                              <Button color="default" onClick={(e) => history.push('/admin/companyCenter/groupForm/' + center)}>
                                단체 등록
                              </Button>
                              :
                              <Button color="default" onClick={(e) => history.push('/admin/companyCenter/groupForm/' + id)}>
                                단체 등록
                              </Button>
                          }
                        </Col>
                    </Row>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive striped>
                    <thead className="thead-light">
                    <tr>
                      <th className="text-center">단체명</th>
                      <th className="text-center">담당자 수</th>
                      <th className="text-center">관리</th>
                    </tr>
                    </thead>
                    <tbody>
                      {data?.serviceGroups?.map((item, i) => {
                        return [
                          <Fragment key={i}>
                            <tr>
                              <td className="text-center">
                                {item.groupName}
                              </td>
                              <td className="text-center">
                                {item.managerCount}
                              </td>
                              <td className="text-center">
                                <Button
                                    className="btn-outline-danger"
                                    onClick={() => remove(item.id)}
                                    size="sm"
                                >
                                  <span className="btn-inner--text">삭제</span>
                                </Button>
                                {user.role === 'center'
                                    ?
                                    <Button
                                        className="btn-outline-default"
                                        onClick={(e) => history.push('/admin/companyCenter/groupDetail/' + center + '/' + item.id)}
                                        size="sm"
                                    >
                                      <span className="btn-inner--text">상세</span>
                                    </Button>
                                    :
                                    <Button
                                        className="btn-outline-default"
                                        onClick={(e) => history.push('/admin/companyCenter/groupDetail/' + id + '/' + item.id)}
                                        size="sm"
                                    >
                                      <span className="btn-inner--text">상세</span>
                                    </Button>
                                }
                              </td>
                            </tr>
                          </Fragment>
                        ];
                      })}
                    </tbody>
                  </Table>

                  <CardFooter className="py-4">
                    <PaginationWrapper
                        isActive={pageActive}
                        pageNumber={pageCount}
                        callback={(page) => setPageActive(page)}
                        itemPerPage={10}
                    />
                  </CardFooter>
                </Card>
              </div>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default CompanyDetail;
