import React, {useEffect, useState} from "react";
// reactstrap components
import {Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Input, Row,} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import {useHistory} from "react-router-dom";
import staffService from "./service/staff.service";

function StaffForm({ match }) {
  const history = useHistory();
  const { id } = match.params;
  const [loading, setLoading] = useState(false);
  const [passwordCheck, setPasswordCheck] = useState('');
  const [data, setData] = useState({
    loginId: '',
    password: '',
  });
  const {
    loginId,
    password,
  } = data;

  const onChange = (e) => {
    let { value, name } = e.target;
    if (name === 'sortCode'
    ) {
      setData({
        ...data,
        [name]: Number(value.replace(/[^0-9]/g, '')),
      })
    } else {
      setData({
        ...data,
        [name]: value
      });
    }
  };

  const submit = async () => {
    setLoading(true);
    try {
      await createOrUpdate();
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }

  const createOrUpdate = async () => {
    if (!loginId) {
        alert('제목을 입력해주세요.');
        return;
    }
    if (!password || !passwordCheck) {
      alert('비밀번호를 입력해주세요');
      return;
    }
    if (password !== passwordCheck) {
        alert('비밀번호가 일치하지 않습니다.');
        return;
    }

    const json = {
      loginId,
      password
    }

    console.log('json',json);

    let res;
    try {
      if (id) {
        res = await staffService.update(id, json);
      } else {
        res = await staffService.register(json);
      }
      if (res.resultMsg === "success") {
        alert(id ? '수정되었습니다.' : '등록되었습니다.');
        history.goBack();
      }
    } catch (e) {
      // alert(JSON.stringify(e, null, 4));
      alert(e.response?.data?.message);
    }
    console.log('웹', res);
  };

  const fetchData = async () => {
    const res = await staffService.detail(id);
    if (res.code === 200) {
      console.log(res);
      setData(res.data)
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const remove = async () => {
    if (window.confirm('삭제하시겠습니까?')) {
      let res = await staffService.remove(id);
      console.log(res);
      if (res.code === 200) {
        history.goBack();
      }
    }
  };

  if (loading) return <div>로딩중..</div>;
  return (
    <>
      <SimpleHeader name="Form elements" parentName="Forms" />
      <Container className="mt--6" fluid>
        <Card className="mb-4">
          <CardHeader style={{ display: 'flex', justifyContent: 'space-between' }}>
            {id?
                <>
                  <h3 className="mt-2">직원 상세</h3>
                  <div>
                    <Button
                      color="default"
                      type="button"
                      onClick={submit}
                    >
                      수정하기
                    </Button>
                    <Button
                      color="danger"
                      type="button"
                      onClick={remove}
                    >
                      삭제하기
                    </Button>
                  </div>
                </>
                :
                <>
                  <h3 className="mt-2">직원 등록</h3>
                  <div>
                    <Button
                        color="default"
                        type="button"
                        onClick={submit}
                    >
                      <span className="btn-inner--icon mr-1">
                          <i className="fas fa-user-edit" />
                      </span>
                      등록하기
                    </Button>
                  </div>
                </>
            }
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    직원 아이디
                  </label>
                  <Input
                      placeholder="아이디를 입력해주세요."
                      type="text"
                      name="loginId"
                      value={loginId || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    직원 비밀번호
                  </label>
                  <Input
                      placeholder="비밀번호를 입력해주세요."
                      type="password"
                      name="password"
                      value={password || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    직원 비밀번호 확인
                  </label>
                  <Input
                      placeholder="비밀번호를 확인해주세요."
                      type="password"
                      name="passwordCheck"
                      value={passwordCheck || ''}
                      onChange={(e) => setPasswordCheck(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default StaffForm;
