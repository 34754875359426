import React, {Fragment, useEffect} from "react";

// reactstrap components
import {
    Button,
    Card,
    CardFooter,
    CardHeader,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row,
    Table,
    UncontrolledTooltip,
} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import PaginationWrapper from "../components/pagination";
import {paginate} from "../../../utils";
import useHistoryState from "../../../common/useHistoryState";
import classnames from "classnames";
import ReactDatetime from "react-datetime";
import moment from "moment";
import {Link} from "react-router-dom";
import {useHistory} from "react-router";
import AuthService from "../auth/auth.service";
import userService from "./user.service";

function StepList({ match }) {
    const { id } = match.params;
    const user = typeof window !== 'undefined' ? AuthService.getCurrentUser() : null;
    const history = useHistory();
    const [pageActive, setPageActive] = useHistoryState(1, 'pageActive');
    const [pageCount, setPageCount] = useHistoryState(1, 'pageCount');
    const [data, setData] = useHistoryState([], 'data');

    const render = () => {
        let option = { page: pageActive, userId: id };
        userService.stepList(option)
            .then((res) => {
                if (res.code === 200) {
                    console.log(res);
                    setPageCount(res.list.count);
                    setData(res.list.rows);
                }
            });
    }

    useEffect(() => {
        render();
    }, [pageActive]);

    return (
        <>
            <SimpleHeader name="Tables" parentName="Tables" />
            <Container className="mt--6" fluid>
                <Row>
                    <div className="col">
                        <Card>
                            <CardHeader className="border-0">
                                {/*<Row className="py-3">*/}
                                {/*    <Col xs="11">*/}
                                {/*        <Form>*/}
                                {/*            <Row>*/}
                                {/*                <Col xs="3">*/}
                                {/*                    <label className=" form-control-label py-2">*/}
                                {/*                    </label>*/}
                                {/*                    <FormGroup>*/}
                                {/*                        <InputGroup*/}
                                {/*                            className={classnames("input-group-merge", {*/}
                                {/*                                focused: searchCurrent,*/}
                                {/*                            })}*/}
                                {/*                        >*/}
                                {/*                            <InputGroupAddon addonType="prepend">*/}
                                {/*                                <InputGroupText>*/}
                                {/*                                    <i className="fas fa-search" />*/}
                                {/*                                </InputGroupText>*/}
                                {/*                            </InputGroupAddon>*/}
                                {/*                            <Input*/}
                                {/*                                // placeholder="제목으로 검색"*/}
                                {/*                                type="text"*/}
                                {/*                                value={search}*/}
                                {/*                                onChange={(e) => setSearch(e.target.value)}*/}
                                {/*                                onFocus={(e) => setSearchCurrent(true)}*/}
                                {/*                                onBlur={(e) => setSearchCurrent(false)}*/}
                                {/*                            />*/}
                                {/*                        </InputGroup>*/}
                                {/*                    </FormGroup>*/}
                                {/*                </Col>*/}
                                {/*            </Row>*/}
                                {/*        </Form>*/}
                                {/*    </Col>*/}
                                {/*</Row>*/}
                                <Row>
                                    <Col xs="6">
                                        <h3 className="mt-2">걸음 관리</h3>
                                    </Col>
                                    {user?.role === 'center' ? null : (
                                      <Col className="text-right">
                                          <Button
                                            color="default"
                                            onClick={
                                              (e) => {
                                                  history.push({
                                                      pathname: '/admin/stepForm',
                                                      state: {
                                                          userId: id
                                                      }
                                                  })
                                              }
                                            }
                                          >
                                              등록하기
                                          </Button>
                                      </Col>
                                    )}
                                </Row>
                            </CardHeader>

                            <Table className="align-items-center table-flush" responsive striped>
                                <thead className="thead-light">
                                    <tr>
                                        <th className="text-center">고유키</th>
                                        <th className="text-center">상태</th>
                                        <th className="text-center">카운트</th>
                                        <th className="text-center">적립일</th>
                                        <th/>
                                    </tr>
                                </thead>
                                <tbody>
                                {data?.map((item, i) => {
                                    return [
                                        <Fragment key={i}>
                                            <tr>
                                                <td className="text-center">
                                                    {item.id}
                                                </td>
                                                <td className="text-center">
                                                    {item.status}
                                                </td>
                                                <td className="text-center">
                                                    {item.stepsCount}
                                                </td>
                                                <td className="text-center">
                                                    {moment(item.date).format('YYYY-MM-DD')}
                                                </td>
                                                {/*<td className="text-center">*/}
                                                {/*    <label className="custom-toggle custom-toggle-success center">*/}
                                                {/*        <input*/}
                                                {/*            checked={item.active}*/}
                                                {/*            type="checkbox"*/}
                                                {/*            onChange={(e) => statusChange(item.id, !item.active)}*/}
                                                {/*        />*/}
                                                {/*        <span*/}
                                                {/*            className="custom-toggle-slider rounded-circle"*/}
                                                {/*            data-label-off="No"*/}
                                                {/*            data-label-on="Yes"*/}
                                                {/*        />*/}
                                                {/*    </label>*/}
                                                {/*</td>*/}
                                                <td className="text-center">
                                                    <Link className="table-action" to={'/admin/stepDetail/' + item.id}>
                                                        <i id={"donation" + item.id} className="fas fa-user-edit"/>
                                                    </Link>
                                                    <UncontrolledTooltip delay={0} target={"donation" + item.id}>
                                                        상세 보기
                                                    </UncontrolledTooltip>
                                                </td>
                                            </tr>
                                        </Fragment>
                                    ];
                                })}
                                </tbody>
                            </Table>

                            <CardFooter className="py-4">
                                <PaginationWrapper
                                    isActive={pageActive}
                                    pageNumber={pageCount}
                                    callback={(page) => setPageActive(page)}
                                    itemPerPage={15}
                                />
                            </CardFooter>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
}

export default StepList;
