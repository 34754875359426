import React, {useEffect, useRef, useState, Fragment} from "react";
// reactstrap components
import {
  Button,
  Card,
  CardBody, CardFooter,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Modal,
  Row, Table,
  UncontrolledTooltip,
} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import {useHistory} from "react-router-dom";
import useHistoryState from "../../../common/useHistoryState";
import moment from "moment";
import PaginationWrapper from "../components/pagination";
import ReactDatetime from "react-datetime";
import InputFile from "../components/InputFile";
import {useRecoilState, useRecoilValue, useResetRecoilState} from "recoil";
import companyStoreService, {
  companyStoreDeletedId,
  companyStorePreviewState,
  companyStoreState
} from "./service/companyStore.service";
import companyService from "../company/service/company.service";
import {FormControl, InputGroup} from "react-bootstrap";
import InputFiles from "../components/InputFiles";
import DaumPostcode from "react-daum-postcode";

function CompanyStoreForm({ match }) {
  const history = useHistory();
  const { id } = match.params;
  const [startDate, setStartDate] = useHistoryState(moment().format('YYYY-MM-DD'), 'startDate');
  const [endDate, setEndDate] = useHistoryState(moment().add(1, 'month').format('YYYY-MM-DD'), 'endDate');
  const [pageActive, setPageActive] = useHistoryState(1, 'pageActive');
  const [pageCount, setPageCount] = useHistoryState(1, 'pageCount');
  const [loading, setLoading] = useState(false);
  const [list, setList] = useHistoryState([], 'list');
  const [image, setImage] = useHistoryState([], 'image');
  const [checkList, setCheckList] = useState([]);
  const [checkOneList, setCheckOneList] = useState([]);
  const [companyList, setCompanyList] = useHistoryState([], 'companyList');
  const [defaultModal, setDefaultModal] = useState(false);

  const filesState = useRecoilValue(companyStoreState);
  const [previewsThumbURL, setPreviewsThumbURL] = useRecoilState(companyStorePreviewState);
  const resetFilesState = useResetRecoilState(companyStoreState);
  const resetFilesPreview = useResetRecoilState(companyStorePreviewState);
  const [data, setData] = useState({
    name: '',
    category: '기업가맹점',
    tel: '',
    discount: '',
    managerTel: '',
    url: '',
    content: '',
    representative: '',
    companyCode: '',
    companyName: '',
    address: '',
    addressDetail: '',
    latitude: '',
    longitude: '',
    origin: '',
  });
  const {
    name,
    category,
    tel,
    discount,
    managerTel,
    url,
    content,
    representative,
    companyCode,
    companyName,
    address,
    addressDetail,
    latitude,
    longitude,
    origin,
  } = data;

  const reset = () => {
    // resetThumbFile();
    // resetDetailFile();
    // resetThumbFilePreview();
    // resetDetailFilePreview()
  }

  const onChange = (e) => {
    let { value, name } = e.target;
    if (name === 'managerTel' ||
        name === 'tel'
    ) {
      setData({
        ...data,
        [name]: Number(value.replace(/[^0-9]/g, '')),
      })
    } else {
      setData({
        ...data,
        [name]: value
      });
    }
  };

  const fetchData = async () => {
    const res = await companyStoreService.detail(id);
    if (res.code === 200) {
      console.log(res);
      setData(res.store)
      setCheckOneList([res.store.companyId])
      setCheckList(res.store.companyStores.map((v, i) => {
        return v.companyId
      }))
      setPreviewsThumbURL(res.store.images.map((v, i) => {
        return `https://d2twzv77g3c69u.cloudfront.net/fit-in/1000x700/${v}`
      }))
    }
  };

  const companyRender = async () => {
    const res = await companyService.list({});
    if (res.code === 200) {
      console.log(res);
      setCompanyList(res.list);
    }
  };

  useEffect(() => {
    companyRender();
    if(id) {
      fetchData();
    } else {
      reset();
    }
  }, []);

  const submit = async () => {
    setLoading(true);
    try {
      await createOrUpdate();
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }

  const createOrUpdate = async () => {
    // if (!title) {
    //     alert('제목을 입력해주세요.');
    //     return;
    // }
    // if (!content) {
    //     alert('내용을 입력해주세요.');
    //     return;
    // }
    //
    const json = {
      ...data,
      companyId: checkOneList[0],
      companyStore: checkList
    }

    console.log('json',json);

    let res;
    try {
      if (id) {
        res = await companyStoreService.update(id, json);
      } else {
        res = await companyStoreService.register(json);
      }
      if (res.statusCode === 200) {
        alert(id ? '수정되었습니다.' : '등록되었습니다.');
        history.goBack();
        reset();
      }
    } catch (e) {
      // alert(JSON.stringify(e, null, 4));
      alert(e.response?.data?.message);
    }
    console.log('웹', res);
  };

  const isCheck = (value, type, setType) => {
    const index = type.indexOf(value); // value 값의 위치를 찾음.
    if (index > -1) { //  값이 있는 경우, -1은 값이없음.
      setType([
        ...type.filter(v => v !== value)
      ]);
    } else {
      setType([
        ...type,
        value
      ]);
    }
  };

  const isCheckOne = (value, type, setType) => {
    const index = type.indexOf(value); // value 값의 위치를 찾음.
    if (index > -1) { //  값이 있는 경우, -1은 값이없음.
      setType([
        ...type.filter(v => v !== value)
      ]);
    } else {
      setType([
        value
      ]);
    }
  };
  console.log(checkList)

  const remove = async () => {
    if (window.confirm('삭제하시겠습니까?')) {
      let res = await companyStoreService.remove(id);
      console.log(res);
      if (res.code === 200) {
        history.goBack();
      }
    }
  };

  const onChangeOpenPost = () => {
    setDefaultModal(true);
  };

  const onCompletePost = (data) => {
    let address = data.address;
    const geocoder = new window.daum.maps.services.Geocoder();
    geocoder.addressSearch(address, function (result, status) {
      // 정상적으로 검색이 완료됐으면
      if (status === window.daum.maps.services.Status.OK) {
        setData({
          ...data,
          address,
          // addressPreview: result[0].address.region_3depth_name,
          longitude: result[0].x,
          latitude: result[0].y
        });
      }
    })
    setDefaultModal(false);
  };

  if (loading) return <div>로딩중..</div>;
  return (
    <>
      {defaultModal  ? (
          <Fragment>
            <Modal
                className="modal-dialog-centered"
                isOpen={defaultModal}
                toggle={() => setDefaultModal(() => false)}
                fade={false}
            >
              <DaumPostcode autoClose onComplete={onCompletePost} className="post-code" />
            </Modal>

          </Fragment>
      ) : null}
      <SimpleHeader name="Form elements" parentName="Forms" />
      <Container className="mt--6" fluid>
        <Card className="mb-4">
          <CardHeader style={{ display: 'flex', justifyContent: 'space-between' }}>
            {id?
                <>
                  <h3 className="mt-2">기부 상세</h3>
                  <div>
                    <Button
                      color="default"
                      type="button"
                      onClick={submit}
                    >
                      수정하기
                    </Button>
                    <Button
                      color="danger"
                      type="button"
                      onClick={remove}
                    >
                      삭제하기
                    </Button>
                  </div>
                </>
                :
                <>
                  <h3 className="mt-2">기부 등록</h3>
                  <div>
                    <Button
                        color="default"
                        type="button"
                        onClick={submit}
                    >
                      <span className="btn-inner--icon mr-1">
                          <i className="fas fa-user-edit" />
                      </span>
                      등록하기
                    </Button>
                  </div>
                </>
            }
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    상점명
                  </label>
                  <Input
                      placeholder="상점명을 입력해주세요."
                      type="text"
                      name="name"
                      value={name || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    상점 구분
                  </label>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              {companyList.map((v, i) => {
                return <Col md="auto" key={i}>
                  <div className="custom-control custom-checkbox mb-3">
                    <input
                        className="custom-control-input"
                        id={"customCheck" + i}
                        type="checkbox"
                        value={v.name}
                        onClick={() => {
                          isCheckOne(v.id, checkOneList, setCheckOneList)
                        }}
                        checked={checkOneList.indexOf(v.id) > -1}
                        readOnly
                    />
                    <label
                        className="custom-control-label"
                        htmlFor={"customCheck" + i}
                    >
                      {v.name}
                    </label>
                  </div>
                </Col>
              })}
            </Row>
            <Row>
              <Col md="5" className="mt-4">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    기업 제휴 배너
                  </label>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              {companyList.map((v, i) => {
                return <Col md="auto" key={i}>
                  <div className="custom-control custom-checkbox mb-3">
                    <input
                        className="custom-control-input"
                        id={"customCheckOne" + i}
                        type="checkbox"
                        value={v.name}
                        onClick={() => {
                          isCheck(v.id, checkList, setCheckList)
                        }}
                        checked={checkList.indexOf(v.id) > -1}
                        readOnly
                    />
                    <label
                        className="custom-control-label"
                        htmlFor={"customCheckOne" + i}
                    >
                      {v.name}
                    </label>
                  </div>
                </Col>
              })}
            </Row>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    주소 검색
                  </label>
                  <InputGroup className="mb-3">
                    <FormControl
                        placeholder="주소 입력"
                        aria-label="주소 입력"
                        aria-describedby="basic-addon2"
                        value={address || ''}
                        readOnly
                    />
                    <Button
                        className="ml-2"
                        variant="outline-secondary"
                        id="button-addon2"
                        color="primary"
                        onClick={() => onChangeOpenPost()}
                    >
                      주소 찾기
                    </Button>
                  </InputGroup>
                  <Input
                      placeholder="세부 주소 입력 ex) OO역 5번출구 도보 4분"
                      type="text"
                      name="addressDetail"
                      value={addressDetail || ''}
                      onChange={onChange}
                  />
                  <Input
                      className="mt-3"
                      placeholder=""
                      type="text"
                      name="latitude"
                      value={latitude || ''}
                      onChange={onChange}
                  />
                  <Input
                      className="mt-3"
                      placeholder=""
                      type="text"
                      name="longitude"
                      value={longitude || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    전화번호
                  </label>
                  <Input
                      placeholder="숫자만 입력해주세요."
                      type="text"
                      name="tel"
                      value={tel || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    담당자 전화번호
                  </label>
                  <Input
                      placeholder="숫자만 입력해주세요."
                      type="text"
                      name="company"
                      value={managerTel || ''}
                      onChange={managerTel}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    웹사이트
                  </label>
                  <Input
                      placeholder="웹사이트를 입력해주세요."
                      type="text"
                      name="url"
                      value={url || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    가게 소개
                  </label>
                  <Input
                      placeholder="제목을 입력해주세요."
                      type="textarea"
                      rows="8"
                      name="content"
                      value={content || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            {/*<Row>*/}
              <InputFiles
                  title={'사진을 업로드하세요.(총3장)'}
                  filesState={companyStoreState}
                  filesPreviewState={companyStorePreviewState}
                  deletedId={companyStoreDeletedId}
                  filesCount={3}
              />
            {/*</Row>*/}
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default CompanyStoreForm;
