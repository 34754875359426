import axios from "axios";
import authHeader from "./auth-header";

import api from '../config/api.routes';

const questionRegister = (data) => {
  return axios.post(api().QUESTIONS_REGISTER, data, { headers: authHeader() });
}

const getQuestions = (disease) => {
  return axios.get(api().QUESTIONS_LIST + `?disease=${disease}`, { headers: authHeader() });
};

const getQuestionDetail = (id) => {
  return axios.get(api().QUESTIONS_DETAIL + `?id=${id}`, { headers: authHeader() });
};

const update = (id, data) => {
  return axios.put(api().QUESTIONS_UPDATE + `?id=${id}`, data, { headers: authHeader() });
}

const getQuestionRemove = (id) => {
  return axios.delete(api().QUESTIONS_REMOVE + `?id=${id}`, { headers: authHeader() });
};

const questionService = {
  getQuestions,
  getQuestionDetail,
  update,
  questionRegister,
  getQuestionRemove
}

export default questionService;
