import React, {Fragment, useEffect, useState} from "react";

// reactstrap components
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import CounselingService from "../../../services/counseling.service";
import PaginationWrapper from "../components/pagination";
import {numberWithCommas, paginate} from "../../../utils";
import useHistoryState from "../../../common/useHistoryState";
import classnames from "classnames";
import ReactDatetime from "react-datetime";
import moment from "moment";
import {Link} from "react-router-dom";
import qnaService from "./service/qna.service";
import {useHistory} from "react-router";
import {shopCategory} from "../shop/service/shop.category";
import challengeService from "../carbon/service/challenge.service";
import dayjs from "dayjs";

function QnaList() {
  const history = useHistory();
  const [pageActive, setPageActive] = useHistoryState(1, 'pageActive');
  const [pageCount, setPageCount] = useHistoryState(1, 'pageCount');
  const [data, setData] = useHistoryState([], 'data');
  const [categoryList, setCategoryList] = useHistoryState([], 'categoryList');
  const [category, setCategory] = useHistoryState('전체', 'category');
  const [searchCurrent, setSearchCurrent] = useHistoryState(false, 'searchCurrent');
  const [search, setSearch] = useHistoryState('', 'search');
  const [categoryId, setCategoryId] = useState(0);

  const render = async () => {
    let option = { };
    if (search) {
      option.search = search;
    }
    if (pageCount) {
      option.page = pageActive;
    }
    if (categoryId !== 0 && categoryId !== '전체') {
      option.categoryId = categoryId;
    }
    const res = await qnaService.list(option);
    if (res.code === 200) {
      console.log(res);
      setPageCount(res.list.count);
      setData(res.list.rows);
    }
  };

  useEffect(() => {
    render();
  }, [categoryId, pageActive, search]);

  const renderCategory = async () => {
    let option = { };
    const res = await qnaService.categoryList(option);
    if (res.code === 200) {
      console.log(res);
      setCategoryList(res.list);
    }
  };

  useEffect(() => {
    renderCategory();
  }, []);

  const remove = async (id) => {
    if (window.confirm('삭제하시겠습니까?')) {
      let res = await qnaService.remove(id);
      console.log(res);
      if (res.code === 200) {
        alert('삭제되었습니다.')
        render();
      }
    }
  };

  return (
    <>
      <SimpleHeader name="Tables" parentName="Tables" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row className="py-3">
                  <Col xs="11">
                    <Form>
                      <Row>
                        <Col xs="3">
                          <label
                              className="form-control-label"
                              htmlFor="exampleFormControlSelect13"
                          >
                            검색
                          </label>
                          <FormGroup>
                            <InputGroup
                                className={classnames("input-group-merge", {
                                  focused: searchCurrent,
                                })}
                            >
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="fas fa-search" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                  placeholder="제목으로 검색"
                                  type="text"
                                  value={search}
                                  onChange={(e) => setSearch(e.target.value)}
                                  onFocus={(e) => setSearchCurrent(true)}
                                  onBlur={(e) => setSearchCurrent(false)}
                              />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col xs="3">
                          <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="exampleFormControlSelect13"
                            >
                              카테고리
                            </label>
                            <Input
                                id="exampleFormControlSelect13"
                                type="select"
                                value={categoryId}
                                onChange={(e) => setCategoryId(Number(e.target.value))}
                            >
                              <option value={0}>전체</option>
                              {categoryList.map((v, i) => {
                                return [
                                  <option key={i} value={v.id}>{v.name}</option>
                                ]
                              })}
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
                <Row>
                  <Col xs="6">
                    <h3 className="mt-2">
                      Q&A
                    </h3>
                  </Col>
                  <Col className="text-right">
                    <Button
                        color="default"
                        onClick={(e) => history.push('/admin/qnaForm')}
                    >
                      등록하기
                    </Button>
                  </Col>
                </Row>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive striped>
                <thead className="thead-light">
                  <tr>
                    <th className="text-center">No.</th>
                    <th className="text-center">등록날짜</th>
                    <th className="text-center">카테고리</th>
                    <th className="text-center">제목</th>
                    <th className="text-center">상세</th>
                  </tr>
                </thead>
                <tbody>
                {data?.map((item, i) => {
                  return [
                    <Fragment key={i}>
                      <tr>
                        <td className="text-center">
                          {item.id}
                        </td>
                        <td className="text-center">
                          {dayjs(item.createdAt).format('YYYY-MM-DD')}
                        </td>
                        <td className="text-center">
                          {item?.category}
                        </td>
                        <td className="text-center">
                          {item.title}
                        </td>
                        <td className="text-center">
                          <Button
                              className="btn-outline-default"
                              onClick={() => history.push('/admin/qnaDetail/' + item.id)}
                              size="sm"
                          >
                            <span className="btn-inner--text">상세</span>
                          </Button>
                        </td>
                      </tr>
                    </Fragment>
                  ];
                })}
                </tbody>
              </Table>

              <CardFooter className="py-4">
                <PaginationWrapper
                  isActive={pageActive}
                  pageNumber={pageCount}
                  callback={(page) => setPageActive(page)}
                  itemPerPage={10}
                />
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default QnaList;
