import React, {Fragment, useEffect, useState} from "react";

// reactstrap components
import {
    Button,
    Card,
    CardFooter,
    CardHeader,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText, Modal,
    Row,
    Table,
    UncontrolledTooltip,
} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import CounselingService from "../../../services/counseling.service";
import PaginationWrapper from "../components/pagination";
import {clickImg, numberWithCommas, paginate} from "../../../utils";
import useHistoryState from "../../../common/useHistoryState";
import classnames from "classnames";
import ReactDatetime from "react-datetime";
import moment from "moment";
import {useHistory} from "react-router";
import AuthService from "../auth/auth.service";
import ecoFriendsService from "./service/ecoFriends.service";
import ecoFriendsHistoryService from "../ecoFriendsHistory/service/ecoFriendsHistory.service";

function EcoFriendsList() {
    const user = typeof window !== 'undefined' ? AuthService.getCurrentUser() : null;
    const history = useHistory();
    const [pageActive, setPageActive] = useHistoryState(1, 'pageActive');
    const [pageCount, setPageCount] = useHistoryState(1, 'pageCount');
    const [data, setData] = useHistoryState([], 'data');
    const [detail, setDetail] = useHistoryState([], 'data');
    const [startDate, setStartDate] = useHistoryState(moment().add(-1, 'month').format('YYYY-MM-DD'), 'startDate');
    const [endDate, setEndDate] = useHistoryState(moment().format('YYYY-MM-DD'), 'endDate');
    const [searchCurrent, setSearchCurrent] = useHistoryState(false, 'searchCurrent');
    const [search, setSearch] = useHistoryState('', 'search');
    const [count, setCount] = useState(0);
    const [diff, setDiff] = useState('');
    const [defaultModal, setDefaultModal] = React.useState(false);

    const render = async () => {
        const res = await ecoFriendsService.list({ search, startDate, endDate});
        if (res.code === 200) {
            console.log(res);
            setPageCount(res.list.length);
            setData(paginate(res.list, pageActive, 10));
        }
    };

    useEffect(() => {
        render();
    }, [pageActive, startDate, endDate, search]);

    const renderDetail = async (ecoFriendId) => {
        const res = await ecoFriendsHistoryService.list({ ecoFriendId  });
        if (res.code === 200) {
            console.log(res);
            setDetail(res.list.rows);
        }
    };

    const handleReactDatetimeChange = (who, date) => {
        if (
            startDate &&
            who === "endDate" &&
            new Date(startDate) > new Date(date.format('YYYY-MM-DD'))
        ) {
            setStartDate(date.format('YYYY-MM-DD'));
            setEndDate(date.format('YYYY-MM-DD'));
        } else if (
            endDate &&
            who === "startDate" &&
            new Date(endDate) < new Date(date.format('YYYY-MM-DD'))
        ) {
            setStartDate(date.format('YYYY-MM-DD'));
            setEndDate(date.format('YYYY-MM-DD'));
        } else {
            if (who === "startDate") {
                setStartDate(date.format('YYYY-MM-DD'));
            } else {
                setEndDate(date.format('YYYY-MM-DD'));
            }
        }
    };

    const getClassNameReactDatetimeDays = (date) => {
        if (startDate && endDate) {
        }
        if (startDate && endDate && startDate !== endDate) {
            if (
                new Date(endDate) > new Date(date.format('YYYY-MM-DD')) &&
                new Date(startDate) < new Date(date.format('YYYY-MM-DD'))
            ) {
                return " middle-date";
            }
            if (endDate === date.format('YYYY-MM-DD')) {
                return " end-date";
            }
            if (startDate === date.format('YYYY-MM-DD')) {
                return " start-date";
            }
        }
        return "";
    };

    return (
        <>
            <SimpleHeader name="Tables" parentName="Tables" />
            <Container className="mt--6" fluid>
                <Row>
                    <div className="col">
                        <Card>
                            <CardHeader className="border-0">
                                <Row className="py-3">
                                    <Col xs="11">
                                        <Form>
                                            <Row>
                                                <Col md="auto">
                                                    <label className=" form-control-label py-2">
                                                        무게 기간조회
                                                    </label>
                                                    <ReactDatetime
                                                        inputProps={{
                                                            placeholder: "",
                                                        }}
                                                        value={startDate}
                                                        locale={'ko'}
                                                        timeFormat={false}
                                                        dateFormat={'YYYY-MM-DD'}
                                                        onChange={(e) =>
                                                            handleReactDatetimeChange("startDate", e)
                                                        }
                                                        renderDay={(props, currentDate, selectedDate) => {
                                                            let classes = props.className;
                                                            classes += getClassNameReactDatetimeDays(
                                                                currentDate
                                                            );
                                                            return (
                                                                <td {...props} className={classes}>
                                                                    {currentDate.date()}
                                                                </td>
                                                            );
                                                        }}
                                                    />
                                                </Col>
                                                <Col md="auto">
                                                    <label className=" form-control-label py-2 mt-3">
                                                    </label>
                                                    <ReactDatetime
                                                        inputProps={{
                                                            placeholder: "",
                                                        }}
                                                        value={endDate}
                                                        locale={'ko'}
                                                        timeFormat={false}
                                                        dateFormat={'YYYY-MM-DD'}
                                                        onChange={(e) =>
                                                            handleReactDatetimeChange("endDate", e)
                                                        }
                                                        renderDay={(props, currentDate, selectedDate) => {
                                                            let classes = props.className;
                                                            classes += getClassNameReactDatetimeDays(
                                                                currentDate
                                                            );
                                                            return (
                                                                <td {...props} className={classes}>
                                                                    {currentDate.date()}
                                                                </td>
                                                            );
                                                        }}
                                                    />
                                                </Col>
                                                <Col xs="3">
                                                    <label className=" form-control-label py-2">
                                                        검색
                                                    </label>
                                                    <FormGroup>
                                                        <InputGroup
                                                            className={classnames("input-group-merge", {
                                                                focused: searchCurrent,
                                                            })}
                                                        >
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText>
                                                                    <i className="fas fa-search" />
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input
                                                                placeholder="그룹명으로 검색"
                                                                type="text"
                                                                value={search}
                                                                onChange={(e) => setSearch(e.target.value)}
                                                                onFocus={(e) => setSearchCurrent(true)}
                                                                onBlur={(e) => setSearchCurrent(false)}
                                                            />
                                                        </InputGroup>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="6">
                                        <h3 className="mt-2">에코프렌즈 지점관리</h3>
                                    </Col>
                                    {user?.role === 'center' ? null : (
                                        <Col className="text-right">
                                            <Button color="default" onClick={(e) => history.push('/admin/ecoFriendsForm')}>
                                                등록하기
                                            </Button>
                                        </Col>
                                    )}
                                </Row>
                                <Row>
                                    <Col xs="auto">
                                        <h3 className="mt-2 text-muted">총 넘버링</h3>
                                    </Col>
                                    <Col xs="auto">
                                        <h3 className="mt-2">{count}</h3>
                                    </Col>
                                </Row>
                            </CardHeader>

                            <Table className="align-items-center table-flush" responsive striped>
                                <thead className="thead-light">
                                <tr>
                                    <th className="text-center">No.</th>
                                    <th className="text-center">그룹명</th>
                                    <th className="text-center">전체 무게</th>
                                    <th className="text-center">참여 인원</th>
                                    <th className="text-center">관리</th>
                                </tr>
                                </thead>
                                <tbody>
                                {data?.map((item, i) => {
                                    return [
                                        <Fragment key={i}>
                                            <tr>
                                                <td className="text-center">
                                                    {item.id}
                                                </td>
                                                <td className="text-center">
                                                    {item.name}
                                                </td>
                                                <td className="text-center">
                                                    {item.kilogram} kg
                                                    &nbsp;&nbsp;&nbsp;
                                                    <Button
                                                        className="px-3 btn-outline-default"
                                                        size="sm"
                                                        onClick={() => {
                                                            setDefaultModal(true);
                                                            renderDetail(item.id)
                                                            setDiff('전체 무게');
                                                        }}
                                                    >
                                                        상세
                                                    </Button>
                                                </td>
                                                <td className="text-center">
                                                    {item.count}
                                                    &nbsp;&nbsp;&nbsp;
                                                    <Button
                                                        className="px-3 btn-outline-default"
                                                        size="sm"
                                                        onClick={() => {
                                                            setDefaultModal(true);
                                                            renderDetail(item.id)
                                                            setDiff('참여 인원');
                                                        }}
                                                    >
                                                        상세
                                                    </Button>
                                                </td>
                                                <td className="text-center">
                                                    <Button
                                                        className="btn-outline-default"
                                                        onClick={() => history.push('/admin/ecoFriendsDetail/' + item.id)}
                                                        size="sm"
                                                    >
                                                        <span className="btn-inner--text">상세</span>
                                                    </Button>
                                                </td>
                                            </tr>
                                        </Fragment>
                                    ];
                                })}
                                </tbody>
                            </Table>

                            <CardFooter className="py-4">
                                <PaginationWrapper
                                    isActive={pageActive}
                                    pageNumber={pageCount}
                                    callback={(page) => setPageActive(page)}
                                    itemPerPage={10}
                                />
                            </CardFooter>
                        </Card>
                    </div>
                </Row>
            </Container>
            <Modal
                className="modal-dialog-centered"
                isOpen={defaultModal}
                size="m"
                // toggle={() => setDefaultModal(false)} // 배경클릭시 창 닫힘
                fade={false}
            >
                <div className="modal-header">
                    <h6 className="modal-title mt-2">
                        {diff}
                    </h6>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => {
                            setDefaultModal(false)
                        }}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Table className="align-items-center table-flush" responsive striped>
                        <thead className="thead-light">
                        <tr>
                            <th className="text-center">이름</th>
                            <th className="text-center">{diff === '전체 무게'? '전체 무게': '참여 인원'}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {detail?.map((item, i) => {
                            return [
                                <Fragment key={i}>
                                    <tr>
                                        <td className="text-center">
                                            {item.user?.name}
                                        </td>
                                        <td className="text-center">
                                            {diff === '전체 무게'? item.kilogram : item.count}
                                        </td>
                                    </tr>
                                </Fragment>
                            ];
                        })}
                        </tbody>
                    </Table>
                </div>
            </Modal>
        </>
    );
}

export default EcoFriendsList;
