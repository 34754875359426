import React, {Fragment, useEffect} from "react";

// reactstrap components
import {Button, Card, CardFooter, CardHeader, Col, Container, Row, Table,} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import {useHistory} from "react-router-dom";
import useHistoryState from "../../../../common/useHistoryState";
import PaginationWrapper from "../../components/pagination";
import advertisementService from "../service/advertisement.service";

function SettingList() {
  const router =  useHistory();
  const [pageActive, setPageActive] = useHistoryState(1, 'pageActive');
  const [pageCount, setPageCount] = useHistoryState(1, 'pageCount');
  const [data, setData] = useHistoryState([], 'data');

  const render = async () => {
    const res = await advertisementService.list({ location: 'setting' });
    if (res.code === 200) {
      console.log(res);
      setPageCount(res?.advertisements.length);
      setData(res?.advertisements);
    }
  };

  useEffect(() => {
    render();
  }, [pageActive]);

  const remove = async (id) => {
    if (window.confirm('삭제하시겠습니까?')) {
      let res = await advertisementService.remove(id);
      console.log(res);
      if (res.code === 200) {
        alert('삭제되었습니다.')
        render();
      }
    }
  };

  return (
    <>
      <SimpleHeader name="Tables" parentName="Tables" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col xs="6">
                    <h4 className="mb-0">설정 화면 광고</h4>
                  </Col>
                  <Col className="text-right" xs="6">
                    <Button
                      className="btn-round btn-icon"
                      color="primary"
                      id="tooltip443412080"
                      onClick={(e) => router.push('/admin/bannerSetting')}
                      size="sm"
                    >
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-user-edit" />
                  </span>
                      <span className="btn-inner--text">등록하기</span>
                    </Button>
                  </Col>
                </Row>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive striped>
                <thead className="thead-light">
                <tr>
                  <th className="text-center">순번</th>
                  <th className="text-center">업체명</th>
                  <th className="text-center">View Count</th>
                  <th className="text-center">Click Count</th>
                  <th className="text-center">관리</th>
                </tr>
                </thead>
                <tbody>
                {data?.map((item, i) => {
                  return [
                    <Fragment key={i}>
                      <tr>
                        <td className="text-center">
                          {item.id}
                        </td>
                        <td className="text-center">
                          {item.company}
                        </td>
                        <td className="text-center">
                          {item.viewCount}
                        </td>
                        <td className="text-center">
                          {item.clickCount? item.clickCount : '-'}
                        </td>
                        <td className="text-center">
                          <Button
                              className="btn-outline-danger"
                              color="danger"
                              onClick={() => remove(item.id)}
                              size="sm"
                          >
                            <span className="btn-inner--text">삭제</span>
                          </Button>
                          <Button
                              className="btn-outline-default"
                              color="danger"
                              onClick={() => router.push('/admin/settingDetail/' + item.id)}
                              size="sm"
                          >
                            <span className="btn-inner--text">상세</span>
                          </Button>
                        </td>
                      </tr>
                    </Fragment>
                  ];
                })}
                </tbody>
              </Table>

              <CardFooter className="py-4">
                <PaginationWrapper
                  isActive={pageActive}
                  pageNumber={pageCount}
                  callback={(page) => setPageActive(page)}
                  itemPerPage={10}
                />
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default SettingList;
