import {atom, selector} from "recoil";
import api from "../../../../config/api.routes";
import axios from "axios";
import authHeader from "../../../../services/auth-header";

export const fileState = atom({
  key: 'fileState',
  default: '',
});

export const filePreviewState = atom({
  key: 'filePreviewState',
  default: '',
});

export const dataState = atom({
  key: 'dataState',
  default: {
    shopId: 0,
    sortCode: 0,
    name: '',
    intro: '',
    price: 0
  },
});

export const dataSelector = selector({
  key: 'dataSelector',
  get: async ({ get }) => {
    const {
      shopId,
      sortCode,
      name,
      intro,
      price,
    } = get(dataState);
    const data = {
      shopId,
      sortCode: Number(sortCode.replace(/[^0-9]/g, '')),
      name,
      price: Number(price.replace(/[^0-9]/g, '')),
    };
    if (intro) {
      data.intro = intro;
    }
    return data;
  },
})

export const register = (data) => {
  let url = api().apiURL + `/shopMenu/register`;
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.post(url, data, { headers: authHeader() })
      .then(res => res.data)
      .then(res => resolve(res))
      .catch(e => reject(e));
  });
};


export const fileRegister = (id, data) => {
  let url = api().apiURL + `/shopMenu/file/register?shopMenuId=${id}`;
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.post(url, data, { headers: authHeader() })
      .then(res => res.data)
      .then(res => resolve(res))
      .catch(e => reject(e));
  });
};

export const menuList = (data) => {
  let url = api().apiURL + `/shopMenu/list?`;
  for (let query of Object.keys(data)) {
    if (data[query] === undefined || data[query] === '') {
      continue;
    }
    url += `${query}=${data[query]}&`;
  }
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.get(url, { headers: authHeader() })
      .then(res => res.data)
      .then(res => resolve(res))
      .catch(e => reject(e));
  });
};

export const menuDetail = (id) => {
  let url = api().apiURL + `/shopMenu/detail?id=${id}`;
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.get(url, { headers: authHeader() })
      .then(res => res.data)
      .then(res => resolve(res))
      .catch(e => reject(e));
  });
};

export const update = (id, data) => {
  let url = api().apiURL + `/shopMenu/update?id=${id}`;
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.put(url, data, { headers: authHeader() })
      .then(res => res.data)
      .then(res => resolve(res))
      .catch(e => reject(e));
  });
};
