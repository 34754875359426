import React, {Fragment, useEffect, useState} from "react";

// reactstrap components
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  FormGroup, Input,
  InputGroup,
  InputGroupAddon, InputGroupText,
  Row,
  Table,
} from "reactstrap";
// layout for this page
// core components
import {useHistory} from "react-router";
import {clickImg, telWithCommas} from "../../../utils";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import PaginationWrapper from "../components/pagination";
import useHistoryState from "../../../common/useHistoryState";
import moment from "moment";
import qrHistoryService from "./service/qrHistory.service";
import companyService from "../company/service/company.service";
import api from "../../../config/api.routes";
import axios from "axios";
import authHeader from "../../../services/auth-header";
import shopService from "../shop/shop.service";
import classnames from "classnames";
import ReactDatetime from "react-datetime";

function QrHistoryList() {
  const history = useHistory();
  const [pageActive, setPageActive] = useHistoryState(1, 'pageActive');
  const [pageCount, setPageCount] = useHistoryState(1, 'pageCount');
  const [data, setData] = useHistoryState([], 'data');
  const [companyList, setCompanyList] = useHistoryState([], 'companyList');
  const [category, setCategory] = useHistoryState('전체', 'category');
  const [startDate, setStartDate] = useHistoryState(moment().format('YYYY-MM-01'), 'startDate');
  const [endDate, setEndDate] = useHistoryState(moment().format('YYYY-MM-DD'), 'endDate');
  const [searchCurrent, setSearchCurrent] = useHistoryState(false, 'searchCurrent');
  const [search, setSearch] = useHistoryState('', 'search');
  const [checkList, setCheckList] = useState([]);

  const render = async () => {
    const res = await qrHistoryService.list({ page: pageActive, limit: 15, search, diff: '탄소적립점', startDate, endDate });
    if (res.code === 200) {
      console.log(res);
      setPageCount(res.list.rows.length);
      setData(res.list.rows);
    }
  };

  useEffect(() => {
    render();
  }, [ pageActive, checkList, startDate, endDate, search ]);

  const statusChange = async (id, check) => {
    await qrHistoryService.update(id, {check});
    render();
  }

  const handleReactDatetimeChange = (who, date) => {
    if (
        startDate &&
        who === "endDate" &&
        new Date(startDate) > new Date(date.format('YYYY-MM-DD'))
    ) {
      setStartDate(date.format('YYYY-MM-DD'));
      setEndDate(date.format('YYYY-MM-DD'));
    } else if (
        endDate &&
        who === "startDate" &&
        new Date(endDate) < new Date(date.format('YYYY-MM-DD'))
    ) {
      setStartDate(date.format('YYYY-MM-DD'));
      setEndDate(date.format('YYYY-MM-DD'));
    } else {
      if (who === "startDate") {
        setStartDate(date.format('YYYY-MM-DD'));
      } else {
        setEndDate(date.format('YYYY-MM-DD'));
      }
    }
  };

  const getClassNameReactDatetimeDays = (date) => {
    if (startDate && endDate) {
    }
    if (startDate && endDate && startDate !== endDate) {
      if (
          new Date(endDate) > new Date(date.format('YYYY-MM-DD')) &&
          new Date(startDate) < new Date(date.format('YYYY-MM-DD'))
      ) {
        return " middle-date";
      }
      if (endDate === date.format('YYYY-MM-DD')) {
        return " end-date";
      }
      if (startDate === date.format('YYYY-MM-DD')) {
        return " start-date";
      }
    }
    return "";
  };

  const remove = async (id) => {
    if (window.confirm('삭제하시겠습니까?')) {
      let res = await qrHistoryService.remove(id);
      console.log(res);
      if (res.code === 200) {
        render();
        alert('삭제되었습니다.')
      }
    }
  }

  return (
    <>
      <SimpleHeader name="Tables" parentName="Tables" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col xs="3">
                    <label className=" form-control-label py-2">
                    </label>
                    <FormGroup>
                      <InputGroup
                          className={classnames("input-group-merge", {
                            focused: searchCurrent,
                          })}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-search" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                            placeholder="성명으로 검색"
                            type="text"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            onFocus={(e) => setSearchCurrent(true)}
                            onBlur={(e) => setSearchCurrent(false)}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col xs="2">
                    <label className=" form-control-label">
                      날짜 조회
                    </label>
                    <FormGroup>
                      <ReactDatetime
                          inputProps={{
                            placeholder: "",
                          }}
                          value={startDate}
                          locale={'ko'}
                          timeFormat={false}
                          dateFormat={'YYYY-MM-DD'}
                          onChange={(e) =>
                              handleReactDatetimeChange("startDate", e)
                          }
                          renderDay={(props, currentDate, selectedDate) => {
                            let classes = props.className;
                            classes += getClassNameReactDatetimeDays(
                                currentDate
                            );
                            return (
                                <td {...props} className={classes}>
                                  {currentDate.date()}
                                </td>
                            );
                          }}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs="2">
                    <FormGroup>
                      <label className=" form-control-label py-2">
                      </label>
                      <ReactDatetime
                          inputProps={{
                            placeholder: "",
                          }}
                          value={endDate}
                          locale={'ko'}
                          timeFormat={false}
                          dateFormat={'YYYY-MM-DD'}
                          onChange={(e) =>
                              handleReactDatetimeChange("endDate", e)
                          }
                          renderDay={(props, currentDate, selectedDate) => {
                            let classes = props.className;
                            classes += getClassNameReactDatetimeDays(
                                currentDate
                            );
                            return (
                                <td {...props} className={classes}>
                                  {currentDate.date()}
                                </td>
                            );
                          }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs="6">
                    <h3 className="mt-2">
                      탄소적립,할인점 이용내역
                    </h3>
                  </Col>
                  {/*<Col className="text-right" xs="6">*/}
                  {/*  <Button*/}
                  {/*      className="btn-round btn-icon"*/}
                  {/*      color="default"*/}
                  {/*      onClick={(e) => history.push('/admin/company/storeForm')}*/}
                  {/*  >*/}
                  {/*    <span className="btn-inner--text">등록하기</span>*/}
                  {/*  </Button>*/}
                  {/*</Col>*/}
                </Row>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive striped>
                <thead className="thead-light">
                <tr>
                  <th className="text-center">번호</th>
                  <th className="text-center">적립일자</th>
                  <th className="text-center">성명</th>
                  <th className="text-center">지자체명/기관명</th>
                  <th className="text-center">QR 생성명</th>
                  <th className="text-center">적립포인트</th>
                  <th className="text-center">탄소저감량적립</th>
                  <th className="text-center" style={{ width: '10%' }}>인증사진</th>
                  <th className="text-center">적립 취소</th>
                </tr>
                </thead>
                <tbody>
                {data?.map((item, i) => {
                  return [
                    <Fragment key={i}>
                      <tr>
                        <td className="text-center">
                          {item.id}
                        </td>
                        <td className="text-center">
                          {item.createdAt}
                        </td>
                        <td className="text-center">
                          {item.userName}
                        </td>
                        <td className="text-center">
                          {item.carbonSaveStoreName}
                        </td>
                        <td className="text-center">
                          {item.qrName}
                        </td>
                        <td className="text-center">
                          {item.point}
                        </td>
                        <td className="text-center">
                          {item.carbonReduction}
                        </td>
                        <td className="text-center">
                          {item.images[0]?.id
                              ? <img
                                  src={item.images[0]?.name}
                                  style={{ width: '30%', cursor: 'pointer' }}
                                  onClick={() => clickImg(item.images[0]?.name)}
                              />
                              :'-'
                          }
                        </td>
                        <td className="text-center">
                          <Button
                              className="btn-outline-danger"
                              onClick={() => remove(item.id)}
                              size="sm"
                          >
                            <span className="btn-inner--text">취소</span>
                          </Button>
                          {/*<Button*/}
                          {/*    className="btn-outline-default"*/}
                          {/*    onClick={(e) => history.push('/admin/company/storeDetail/' + item.id)}*/}
                          {/*    size="sm"*/}
                          {/*>*/}
                          {/*  <span className="btn-inner--text">상세</span>*/}
                          {/*</Button>*/}
                        </td>
                      </tr>
                    </Fragment>
                  ];
                })}
                </tbody>
              </Table>

              <CardFooter className="py-4">
                <PaginationWrapper
                  isActive={pageActive}
                  pageNumber={pageCount}
                  callback={(page) => setPageActive(page)}
                  itemPerPage={10}
                />
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default QrHistoryList;
