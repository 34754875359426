import React, {Fragment, useEffect} from "react";

// reactstrap components
import {
    Button,
    Card,
    CardFooter,
    CardHeader,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row,
    Table,
    UncontrolledTooltip,
} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import PaginationWrapper from "../components/pagination";
import {clickImg, paginate} from "../../../utils";
import useHistoryState from "../../../common/useHistoryState";
import classnames from "classnames";
import ReactDatetime from "react-datetime";
import moment from "moment";
import {Link} from "react-router-dom";
import {useHistory} from "react-router";
import AuthService from "../auth/auth.service";
import userService from "./user.service";
import xlsx from "xlsx";

function UserList() {
    const user = typeof window !== 'undefined' ? AuthService.getCurrentUser() : null;
    const history = useHistory();
    const [pageActive, setPageActive] = useHistoryState(1, 'pageActive');
    const [pageCount, setPageCount] = useHistoryState(1, 'pageCount');
    const [data, setData] = useHistoryState([], 'data');
    const [diff, setDiff] = useHistoryState('all', 'diff');
    const [startDate, setStartDate] = useHistoryState(moment().add(-1, 'month').format('YYYY-MM-DD'), 'startDate');
    const [endDate, setEndDate] = useHistoryState(moment().format('YYYY-MM-DD'), 'endDate');
    const [searchCurrent, setSearchCurrent] = useHistoryState(false, 'searchCurrent');
    const [search, setSearch] = useHistoryState('', 'search');
    const [tel, setTel] = useHistoryState('', 'tel');
    const [loading, setLoading] = useHistoryState(false, 'loading');

    const render = () => {
        const option = {
            page: pageActive,
        }
        if(tel.length === 11) {
            option.tel = tel
        }
        if(search) {
            option.search = search
        }
        userService.list(option)
            .then((res) => {
                if (res.code === 200) {
                    console.log(res);
                    setPageCount(res.users.count);
                    setData(res.users.rows);
                }
            });
    }

    useEffect(() => {
        render();
    }, [pageActive, startDate, endDate, search, diff, tel]);

    const excelDownload = () => {
        setLoading(true)
        const option = {}
        if(search) {
            option.search = search
        }
        userService.list(option)
        .then((res) => {
            if (res.code === 200) {
                const workSheet = xlsx.utils.json_to_sheet(res.users.map((v) => {
                    return {
                        '유저ID': v.id,
                        '기업코드': v.code? v.code:'-',
                        '아이디': v.loginId,
                        '직번': v.staffNumber? v.staffNumber:'-',
                        '회원명': v.name,
                        '생년월일': v.birth,
                        '전화번호': v.tel,
                        '성별': v.gender,
                        '가입날짜': moment(v.createdAt).format('YYYY-MM-DD HH:mm'),
                        '1365회원번호': v.number1365? v.number1365:'-',
                        '이메일': v.email? v.email:'-',
                    }
                }));
                workSheet['!autofilter'] = { ref: 'A1:R11' };

                const workBook = xlsx.utils.book_new();
                xlsx.utils.book_append_sheet(workBook, workSheet, 'sheet title');
                xlsx.writeFile(workBook, 'report.xlsx');
                setLoading(false)
            }
        });
    };

    if (loading) return <div>로딩중..</div>;
    return (
        <>
            <SimpleHeader name="Tables" parentName="Tables" />
            <Container className="mt--6" fluid>
                <Row>
                    <div className="col">
                        <Card>
                            <CardHeader className="border-0">
                                <Row className="py-3">
                                    <Col xs="11">
                                        <Form>
                                            <Row>
                                                <Col xs="3">
                                                    <label className=" form-control-label py-2">
                                                    </label>
                                                    <FormGroup>
                                                        <InputGroup
                                                            className={classnames("input-group-merge", {
                                                                focused: searchCurrent,
                                                            })}
                                                        >
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText>
                                                                    <i className="fas fa-search" />
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input
                                                                placeholder="아이디, 회원명으로 검색"
                                                                type="text"
                                                                value={search}
                                                                onChange={(e) => setSearch(e.target.value)}
                                                                onFocus={(e) => setSearchCurrent(true)}
                                                                onBlur={(e) => setSearchCurrent(false)}
                                                            />
                                                        </InputGroup>
                                                    </FormGroup>
                                                </Col>
                                                {/*<Col xs="3">*/}
                                                {/*    <label className=" form-control-label py-2">*/}
                                                {/*    </label>*/}
                                                {/*    <FormGroup>*/}
                                                {/*        <InputGroup*/}
                                                {/*            className={classnames("input-group-merge", {*/}
                                                {/*                focused: searchCurrent,*/}
                                                {/*            })}*/}
                                                {/*        >*/}
                                                {/*            <InputGroupAddon addonType="prepend">*/}
                                                {/*                <InputGroupText>*/}
                                                {/*                    <i className="fas fa-search" />*/}
                                                {/*                </InputGroupText>*/}
                                                {/*            </InputGroupAddon>*/}
                                                {/*            <Input*/}
                                                {/*                placeholder="전화번호로 검색"*/}
                                                {/*                type="text"*/}
                                                {/*                value={tel}*/}
                                                {/*                onChange={(e) => setTel(e.target.value)}*/}
                                                {/*                onFocus={(e) => setSearchCurrent(true)}*/}
                                                {/*                onBlur={(e) => setSearchCurrent(false)}*/}
                                                {/*            />*/}
                                                {/*        </InputGroup>*/}
                                                {/*    </FormGroup>*/}
                                                {/*</Col>*/}
                                            </Row>
                                        </Form>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="6">
                                        <h3 className="mt-2">유저 관리</h3>
                                    </Col>
                                    <Col className="text-right">
                                        <Button
                                            color="success"
                                            onClick={() => excelDownload()}
                                        >
                                            엑셀 다운로드
                                        </Button>
                                    </Col>
                                    {user?.role === 'center' ? null : (
                                      <Col className="text-right">
                                          <Button color="default" onClick={(e) => history.push('/admin/userForm')}>
                                              등록하기
                                          </Button>
                                      </Col>
                                    )}
                                </Row>
                            </CardHeader>

                            <Table className="align-items-center table-flush" responsive striped>
                                <thead className="thead-light">
                                    <tr>
                                        <th className="text-center">유저ID</th>
                                        <th className="text-center">기업코드</th>
                                        <th className="text-center">아이디</th>
                                        <th className="text-center">가입경로</th>
                                        <th className="text-center">직번</th>
                                        <th className="text-center">회원명</th>
                                        <th className="text-center">생년월일</th>
                                        <th className="text-center">전화번호</th>
                                        <th className="text-center">성별</th>
                                        <th className="text-center">가입날짜</th>
                                        <th className="text-center">1365회원번호</th>
                                        <th className="text-center">1365이미지</th>
                                        <th className="text-center">걸음</th>
                                        <th className="text-center">관리</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {data?.map((item, i) => {
                                    return [
                                        <Fragment key={i}>
                                            <tr>
                                                <td className="text-center">
                                                    {item.id}
                                                </td>
                                                <td className="text-center">
                                                    {item.code? item.code:'-'}
                                                </td>
                                                <td className="text-center">
                                                    {item.loginId}
                                                </td>
                                                <td className="text-center">
                                                    {/*{item.loginId}*/}
                                                </td>
                                                <td className="text-center">
                                                    {item.staffNumber? item.staffNumber:'-'}
                                                </td>
                                                <td className="text-center">
                                                    {item.name}
                                                </td>
                                                <td className="text-center">
                                                    {item.birth}
                                                </td>
                                                <td className="text-center">
                                                    {item.tel}
                                                </td>
                                                <td className="text-center">
                                                    {item.gender}
                                                </td>
                                                <td className="text-center">
                                                    {moment(item.createdAt).format('YYYY-MM-DD HH:mm')}
                                                </td>
                                                <td className="text-center">
                                                    {item.number1365? item.number1365:'-'}
                                                </td>
                                                <td className="text-center">
                                                    {item.image1365
                                                        ? <img
                                                            src={`https://d2twzv77g3c69u.cloudfront.net/fit-in/1000x700/${item.image1365}`}
                                                            style={{ width: '10%', cursor: 'pointer' }}
                                                            onClick={() => clickImg(`https://d2twzv77g3c69u.cloudfront.net/fit-in/1000x700/${item.image1365}`)}
                                                        />
                                                        :'-'
                                                    }
                                                </td>
                                                <td className="text-center">
                                                    <Button
                                                        className="px-3"
                                                        color="warning"
                                                        size="sm"
                                                        onClick={() => history.push('/admin/stepList/' + item.id)}
                                                    >
                                                        보기
                                                    </Button>
                                                </td>
                                                <td className="text-center">
                                                    <Button
                                                        className="btn-outline-default px-3"
                                                        size="sm"
                                                        onClick={() => history.push('/admin/userDetail/' + item.id)}
                                                    >
                                                        수정
                                                    </Button>
                                                </td>
                                            </tr>
                                        </Fragment>
                                    ];
                                })}
                                </tbody>
                            </Table>

                            <CardFooter className="py-4">
                                <PaginationWrapper
                                    isActive={pageActive}
                                    pageNumber={pageCount}
                                    callback={(page) => setPageActive(page)}
                                    itemPerPage={10}
                                />
                            </CardFooter>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
}

export default UserList;
