import SimpleHeader from "../../../components/Headers/SimpleHeader";
import React, {Fragment, useEffect, useRef, useState} from "react";
import {Button, Card, CardHeader, Col, Container, Input, Row} from "reactstrap";
import {useFieldArray, useForm} from "react-hook-form";
import sharingPloggingService from "./service/sharingPlogging.service";
import charityService from "../charity/service/charity.service";
import {AxiosError} from 'axios';

const SharedPloggingMgmt = () => {
    const [isLoading, setIsLoading] = useState(true);

    // 저장 등의 동작 시, 로딩 여부
    const [isProcessing, setIsProcessing] = useState(false);

    const {watch, setValue, reset, register, control} = useForm(
        {
            defaultValues: {
                fields: [
                    {
                        centerLoginId: '1234',
                        price: '',
                        thumbnail: undefined,
                        thumbnailPreview: '',
                        image: undefined,
                        imagePreview: '',
                    }
                ],
            }
        }
    );

    const {fields, append, remove} = useFieldArray({
        control,
        name: 'fields',
    });

    const fetchData = async () => {
        try {
            const list = await sharingPloggingService.list().then(value => value['list'].reverse());

            // 서버로부터 불러온 thumbnail 프로퍼티를 thumbnailPreview 로 변경한 후, Form 에 저장한다.
            for (const eachData of list) {
                eachData['thumbnailPreview'] = eachData['thumbnail'];
                eachData['thumbnail'] = undefined;
                eachData['imagePreview'] = eachData['image'];
                eachData['image'] = undefined;
                eachData['price'] = eachData['price'].toString();
                console.log(eachData);
            }

            let defaultValues = {};
            defaultValues['fields'] = list;
            setValue('fields', list);

            setIsLoading(false);
        } catch (err) {
            console.log(err);
            alert('데이터를 불러오는 중 오류가 발생했습니다.');
        }
    }

    /// 데이터를 불러온다.
    useEffect(() => fetchData(), []);

    const statusChange = async (id, {active}) => {
        await sharingPloggingService.update(id, {active});
        fetchData();
    }

    console.log(watch());

    /// 데이터를 전송한다. 이때, id 의 유무에 따라 등록/수정을 분기 처리한다.
    const handleSubmitClicked = async (index) => {
        try {
            setIsProcessing(true);
            // 전달받은 인덱스에 대한 데이터를 가져온다.
            const data = watch(`fields.${index}`);
            const updateId = data['id'];

            // 데이터를 검증한다. 이때, 만족하지 못한 조건이 생길 경우 Alert 를 출력한 후 return 한다.
            // 그렇지 않을 경우, 서버로 데이터를 전송한다.
            if (!data.centerLoginId || !data.price || (!updateId ? !data.thumbnail : false) || (!updateId ? !data.image : false)) {
                alert('모든 입력값을 채워주세요.');
                return;
            }

            const thumbnail = data.thumbnail;
            const image = data.image;
            delete data['thumbnail'];
            delete data['thumbnailPreview'];
            delete data['image'];
            delete data['imagePreview'];
            delete data['id'];
            delete data['createdAt'];

            let statusCode;
            let response;

            let sharingPloggingId;

            if (!updateId) {
                // 등록
                response = await sharingPloggingService.register(data);
                statusCode = response['code'];
                sharingPloggingId = response['data']['sharingPloggingId'];
            } else {
                delete data['isSubscribe'];
                delete data['center'];
                delete data['totalCount'];
                delete data['workingCount'];
                delete data['payAmount'];
                delete data['totalPrice'];
                // 수정
                response = await sharingPloggingService.update(updateId, data);
                statusCode = response['code'];
                sharingPloggingId = updateId;
            }

            if (statusCode === 203) {
                alert('존재하지 않는 아이디입니다.');
                return;
            }

            // 이미지를 서버로 전송한다.
            if (!!thumbnail) {
                let form = new FormData();
                form.append('image', thumbnail);
                await sharingPloggingService.fileRegister(sharingPloggingId, form);
            }

            if (!!image) {
                let form = new FormData();
                form.append('image', image);
                await sharingPloggingService.imageRegister(sharingPloggingId, form);
            }
            alert('정상적으로 저장되었습니다.');
            window.location.reload();
        } catch (err) {
            // alert('서버 전송 중 오류가 발생했습니다.');
        } finally {
            setIsProcessing(false);
        }
    }

    /// 데이터를 삭제한다.
    const handleRemoveClicked = async (index) => {
        if (!window.confirm('삭제하시겠습니까?')) {
            return;
        }
        try {
            // 인덱스에 해당하는 데이터를 가져온다.
            // 만일, id 가 존재할 경우 서버 요청을 병행하며, 그렇지 않을 경우는 단순 다이나믹 폼만 제거한다.
            const data = watch(`fields.${index}`);
            const updateId = data['id'];

            if (!!updateId) {
                sharingPloggingService.remove(updateId).then(() => alert('정상적으로 삭제되었습니다.'));
            }

            remove(index);
        } catch (err) {
            alert(err);
        }
    }

    if (isLoading) return <></>;

    return <>
        <SimpleHeader name="Tables" parentName="Tables"/>
        <Container className="mt--6" fluid>
            <Card>
                <CardHeader className="border-0">
                    <Row>
                        <Col xs="6">
                            <h3 className="mt-2">나눔 플로깅 관리</h3>
                        </Col>
                    </Row>
                </CardHeader>
                <div className={'d-flex p-4 flex-column'} style={{
                    gap: '10px',
                }}>
                    {
                        fields.map((field, index) => {
                            return <div key={field.id} className={''} style={{columnGap: '14px'}}>
                                <div className={'row'}>
                                    <div
                                        className={'col-sm d-flex flex-column'}>
                                        {
                                            index === 0 &&
                                            <span>나눔 플로깅 연결 봉사센터 아이디</span>
                                        }
                                        <Input
                                            defaultValue={watch(
                                                `fields.${index}.centerLoginId`)}
                                            {...register(
                                                `fields.${index}.centerLoginId`)}
                                            placeholder={'아이디 입력'}
                                            type="text"
                                        />
                                    </div>
                                    <div
                                        className={'col-sm d-flex flex-column'}>
                                        {
                                            index === 0 &&
                                            <span>1인 기준 월 기부 전달 금액</span>
                                        }
                                        <Input
                                            defaultValue={watch(
                                                `fields.${index}.price`)}
                                            {...register(
                                                `fields.${index}.price`)}
                                            placeholder={'숫자만'}
                                            type="text"
                                        />
                                    </div>
                                    {/* thumbnailPreview 가 '' 이거나, 비어있지 않은 경우, 새로 추가된 데이터이므로 해당 경우일 때 활성화 버튼을 렌더링하지 않는다. */}
                                    {
                                        !!watch(`fields.${index}.id`) &&
                                        <div
                                            className={'col-xs d-flex flex-column mr-2 ml-2'}>
                                            <span>활성화</span>
                                            <label
                                                className="custom-toggle custom-toggle-success center mt-2">
                                                <input
                                                    checked={field.active}
                                                    type="checkbox"
                                                    onChange={(e) => {
                                                        const data = watch(`fields.${index}`);
                                                        statusChange(
                                                            data.id,
                                                            {
                                                                active: !field.active,
                                                            })
                                                    }}
                                                />
                                                <span
                                                    className="custom-toggle-slider rounded-circle"
                                                    data-label-off="No"
                                                    data-label-on="Yes"
                                                />
                                            </label>
                                        </div>
                                    }
                                    <div
                                        className={'col-sm d-flex flex-column'}>
                                        {
                                            index === 0 &&
                                            <span>썸네일</span>
                                        }
                                        <div className={'d-flex'}
                                             style={{columnGap: '10px'}}>
                                            <div
                                                className={'d-flex flex-column'}
                                                style={{rowGap: 10}}>
                                                <input id={'attach'}
                                                       type={'file'}
                                                       style={{width: 150}}
                                                       onChange={(event) => {
                                                           const file = event.currentTarget.files[0];

                                                           if (file) {
                                                               const objectUrl = URL.createObjectURL(
                                                                   file);

                                                               setValue(
                                                                   `fields.${index}.thumbnail`,
                                                                   file);
                                                               setValue(
                                                                   `fields.${index}.thumbnailPreview`,
                                                                   objectUrl);
                                                           }
                                                       }}/>
                                                {
                                                    watch(
                                                        `fields.${index}.thumbnailPreview`)
                                                        ?
                                                        <img src={watch(
                                                            `fields.${index}.thumbnailPreview`)}
                                                             alt={'사진'} style={{
                                                            width: '100px',
                                                            height: '100px',
                                                        }}/> : <div style={{
                                                            width: 100,
                                                            height: 100
                                                        }}/>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={'col-sm d-flex flex-column'}>
                                        {
                                            index === 0 &&
                                            <span>이미지</span>
                                        }
                                        <div className={'d-flex'}
                                             style={{columnGap: '10px'}}>
                                            <div
                                                className={'d-flex flex-column'}
                                                style={{rowGap: 10}}>
                                                <input id={'attach'}
                                                       type={'file'}
                                                       style={{width: 150}}
                                                       onChange={(event) => {
                                                           const file = event.currentTarget.files[0];

                                                           if (file) {
                                                               const objectUrl = URL.createObjectURL(
                                                                   file);

                                                               setValue(
                                                                   `fields.${index}.image`,
                                                                   file);
                                                               setValue(
                                                                   `fields.${index}.imagePreview`,
                                                                   objectUrl);
                                                           }
                                                       }}/>
                                                {
                                                    watch(
                                                        `fields.${index}.imagePreview`)
                                                        ?
                                                        <img src={watch(
                                                            `fields.${index}.imagePreview`)}
                                                             alt={'사진'} style={{
                                                            width: '100px',
                                                            height: '100px',
                                                        }}/> : <div style={{
                                                            width: 100,
                                                            height: 100
                                                        }}/>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'col-sm d-flex flex-row'}>
                                        <Button
                                            className="btn-outline-dark"
                                            disabled={isProcessing}
                                            onClick={() => handleSubmitClicked(
                                                index)}
                                            size="sm"
                                            style={{
                                                width: '100px',
                                                height: '40px'
                                            }}
                                        >
                                                <span
                                                    className="btn-inner--text">
                                                    {isProcessing ? '처리중'
                                                        : '저장'}
                                                </span>
                                        </Button>
                                        <Button
                                            className="btn-outline-warning"
                                            onClick={() => handleRemoveClicked(
                                                index)}
                                            size="sm"
                                            style={{
                                                width: '100px',
                                                height: '40px'
                                            }}
                                        >
                                                <span
                                                    className="btn-inner--text">삭제</span>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        })
                    }
                </div>
                <Button onClick={() => {
                    append({
                        centerLoginId: '',
                        price: '',
                        thumbnail: undefined,
                        thumbnailPreview: '',
                    });
                }}>봉사센터 추가</Button>
            </Card>
        </Container>
    </>;
}

export default SharedPloggingMgmt;