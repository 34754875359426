import axios from "axios";
import api from "../../../../config/api.routes";
import authHeader from "../../../../services/auth-header";
import {atom} from "recoil";

//file
export const companyLogoFileState = atom({
  key: 'companyLogoFileState',
  default: '',
});
export const companyLogoFilePreviewState = atom({
  key: 'companyLogoFilePreviewState',
  default: '',
});

export const nanumTabFileState = atom({
  key: 'nanumTabFileState',
  default: '',
});
export const nanumTabFilePreviewState = atom({
  key: 'nanumTabFilePreviewState',
  default: '',
});

export const foavTabFileState = atom({
  key: 'foavTabFileState',
  default: '',
});
export const foavTabFilePreviewState = atom({
  key: 'foavTabFilePreviewState',
  default: '',
});

export const storeDetailFileState = atom({
  key: 'storeDetailFileState',
  default: '',
});
export const storeDetailFilePreviewState = atom({
  key: 'storeDetailFilePreviewState',
  default: '',
});

export const logo1FileState = atom({
  key: 'logo1FileState',
  default: '',
});
export const logo1FilePreviewState = atom({
  key: 'logo1FilePreviewState',
  default: '',
});

export const logo2FileState = atom({
  key: 'logo2FileState',
  default: '',
});
export const logo2FilePreviewState = atom({
  key: 'logo2FilePreviewState',
  default: '',
});

export const etcFileState = atom({
  key: 'etcFileState',
  default: '',
});
export const etcFilePreviewState = atom({
  key: 'etcFilePreviewState',
  default: '',
});

const list = (data) => {
  let url = api().apiURL + `/company/list?`;
  for (let query of Object.keys(data)) {
    if (data[query] === undefined || data[query] === '') {
      continue;
    }
    url += `${query}=${data[query]}&`;
  }
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.get(url, { headers: authHeader() })
      .then(res => res.data)
      .then(res => resolve(res))
      .catch(e => reject(e));
  });
};

const subList = (data) => {
  let url = api().apiURL + `/companySub/list?`;
  for (let query of Object.keys(data)) {
    if (data[query] === undefined || data[query] === '') {
      continue;
    }
    url += `${query}=${data[query]}&`;
  }
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.get(url, { headers: authHeader() })
      .then(res => res.data)
      .then(res => resolve(res))
      .catch(e => reject(e));
  });
};

const register = (data) => {
  let url = api().apiURL + `/company/register?`;
  console.log('서비스',url,data);
  return new Promise((resolve, reject) => {
    axios.post(url, data, { headers: authHeader() })
        .then(res => res.data)
        .then(res => resolve(res))
        .catch(e => reject(e));
  });
};

const subRegister = (data) => {
  let url = api().apiURL + `/companySub/register?`;
  console.log('서비스',url,data);
  return new Promise((resolve, reject) => {
    axios.post(url, data, { headers: authHeader() })
        .then(res => res.data)
        .then(res => resolve(res))
        .catch(e => reject(e));
  });
};

const fileRegister = (id, data, diff) => {
  let url = api().apiURL + `/company/file/register?companyId=${id}&diff=${diff}`;
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.post(url, data, { headers: authHeader() })
        .then(res => res.data)
        .then(res => resolve(res))
        .catch(e => reject(e));
  });
};

const detail = (id, data) => {
  let url = api().apiURL + `/company/detail?id=${id}`;
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.get(url, { headers: authHeader() })
      .then(res => res.data)
      .then(res => resolve(res))
      .catch(e => reject(e));
  });
};

const subDetail = (id, data) => {
  let url = api().apiURL + `/companySub/detail?id=${id}`;
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.get(url, { headers: authHeader() })
      .then(res => res.data)
      .then(res => resolve(res))
      .catch(e => reject(e));
  });
};

const update = (id, data) => {
  let url = api().apiURL + `/company/update?id=${id}`;
  console.log('수정',url);
  return new Promise((resolve, reject) => {
    axios.put(url, data, { headers: authHeader() })
        .then(res => res.data)
        .then(res => resolve(res))
        .catch(e => reject(e));
  });
};

const subUpdate = (id, data) => {
  let url = api().apiURL + `/companySub/update?id=${id}`;
  console.log('수정',url);
  return new Promise((resolve, reject) => {
    axios.put(url, data, { headers: authHeader() })
        .then(res => res.data)
        .then(res => resolve(res))
        .catch(e => reject(e));
  });
};

const updateService = (id, data) => {
  let url = api().apiURL + `/admin/service/history/${id}?confirm=${data}`;
  console.log('수정',url);
  return new Promise((resolve, reject) => {
    axios.put(url, data, { headers: authHeader() })
        .then(res => res.data)
        .then(res => resolve(res))
        .catch(e => reject(e));
  });
};

const remove = (id) => {
  let url = api().apiURL + `/company/remove?id=${id}`;
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.delete(url, { headers: authHeader() })
        .then(res => res.data)
        .then(res => resolve(res))
        .catch(e => reject(e));
  });
};

const subRemove = (id) => {
  let url = api().apiURL + `/companySub/remove?id=${id}`;
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.delete(url, { headers: authHeader() })
        .then(res => res.data)
        .then(res => resolve(res))
        .catch(e => reject(e));
  });
};

const companyService = {
  list,
  subList,
  register,
  subRegister,
  fileRegister,
  detail,
  subDetail,
  update,
  subUpdate,
  updateService,
  remove,
  subRemove,
}

export default companyService;
