import React, {Fragment, useEffect} from "react";

// reactstrap components
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText, Media,
  Row,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import PaginationWrapper from "../components/pagination";
import {clickImg, paginate} from "../../../utils";
import useHistoryState from "../../../common/useHistoryState";
import classnames from "classnames";
import {Link} from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import challengeService from "./service/challenge.service";
import shopService from "../shop/shop.service";

function ChallengeMem({ history }) {
  const [pageActive, setPageActive] = useHistoryState(1, 'pageActive');
  const [pageCount, setPageCount] = useHistoryState(1, 'pageCount');
  const [data, setData] = useHistoryState([], 'data');

  console.log(pageActive, pageCount)

  const render = async () => {
    try {
      const res = await challengeService.companyList({});
      console.log('상점프로필 유저 관리', res);
      if (res.code === 200) {
        setPageCount(res.list.length);
        setData(paginate(res.list, pageActive, 10));
      }
    } catch (e) {
      alert(e.response?.data?.resultMsg);
    }
  };

  useEffect(() => {
    render();
  }, [ pageActive, pageCount ]);

  return (
    <>
      <SimpleHeader name="Tables" parentName="Tables" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">
                      임직원 챌린지 관리
                    </h3>
                  </Col>
                  {/*<Col className="text-right" xs="6">*/}
                  {/*  <Button*/}
                  {/*    className="btn-round btn-icon"*/}
                  {/*    color="default"*/}
                  {/*    id="tooltip443412080"*/}
                  {/*    onClick={(e) => history.push('/admin/shopRegister')}*/}
                  {/*    size="sm"*/}
                  {/*  >*/}
                  {/*<span className="btn-inner--icon mr-1">*/}
                  {/*  <i className="fas fa-user-edit" />*/}
                  {/*</span>*/}
                  {/*    <span className="btn-inner--text">등록하기</span>*/}
                  {/*  </Button>*/}
                  {/*</Col>*/}
                </Row>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive striped>
                <thead className="thead-light">
                <tr>
                  <th className="text-center">No.</th>
                  <th className="text-center">기업명</th>
                  <th className="text-center">기업코드</th>
                  <th className="text-center">상세</th>
                </tr>
                </thead>
                <tbody>
                  {data?.map((item, i) => {
                  return [
                    <Fragment key={uuidv4()}>
                      <tr>
                        <td className="text-center">
                          {item.id}
                        </td>
                        <td className="text-center">
                          {item.name}
                        </td>
                        <td className="text-center">
                          {item.code}
                        </td>
                        <td className="text-center">
                          <Button
                              className="btn-outline-default"
                              color="danger"
                              onClick={() => history.push('/admin/challengeMemDetail/' + item.id + '/' + item.name)}
                              size="sm"
                          >
                            <span className="btn-inner--text">상세</span>
                          </Button>
                        </td>
                      </tr>
                    </Fragment>
                  ];
                })}
                </tbody>
              </Table>

              <CardFooter className="py-4">
                <PaginationWrapper
                  isActive={pageActive}
                  pageNumber={pageCount}
                  callback={(page) => setPageActive(page)}
                  itemPerPage={10}
                />
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default ChallengeMem;
