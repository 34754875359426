import React, {Fragment, useEffect} from "react";

// reactstrap components
import {
    Button,
    Card,
    CardFooter,
    CardHeader,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText, Modal,
    Row,
    Table, UncontrolledTooltip,
} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import moment from "moment";
import {useHistory} from "react-router";
import companyService from "../company/service/company.service";
import useHistoryState from "../../../common/useHistoryState";
import PaginationWrapper from "../components/pagination";
import classnames from "classnames";

function PloggingCompany() {
    const history = useHistory();
    const [pageActive, setPageActive] = useHistoryState(1, 'pageActive');
    const [pageCount, setPageCount] = useHistoryState(1, 'pageCount');
    const [data, setData] = useHistoryState([], 'data');
    const [searchCurrent, setSearchCurrent] = useHistoryState(false, 'searchCurrent');
    const [search, setSearch] = useHistoryState('', 'search');

    const render = () => {
        let option = { diff: '플로깅' };
        if (search) {
            option.search = search;
        }
        if (pageCount) {
            option.page = pageActive;
        }
        companyService.list(option)
            .then((res) => {
                if (res.code === 200) {
                    console.log(res);
                    setPageCount(res.list.count);
                    setData(res.list.rows);
                }
            });
    };

    useEffect(() => {
        render();
    }, [ pageActive, pageCount, search ]);

    const statusChange = async (id, check) => {
        await companyService.update(id, {isPlogging: check});
        render();
    }

    return (
        <>
            <SimpleHeader name="Tables" parentName="Tables" />
            <Container className="mt--6" fluid>
                <Row>
                    <div className="col">
                        <Card>
                            <CardHeader className="border-0">
                                <Row className="py-3">
                                    <Col xs="11">
                                        <Form>
                                            <Row>
                                                <Col xs="3">
                                                    <label className=" form-control-label py-2">
                                                    </label>
                                                    <FormGroup>
                                                        <InputGroup
                                                            className={classnames("input-group-merge", {
                                                                focused: searchCurrent,
                                                            })}
                                                        >
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText>
                                                                    <i className="fas fa-search" />
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input
                                                                placeholder="기업명으로 검색"
                                                                type="text"
                                                                value={search}
                                                                onChange={(e) => setSearch(e.target.value)}
                                                                onFocus={(e) => setSearchCurrent(true)}
                                                                onBlur={(e) => setSearchCurrent(false)}
                                                            />
                                                        </InputGroup>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="6">
                                        <h3 className="mt-2">인증 플로깅 센터 리스트</h3>
                                    </Col>
                                </Row>
                            </CardHeader>

                            <Table className="align-items-center table-flush" responsive striped>
                                <thead className="thead-light">
                                <tr>
                                    <th className="text-center">기업명</th>
                                    <th className="text-center">기업관리자 연결 여부</th>
                                    <th className="text-center">관리</th>
                                </tr>
                                </thead>
                                <tbody>
                                {data?.map((item, i) => {
                                    return [
                                        <Fragment key={i}>
                                            <tr>
                                                <td className="text-center">
                                                    {item.name}
                                                </td>
                                                <td className="text-center">
                                                    <label className="custom-toggle custom-toggle-success center">
                                                        <input
                                                            checked={item.isPlogging}
                                                            type="checkbox"
                                                            onChange={(e) => statusChange(item.id, !item.isPlogging)}
                                                        />
                                                        <span
                                                            className="custom-toggle-slider rounded-circle"
                                                            data-label-off="No"
                                                            data-label-on="Yes"
                                                        />
                                                    </label>
                                                </td>
                                                <td className="text-center">
                                                    <Button
                                                        className="btn-outline-warning"
                                                        onClick={() => history.push('/admin/ploggingList/:centerId/' + item.id)}
                                                        size="sm"
                                                    >
                                                        <span className="btn-inner--text">상세</span>
                                                    </Button>
                                                </td>
                                            </tr>
                                        </Fragment>
                                    ];
                                })}
                                </tbody>
                            </Table>

                            <CardFooter className="py-4">
                                <PaginationWrapper
                                    isActive={pageActive}
                                    pageNumber={pageCount}
                                    callback={(page) => setPageActive(page)}
                                    itemPerPage={10}
                                />
                            </CardFooter>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
}

export default PloggingCompany;
