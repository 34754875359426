/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useEffect, useState} from "react";
// node.js library that concatenates classes (strings)
// javascipt plugin for creating charts
import {Chart} from "chart.js";
// react plugin used to create charts
import {Line} from "react-chartjs-2";
// reactstrap components
import {Card, CardBody, CardHeader, CardTitle, Col, Container, Row,} from "reactstrap";

// core components
import CardsHeader from "components/Headers/CardsHeader.js";

import {chartOptions, parseOptions,} from "variables/charts.js";
import visitorsService from "../../../services/visitors.service";
import moment from "moment";
import AuthService from "../auth/auth.service";
import {centerMain} from "../../../images";

const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

function Dashboard() {
  const user = typeof window !== 'undefined' ? AuthService.getCurrentUser() : null;
  const [centerList, setCenterList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [accessList, setAccessList] = useState([]);
  const [serviceHistory, setServiceHistory] = useState([]);
  let today = moment().format('YYYY-MM-DD');
  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  const accessRender = async () => {
    const res = await visitorsService.accessList({});
    // console.log(res);
    if (res.code === 200) {
      setAccessList(res.accessList.slice(-8));
    }
  }

  const render = async () => {
    const res = await visitorsService.serviceHistory({});
    // console.log(res);
    if (res.code === 200) {
      setServiceHistory(res.serviceHistory);
    }
  }

  const centerRender = async () => {
    const res = await visitorsService.centerRanking({});
    // console.log(res);
    if (res.code === 200) {
      setCenterList(res.serviceHistory);
    }
  }

  const groupRender = async () => {
    const res = await visitorsService.groupRanking({});
    // console.log(res);
    if (res.code === 200) {
      setGroupList(res.serviceHistory);
    }
  }

  const qrRender = async () => {
    const res = await visitorsService.centerRanking({});
    // console.log(res);
    if (res.code === 200) {
      setCenterList(res.serviceHistory);
    }
  }

  useEffect(() => {
    if(user.role !== 'center') {
      accessRender();
      render();
      centerRender();
      groupRender();
      qrRender();
    }
  }, []);

  let colors = {
    gray: {
      100: "#f6f9fc",
      200: "#e9ecef",
      300: "#dee2e6",
      400: "#ced4da",
      500: "#adb5bd",
      600: "#8898aa",
      700: "#525f7f",
      800: "#32325d",
      900: "#212529",
    },
    theme: {
      default: "#172b4d",
      primary: "#5e72e4",
      secondary: "#f4f5f7",
      info: "#11cdef",
      success: "#2dce89",
      danger: "#f5365c",
      warning: "#fb6340",
    },
    black: "#12263F",
    white: "#FFFFFF",
    transparent: "transparent",
  };

  let chartExample = {
    options: {
      scales: {
        yAxes: [
          {
            gridLines: {
              color: colors.gray[100],
              // zeroLineColor: colors.theme.info,
            },
            ticks: {},
          },
        ],
      },
    },
    data: {
      labels: accessList.map((v) => {
        return v.date
      }),
      datasets: [
        {
          label: "방문자 수",
          data: accessList.map((v) => {
            return v.count
          }),
        },
      ],
    },
  };

  return (
    <>
      <CardsHeader name="Default" parentName="Dashboards" />
      <Container className="mt--6" fluid>
        {user.role === 'center'
            ?
            <>
              <Row>
                <Col xl="12">
                  <Card>
                    <CardBody>
                      <Row className="mb-3">
                        <Col md="auto">
                          <h2>홈</h2>
                        </Col>
                        <Col className="mt-1">
                          {user.companyId
                              ? <span>기업관리자에 오신걸 환영합니다.</span>
                              : <span>어서오세요.<br/>투명한 봉사 관리를 시작해보세요.<br/>{today}</span>
                          }
                        </Col>
                      </Row>
                      <Row>
                        <img style={{ width: '100%' }} src={centerMain}/>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
            :
            <>
              <Row>
                <Col xl="12">
                  <Card>
                    <CardHeader className="bg-transparent">
                      <Row className="align-items-center">
                        <div className="col">
                          <h5 className="h3 text-black mb-0">접속 통계</h5>
                        </div>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <div className="chart">
                        <Line
                            data={chartExample.data}
                            options={chartExample.options}
                            id="chart-sales-dark"
                            className="chart-canvas"
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col xl="6">
                  <Card className="card-stats">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                              tag="h3"
                              className="text-uppercase text-muted mb-0"
                          >
                            FOAV 플랫폼 전체 봉사시간 상위<span className="text-red"> (센터)</span>
                          </CardTitle>
                        </div>
                      </Row>
                      <Row className="mt-2">
                        <Col md="6" className=""><h4>센터명</h4></Col>
                        <Col md="4" className="text-center"><h4>봉사시간</h4></Col>
                        <Col md="2" className="text-center"><h4>순위</h4></Col>
                      </Row>
                      {centerList?.map((v, i) => {
                        return <>
                          <Row className="mt-1">
                            <Col md="6" className="">{v.centerName}</Col>
                            <Col md="4" className="text-center">{new Date(v.serviceTime).toISOString().slice(11, 16)}</Col>
                            <Col md="2" className="text-center">{i + 1}</Col>
                          </Row>
                        </>
                      })}
                    </CardBody>
                  </Card>
                </Col>
                <Col xl="6">
                  <Card className="card-stats">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                              tag="h3"
                              className="text-uppercase text-muted mb-0"
                          >
                            FOAV 플랫폼 전체 이용 상위 센터순위<span className="text-red"> (QR생성종료기준)</span>
                          </CardTitle>
                        </div>
                      </Row>
                      <Row className="mt-2">
                        <Col md="6" className=""><h4>센터명</h4></Col>
                        <Col md="4" className="text-center"><h4>봉사시간</h4></Col>
                        {/*<Col md="3" className="text-center"><h4>QR 생성횟수</h4></Col>*/}
                        <Col md="2" className="text-center"><h4>순위</h4></Col>
                      </Row>
                      {centerList?.map((v, i) => {
                        return <>
                          <Row className="mt-1">
                            <Col md="6" className="">{v.centerName}</Col>
                            <Col md="4" className="text-center">{new Date(v.serviceTime).toISOString().slice(11, 16)}</Col>
                            <Col md="2" className="text-center">{i + 1}</Col>
                          </Row>
                        </>
                      })}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col xl="6">
                  <Card className="card-stats">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                              tag="h3"
                              className="text-uppercase text-muted mb-0"
                          >
                            FOAV 플랫폼 전체 봉사시간 상위<span className="text-red"> (봉사단체)</span>
                          </CardTitle>
                        </div>
                      </Row>
                      <Row className="mt-2">
                        <Col md="4" className=""><h4>센터명</h4></Col>
                        <Col md="3" className="text-center"><h4>단체명</h4></Col>
                        <Col md="3" className="text-center"><h4>봉사시간</h4></Col>
                        <Col md="2" className="text-center"><h4>순위</h4></Col>
                      </Row>
                      {groupList?.map((v, i) => {
                        return <>
                          <Row className="mt-1">
                            <Col md="4" className="">{v.centerName}</Col>
                            <Col md="3" className="text-center">{v.groupName}</Col>
                            <Col md="3" className="text-center">{new Date(v.serviceTime).toISOString().slice(11, 16)}</Col>
                            <Col md="2" className="text-center">{i + 1}</Col>
                          </Row>
                        </>
                      })}
                    </CardBody>
                  </Card>
                </Col>
                <Col xl="6">
                  <Card className="card-stats">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                              tag="h3"
                              className="text-uppercase text-muted mb-0"
                          >
                            FOAV 플랫폼 전체 이용 상위 단체순위<span className="text-red"> (QR생성종료기준)</span>
                          </CardTitle>
                        </div>
                      </Row>
                      <Row className="mt-2">
                        <Col md="3" className=""><h4>센터명</h4></Col>
                        <Col md="3" className=""><h4>단체명</h4></Col>
                        <Col md="2" className="text-center"><h4>봉사시간</h4></Col>
                        <Col md="2" className="text-center"><h4>QR 생성횟수</h4></Col>
                        <Col md="2" className="text-center"><h4>순위</h4></Col>
                      </Row>
                      {serviceHistory?.map((v, i) => {
                        return <>
                          <Row className="mt-1">
                            <Col md="3" className="">{v.centerName}</Col>
                            <Col md="3" className="">{v.groupName}</Col>
                            <Col md="2" className="text-center">{new Date(v.serviceTime).toISOString().slice(11, 16)}</Col>
                            <Col md="2" className="text-center">{v.zoneCount}</Col>
                            <Col md="2" className="text-center">{i + 1}</Col>
                          </Row>
                        </>
                      })}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
        }

      </Container>
    </>
  );
}

export default Dashboard;
