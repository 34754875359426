import React, {Fragment, useEffect} from "react";

// reactstrap components
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup, Input,
  InputGroup, InputGroupAddon, InputGroupText,
  Row,
  Table,
} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import {useHistory} from "react-router-dom";
import useHistoryState from "../../../common/useHistoryState";
import challengeService from "./service/challenge.service";
import {clickImg} from "../../../utils";
import PaginationWrapper from "../components/pagination";
import ReactDatetime from "react-datetime";
import moment from "moment";
import classnames from "classnames";
import charityService from "../charity/service/charity.service";

function CommentList({ match }) {
  const { id } = match.params;
  const router =  useHistory();
  const [pageActive, setPageActive] = useHistoryState(1, 'pageActive');
  const [pageCount, setPageCount] = useHistoryState(1, 'pageCount');
  const [data, setData] = useHistoryState([], 'data');
  const [startDate, setStartDate] = useHistoryState(moment().add(-2, 'years').format('YYYY-MM-01'), 'startDate');
  const [endDate, setEndDate] = useHistoryState(moment().format('YYYY-MM-DD'), 'endDate');
  const [searchCurrent, setSearchCurrent] = useHistoryState(false, 'searchCurrent');
  const [search, setSearch] = useHistoryState('', 'search');

  const render = async () => {
    const res = await challengeService.commentList({ page: pageActive, challengeUserId: id });
    if (res.code === 200) {
      console.log(res);
      setPageCount(res.list.count);
      setData(res.list.rows);
    }
  };

  const handleReactDatetimeChange = (who, date) => {
    if (
        startDate &&
        who === "endDate" &&
        new Date(startDate) > new Date(date.format('YYYY-MM-DD'))
    ) {
      setStartDate(date.format('YYYY-MM-DD'));
      setEndDate(date.format('YYYY-MM-DD'));
    } else if (
        endDate &&
        who === "startDate" &&
        new Date(endDate) < new Date(date.format('YYYY-MM-DD'))
    ) {
      setStartDate(date.format('YYYY-MM-DD'));
      setEndDate(date.format('YYYY-MM-DD'));
    } else {
      if (who === "startDate") {
        setStartDate(date.format('YYYY-MM-DD'));
      } else {
        setEndDate(date.format('YYYY-MM-DD'));
      }
    }
  };

  const getClassNameReactDatetimeDays = (date) => {
    if (startDate && endDate) {
    }
    if (startDate && endDate && startDate !== endDate) {
      if (
          new Date(endDate) > new Date(date.format('YYYY-MM-DD')) &&
          new Date(startDate) < new Date(date.format('YYYY-MM-DD'))
      ) {
        return " middle-date";
      }
      if (endDate === date.format('YYYY-MM-DD')) {
        return " end-date";
      }
      if (startDate === date.format('YYYY-MM-DD')) {
        return " start-date";
      }
    }
    return "";
  };

  useEffect(() => {
    render();
  }, [pageActive]);

  const remove = async () => {
    if (window.confirm('삭제하시겠습니까?')) {
      let res = await challengeService.removeComment(id);
      console.log(res);
      if (res.code === 200) {
        alert('삭제되었습니다.')
        router.goBack();
      }
    }
  };

  return (
    <>
      <SimpleHeader name="Tables" parentName="Tables" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col xs="6">
                    <h4 className="mb-0">인증 리스트 댓글</h4>
                  </Col>
                </Row>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive striped>
                <thead className="thead-light">
                  <tr>
                    <th className="text-center">No.</th>
                    <th className="text-center">내용</th>
                    <th className="text-center">작성자</th>
                    <th className="text-center">작성날짜</th>
                    <th className="text-center">좋아요</th>
                    <th className="text-center">삭제</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((item, i) => {
                    return [
                      <Fragment key={i}>
                        <tr>
                          <td className="text-center">
                            {item.id}
                          </td>
                          <td className="text-center">
                            {item.content}
                          </td>
                          <td className="text-center">
                            {item.user.loginId}
                          </td>
                          <td className="text-center">
                            {item.createdAt}
                          </td>
                          <td className="text-center">
                            {item.wishCount}
                          </td>
                          <td className="text-center">
                            <Button
                                className="btn-outline-danger"
                                color="danger"
                                onClick={() => remove(item.id)}
                                size="sm"
                            >
                              <span className="btn-inner--text">삭제</span>
                            </Button>
                          </td>
                        </tr>
                      </Fragment>
                    ];
                  })}
                </tbody>
              </Table>

              <CardFooter className="py-4">
                <PaginationWrapper
                  isActive={pageActive}
                  pageNumber={pageCount}
                  callback={(page) => setPageActive(page)}
                  itemPerPage={10}
                />
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default CommentList;
