import React, {Fragment, useEffect} from "react";

// reactstrap components
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import CounselingService from "../../../services/counseling.service";
import PaginationWrapper from "../components/pagination";
import {paginate} from "../../../utils";
import useHistoryState from "../../../common/useHistoryState";
import classnames from "classnames";
import ReactDatetime from "react-datetime";
import moment from "moment";
import {Link} from "react-router-dom";
import shopService from "./shop.service";
import {shopCategory} from "./service/shop.category";

function ShopCouponList({ match, history }) {
  const [pageActive, setPageActive] = useHistoryState(1, 'pageActive');
  const [pageCount, setPageCount] = useHistoryState(1, 'pageCount');
  const [data, setData] = useHistoryState([], 'data');
  const [category, setCategory] = useHistoryState('전체', 'category');
  const [active, setActive] = useHistoryState('전체', 'active');
  const [startDate, setStartDate] = useHistoryState('', 'startDate');
  const [endDate, setEndDate] = useHistoryState('', 'endDate');
  const [searchCurrent, setSearchCurrent] = useHistoryState(false, 'searchCurrent');
  const [search, setSearch] = useHistoryState('', 'search');
  const { id } = match.params;

  const handleReactDatetimeChange = (who, date) => {
    if (
      startDate &&
      who === "endDate" &&
      new Date(startDate) > new Date(date.format('YYYY-MM-DD'))
    ) {
      setStartDate(date.format('YYYY-MM-DD'));
      setEndDate(date.format('YYYY-MM-DD'));
    } else if (
      endDate &&
      who === "startDate" &&
      new Date(endDate) < new Date(date.format('YYYY-MM-DD'))
    ) {
      setStartDate(date.format('YYYY-MM-DD'));
      setEndDate(date.format('YYYY-MM-DD'));
    } else {
      if (who === "startDate") {
        setStartDate(date.format('YYYY-MM-DD'));
      } else {
        setEndDate(date.format('YYYY-MM-DD'));
      }
    }
  };

  const getClassNameReactDatetimeDays = (date) => {
    if (startDate && endDate) {
    }
    if (startDate && endDate && startDate !== endDate) {
      if (
        new Date(endDate) > new Date(date.format('YYYY-MM-DD')) &&
        new Date(startDate) < new Date(date.format('YYYY-MM-DD'))
      ) {
        return " middle-date";
      }
      if (endDate === date.format('YYYY-MM-DD')) {
        return " end-date";
      }
      if (startDate === date.format('YYYY-MM-DD')) {
        return " start-date";
      }
    }
    return "";
  };

  const render = async () => {
    // category, startDate, endDate, search, active
    const option = {
      startDate, endDate, category, search
    };
    if (active !== '전체') {
      option.active = active === '활성화';
    }
    if (id) {
      option.shopId = id;
    }
    const res = await shopService.couponList(option)
    if (res.code === 200) {
      console.log(res);
      setPageCount(res.list.length);
      setData(paginate(res.list, pageActive, 10));
    }
  }

  useEffect(() => {
    render();
  }, [category, pageActive, startDate, endDate, search, active]);

  const statusChange = async (id, active) => {
    const res = await shopService.couponUpdate(id, {active});
    if (res.code === 200) {
      render();
    }
  }

  return (
    <>
      <SimpleHeader name="Tables" parentName="Tables" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row className="py-3">
                  <Col xs="11">
                    <Form>
                      <Row>
                        <Col xs="3">
                          <label className=" form-control-label py-2">
                          </label>
                          <FormGroup>
                            <InputGroup
                              className={classnames("input-group-merge", {
                                focused: searchCurrent,
                              })}
                            >
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="fas fa-search" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="업체명, 아이디, 쿠폰 명으로 검색"
                                type="text"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                onFocus={(e) => setSearchCurrent(true)}
                                onBlur={(e) => setSearchCurrent(false)}
                              />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col xs="2">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="exampleFormControlSelect13"
                            >
                              카테고리
                            </label>
                            <Input
                              id="exampleFormControlSelect13"
                              type="select"
                              value={category}
                              onChange={(e) => setCategory(e.target.value)}
                            >
                              <option>전체</option>
                              {shopCategory.map((c, i) => {
                                return [
                                  <option key={i}>{c}</option>
                                ]
                              })}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col xs="2">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="active"
                            >
                              상태
                            </label>
                            <Input
                              id="active"
                              type="select"
                              value={active}
                              onChange={(e) => setActive(e.target.value)}
                            >
                              <option>전체</option>
                              <option>활성화</option>
                              <option>비활성화</option>
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col xs="2">
                          <label className=" form-control-label">
                            쿠폰 사용 마감 기간
                          </label>
                          <FormGroup>
                            <ReactDatetime
                              inputProps={{
                                placeholder: "",
                              }}
                              value={startDate}
                              locale={'ko'}
                              timeFormat={false}
                              dateFormat={'YYYY-MM-DD'}
                              onChange={(e) =>
                                handleReactDatetimeChange("startDate", e)
                              }
                              renderDay={(props, currentDate, selectedDate) => {
                                let classes = props.className;
                                classes += getClassNameReactDatetimeDays(
                                  currentDate
                                );
                                return (
                                  <td {...props} className={classes}>
                                    {currentDate.date()}
                                  </td>
                                );
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs="2">
                          <FormGroup>
                            <label className=" form-control-label py-2">
                            </label>
                            <ReactDatetime
                              inputProps={{
                                placeholder: "",
                              }}
                              value={endDate}
                              locale={'ko'}
                              timeFormat={false}
                              dateFormat={'YYYY-MM-DD'}
                              onChange={(e) =>
                                handleReactDatetimeChange("endDate", e)
                              }
                              renderDay={(props, currentDate, selectedDate) => {
                                let classes = props.className;
                                classes += getClassNameReactDatetimeDays(
                                  currentDate
                                );
                                return (
                                  <td {...props} className={classes}>
                                    {currentDate.date()}
                                  </td>
                                );
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">업체 쿠폰 관리</h3>
                  </Col>
                  <Col className="text-right" xs="6">
                    <Button
                      className="btn-round btn-icon"
                      color="default"
                      id="tooltip443412080"
                      onClick={(e) => history.push('/admin/shopCouponRegister')}
                      size="sm"
                    >
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-user-edit" />
                  </span>
                      <span className="btn-inner--text">등록하기</span>
                    </Button>
                  </Col>
                </Row>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive striped>
                <thead className="thead-light">
                <tr>
                  <th className="text-center">등록 날짜</th>
                  <th className="text-center">아이디</th>
                  <th className="text-center">카테고리</th>
                  <th className="text-center">업체명</th>
                  <th className="text-center">탄소이음 차감 포인트</th>
                  <th className="text-center">쿠폰 명</th>
                  <th className="text-center">발행개수</th>
                  <th className="text-center">사용개수</th>
                  <th className="text-center">쿠폰 사용 마감 기간</th>
                  <th className="text-center">활성화</th>
                  <th />
                </tr>
                </thead>
                <tbody>
                {data?.map((item, i) => {
                  return [
                    <Fragment key={i}>
                      <tr>
                        <td className="text-center">
                          {item.startDate}
                        </td>
                        <td className="text-center">
                          {item.loginId}
                        </td>
                        <td className="text-center">
                          {item.category}
                        </td>
                        <td className="text-center">
                          {item.storeName}
                        </td>
                        <td className="text-center">
                          {item.point}
                        </td>
                        <td className="text-center">
                          {item.name}
                        </td>
                        <td className="text-center">
                          {item.count}
                        </td>
                        <td className="text-center">
                          {item.useCount}
                        </td>
                        <td className="text-center">
                          {item.startDate} ~ {item.endDate}
                        </td>
                        <td className="text-center">
                          <label className="custom-toggle custom-toggle-success center">
                            <input
                              checked={item.active}
                              type="checkbox"
                              onChange={(e) => statusChange(item.id, !item.active)}
                            />
                            <span
                              className="custom-toggle-slider rounded-circle"
                              data-label-off="No"
                              data-label-on="Yes"
                            />
                          </label>
                        </td>
                        <td className="table-actions">
                          <Link
                            className="table-action"
                            to={{
                              pathname: '/admin/shopCouponDetail/' + item.id,
                              state: {
                                ...item
                              }
                            }}
                          >
                            <i id={'tooltip' + i} className="fas fa-user-edit" />
                          </Link>
                          <UncontrolledTooltip delay={0} target={'tooltip' + i}>
                            상세 보기
                          </UncontrolledTooltip>
                        </td>
                      </tr>
                    </Fragment>
                  ];
                })}
                </tbody>
              </Table>

              <CardFooter className="py-4">
                <PaginationWrapper
                  isActive={pageActive}
                  pageNumber={pageCount}
                  callback={(page) => setPageActive(page)}
                  itemPerPage={10}
                />
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default ShopCouponList;
