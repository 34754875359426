import React, {Fragment, useEffect} from "react";

// reactstrap components
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import CounselingService from "../../../services/counseling.service";
import PaginationWrapper from "../components/pagination";
import {numberWithCommas, paginate} from "../../../utils";
import useHistoryState from "../../../common/useHistoryState";
import classnames from "classnames";
import ReactDatetime from "react-datetime";
import moment from "moment";
import {Link} from "react-router-dom";
import companyCenterService from "./service/companyCenter.service";
import {useHistory} from "react-router";
import {shopCategory} from "../shop/service/shop.category";
import AuthService from "../auth/auth.service";

function CompanyCenterList() {
  const user = typeof window !== 'undefined' ? AuthService.getCurrentUser() : null;
  const history = useHistory();
  const [pageActive, setPageActive] = useHistoryState(1, 'pageActive');
  const [pageCount, setPageCount] = useHistoryState(1, 'pageCount');
  const [data, setData] = useHistoryState([], 'data');
  const [category, setCategory] = useHistoryState('전체', 'category');
  const [startDate, setStartDate] = useHistoryState(moment().format('YYYY-MM-01'), 'startDate');
  const [endDate, setEndDate] = useHistoryState(moment().format('YYYY-MM-DD'), 'endDate');
  const [searchCurrent, setSearchCurrent] = useHistoryState(false, 'searchCurrent');
  const [search, setSearch] = useHistoryState('', 'search');

  const render = async () => {
    const res = await companyCenterService.list({ limit: 11, page: pageActive, diff: '기업', search });
    if (res.code === 200) {
      console.log(res);
      setPageCount(res.centers.count);
      setData(res.centers.rows);
    }
  };

  useEffect(() => {
    render();
  }, [category, pageActive, startDate, endDate, search]);

    const handleReactDatetimeChange = (who, date) => {
        if (
            startDate &&
            who === "endDate" &&
            new Date(startDate) > new Date(date.format('YYYY-MM-DD'))
        ) {
            setStartDate(date.format('YYYY-MM-DD'));
            setEndDate(date.format('YYYY-MM-DD'));
        } else if (
            endDate &&
            who === "startDate" &&
            new Date(endDate) < new Date(date.format('YYYY-MM-DD'))
        ) {
            setStartDate(date.format('YYYY-MM-DD'));
            setEndDate(date.format('YYYY-MM-DD'));
        } else {
            if (who === "startDate") {
                setStartDate(date.format('YYYY-MM-DD'));
            } else {
                setEndDate(date.format('YYYY-MM-DD'));
            }
        }
    };

    const getClassNameReactDatetimeDays = (date) => {
        if (startDate && endDate) {
        }
        if (startDate && endDate && startDate !== endDate) {
            if (
                new Date(endDate) > new Date(date.format('YYYY-MM-DD')) &&
                new Date(startDate) < new Date(date.format('YYYY-MM-DD'))
            ) {
                return " middle-date";
            }
            if (endDate === date.format('YYYY-MM-DD')) {
                return " end-date";
            }
            if (startDate === date.format('YYYY-MM-DD')) {
                return " start-date";
            }
        }
        return "";
    };


    return (
    <>
      <SimpleHeader name="Tables" parentName="Tables" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row className="py-3">
                    <Col xs="11">
                        <Form>
                            <Row>
                                <Col xs="3">
                                    <label className=" form-control-label py-2">
                                    </label>
                                    <FormGroup>
                                        <InputGroup
                                            className={classnames("input-group-merge", {
                                                focused: searchCurrent,
                                            })}
                                        >
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="fas fa-search" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                placeholder="기업센터명으로 검색"
                                                type="text"
                                                value={search}
                                                onChange={(e) => setSearch(e.target.value)}
                                                onFocus={(e) => setSearchCurrent(true)}
                                                onBlur={(e) => setSearchCurrent(false)}
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                <Row>
                  <Col xs="6">
                    <h3 className="mt-2">기업 ADMIN 관리</h3>
                  </Col>
                  {user?.role === 'center' ? null : (
                    <Col className="text-right">
                        <Button color="default" onClick={(e) => history.push('/admin/companyCenter/form')}>
                            등록하기
                        </Button>
                    </Col>
                  )}
                </Row>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive striped>
                <thead className="thead-light">
                <tr>
                  <th className="text-center">국가</th>
                  <th className="text-center">기업센터명</th>
                  <th className="text-center">아이디</th>
                  <th className="text-center">기업코드</th>
                  <th className="text-center">전화번호</th>
                  <th className="text-center">관리</th>
                </tr>
                </thead>
                <tbody>
                {data?.map((item, i) => {
                  return [
                    <Fragment key={i}>
                      <tr>
                        <td className="text-center">
                          {item.nation}
                        </td>
                        <td className="text-center">
                          {item.company.name}
                        </td>
                        <td className="text-center">
                          {item.loginId}
                        </td>
                        <td className="text-center">
                            {item.code}
                        </td>
                        <td className="text-center">
                          {item.tel === '00000000000' || item.tel === '0000000000'? '-':item.tel}
                        </td>
                        <td className="text-center">
                          <Button
                              className="btn-outline-default"
                              onClick={() => history.push('/admin/companyCenter/detail/' + item.id)}
                              size="sm"
                          >
                            <span className="btn-inner--text">상세</span>
                          </Button>
                        </td>
                      </tr>
                    </Fragment>
                  ];
                })}
                </tbody>
              </Table>

              <CardFooter className="py-4">
                <PaginationWrapper
                  isActive={pageActive}
                  pageNumber={pageCount}
                  callback={(page) => setPageActive(page)}
                  itemPerPage={10}
                />
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default CompanyCenterList;
