import React, {Fragment, useEffect, useState} from "react";

// reactstrap components
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup, Input,
  InputGroup, InputGroupAddon, InputGroupText,
  Row,
  Table,
} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import {useHistory} from "react-router-dom";
import useHistoryState from "../../../common/useHistoryState";
import challengeService from "./service/challenge.service";
import {clickImg} from "../../../utils";
import PaginationWrapper from "../components/pagination";
import ReactDatetime from "react-datetime";
import moment from "moment";
import classnames from "classnames";
import charityService from "../charity/service/charity.service";
import livingService from "../carbonTab/service/carbonTab.service";
import companyStoreService from "../companyStore/service/companyStore.service";
import Spinner from "../components/Spinner";

function CertificateList({ match }) {
  const [loading, setLoading] = useState(true);
  const { id } = match.params;
  const router =  useHistory();
  const [pageActive, setPageActive] = useHistoryState(1, 'pageActive');
  const [pageCount, setPageCount] = useHistoryState(1, 'pageCount');
  const [carbon, setCarbon] = useState(0);
  const [point, setPoint] = useState(0);
  const [dataId, setDataId] = useState([]);
  const [data, setData] = useHistoryState([], 'data');
  const [startDate, setStartDate] = useHistoryState(moment().add('month', -1).format('YYYY-MM-01'), 'startDate');
  const [endDate, setEndDate] = useHistoryState(moment().format('YYYY-MM-DD'), 'endDate');
  const [searchCurrent, setSearchCurrent] = useHistoryState(false, 'searchCurrent');
  const [search, setSearch] = useHistoryState('', 'search');

  const render = async () => {
    const res = await challengeService.certificateList({ page: pageActive, challengeId: Number(id), startDate, endDate });
    if (res.code === 200) {
      console.log(res);
      setPageCount(res.list.count);
      setData(res.list.rows);
      setLoading(false);
    }
  };

  const handleReactDatetimeChange = (who, date) => {
    if (
        startDate &&
        who === "endDate" &&
        new Date(startDate) > new Date(date.format('YYYY-MM-DD'))
    ) {
      setStartDate(date.format('YYYY-MM-DD'));
      setEndDate(date.format('YYYY-MM-DD'));
    } else if (
        endDate &&
        who === "startDate" &&
        new Date(endDate) < new Date(date.format('YYYY-MM-DD'))
    ) {
      setStartDate(date.format('YYYY-MM-DD'));
      setEndDate(date.format('YYYY-MM-DD'));
    } else {
      if (who === "startDate") {
        setStartDate(date.format('YYYY-MM-DD'));
      } else {
        setEndDate(date.format('YYYY-MM-DD'));
      }
    }
  };

  const getClassNameReactDatetimeDays = (date) => {
    if (startDate && endDate) {
    }
    if (startDate && endDate && startDate !== endDate) {
      if (
          new Date(endDate) > new Date(date.format('YYYY-MM-DD')) &&
          new Date(startDate) < new Date(date.format('YYYY-MM-DD'))
      ) {
        return " middle-date";
      }
      if (endDate === date.format('YYYY-MM-DD')) {
        return " end-date";
      }
      if (startDate === date.format('YYYY-MM-DD')) {
        return " start-date";
      }
    }
    return "";
  };

  useEffect(() => {
    render();
  }, [pageActive, startDate, endDate]);

  const isCheck = (value, type, setType) => {
    const index = type.indexOf(value); // value 값의 위치를 찾음.
    if (index > -1) { //  값이 있는 경우, -1은 값이없음.
      setType([
        ...type.filter(v => v !== value)
      ]);
    } else {
      setType([
        ...type,
        value
      ]);
    }
  };
  console.log(dataId)

  const update = () => {
    if(point === 0 && carbon === 0) {
      alert('탄소e음 포인트, 탄소 저감량 값이 둘다 0일순 없습니다.')
      return;
    }
    if(dataId.length === 0) {
      alert('유저를 선턱해주세요.')
      return;
    }

    const json = {
      point,
      carbon
    }

    setLoading(true)
    let res;
    {dataId.map((v, i) => {
      setTimeout( async function () {
        res = await challengeService.update(v, json);
      }, i * 500);
    })}
    setTimeout(function() {
      alert('적립되었습니다.')
      router.go(0);
    }, dataId.length * 600);
  };

  const historyRemove = async (id) => {
    if (window.confirm('포인트 적립을 취소하시겠습니까?')) {
      let res = await challengeService.historyRemove(id);
      console.log(res);
      if (res.code === 200) {
        alert('적립이 취소되었습니다.')
        render();
      }
    }
  };

  const remove = async (id) => {
    if (window.confirm('삭제하시겠습니까?')) {
      let res = await challengeService.challengeRemove(id);
      console.log(res);
      if (res.code === 200) {
        alert('삭제되었습니다.')
        render();
      }
    }
  };

  const down = () => {
    challengeService.certificateExcel({ challengeId: Number(id), startDate, endDate, excel: 'download' })
        .then((res) => {
          fileCreate(res);
        });
  };

  const fileCreate = (res) => {
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');
    const contentDisposition = res.headers['content-disposition']; // 파일 이름
    let fileName = 'report';
    if (contentDisposition) {
      const [ fileNameMatch ] = contentDisposition.split(';').filter(str => str.includes('filename'));
      if (fileNameMatch)
        [ , fileName ] = fileNameMatch.split('=');
    }
    link.href = url;
    link.setAttribute('download', `${fileName}.xlsx`);
    link.style.cssText = 'display:none';
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  if (loading) return <div className="text-center"><Spinner/></div>;
  return (
    <>
      <SimpleHeader name="Tables" parentName="Tables" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row className="py-3">
                  <Col xs="11">
                    <Form>
                      <Row>
                        <Col xs="2">
                          <label className=" form-control-label">
                            날짜 조회
                          </label>
                          <FormGroup>
                            <ReactDatetime
                                inputProps={{
                                  placeholder: "",
                                }}
                                value={startDate}
                                locale={'ko'}
                                timeFormat={false}
                                dateFormat={'YYYY-MM-DD'}
                                onChange={(e) =>
                                    handleReactDatetimeChange("startDate", e)
                                }
                                renderDay={(props, currentDate, selectedDate) => {
                                  let classes = props.className;
                                  classes += getClassNameReactDatetimeDays(
                                      currentDate
                                  );
                                  return (
                                      <td {...props} className={classes}>
                                        {currentDate.date()}
                                      </td>
                                  );
                                }}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs="2">
                          <FormGroup>
                            <label className=" form-control-label py-2">
                            </label>
                            <ReactDatetime
                                inputProps={{
                                  placeholder: "",
                                }}
                                value={endDate}
                                locale={'ko'}
                                timeFormat={false}
                                dateFormat={'YYYY-MM-DD'}
                                onChange={(e) =>
                                    handleReactDatetimeChange("endDate", e)
                                }
                                renderDay={(props, currentDate, selectedDate) => {
                                  let classes = props.className;
                                  classes += getClassNameReactDatetimeDays(
                                      currentDate
                                  );
                                  return (
                                      <td {...props} className={classes}>
                                        {currentDate.date()}
                                      </td>
                                  );
                                }}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs="3">
                          <label className=" form-control-label py-2">
                          </label>
                          <FormGroup>
                            <InputGroup
                                className={classnames("input-group-merge", {
                                  focused: searchCurrent,
                                })}
                            >
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="fas fa-search" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                  placeholder=""
                                  type="text"
                                  value={search}
                                  onChange={(e) => setSearch(e.target.value)}
                                  onFocus={(e) => setSearchCurrent(true)}
                                  onBlur={(e) => setSearchCurrent(false)}
                              />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
                <Row className="">
                  <Col xs="6">
                    <h3 className="mb-0">챌린지 인증 리스트</h3>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col className="mt-2 text-right" md="auto">
                    <h4>탄소e음 포인트</h4>
                  </Col>
                  <Col className="text-right" md="1">
                    <Input
                        type="text"
                        value={point}
                        onChange={(e) => setPoint(e.target.value)}
                    />
                  </Col>
                  <Col className="mt-2 text-right" md="auto">
                    <h4>탄소 저감량</h4>
                  </Col>
                  <Col className="text-right" md="1">
                    <Input
                        type="text"
                        value={carbon}
                        onChange={(e) => setCarbon(e.target.value)}
                    />
                  </Col>
                  <Col>
                    <Button
                      className="btn-round btn-icon"
                      color="warning"
                      onClick={update}
                    >
                      <span className="btn-inner--text">적립하기</span>
                    </Button>
                  </Col>
                  <Col className="text-right">
                    <Button color="primary" onClick={(e) => down()}>
                      엑셀 다운로드
                    </Button>
                  </Col>
                </Row>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive striped>
                <thead className="thead-light">
                  <tr>
                    <th className="text-center">
                      <div className="custom-control custom-checkbox">
                        <input
                            className="custom-control-input"
                            id="table-check-all1"
                            type="checkbox"
                            onClick={() => {
                              setDataId(data.map((v) => {
                                return v.id
                              }))
                            }}
                            checked={dataId.length === 15}
                            readOnly
                        />
                        <label
                            className="custom-control-label"
                            htmlFor="table-check-all1"
                        />
                      </div>
                    </th>
                    <th className="text-center" style={{ width: '10%' }}>회원번호</th>
                    <th className="text-center" style={{ width: '10%' }}>닉네임</th>
                    <th className="text-center" style={{ width: '10%' }}>등록일</th>
                    <th className="text-center" style={{ width: '10%' }}>내용</th>
                    <th className="text-center" style={{ width: '10%' }}>사진1</th>
                    <th className="text-center" style={{ width: '10%' }}>사진2</th>
                    <th className="text-center" style={{ width: '10%' }}>사진3</th>
                    <th className="text-center" style={{ width: '10%' }}>탄소e음 포인트</th>
                    <th className="text-center" style={{ width: '10%' }}>탄소 저감량</th>
                    {/*<th className="text-center" style={{ width: '10%' }}>적립 버튼</th>*/}
                    <th className="text-center" style={{ width: '10%' }}>적립 유무</th>
                    <th className="text-center" style={{ width: '10%' }}>적립 취소</th>
                    <th className="text-center" style={{ width: '10%' }}>게시글 삭제</th>
                    <th className="text-center" style={{ width: '10%' }}>댓글</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((item, i) => {
                    return [
                      <Fragment key={i}>
                        <tr>
                          <td className="text-center">
                            <div className="custom-control custom-checkbox">
                              <input
                                  className="custom-control-input"
                                  id={"table-check-all" + item.id}
                                  type="checkbox"
                                  onClick={() => {
                                    isCheck(item.id, dataId, setDataId)
                                  }}
                                  checked={dataId.indexOf(item.id) > -1}
                                  readOnly
                              />
                              <label
                                  className="custom-control-label"
                                  htmlFor={"table-check-all" + item.id}
                              />
                            </div>
                          </td>
                          <td className="text-center">
                            {item.id}
                          </td>
                          <td className="text-center">
                            {item.name}
                          </td>
                          <td className="text-center">
                            {item.createdAt}
                          </td>
                          <td className="text-center">
                            {item.content.length > 30? item.content.substr(0,20) + ' ···':item.content}
                          </td>
                          <td className="text-center">
                            {item.challengeUserImages[0]?.id
                                ? <img
                                    src={`https://d2twzv77g3c69u.cloudfront.net/fit-in/1000x700/${item.challengeUserImages[0]?.name}`}
                                    style={{ width: '50%', cursor: 'pointer' }}
                                    onClick={() => clickImg(`https://d2twzv77g3c69u.cloudfront.net/fit-in/1000x700/${item.challengeUserImages[0]?.name}`)}
                                />
                                :'-'
                            }
                          </td>
                          <td className="text-center">
                            {item.challengeUserImages[1]?.id
                                ? <img
                                    src={`https://d2twzv77g3c69u.cloudfront.net/fit-in/1000x700/${item.challengeUserImages[1]?.name}`}
                                    style={{ width: '50%', cursor: 'pointer' }}
                                    onClick={() => clickImg(`https://d2twzv77g3c69u.cloudfront.net/fit-in/1000x700/${item.challengeUserImages[1]?.name}`)}
                                />
                                :'-'
                            }
                          </td>
                          <td className="text-center">
                            {item.challengeUserImages[2]?.id
                                ? <img
                                    src={`https://d2twzv77g3c69u.cloudfront.net/fit-in/1000x700/${item.challengeUserImages[2]?.name}`}
                                    style={{ width: '50%', cursor: 'pointer' }}
                                    onClick={() => clickImg(`https://d2twzv77g3c69u.cloudfront.net/fit-in/1000x700/${item.challengeUserImages[2]?.name}`)}
                                />
                                :'-'
                            }
                          </td>
                          <td className="text-center">
                            {item.point? item.point:'-'}
                          </td>
                          <td className="text-center">
                            {item.carbon? item.carbon:'-'}
                          </td>
                          {/*<td className="text-center">*/}
                          {/*  <Button*/}
                          {/*    className="btn-outline-warning"*/}
                          {/*    color="danger"*/}
                          {/*    // onClick={() => setDefaultModal(true)}*/}
                          {/*    size="sm"*/}
                          {/*  >*/}
                          {/*    <span className="btn-inner--text">적립하기</span>*/}
                          {/*  </Button>*/}
                          {/*</td>*/}
                          <td className="text-center">
                            {item.isConfirm
                              ? <div className='text-blue'>적립완료</div>
                              : <div className=''>적립전</div>
                            }
                          </td>
                          {item.carbonPointHistoryId?
                              <td className="text-center">
                                <Button
                                    className="btn-danger"
                                    color="danger"
                                    onClick={() => historyRemove(item.carbonPointHistoryId)}
                                    size="sm"
                                >
                                  <span className="btn-inner--text">적립취소</span>
                                </Button>
                              </td>
                              :
                              <>
                                {item.isConfirm === false
                                  ? <td className="text-center">-</td>
                                  : <td className="text-center"><span className="text-red">취소완료</span></td>
                                }
                              </>
                          }
                          <td className="text-center">
                            <Button
                                className="btn-danger"
                                color="danger"
                                onClick={() => remove(item.id)}
                                size="sm"
                            >
                              <span className="btn-inner--text">삭제</span>
                            </Button>
                          </td>
                          <td className="text-center">
                            <Button
                                className="btn-outline-default"
                                color="danger"
                                onClick={() => router.push('/admin/commentList/' + item.id)}
                                size="sm"
                            >
                              <span className="btn-inner--text">상세</span>
                            </Button>
                          </td>
                        </tr>
                      </Fragment>
                    ];
                  })}
                </tbody>
              </Table>

              <CardFooter className="py-4">
                <PaginationWrapper
                  isActive={pageActive}
                  pageNumber={pageCount}
                  callback={(page) => setPageActive(page)}
                  itemPerPage={10}
                />
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default CertificateList;
