import axios from "axios";
import authHeader from "./auth-header";

import api from '../config/api.routes';

const statistics = () => {
  return axios.get(api().VISITORS_STATISTICS, { headers: authHeader() });
}

const accessList = () => {
  let url = api().apiURL + `/admin/accessList`;
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.get(url, { headers: authHeader() })
        .then(res => res.data)
        .then(res => resolve(res))
        .catch(e => reject(e));
  });
};

const serviceHistory = () => {
  let url = api().apiURL + `/admin/managerServiceCreateRanking?`;
  console.log(url)
  return new Promise((resolve, reject) => {
    axios.get(url, { headers: authHeader() })
        .then(res => res.data)
        .then(res => resolve(res))
        .catch(e => reject(e));
  });
};

const centerRanking = () => {
  let url = api().apiURL + `/admin/centerServiceTimeRanking?`;
  console.log(url)
  return new Promise((resolve, reject) => {
    axios.get(url, { headers: authHeader() })
        .then(res => res.data)
        .then(res => resolve(res))
        .catch(e => reject(e));
  });
};

const qrRanking = () => {
  let url = api().apiURL + `/admin/centerServiceTimeRanking?`;
  console.log(url)
  return new Promise((resolve, reject) => {
    axios.get(url, { headers: authHeader() })
        .then(res => res.data)
        .then(res => resolve(res))
        .catch(e => reject(e));
  });
};

const groupRanking = () => {
  let url = api().apiURL + `/admin/managerServiceTimeRanking?`;
  console.log(url)
  return new Promise((resolve, reject) => {
    axios.get(url, { headers: authHeader() })
        .then(res => res.data)
        .then(res => resolve(res))
        .catch(e => reject(e));
  });
};

const visitorsService = {
  statistics,
  serviceHistory,
  accessList,
  centerRanking,
  qrRanking,
  groupRanking,
}

export default visitorsService;
