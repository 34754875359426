import React, {Fragment, useEffect} from "react";

// reactstrap components
import {Button, Card, CardFooter, CardHeader, Col, Container, Row, Table,} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import {useHistory} from "react-router-dom";
import useHistoryState from "../../../../common/useHistoryState";
import PaginationWrapper from "../../components/pagination";
import carbonTabService from "../service/carbonTab.service";

function ActivityList() {
  const router =  useHistory();
  const [pageActive, setPageActive] = useHistoryState(1, 'pageActive');
  const [pageCount, setPageCount] = useHistoryState(1, 'pageCount');
  const [data, setData] = useHistoryState([], 'data');

  const render = async () => {
    const res = await carbonTabService.activityList({ page: pageActive });
    if (res.code === 200) {
      console.log(res);
      setPageCount(res.list.rows.length);
      setData(res.list.rows);
    }
  };

  useEffect(() => {
    render();
  }, [pageActive]);

  return (
    <>
      <SimpleHeader name="Tables" parentName="Tables" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col xs="6">
                    <h4 className="mb-0">상세활동 방법(최대 7페이지 가지 등록 가능)</h4>
                  </Col>
                  <Col className="text-right" xs="6">
                    <Button
                      className="btn-round btn-icon"
                      color="primary"
                      id="tooltip443412080"
                      onClick={(e) => router.push('/admin/activityForm')}
                    >
                      <span className="btn-inner--text">등록하기</span>
                    </Button>
                  </Col>
                </Row>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive striped>
                <thead className="thead-light">
                  <tr>
                    <th className="text-center">NO.</th>
                    <th className="text-center">상세활동방법 명</th>
                    <th className="text-center">관리</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((item, i) => {
                    return [
                      <Fragment key={i}>
                        <tr>
                          <td className="text-center">
                            {item.id}
                          </td>
                          <td className="text-center">
                            {item.title}
                          </td>
                          <td className="text-center">
                            <Button
                                className="btn-outline-default"
                                color="danger"
                                onClick={() => router.push('/admin/activityDetail/' + item.id)}
                                size="sm"
                            >
                              <span className="btn-inner--text">상세</span>
                            </Button>
                          </td>
                        </tr>
                      </Fragment>
                    ];
                  })}
                </tbody>
              </Table>

              <CardFooter className="py-4">
                <PaginationWrapper
                  isActive={pageActive}
                  pageNumber={pageCount}
                  callback={(page) => setPageActive(page)}
                  itemPerPage={10}
                />
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default ActivityList;
