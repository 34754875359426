import React, {useEffect, useRef, useState, Fragment} from "react";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Modal,
  Row,
} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import {useHistory} from "react-router-dom";
import {FormControl, InputGroup} from "react-bootstrap";
import DaumPostcode from "react-daum-postcode";
import {clickImg} from "../../../utils";
import advertisementService from "../advertisments/service/advertisement.service";
import {marketCategory} from "./market.const";
import marketService from "./market.service";

function MarketFrom({ match }) {
  const history = useHistory();
  const { id } = match.params;
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const [inputs, setInputs] = useState({
    category: '기획전/추천',
    sortCode: '',
    name: '',
    intro: '',
    price: '',
    realPrice: '',
    link: '',
  });

  const { category, name, link, price, realPrice, sortCode, intro } = inputs;

  const onChange = (e) => {
    let { value, name } = e.target;
    if (
        name === 'sortCode' ||
        name === 'price' ||
        name === 'realPrice'
    ) {
      setInputs({
        ...inputs,
        [name]: Number(value.replace(/[^0-9]/g, '')),
      })
    } else {
      setInputs({
        ...inputs,
        [name]: value
      });
    }
  };

  const [file, setFile] = useState('');
  const [previewURL, setPreviewURL] = useState('');
  const [preview, setPreview] = useState(null);
  const fileRef = useRef();

  const fetchData = async () => {
    setLoading(true);
    if (id) {
      const res = await marketService.detail(id);
      console.log(res);
      if (res.code === 200) {
        setData(res.data);
        if (res.data.thumbnail) {
          setPreviewURL(res.data.thumbnail);
          setPreview(
            <img
              className='img_preview ml-3'
              style={{ width: '250px', height: '250px' }}
              src={res.data.thumbnail}
            />
          );
        }
      }
      setInputs({
        category: res.data.category,
        sortCode: res.data.sortCode,
        name: res.data.name,
        intro: res.data.intro,
        price: res.data.price,
        realPrice: res.data.realPrice,
        link: res.data.link,
      });
    }
    setLoading(false);
  };

  useEffect( () => {
    fetchData();
  }, []);

  const handleFileOnChange = (event) => {
    //파일 불러오기
    event.preventDefault();
    let file = event.target.files[0];
    let reader = new FileReader();

    reader.onloadend = (e) => {
      setFile(file);
      setPreviewURL(reader.result);
    };
    if (file) reader.readAsDataURL(file);
  };

  const handleFileButtonClick = (e) => {
    //버튼 대신 클릭하기
    e.preventDefault();
    fileRef.current.click(); // file 불러오는 버튼을 대신 클릭함
  };

  useEffect(() => {
    if (file !== '')
      //처음 파일 등록하지 않았을 때를 방지
      setPreview(
        <img
          className='img_preview ml-3'
          style={{ width: '250px', height: '250px' }}
          src={previewURL}
        />
      );
    return () => {};
  }, [previewURL]);

  const submit = async (e) => {
    setLoading(true);
    try {
      await createOrUpdate(e);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }

  const createOrUpdate = async (e) => {
    if (category.length === 0) {
      alert('카테고리를 선택해주세요.');
      return;
    }

    if (sortCode.length === 0) {
      alert('우선순위를 입력해주세요.');
      return;
    }

    if (name.length === 0) {
      alert('상품명을 입력해주세요.');
      return;
    }

    if (intro.length === 0) {
      alert('한줄소개를 입력해주세요.');
      return;
    }

    if (price.length === 0) {
      alert('판매금액을 입력해주세요.');
      return;
    }

    if (realPrice.length === 0) {
      alert('상품표준가격을 입력해주세요.');
      return;
    }

    if (link.length === 0) {
      alert('링크(url)를 입력해주세요.');
      return;
    }

    if (!file && !previewURL) {
      alert('이미지를 등록해주세요.');
      return;
    }

    const data = {
      category, name, link, price, realPrice, sortCode, intro
    };

    let res;
    if (id) {
      res = await marketService.update(id, data);
    } else {
      res = await marketService.register(data);
    }
    console.log(res);
    if (res.code === 200) {
      const marketId = res.data?.marketId || id;
      if (file) {
        let form = new FormData();
        form.append('image', file);
        await marketService.fileRegister(marketId, form);
      }
      alert(`${id ? '수정' : '등록'}되었습니다.`);
      history.push('/admin/marketList');
    }
  }


  const remove = async () => {
    if (window.confirm('삭제하시겠습니까?')) {
      let res = await marketService.remove(id);
      console.log(res);
      if (res.code === 200) {
        history.goBack();
      }
    }
  }

  if (loading) return <div>로딩중..</div>;
  return (
    <>
      <SimpleHeader name="Form elements" parentName="Forms" />
      <Container className="mt--6" fluid>
        <Card className="mb-4">
          <CardHeader>
            <Row>
              <Col xs="11">
                <h3 className="mb-0">로컬 이음 마켓 {id ? '상세' : '등록'}</h3>
              </Col>
              {
                id ?
                  (<Col className="text-right" xs="12">
                    <Button
                      className="btn-round btn-icon"
                      color="primary"
                      onClick={submit}
                      size="sm"
                    >
                      <span className="btn-inner--text">저장하기</span>
                    </Button>
                    <Button
                      className="btn-round btn-icon"
                      color="danger"
                      onClick={remove}
                      size="sm"
                    >
                      <span className="btn-inner--text">삭제하기</span>
                    </Button>
                  </Col>) :
                  <Button
                    className="btn-round btn-icon"
                    color="primary"
                    onClick={submit}
                    size="md"
                  >
                    <span className="btn-inner--text">등록하기</span>
                  </Button>
              }
            </Row>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    카테고리 선택 <span className='text-red'>(필수)</span>
                  </label>
                  <Input
                    id="example3cols1Input"
                    type="select"
                    value={category || ''}
                    onChange={(e) => setInputs({ ...inputs, category: e.target.value})}
                  >
                    {marketCategory.map((c, i) => {
                      return [
                        <option key={i}>{c}</option>
                      ]
                    })}
                  </Input>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md="6">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    우선순위 <span className='text-red'>(필수)</span>
                  </label>
                  <Input
                      placeholder="숫자만"
                      type="text"
                      name="sortCode"
                      value={sortCode || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    상품명 <span className='text-red'>(필수)</span>
                  </label>
                  <Input
                    placeholder="상품명을 입력해주세요."
                    type="text"
                    name="name"
                    value={name || ''}
                    onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md="6">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    한줄소개 <span className='text-red'>(필수)</span>
                  </label>
                  <Input
                      placeholder="한줄소개를 입력해주세요."
                      type="text"
                      name="intro"
                      value={intro || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md="6">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    판매금액 <span className='text-red'>(필수)</span>
                  </label>
                  <Input
                      placeholder="숫자만"
                      type="text"
                      name="price"
                      value={price || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md="6">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    상품표준가격 <span className='text-red'>(필수)</span>
                  </label>
                  <Input
                      placeholder="숫자만"
                      type="text"
                      name="realPrice"
                      value={realPrice || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md="6">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    링크(url) <span className='text-red'>(필수)</span>
                  </label>
                  <Input
                      placeholder="링크(url)을 입력해주세요"
                      type="text"
                      name="link"
                      value={link || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row className={'mt-4'}>
              <Col xs="12">
                <label
                  className="form-control-label"
                  htmlFor="example4cols2Input"
                >
                  대표이미지첨부(300x300px) <span className='text-red'>(필수)</span>
                </label>
                <input
                  ref={fileRef}
                  hidden={true}
                  id='file'
                  type='file'
                  onChange={handleFileOnChange}
                />
                <Button
                  className="ml-4"
                  color="primary"
                  size="md"
                  type="button"
                  onClick={handleFileButtonClick}
                >
                  첨부하기
                </Button>
                <div className={'mt-3'}>
                  {previewURL && (
                    <img
                      style={{
                        height: '150px',
                        borderRadius: '5px',
                        marginRight: '20px',
                        cursor: 'pointer',
                      }}
                      src={previewURL}
                      onClick={() => clickImg(previewURL)}
                    />
                    // <a
                    //   className="avatar avatar-xl rounded"
                    //   style={{ cursor: 'pointer'}}
                    //   onClick={() => clickImg(previewURL)}
                    // >
                    //   <img src={previewURL}/>
                    // </a>
                  )}
                </div>
              </Col>

            </Row>

            <div className={'p-5'} />


          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default MarketFrom;
