import React, {Fragment, useEffect} from "react";

// reactstrap components
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Row,
  Table,
} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import PaginationWrapper from "../components/pagination";
import {paginate} from "../../../utils";
import useHistoryState from "../../../common/useHistoryState";
import classnames from "classnames";
import ReactDatetime from "react-datetime";
import moment from "moment";
import pushService from "../../../services/push.service";

function Push({ history }) {
  const [pageActive, setPageActive] = useHistoryState(1, 'pageActive');
  const [pageCount, setPageCount] = useHistoryState(1, 'pageCount');
  const [data, setData] = useHistoryState([], 'data');
  const [startDate, setStartDate] = useHistoryState(moment().add(-1, 'month').format('YYYY-MM-DD'), 'startDate');
  const [endDate, setEndDate] = useHistoryState(moment().format('YYYY-MM-DD'), 'endDate');
  const [searchCurrent, setSearchCurrent] = useHistoryState(false, 'searchCurrent');
  const [message, setMessage] = useHistoryState('', 'message');

  const handleReactDatetimeChange = (who, date) => {
    if (
      startDate &&
      who === "endDate" &&
      new Date(startDate) > new Date(date.format('YYYY-MM-DD'))
    ) {
      setStartDate(date.format('YYYY-MM-DD'));
      setEndDate(date.format('YYYY-MM-DD'));
    } else if (
      endDate &&
      who === "startDate" &&
      new Date(endDate) < new Date(date.format('YYYY-MM-DD'))
    ) {
      setStartDate(date.format('YYYY-MM-DD'));
      setEndDate(date.format('YYYY-MM-DD'));
    } else {
      if (who === "startDate") {
        setStartDate(date.format('YYYY-MM-DD'));
      } else {
        setEndDate(date.format('YYYY-MM-DD'));
      }
    }
  };

  const getClassNameReactDatetimeDays = (date) => {
    if (startDate && endDate) {
    }
    if (startDate && endDate && startDate !== endDate) {
      if (
        new Date(endDate) > new Date(date.format('YYYY-MM-DD')) &&
        new Date(startDate) < new Date(date.format('YYYY-MM-DD'))
      ) {
        return " middle-date";
      }
      if (endDate === date.format('YYYY-MM-DD')) {
        return " end-date";
      }
      if (startDate === date.format('YYYY-MM-DD')) {
        return " start-date";
      }
    }
    return "";
  };

  const render = () => {
    pushService.list({ startDate, endDate })
      .then((res) => res.data)
      .then((res) => {
        if (res.statusCode === 200) {
          console.log(res);
          setPageCount(res.list.length);
          setData(paginate(res.list, pageActive, 10));
        }
      });
  }

  useEffect(() => {
    render();
  }, [pageActive, startDate, endDate]);

  const send = () => {
    pushService.send(message)
      .then(res => res.data)
      .then(res => {
        if (res.code === 200) {
          console.log(res.data);
          alert(`${res.successCount} 명에게 전송했습니다.`);
          setMessage('');
          render();
        }
      });
  }

  return (
    <>
      <SimpleHeader name="Tables" parentName="Tables" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">푸시 알림 전송</h3>
                  </Col>
                </Row>
                <Row className="py-3">
                  <Col xs="11">
                    <Form>
                      <Row>
                        <Col xs="5">
                          <FormGroup>
                            <InputGroup
                              className={classnames("input-group-merge", {
                                focused: searchCurrent,
                              })}
                            >
                              <Input
                                placeholder="내용을 입력해주세요."
                                type="text"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                onFocus={(e) => setSearchCurrent(true)}
                                onBlur={(e) => setSearchCurrent(false)}
                              />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col>
                          <Button
                            color="primary"
                            type="button"
                            onClick={send}
                          >
                            보내기
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
                {/*<Row>*/}
                {/*  <Col xs="11">*/}
                {/*    <Form>*/}
                {/*      <Row>*/}
                {/*        <Col xs="2">*/}
                {/*          <label className=" form-control-label">*/}
                {/*            전송 날짜*/}
                {/*          </label>*/}
                {/*          <FormGroup>*/}
                {/*            <ReactDatetime*/}
                {/*              inputProps={{*/}
                {/*                placeholder: "",*/}
                {/*              }}*/}
                {/*              value={startDate}*/}
                {/*              locale='ko'*/}
                {/*              timeFormat={false}*/}
                {/*              dateFormat={'YYYY-MM-DD'}*/}
                {/*              onChange={(e) =>*/}
                {/*                handleReactDatetimeChange("startDate", e)*/}
                {/*              }*/}
                {/*              renderDay={(props, currentDate, selectedDate) => {*/}
                {/*                let classes = props.className;*/}
                {/*                classes += getClassNameReactDatetimeDays(*/}
                {/*                  currentDate*/}
                {/*                );*/}
                {/*                return (*/}
                {/*                  <td {...props} className={classes}>*/}
                {/*                    {currentDate.date()}*/}
                {/*                  </td>*/}
                {/*                );*/}
                {/*              }}*/}
                {/*            />*/}
                {/*          </FormGroup>*/}
                {/*        </Col>*/}
                {/*        <Col xs="2">*/}
                {/*          <FormGroup>*/}
                {/*            <label className=" form-control-label py-2">*/}
                {/*            </label>*/}
                {/*            <ReactDatetime*/}
                {/*              inputProps={{*/}
                {/*                placeholder: "",*/}
                {/*              }}*/}
                {/*              value={endDate}*/}
                {/*              locale='ko'*/}
                {/*              timeFormat={false}*/}
                {/*              dateFormat={'YYYY-MM-DD'}*/}
                {/*              onChange={(e) =>*/}
                {/*                handleReactDatetimeChange("endDate", e)*/}
                {/*              }*/}
                {/*              renderDay={(props, currentDate, selectedDate) => {*/}
                {/*                let classes = props.className;*/}
                {/*                classes += getClassNameReactDatetimeDays(*/}
                {/*                  currentDate*/}
                {/*                );*/}
                {/*                return (*/}
                {/*                  <td {...props} className={classes}>*/}
                {/*                    {currentDate.date()}*/}
                {/*                  </td>*/}
                {/*                );*/}
                {/*              }}*/}
                {/*            />*/}
                {/*          </FormGroup>*/}
                {/*        </Col>*/}
                {/*      </Row>*/}
                {/*    </Form>*/}
                {/*  </Col>*/}
                {/*</Row>*/}
                {/*<Row>*/}
                {/*  <Col xs="6">*/}
                {/*    <h3 className="mb-0">푸시 알림 전송내역</h3>*/}
                {/*  </Col>*/}
                {/*</Row>*/}
              </CardHeader>

              {/*<Table className="align-items-center table-flush" responsive striped>*/}
              {/*  <thead className="thead-light">*/}
              {/*  <tr>*/}
              {/*    <th className="text-center" style={{width: '10%'}}>번호</th>*/}
              {/*    <th className="text-center" style={{width: '15%'}}>전송날짜</th>*/}
              {/*    <th className="text-center" style={{width: '65%'}}>내용</th>*/}
              {/*  </tr>*/}
              {/*  </thead>*/}
              {/*  <tbody>*/}
              {/*  {data?.map((item, i) => {*/}
              {/*    return [*/}
              {/*      <Fragment key={i}>*/}
              {/*        <tr>*/}
              {/*          <td className="text-center">*/}
              {/*            {item.id}*/}
              {/*          </td>*/}
              {/*          <td className="text-center">*/}
              {/*            {item.createdAt}*/}
              {/*          </td>*/}
              {/*          <td className="text-center">*/}
              {/*            {item.message}*/}
              {/*          </td>*/}
              {/*        </tr>*/}
              {/*      </Fragment>*/}
              {/*    ];*/}
              {/*  })}*/}
              {/*  </tbody>*/}
              {/*</Table>*/}

              {/*<CardFooter className="py-4">*/}
              {/*  <PaginationWrapper*/}
              {/*    isActive={pageActive}*/}
              {/*    pageNumber={pageCount}*/}
              {/*    callback={(page) => setPageActive(page)}*/}
              {/*    itemPerPage={10}*/}
              {/*  />*/}
              {/*</CardFooter>*/}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default Push;
