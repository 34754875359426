import React, {useEffect, useState} from "react";
// reactstrap components
import {Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Input, Row,} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import {useHistory} from "react-router-dom";

import moment from "moment";
import {useRecoilState, useRecoilValue, useResetRecoilState} from "recoil";
import InputFile from "../components/InputFile";
import useHistoryState from "../../../common/useHistoryState";
import ReactDatetime from "react-datetime";
import {detailFilePreviewState, detailFileState} from "../advertisments/service/advertisement.service";
import togetherChallengeService from "../togetherChallenge/service/togetherChallenge.service";
import regionAdvertService, {regionFilePreviewState, regionFileState} from "./service/regionAdvert.service";

function RegionAdvertForm({ match }) {
  const history = useHistory();
  const { id } = match.params;
  const [loading, setLoading] = useState(false);
  const files = useRecoilValue(regionFileState);
  const [previewURLs, setPreviewURLs] = useRecoilState(regionFilePreviewState);
  const [startDate, setStartDate] = useHistoryState('', 'startDate');
  const [endDate, setEndDate] = useHistoryState('', 'endDate');
  const [data, setData] = useState({});

  const fetchData = async () => {
    const res = await regionAdvertService.detail(id);
    if (res.code === 200) {
      console.log(res);
      setData(res.data)
      setEndDate(res.data.endDate);
      if(res.data.thumbnail) {
        setPreviewURLs(`https://d2twzv77g3c69u.cloudfront.net/fit-in/1000x700/${res.data.thumbnail}`)
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const remove = async () => {
    if (window.confirm('삭제하시겠습니까?')) {
      let res = await regionAdvertService.remove(id);
      console.log(res);
      if (res.code === 200) {
        alert('삭제되었습니다.')
        history.goBack();
      }
    }
  };

  if (loading) return <div>로딩중..</div>;
  return (
    <>
      <SimpleHeader name="Form elements" parentName="Forms" />
      <Container className="mt--6" fluid>
        <Card className="mb-4">
          <CardHeader style={{ display: 'flex', justifyContent: 'space-between' }}>
            {id?
                <>
                  <h3 className="mt-2">지역 광고 관리 상세</h3>
                  <div>
                    {/*<Button*/}
                    {/*  color="default"*/}
                    {/*  type="button"*/}
                    {/*  onClick={submit}*/}
                    {/*>*/}
                    {/*  수정하기*/}
                    {/*</Button>*/}
                    <Button
                      color="danger"
                      type="button"
                      onClick={remove}
                    >
                      삭제하기
                    </Button>
                  </div>
                </>
                :
                <>
                  {/*<h3 className="mt-2">지역 광고 관리 등록</h3>*/}
                  {/*<div>*/}
                  {/*  <Button*/}
                  {/*      color="default"*/}
                  {/*      type="button"*/}
                  {/*      onClick={submit}*/}
                  {/*  >*/}
                  {/*    <span className="btn-inner--icon mr-1">*/}
                  {/*        <i className="fas fa-user-edit" />*/}
                  {/*    </span>*/}
                  {/*    등록하기*/}
                  {/*  </Button>*/}
                  {/*</div>*/}
                </>
            }
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="2">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    광고 등록 날짜
                  </label>
                  <h3>{moment(data.createdAt).format('YYYY-MM-DD HH:mm')}</h3>
                </FormGroup>
              </Col>
              <Col md="2">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    아이디
                  </label>
                  <h3>{data.shop?.user.loginId}</h3>
                  <h3>{data.sns?.user.loginId}</h3>
                </FormGroup>
              </Col>
              <Col md="2">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    카테고리
                  </label>
                  <h3>{data.shop? data.shop.category:'-'}</h3>
                </FormGroup>
              </Col>
              <Col md="2">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    업체명
                  </label>
                  <h3>{data.shop? data.shop.name:'-'}</h3>
                </FormGroup>
              </Col>
              <Col md="2">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    구분
                  </label>
                  <h3>{data.snsId? '게시글AD':'상점AD'}</h3>
                </FormGroup>
              </Col>
              <Col md="2">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    주소
                  </label>
                  <h3>{data.address}</h3>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="2">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    리뷰 내용
                  </label>
                  <h3>{data.review?.content}</h3>
                </FormGroup>
              </Col>
            </Row>
            {/*<Row>*/}
            {/*  <Col>*/}
            {/*    <InputFile*/}
            {/*        title={'사진'}*/}
            {/*        fileState={regionFileState}*/}
            {/*        filePreviewState={regionFilePreviewState}*/}
            {/*    />*/}
            {/*  </Col>*/}
            {/*</Row>*/}
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default RegionAdvertForm;
