import React, {useEffect, useState} from "react";
// reactstrap components
import {Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Input, Row,} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import {useHistory} from "react-router-dom";

import moment from "moment";
import {useRecoilState, useRecoilValue, useResetRecoilState} from "recoil";
import InputFile from "../components/InputFile";
import useHistoryState from "../../../common/useHistoryState";
import ReactDatetime from "react-datetime";
import togetherChallengeService, {
  togetherBannerDetailFilePreviewState,
  togetherBannerDetailFileState,
  togetherBannerFilePreviewState,
  togetherBannerFileState,
  togetherThumbFilePreviewState,
  togetherThumbFileState
} from "./service/togetherChallenge.service";
import {detailFilePreviewState, detailFileState} from "../advertisments/service/advertisement.service";

function TogetherChallengeForm({ match }) {
  const history = useHistory();
  const { id } = match.params;
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState('url');
  const [url, setUrl] = useState('');

  const thumbFile = useRecoilValue(togetherThumbFileState);
  const [previewThumbURL, setPreviewThumbURL] = useRecoilState(togetherThumbFilePreviewState);
  const resetThumbFile = useResetRecoilState(togetherThumbFileState);
  const resetDetailFilePreview = useResetRecoilState(togetherThumbFilePreviewState);

  const bannerFile = useRecoilValue(togetherBannerFileState);
  const [previewBannerURL, setPreviewBannerURL] = useRecoilState(togetherBannerFilePreviewState);
  const resetBannerFile = useResetRecoilState(togetherBannerFileState);
  const resetBannerFilePreview = useResetRecoilState(togetherBannerFilePreviewState);

  const bannerDetailFile = useRecoilValue(togetherBannerDetailFileState);
  const [previewBannerDetailURL, setPreviewBannerDetailURL] = useRecoilState(togetherBannerDetailFilePreviewState);
  const resetBannerDetailFile = useResetRecoilState(togetherBannerDetailFileState);
  const resetBannerDetailFilePreview = useResetRecoilState(togetherBannerDetailFilePreviewState);

  const [startDate, setStartDate] = useHistoryState('', 'startDate');
  const [endDate, setEndDate] = useHistoryState('', 'endDate');
  const [data, setData] = useState({
    title: '',
    loginId: '',
    mission: '',
    way: '',
    people: '',
    condition: '',
    note: '',
    diff: '',
    youtube: '',
    link: '',
  });
  const {
    title,
    loginId,
    mission,
    way,
    people,
    condition,
    note,
    diff,
    youtube,
    link,
  } = data;

  const reset = () => {
    resetThumbFile();
    resetDetailFilePreview();
    resetBannerFile();
    resetBannerFilePreview();
    resetBannerDetailFile();
    resetBannerDetailFilePreview();
  }

  const onChange = (e) => {
    let { value, name } = e.target;
    if (name === 'sortCode'
    ) {
      setData({
        ...data,
        [name]: Number(value.replace(/[^0-9]/g, '')),
      })
    } else {
      setData({
        ...data,
        [name]: value
      });
    }
  };

  const handleReactDatetimeChange = (who, date) => {
    if (
        startDate &&
        who === "endDate" &&
        new Date(startDate) > new Date(date.format('YYYY-MM-DD'))
    ) {
      setStartDate(date.format('YYYY-MM-DD'));
      setEndDate(date.format('YYYY-MM-DD'));
    } else if (
        endDate &&
        who === "startDate" &&
        new Date(endDate) < new Date(date.format('YYYY-MM-DD'))
    ) {
      setStartDate(date.format('YYYY-MM-DD'));
      setEndDate(date.format('YYYY-MM-DD'));
    } else {
      if (who === "startDate") {
        setStartDate(date.format('YYYY-MM-DD'));
      } else {
        setEndDate(date.format('YYYY-MM-DD'));
      }
    }
  };

  const getClassNameReactDatetimeDays = (date) => {
    if (startDate && endDate) {
    }
    if (startDate && endDate && startDate !== endDate) {
      if (
          new Date(endDate) > new Date(date.format('YYYY-MM-DD')) &&
          new Date(startDate) < new Date(date.format('YYYY-MM-DD'))
      ) {
        return " middle-date";
      }
      if (endDate === date.format('YYYY-MM-DD')) {
        return " end-date";
      }
      if (startDate === date.format('YYYY-MM-DD')) {
        return " start-date";
      }
    }
    return "";
  };

  const submit = async () => {
    setLoading(true);
    try {
      await createOrUpdate();
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }

  const createOrUpdate = async () => {
    if (!title) {
        alert('제목을 입력해주세요.');
        return;
    }

    const json = {
      ...data,
      startDate,
      endDate,
    }
    if(id) {
      delete json.companyId
    }
    if(url) {
      json.url = url
    }
    if(!loginId) {
      delete json.loginId
    }

    console.log('json',json);

    let res;
    try {
      if (id) {
        res = await togetherChallengeService.update(id, json);
      } else {
        res = await togetherChallengeService.register(json);
      }
      if (res.resultMsg === "success") {
        if (thumbFile) {
          let form = new FormData();
          console.log('image', thumbFile, typeof thumbFile);
          form.append('image', thumbFile);
          await togetherChallengeService.fileRegister(id? id:res.data.challengeId, form);
        }
        if (bannerFile) {
          let form = new FormData();
          console.log('image', bannerFile, typeof bannerFile);
          form.append('image', bannerFile);
          await togetherChallengeService.bannerRegister(id? id:res.data.challengeId, form);
        }
        if (bannerDetailFile) {
          let form = new FormData();
          console.log('image', bannerDetailFile, typeof bannerDetailFile);
          form.append('image', bannerDetailFile);
          await togetherChallengeService.bannerDetailRegister(id? id:res.data.challengeId, form);
        }
        alert(id ? '수정되었습니다.' : '등록되었습니다.');
        history.goBack();
        reset();
      }
    } catch (e) {
      // alert(JSON.stringify(e, null, 4));
      alert(e.response?.data?.message);
    }
    console.log('웹', res);
  };

  const fetchData = async () => {
    const res = await togetherChallengeService.detail(id);
    if (res.code === 200) {
      console.log(res);
      setData({
        title: res.data.title,
        mission: res.data.mission,
        way: res.data.way,
        people: res.data.people,
        loginId: res.data.loginId,
        condition: res.data.condition,
        note: res.data.note,
        diff: res.data.diff,
        youtube: res.data.youtube,
        link: res.data.link,
      });
      setStartDate(res.data.startDate);
      setEndDate(res.data.endDate);
      setUrl(res.data.url);
      if(res.data.thumbnail) {
        setPreviewThumbURL(`https://d2twzv77g3c69u.cloudfront.net/fit-in/1000x700/${res.data.thumbnail}`)
      } else {
        setPreviewThumbURL('')
      }
      if(res.data.banner) {
        setPreviewBannerURL(`https://d2twzv77g3c69u.cloudfront.net/fit-in/1000x700/${res.data.banner}`)
      } else {
        setPreviewBannerURL('')
      }
      if(res.data.bannerDetail) {
        setPreviewBannerDetailURL(`https://d2twzv77g3c69u.cloudfront.net/fit-in/1000x700/${res.data.bannerDetail}`)
        setType('이미지')
      } else {
        setPreviewBannerDetailURL('')
      }
    }
  };

  useEffect(() => {
    if(id) {
      fetchData();
    } else {
      reset();
    }
  }, []);

  const remove = async () => {
    if (window.confirm('삭제하시겠습니까?')) {
      let res = await togetherChallengeService.remove(id);
      console.log(res);
      if (res.code === 200) {
        alert('삭제되었습니다.')
        history.goBack();
      }
    }
  };

  if (loading) return <div>로딩중..</div>;
  return (
    <>
      <SimpleHeader name="Form elements" parentName="Forms" />
      <Container className="mt--6" fluid>
        <Card className="mb-4">
          <CardHeader style={{ display: 'flex', justifyContent: 'space-between' }}>
            {id?
                <>
                  <h3 className="mt-2">함께하는 챌린지 상세</h3>
                  <div>
                    <Button
                      color="default"
                      type="button"
                      onClick={submit}
                    >
                      수정하기
                    </Button>
                    <Button
                      color="danger"
                      type="button"
                      onClick={remove}
                    >
                      삭제하기
                    </Button>
                  </div>
                </>
                :
                <>
                  <h3 className="mt-2">함께하는 챌린지 등록</h3>
                  <div>
                    <Button
                        color="default"
                        type="button"
                        onClick={submit}
                    >
                      <span className="btn-inner--icon mr-1">
                          <i className="fas fa-user-edit" />
                      </span>
                      등록하기
                    </Button>
                  </div>
                </>
            }
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    카테고리
                  </label>
                  <Input
                      id="diff"
                      type="select"
                      value={diff}
                      onChange={(e) => {
                        setData({...data, diff: e.target.value})
                      }}
                  >
                    <option>전체</option>
                    <option>기업/기관</option>
                    <option>봉사센터</option>
                    <option>지자체</option>
                    <option>기타</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    센터 아이디
                  </label>
                  <Input
                      placeholder="챌린지명을 입력해주세요."
                      type="text"
                      name="loginId"
                      value={loginId || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    챌린지명
                  </label>
                  <Input
                      placeholder="챌린지명을 입력해주세요."
                      type="text"
                      name="title"
                      value={title || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    시작날짜
                  </label>
                  <FormGroup>
                    <ReactDatetime
                        inputProps={{
                          placeholder: "",
                        }}
                        value={startDate}
                        locale={'ko'}
                        timeFormat={false}
                        dateFormat={'YYYY-MM-DD'}
                        onChange={(e) =>
                            handleReactDatetimeChange("startDate", e)
                        }
                        renderDay={(props, currentDate, selectedDate) => {
                          let classes = props.className;
                          classes += getClassNameReactDatetimeDays(
                              currentDate
                          );
                          return (
                              <td {...props} className={classes}>
                                {currentDate.date()}
                              </td>
                          );
                        }}
                    />
                  </FormGroup>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    종료날짜
                  </label>
                  <ReactDatetime
                      inputProps={{
                        placeholder: "",
                      }}
                      value={endDate}
                      locale={'ko'}
                      timeFormat={false}
                      dateFormat={'YYYY-MM-DD'}
                      onChange={(e) =>
                          handleReactDatetimeChange("endDate", e)
                      }
                      renderDay={(props, currentDate, selectedDate) => {
                        let classes = props.className;
                        classes += getClassNameReactDatetimeDays(
                            currentDate
                        );
                        return (
                            <td {...props} className={classes}>
                              {currentDate.date()}
                            </td>
                        );
                      }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    인증미션
                  </label>
                  <Input
                      placeholder="인증미션을 입력해주세요."
                      type="textarea"
                      rows="10"
                      name="mission"
                      value={mission || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    인증방법
                  </label>
                  <Input
                      placeholder="인증방법을 입력해주세요."
                      type="textarea"
                      rows="10"
                      name="way"
                      value={way || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    모집인원
                  </label>
                  <Input
                      placeholder="모집인원을 입력해주세요."
                      type="text"
                      name="people"
                      value={people || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    멤버조건
                  </label>
                  <Input
                      placeholder="멤버조건을 입력해주세요."
                      type="text"
                      name="condition"
                      value={condition || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    특이사항
                  </label>
                  <Input
                      placeholder="특이사항을 입력해주세요."
                      type="text"
                      name="note"
                      value={note || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    유튜브 링크
                  </label>
                  <Input
                      placeholder="유튜브 링크를 입력해주세요."
                      type="text"
                      name="youtube"
                      value={youtube || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    관련 링크
                  </label>
                  <Input
                      placeholder="관련 링크를 입력해주세요."
                      type="text"
                      name="link"
                      value={link || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <InputFile
                    title={'대표 이미지 첨부(720px X 755px)'}
                    fileState={togetherThumbFileState}
                    filePreviewState={togetherThumbFilePreviewState}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <InputFile
                    title={'인증리스트 상단 배너광고(720px)'}
                    fileState={togetherBannerFileState}
                    filePreviewState={togetherBannerFilePreviewState}
                />
              </Col>
            </Row>
            <Row>
              <Col className="pt-3">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="check1"
                  >
                    구분
                  </label>
                  <Row className="pl-3 pt-2">
                    <Col xs="1" className="custom-control custom-radio mb-3">
                      <input
                          className="custom-control-input"
                          id="customRadio5"
                          name="diff"
                          type="radio"
                          value='url'
                          checked={type === 'url'}
                          onChange={(e) => setType(e.target.value)}
                      />
                      <label
                          className="custom-control-label"
                          htmlFor="customRadio5"
                      >
                        url
                      </label>
                    </Col>
                    <Col xs="1" className="custom-control custom-radio mb-3">
                      <input
                          className="custom-control-input"
                          defaultChecked
                          id="customRadio6"
                          name="diff"
                          type="radio"
                          value='이미지'
                          checked={type === '이미지'}
                          onChange={(e) => setType(e.target.value)}
                      />
                      <label
                          className="custom-control-label"
                          htmlFor="customRadio6"
                      >
                        이미지
                      </label>
                    </Col>
                  </Row>
                  {type === 'url'
                      ?
                      <Row>
                        <Col md="4">
                          <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="titleInput1"
                            >
                              url
                            </label>
                            <Input
                                id="titleInput1"
                                placeholder="url을 입력해주세요"
                                name="url"
                                type="text"
                                value={url || ''}
                                onChange={(e) => setUrl(e.target.value)}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      :
                      <Row>
                        <Col>
                          <InputFile
                              title={'상세 이미지(가로 최소 720px)'}
                              fileState={togetherBannerDetailFileState}
                              filePreviewState={togetherBannerDetailFilePreviewState}
                          />
                        </Col>
                      </Row>
                  }
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default TogetherChallengeForm;
