import axios from "axios";
import api from "../../../../config/api.routes";
import authHeader from "../../../../services/auth-header";
import {atom} from "recoil";

//file
export const charityFileState = atom({
  key: 'charityFileState',
  default: '',
});

export const charityFilePreviewState = atom({
  key: 'charityFilePreviewState',
  default: '',
});

export const charityDetailFileState = atom({
  key: 'charityDetailFileState',
  default: '',
});

export const charityDetailFilePreviewState = atom({
  key: 'charityDetailFilePreviewState',
  default: '',
});

export const englishDetailFileState = atom({
  key: 'englishDetailFileState',
  default: '',
});

export const englishDetailFilePreviewState = atom({
  key: 'englishDetailFilePreviewState',
  default: '',
});

const list = (data) => {
  let url = api().apiURL + `/v2/charity/list?`;
  for (let query of Object.keys(data)) {
    if (data[query] === undefined || data[query] === '') {
      continue;
    }
    url += `${query}=${data[query]}&`;
  }
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.get(url, { headers: authHeader() })
      .then(res => res.data)
      .then(res => resolve(res))
      .catch(e => reject(e));
  });
};

const historyList = (data) => {
  let url = api().apiURL + `/charityHistory/detail?`;
  for (let query of Object.keys(data)) {
    if (data[query] === undefined || data[query] === '') {
      continue;
    }
    url += `${query}=${data[query]}&`;
  }
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.get(url, { headers: authHeader() })
      .then(res => res.data)
      .then(res => resolve(res))
      .catch(e => reject(e));
  });
};

const register = (data) => {
  let url = api().apiURL + `/charity/register?`;
  console.log('서비스',url,data);
  return new Promise((resolve, reject) => {
    axios.post(url, data, { headers: authHeader() })
        .then(res => res.data)
        .then(res => resolve(res))
        .catch(e => reject(e));
  });
};

const fileRegister = (id, data, diff) => {
  let url = api().apiURL + `/charity/fileUpload?charityId=${id}&diff=${diff}`;
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.post(url, data, { headers: authHeader() })
        .then(res => res.data)
        .then(res => resolve(res))
        .catch(e => reject(e));
  });
};

const detail = (id) => {
  let url = api().apiURL + `/v2/charity/detail?charityId=${id}`;
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.get(url, { headers: authHeader() })
      .then(res => res.data)
      .then(res => resolve(res))
      .catch(e => reject(e));
  });
};

const update = (id, data) => {
  let url = api().apiURL + `/charity/update?charityId=${id}`;
  console.log('수정',url);
  return new Promise((resolve, reject) => {
    axios.put(url, data, { headers: authHeader() })
        .then(res => res.data)
        .then(res => resolve(res))
        .catch(e => reject(e));
  });
};

const updateService = (id, data) => {
  let url = api().apiURL + `/admin/service/history/${id}?confirm=${data}`;
  console.log('수정',url);
  return new Promise((resolve, reject) => {
    axios.put(url, data, { headers: authHeader() })
        .then(res => res.data)
        .then(res => resolve(res))
        .catch(e => reject(e));
  });
};

const remove = (id) => {
  let url = api().apiURL + `/admin/service/history/${id}`;
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.delete(url, { headers: authHeader() })
        .then(res => res.data)
        .then(res => resolve(res))
        .catch(e => reject(e));
  });
};

const removeCharity = (id) => {
  let url = api().apiURL + `/charity/delete?charityId=${id}`;
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.delete(url, { headers: authHeader() })
        .then(res => res.data)
        .then(res => resolve(res))
        .catch(e => reject(e));
  });
};

const charityHistoryDetailDown = (data) => {
  let url = api().apiURL + `/charityHistory/detail?`;
  for (let query of Object.keys(data)) {
    if (data[query] === undefined || data[query] === '') {
      continue;
    }
    url += `${query}=${data[query]}&`;
  }
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.get(url, { headers: authHeader(), responseType: 'arraybuffer' })
        .then(res => resolve(res))
        .catch(e => reject(e));
  });
};

const charityService = {
  list,
  historyList,
  register,
  fileRegister,
  detail,
  update,
  updateService,
  remove,
  removeCharity,
  charityHistoryDetailDown,
}

export default charityService;
