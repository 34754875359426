import React, {Fragment, useEffect, useState} from "react";

// reactstrap components
import {
    Button,
    Card,
    CardFooter,
    CardHeader,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText, Modal,
    Row,
    Table, UncontrolledTooltip,
} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import PaginationWrapper from "../components/pagination";
import {clickImg, numberWithCommas, paginate, telWithCommas} from "../../../utils";
import useHistoryState from "../../../common/useHistoryState";
import moment from "moment";
import {useHistory} from "react-router";
import ploggingService from "./service/plogging.service";
import centerService from "../center/service/center.service";
import {Link} from "react-router-dom";
import storeService from "../store/service/store.service";
import ReactDatetime from "react-datetime";
import classnames from "classnames";
import AuthService from "../auth/auth.service";
import sharingPloggingService from "./service/sharingPlogging.service";
import dayjs from "dayjs";

function SharedPloggingCalcMgmt({match}) {
    const user = typeof window !== 'undefined' ? AuthService.getCurrentUser() : null;
    const centerPlogging = JSON.parse(localStorage.getItem('user'))?.centerPlogging;
    const companyPlogging = JSON.parse(localStorage.getItem('user'))?.companyPlogging;
    const history = useHistory();
    const [finalDate, setFinalDate] = useState('');
    const [pageActive, setPageActive] = useHistoryState(1, 'pageActive');
    const [pageCount, setPageCount] = useHistoryState(1, 'pageCount');
    const [data, setData] = useHistoryState([], 'data');
    const [searchCurrent, setSearchCurrent] = useHistoryState(false, 'searchCurrent');
    const [search, setSearch] = useHistoryState('', 'search');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const render = () => {
        const revisedStartDate = !startDate ? undefined : dayjs(startDate).startOf('month').format('YYYY-MM-DD');
        const revisedEndDate = !startDate ? undefined : dayjs(startDate).endOf('month').format('YYYY-MM-DD');
        let option = {startDate: revisedStartDate, endDate: revisedEndDate};
        if (search) {
            option.search = search;
        }
        if (pageCount) {
            option.page = pageActive;
        }
        if (user.centerId) {
            option.centerId = user.centerId;
        }

        sharingPloggingService.list(option)
            .then((res) => {
                if (res.code === 200) {
                    console.log(res);
                    setPageCount(res.list.count);
                    setData(res.list.rows);
                }
            });
    };

    useEffect(() => {
        render();
    }, [pageActive, pageCount, search, startDate, endDate]);

    const remove = (id) => {
        if (window.confirm('삭제하시겠습니까?')) {
            ploggingService.remove(Number(id))
                .then(res => res.data)
                .then(res => {
                    alert('삭제되었습니다.')
                    render();
                })
        }
    };

    const handleReactDatetimeChange = (who, date) => {
        if (
            startDate &&
            who === "endDate" &&
            new Date(startDate) > new Date(date.format('YYYY-MM-DD'))
        ) {
            setStartDate(date.format('YYYY-MM-DD'));
            setEndDate(date.format('YYYY-MM-DD'));
        } else if (
            endDate &&
            who === "startDate" &&
            new Date(endDate) < new Date(date.format('YYYY-MM-DD'))
        ) {
            setStartDate(date.format('YYYY-MM-DD'));
            setEndDate(date.format('YYYY-MM-DD'));
        } else {
            if (who === "startDate") {
                setStartDate(date.format('YYYY-MM-DD'));
            } else {
                setEndDate(date.format('YYYY-MM-DD'));
            }
        }
    };

    const getClassNameReactDatetimeDays = (date) => {
        if (startDate && endDate) {
        }
        if (startDate && endDate && startDate !== endDate) {
            if (
                new Date(endDate) > new Date(date.format('YYYY-MM-DD')) &&
                new Date(startDate) < new Date(date.format('YYYY-MM-DD'))
            ) {
                return " middle-date";
            }
            if (endDate === date.format('YYYY-MM-DD')) {
                return " end-date";
            }
            if (startDate === date.format('YYYY-MM-DD')) {
                return " start-date";
            }
        }
        return "";
    };

    const statusChange = async (id, check) => {
        await centerService.update(id, {isPlogging: check});
        render();
    }

    const changeCenter = async (i, id) => {
        await centerService.update(id, {endDate: data[i].endDate});
        render();
        alert('수정되었습니다')
    }

    return (
        <>
            <>
                <>
                    <SimpleHeader name="Tables" parentName="Tables"/>
                    <Container className="mt--6" fluid>
                        <Row>
                            <div className="col">
                                <Card>
                                    <CardHeader className="border-0">
                                        <Row className="py-3">
                                            <Col xs="11">
                                                <Form>
                                                    <Row>
                                                            <Col md="auto">
                                                                <label className=" form-control-label">
                                                                    날짜 조회
                                                                </label>
                                                                <Row style={{ gap: 16 }}>
                                                                    <ReactDatetime
                                                                        inputProps={{
                                                                            placeholder: "",
                                                                        }}
                                                                        value={startDate}
                                                                        locale={'ko'}
                                                                        timeFormat={false}
                                                                        dateFormat={'YYYY-MM'}
                                                                        onChange={(e) =>
                                                                            handleReactDatetimeChange("startDate", e)
                                                                        }
                                                                        renderDay={(props, currentDate, selectedDate) => {
                                                                            let classes = props.className;
                                                                            classes += getClassNameReactDatetimeDays(
                                                                                currentDate
                                                                            );
                                                                            return (
                                                                                <td {...props} className={classes}>
                                                                                    {currentDate.date()}
                                                                                </td>
                                                                            );
                                                                        }}
                                                                    />
                                                                    <Button
                                                                        className="btn-default"
                                                                        onClick={() => {
                                                                            setStartDate('')
                                                                            setEndDate('')
                                                                            setSearch("")
                                                                        }}
                                                                    >
                                                                        <span className="btn-inner-text">초기화</span>
                                                                    </Button>
                                                                </Row>
                                                            </Col>
                                                    </Row>
                                                </Form>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="6">
                                                <h3 className="mt-2">나눔플로깅 리스트 및 정산 관리</h3>
                                            </Col>
                                            {/*{user?.role === 'center' ? null : (*/}
                                            {/*  <Col className="text-right">*/}
                                            {/*      <Button color="default" onClick={(e) => history.push('/admin/donationForm')}>*/}
                                            {/*          등록하기*/}
                                            {/*      </Button>*/}
                                            {/*  </Col>*/}
                                            {/*)}*/}
                                        </Row>
                                    </CardHeader>

                                    <Table className="align-items-center table-flush" responsive striped>
                                        <thead className="thead-light">
                                        <tr>
                                            <th className="text-center" style={{width: '10%'}}>봉사 센터명</th>
                                            <th className="text-center" style={{width: '10%'}}>참여 인원 수</th>
                                            <th className="text-center" style={{width: '10%'}}>건별 활동 수</th>
                                            <th className="text-center" style={{width: '10%'}}>결제 금액</th>
                                            <th className="text-center" style={{width: '10%'}}>기부 전달 금액</th>
                                            <th className="text-center" style={{width: '45%'}}></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {data?.map((item, i) => {
                                            return [
                                                <Fragment key={i}>
                                                    <tr>
                                                        <td className="text-center">
                                                            {item.center}
                                                        </td>
                                                        <td className="text-center">
                                                            {numberWithCommas(item.totalCount)}
                                                        </td>
                                                        <td className="text-center">
                                                            {numberWithCommas(item.workingCount)}
                                                        </td>
                                                        <td className="text-center" style={{display: 'flex', gap: 8, justifyContent: 'center', alignItems: 'center'}}>
                                                            {numberWithCommas(item.payAmount)}
                                                            <Button
                                                                className="btn-outline-warning"
                                                                onClick={() => history.push('/admin/sharedPloggingCalcMgmtPlogging/' + item.id + `/${item.center}`)}
                                                                size="sm"
                                                            >
                                                                <span className="btn-inner--text">리스트 보기</span>
                                                            </Button>
                                                        </td>
                                                        <td className="text-center">
                                                            {numberWithCommas(item.totalPrice)}
                                                        </td>
                                                    </tr>
                                                </Fragment>
                                            ];
                                        })}
                                        </tbody>
                                    </Table>

                                    <CardFooter className="py-4">
                                        <PaginationWrapper
                                            isActive={pageActive}
                                            pageNumber={pageCount}
                                            callback={(page) => setPageActive(page)}
                                            itemPerPage={10}
                                        />
                                    </CardFooter>
                                </Card>
                            </div>
                        </Row>
                    </Container>
                </>
            </>

        </>
    );
}

export default SharedPloggingCalcMgmt;
