const Spinner = () => {
    return (
        <>
            <div
                class="spinner-border"
                style={{ width: '4rem', height: '4rem', marginTop: '20%'}}
                role="status"
            >
                <span class="sr-only">
                    Loading...
                </span>

            </div>
            <br/>
            <br/>
            <span>
                Loading...
            </span>
        </>
    )
}

export default Spinner;