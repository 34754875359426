import {Button, Card, CardBody, CardHeader, Col, Container, Input, Row} from "reactstrap";
import SimpleHeader from "../../../components/Headers/SimpleHeader";
import React, {useEffect, useState} from "react";
import ReactDatetime from "react-datetime";
import moment from "moment";
import AuthService from "../auth/auth.service";
import userService from "./user.service";

function StepForm({ match, history }) {
    const user = typeof window !== 'undefined' ? AuthService.getCurrentUser() : null;
    const {id} = match.params;
    const userId = history?.location?.state?.userId;
    const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
    const [data, setData] = useState(
      {
          stepsCount: 0,
      }
    );
    console.log(userId);

    const {stepsCount} = data;

    const fetchData = async () => {
        if (id) {
                const res = await userService.stepDetail(id);
                console.log(res);
                if (res.code === 200) {
                    setData(res.data);

                }
                // setData({
                //     company: res.charity.company,
                //     category: res.charity.category,
                //     title: res.charity.title,
                //     date: res.charity.date,
                //     endDate: res.charity.endDate,
                //     stepsCount: res.charity.stepsCount,
                //     diff: res.charity.diff,
                //     companyName: res.charity.companyName
                // });
                // setDate(res.charity.date)
                // setEndDate(res.charity.endDate)
            }
        };

        useEffect(() => {
            fetchData();
        }, []);

        const createOrUpdate = async (e) => {
            if (stepsCount === 0 || stepsCount === '0') {
                alert('0 이상 입력해주세요.');
                return;
            }

            const json = {
                date,
                stepsCount: Number(stepsCount),
            }
            if (userId) {
                json.userId = Number(userId);
            }

            console.log(json);

            try {
                let res;
                if (id) {
                    res = await userService.stepUpdate(id, json);
                } else {
                    res = await userService.stepRegister(json);
                }
                console.log(res);
                if (res.code === 200) {
                    alert(`${id ? '수정' : '등록'}되었습니다.`);
                    history.goBack();
                }
            } catch (e) {
                console.log(JSON.stringify(e.response?.data?.resultMsg, null, 4));
            }
        }

        const onChange = (e) => {
            let {value, name} = e.target;
            if (
              name === 'stepsCount' ||
              name === 'goal'
            ) {
                let num = value || 0;
                if (!isFinite(num)) return;
                num = num.toString();
                if (num !== '0' && !num.includes('.')) {
                    num = num.replace(/^0+/, '');
                }
                setData({
                    ...data,
                    [name]: num,
                })
            } else {
                setData({
                    ...data,
                    [name]: value
                });
            }
        };

        const handleReactDatetimeChange = (who, date) => {
            if (
              date &&
              who === "endDate" &&
              new Date(date) > new Date(date.format('YYYY-MM-DD'))
            ) {
                setDate(date.format('YYYY-MM-DD'));
            } else if (
              who === "date"
            ) {
                setDate(date.format('YYYY-MM-DD'));
            } else {
                if (who === "date") {
                    setDate(date.format('YYYY-MM-DD'));
                } else {
                }
            }
        };

        const remove = () => {
            if (window.confirm('삭제하시겠습니까?')) {
                userService.stepRemove(id)
                  .then(res => res.data)
                  .then(res => {
                      alert('삭제되었습니다.')
                      history.goBack();
                  })
            }
        };

        return (
          <>
              <SimpleHeader name="Tables" parentName="Tables"/>
              <Container className="mt--6" fluid>
                  <Row>
                      <div className="col">
                          <Card>
                              <div className="col">
                                  <CardHeader>
                                      {id ?
                                        <Row>
                                            <h3 className="mt-2">걸음 관리 상세</h3>
                                            {user?.role === 'center' ? null : (
                                              <Col className="text-right">
                                                  <div className="text-right">
                                                      <Button
                                                        color="default"
                                                        type="button"
                                                        onClick={createOrUpdate}
                                                      >
                                                          수정하기
                                                      </Button>
                                                      <Button
                                                        color="danger"
                                                        type="button"
                                                        onClick={remove}
                                                      >
                                                          삭제하기
                                                      </Button>
                                                  </div>
                                              </Col>
                                            )}
                                        </Row>
                                        :
                                        <Row>
                                            <h3 className="mt-2">걸음 관리 등록</h3>
                                            <Col className="text-right">
                                                <Button color="default" type="button" onClick={createOrUpdate}>
                                                    <span className="btn-inner--icon mr-1">
                                                        <i className="fas fa-user-edit"/>
                                                    </span>
                                                    등록하기
                                                </Button>
                                            </Col>
                                        </Row>
                                      }
                                  </CardHeader>
                              </div>
                              <CardBody>

                                  <Row className="">
                                      <Col md="auto">
                                          <label
                                            className="form-control-label"
                                            htmlFor="example3cols1Input"
                                          >
                                              적립일
                                          </label>
                                      </Col>
                                  </Row>
                                  <Row>
                                      <Col md="5">
                                          <ReactDatetime
                                            inputProps={{
                                                placeholder: "",
                                            }}
                                            locale={'ko'}
                                            timeFormat={false}
                                            dateFormat={'YYYY-MM-DD'}
                                            value={date || ''}
                                            onChange={(e) =>
                                              handleReactDatetimeChange("date", e)
                                            }
                                          />
                                      </Col>
                                  </Row>
                                  <Row className="mt-4">
                                      <Col md="auto">
                                          <label
                                            className="form-control-label"
                                            htmlFor="example3cols1Input"
                                          >
                                              카운트
                                          </label>
                                      </Col>
                                  </Row>
                                  <Row>
                                      <Col md="5">
                                          <Input
                                            placeholder="걸음수"
                                            type="text"
                                            id="stepsCount"
                                            name="stepsCount"
                                            value={stepsCount || 0}
                                            onChange={onChange}
                                          />
                                      </Col>
                                  </Row>

                              </CardBody>
                          </Card>
                      </div>
                  </Row>
              </Container>
          </>
        )
    }


export default StepForm;
