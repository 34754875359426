import React, {useRef} from "react";
import {Button, Col, FormGroup, Label, Row,} from "reactstrap";
import {useRecoilState} from "recoil";
import {Fragment} from "@fullcalendar/core";
import {clickImg} from "../../../utils";

function InputFiles({ title, filesPreviewState, filesState, deletedId, filesCount }) {
  const filesRef = useRef();
  const [previewsURL, setPreviewsURL] = useRecoilState(filesPreviewState);
  const [files, setFiles] = useRecoilState(filesState);
  const [deleteFilesId, setDeleteFilesId] = useRecoilState(deletedId);
  // console.log(filesCount)

  const handleFilesOnChange = (e) => {
    e.preventDefault();
    let tempFiles = e.target.files;
    if (((tempFiles && tempFiles.length) + files.length) > filesCount) {
      alert(`파일은 최대 ${filesCount}개만 등록 가능합니다.`);
      return;
    }
    const filesArr = Array.prototype.slice.call(tempFiles);
    let tmpFiles = [];
    const tempPreviews = [];
    for (let file of filesArr) {
      tmpFiles.push(file);
      let reader = new FileReader();
      reader.onload = (e) => {
        tempPreviews.push(reader.result);
        if (filesArr.length === tempPreviews.length) {
          setPreviewsURL([...tempPreviews, ...previewsURL]);
        }
      };
      if (file) reader.readAsDataURL(file);
    }
    setFiles([...files, ...tmpFiles]);
  };

  const handleFilesButtonClick = (e) => {
    e.preventDefault();
    filesRef.current.click(); // file 불러오는 버튼을 대신 클릭함
  };

  const deleteFile = (eventParam, orderParam) => {
    const find = previewsURL.find((v, i) => i === orderParam);
    if (find.id) {
      setDeleteFilesId([
        ...deleteFilesId,
        previewsURL.find((v, i) => i === orderParam).id
      ]);
    }
    setFiles(files.filter((v, i) => i !== orderParam));
    setPreviewsURL(previewsURL.filter((v, i) => i !== orderParam));
  }

  return (
    <>
      <FormGroup>
        <Label
          className="form-control-label"
          htmlFor="files-input"
        >
          {title}
        </Label>
        <input
          ref={filesRef}
          hidden={true}
          id='files-input'
          type='file'
          multiple
          onChange={handleFilesOnChange}
        />
        <Button
          className="ml-3"
          type="button"
          onClick={handleFilesButtonClick}
          style={{ backgroundColor: '#2b7aaa', color: 'white', border: 'none' }}
          color="warning"
        >
          첨부하기
        </Button>
      </FormGroup>
      <Row>
        <Col>
          {previewsURL.length > 0 && (
            previewsURL.map((item, i) => {
              return [
                <Fragment key={i}>
                  <a
                      style={{
                        position: 'relative',
                        height: '150px',
                        marginRight: '15px',
                      }}
                  >
                    <img
                      style={{
                        height: '150px',
                        borderRadius: '5px',
                        marginRight: '20px',
                        marginBottom: '20px',
                        cursor: 'pointer',
                      }}
                      src={item.name ? item.name : item}
                      onClick={() => clickImg(item.name ? item.name : item)}
                    />
                    <div
                      style={{
                        position: 'absolute',
                        top: -85,
                        right: 7,
                      }}
                    >
                      <span
                        style={{
                          backgroundColor: 'rgba(0, 0, 0, 0.1)',
                          borderRadius: '100%',
                          padding: '1px 10px',
                          cursor: 'pointer',
                        }}
                        onClick={(e) => deleteFile(e, i)}
                      >
                        ×
                    </span>
                    </div>
                  </a>
                </Fragment>
              ];
            }))
          }
        </Col>
      </Row>
    </>
  );
}

export default InputFiles;
