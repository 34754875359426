import React, {useEffect} from "react";
// reactstrap components
import {Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Input, Row,} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import {numberWithCommas} from "../../../utils";
import InputFile from "./components/InputFile";
import {useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState} from "recoil";
import {
  dataSelector,
  dataState,
  filePreviewState,
  fileRegister,
  fileState,
  menuDetail,
  register, update
} from "./service/menu.service";

function ShopMenuForm({ match, history }) {
  const { id } = match.params;
  const [data, setData] = useRecoilState(dataState);
  const file = useRecoilValue(fileState);
  const resetData = useResetRecoilState(dataState);
  const resetFile = useResetRecoilState(fileState);
  const resetPreview = useResetRecoilState(filePreviewState);
  const setPreview = useSetRecoilState(filePreviewState);
  const detailData = history.location.state;
  let shopMenuId = detailData?.shopMenuId;

  const { sortCode, name, intro, price } = data;

  const fetchData = async () => {
    if (detailData) {
      const res = await menuDetail(detailData.shopMenuId);
      console.log(res);
      if (res.code === 200) {
        setData({
          sortCode: numberWithCommas(res.data.sortCode),
          name: res.data.name,
          intro: res.data.intro,
          price: numberWithCommas(res.data.price),
        });
        if (res.data.thumbnail) {
          setPreview(res.data.thumbnail);
        }
      }
    }
  };

  useEffect(() => {
    if (detailData) {
      setData({
        ...data,
        shopId: detailData.shopId
      });
      fetchData();
    } else {
      setData({
        ...data,
        shopId: id
      });
    }
    return () => {
      resetData();
      resetFile();
      resetPreview();
    }
  }, []);

  const onChange = (e) => {
    let { value, name } = e.target;
    if (name === 'sortCode' || name === 'price') {
      const onlyNumber = value.replace(/[^0-9]/g, '')
      setData({
        ...data,
        [name]: numberWithCommas(onlyNumber)
      });
    } else {
      setData({
        ...data,
        [name]: value
      });
    }
  };

  const submit = async () => {

    // console.log(jsonData);
    // sortCode, name, intro, price
    if (!sortCode || sortCode < 1) {
        alert('우선순위를 입력해주세요.');
        return;
      }

    if (name.length === 0) {
      alert('메뉴명을 입력해주세요.');
      return;
    }

    if (!price || price < 1) {
      alert('판매가를 입력해주세요.');
      return;
    }

    const json = {
      shopId: data.shopId,
      sortCode: Number(sortCode.replace(/[^0-9]/g, '')),
      name,
      price: Number(price.replace(/[^0-9]/g, '')),
    };

    if (intro) {
      json.intro = intro;
    }

    try {
      let res;
      if (detailData) {
        res = await update(shopMenuId, json);
      } else {
        res = await register(json);
      }
      console.log(res);
      if (res.code === 200) {
        if (!shopMenuId) {
          shopMenuId = res.data.shopMenuId;
        }
        if (file) {
          let form = new FormData();
          form.append('image', file);
          await fileRegister(shopMenuId, form);
        }
        alert(detailData ? '수정되었습니다.' : '등록되었습니다.');
        resetData();
        resetFile();
        resetPreview();
        history.goBack();
      } else {
        alert(res.resultMsg);
      }
    } catch (e) {
      alert(e.response?.data?.resultMsg);
    }

  }

  return (
    <>
      <SimpleHeader name="Form elements" parentName="Forms" />
      <Container className="mt--6" fluid>
        <Card className="mb-4">
          <CardHeader>
            <Row>
              <Col xs="11">
                <h3 className="mb-0">메뉴 추가</h3>
              </Col>
              {
                detailData ?
                  (<Col className="text-right" xs="12">
                    <Button
                      className="btn-round btn-icon"
                      color="primary"
                      onClick={submit}
                      size="sm"
                    >
                      <span className="btn-inner--text">저장하기</span>
                    </Button>
                    <Button
                      className="btn-round btn-icon"
                      color="danger"
                      // onClick={() => setDefaultModal(true)}
                      size="sm"
                    >
                      <span className="btn-inner--text">삭제하기</span>
                    </Button>
                  </Col>) :
                  <Button
                    className="btn-round btn-icon"
                    color="primary"
                    onClick={submit}
                    size="md"
                  >
                    <span className="btn-inner--text">등록하기</span>
                  </Button>
              }
            </Row>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    우선순위 <span className='text-red'>(필수)</span>
                    <p className="text-muted text-sm mb--2">메뉴의 순서를 정할 수 있습니다.</p>
                  </label>
                  <Input
                    placeholder="숫자만"
                    type="text"
                    name="sortCode"
                    value={sortCode || ''}
                    onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    메뉴명 <span className='text-red'>(필수)</span>
                  </label>
                  <Input
                    placeholder="메뉴명을 입력해주세요."
                    type="text"
                    name="name"
                    value={name || ''}
                    onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    한줄 설명
                  </label>
                  <Input
                    placeholder="23자까지 작성 가능합니다."
                    type="text"
                    name="intro"
                    value={intro || ''}
                    onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    판매 가 <span className='text-red'>(필수)</span>
                  </label>
                  <Input
                    placeholder="콤마 제외한 숫자만 입력"
                    type="text"
                    name="price"
                    value={price || ''}
                    onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <InputFile />
              </Col>
            </Row>

          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default ShopMenuForm;
