import axios from "axios";
import api from "../../../../config/api.routes";
import authHeader from "../../../../services/auth-header";
import {atom} from "recoil";

//file
export const charityFileState = atom({
  key: 'charityFileState',
  default: '',
});

export const charityFilePreviewState = atom({
  key: 'charityFilePreviewState',
  default: '',
});

export const charityDetailFileState = atom({
  key: 'charityDetailFileState',
  default: '',
});

export const charityDetailFilePreviewState = atom({
  key: 'charityDetailFilePreviewState',
  default: '',
});

const list = (data) => {
  let url = api().apiURL + `/center?`;
  for (let query of Object.keys(data)) {
    if (data[query] === undefined || data[query] === '') {
      continue;
    }
    url += `${query}=${data[query]}&`;
  }
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.get(url, { headers: authHeader() })
      .then(res => res.data)
      .then(res => resolve(res))
      .catch(e => reject(e));
  });
};

const register = (data) => {
  let url = api().apiURL + `/center/join?`;
  console.log('서비스',url,data);
  return new Promise((resolve, reject) => {
    axios.post(url, data, { headers: authHeader() })
        .then(res => res.data)
        .then(res => resolve(res))
        .catch(e => reject(e));
  });
};

const groupRegister = (data) => {
  let url = api().apiURL + `/serviceGroup?`;
  console.log('서비스',url,data);
  return new Promise((resolve, reject) => {
    axios.post(url, data, { headers: authHeader() })
        .then(res => res.data)
        .then(res => resolve(res))
        .catch(e => reject(e));
  });
};

const managerRegister = (data) => {
  let url = api().apiURL + `/manager/join?serviceGroupId=${data.serviceGroupId}&userId=${data.userId}&name=${data.name}`;
  console.log('서비스',url,data);
  return new Promise((resolve, reject) => {
    axios.get(url, { headers: authHeader() })
        .then(res => res.data)
        .then(res => resolve(res))
        .catch(e => reject(e));
  });
};

const detail = (id) => {
  let url = api().apiURL + `/center/${id}`;
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.get(url, { headers: authHeader() })
      .then(res => res.data)
      .then(res => resolve(res))
      .catch(e => reject(e));
  });
};

const userSearch = (id) => {
  let url = api().apiURL + `/user/undefine?search=${id}`;
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.get(url, { headers: authHeader() })
      .then(res => res.data)
      .then(res => resolve(res))
      .catch(e => reject(e));
  });
};

const groupDetail = (id) => {
  let url = api().apiURL + `/serviceGroup/${id}`;
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.get(url, { headers: authHeader() })
      .then(res => res.data)
      .then(res => resolve(res))
      .catch(e => reject(e));
  });
};

const update = (id, data) => {
  let url = api().apiURL + `/center/${id}`;
  console.log('수정',url);
  return new Promise((resolve, reject) => {
    axios.put(url, data, { headers: authHeader() })
        .then(res => res.data)
        .then(res => resolve(res))
        .catch(e => reject(e));
  });
};

const groupUpdate = (id, data) => {
  let url = api().apiURL + `/serviceGroup/${id}`;
  console.log('수정',url);
  return new Promise((resolve, reject) => {
    axios.put(url, data, { headers: authHeader() })
        .then(res => res.data)
        .then(res => resolve(res))
        .catch(e => reject(e));
  });
};

const remove = (id) => {
  let url = api().apiURL + `/serviceGroup/${id}`;
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.delete(url, { headers: authHeader() })
        .then(res => res.data)
        .then(res => resolve(res))
        .catch(e => reject(e));
  });
};

const removeManager = (id) => {
  let url = api().apiURL + `/manager/${id}`;
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.delete(url, { headers: authHeader() })
        .then(res => res.data)
        .then(res => resolve(res))
        .catch(e => reject(e));
  });
};

const companyCenterService = {
  list,
  register,
  groupRegister,
  managerRegister,
  detail,
  userSearch,
  groupDetail,
  update,
  groupUpdate,
  remove,
  removeManager,
}

export default companyCenterService;
