import React, { useEffect, useState } from 'react';
import pollutionHistoryService from "./service/pollutionHistory.service";
import useHistoryState from "../../../common/useHistoryState";
import {Col, Row} from "reactstrap";
import pollutionService from "./service/pollution.service";
import dayjs from "dayjs";
import {numberWithCommas, telWithCommas} from "../../../utils";
import {blackMarker, blueMarker, greenMarker, orangeMarker, redMarker, yellowMarker} from "./images";


const MapPop = ({ id }) => {
    const { kakao } = window;
    const [lat,setLat] = useState(33.451475);
    const [lon,setLon] = useState(126.570528);
    const [detailIds,setDetailIds] = useState();
    const [detail,setDetail] = useState({});
    const [data, setData] = useHistoryState([], 'data');

    const render = async () => {
        const res = await pollutionHistoryService.list({ pollutionId: id });
        if (res.code === 200) {
            console.log(res);
            setLat(res.list[0]?.latitude)
            setLon(res.list[0]?.longitude)
            setData(res.list.map((v,i) => {
                return {
                    ...v,
                    title: v.level,
                    latlng: new kakao.maps.LatLng(v.latitude, v.longitude)
                }
            }));
        }
    };

    useEffect(() => {
        render();
    }, []);

    const fetchData = async () => {
        const res = await pollutionHistoryService.detail(detailIds);
        if (res.code === 200) {
            console.log(res);
            setDetail(res.data)
        }
    };

    useEffect(() => {
        fetchData();
    }, [detailIds]);

    //처음 지도 그리기
    useEffect(()=>{
        const mapContainer = document.getElementById('map'),
            mapOption = {
                center: new kakao.maps.LatLng(lat, lon), // 지도의 중심좌표
                level: 2 // 지도의 확대 레벨
            };

        const map = new kakao.maps.Map(mapContainer, mapOption); // 지도를 생성합니다

        const imageSrc = "https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/markerStar.png";

        for (let i = 0; i < data?.length; i ++) {

            // 마커 이미지의 이미지 크기 입니다
            let imageSize = new kakao.maps.Size(44, 55);

            // 마커 이미지를 생성합니다
            let markerImage = new kakao.maps.MarkerImage(imageSrc, imageSize);

            // 마커를 생성합니다
            const marker = new kakao.maps.Marker({
                map: map, // 마커를 표시할 지도
                position: data[i]?.latlng, // 마커를 표시할 위치
                title : data[i]?.title, // 마커의 타이틀, 마커에 마우스를 올리면 타이틀이 표시됩니다
                image :
                    data[i]?.level === '무취' && new kakao.maps.MarkerImage(blackMarker, imageSize) ||
                    data[i]?.level === '감지 냄새' && new kakao.maps.MarkerImage(blueMarker, imageSize) ||
                    data[i]?.level === '보통 냄새' && new kakao.maps.MarkerImage(greenMarker, imageSize) ||
                    data[i]?.level === '강한 냄새' && new kakao.maps.MarkerImage(yellowMarker, imageSize) ||
                    data[i]?.level === '극심한 냄새' && new kakao.maps.MarkerImage(orangeMarker, imageSize) ||
                    data[i]?.level === '참기 어려운 냄새' && new kakao.maps.MarkerImage(redMarker, imageSize)
            });
            kakao.maps.event.addListener(marker, 'click', function () {
                setDetailIds(data[i].id)
            });
        }
    },[data])

    return (
        <>
            <div
                style={{
                    width: '100%',
                    display: 'inline-block',
                    marginLeft: '5px',
                    marginRight: '5px',
                }}
            >
                <div id="map" style={{ width: '99%', height: '700px' }}/>
            </div>
            <Row className="my-2">
                <Col>
                    <h3>{detail.address}</h3>
                </Col>
            </Row>
            <Row>
                <Col>
                    날씨
                </Col>
                <Col>
                    악취 추정 원인
                </Col>
                <Col>
                    악취
                </Col>
                <Col>
                    악취 지속시간
                </Col>
                <Col>
                    이름
                </Col>
                <Col>
                    휴대폰 번호
                </Col>
                <Col>
                    측정 날짜
                </Col>
            </Row>
            <Row>
                <Col>
                    <h3>{detail?.weather}, {detail?.wind}/s</h3>
                </Col>
                <Col>
                    <h3>{detail?.industry || detail?.life || detail?.etc}</h3>
                </Col>
                <Col>
                    <h3>{detail?.level}</h3>
                </Col>
                <Col>
                    <h3>{detail?.time}</h3>
                </Col>
                <Col>
                    <h3>{detail?.user?.name}</h3>
                </Col>
                <Col>
                    <h3>{telWithCommas(detail?.user?.tel)}</h3>
                </Col>
                <Col>
                    <h3>{dayjs(detail?.createdAt).format('YYYY-MM-DD')}</h3>
                </Col>
            </Row>
        </>
    );
};

export default MapPop;