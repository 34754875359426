import axios from "axios";
import authHeader from "./auth-header";

import api from '../config/api.routes';

const list = (data) => {
  let url = api().COUNSELING_LIST + `?`;
  for (let query of Object.keys(data)) {
    url += `${query}=${data[query]}&`;
  }
  console.log(url);
  return axios.get(url, { headers: authHeader() });
};

const detail = (id) => {
  return axios.get(api().COUNSELING_DETAIL + `?id=${id}`, { headers: authHeader() });
};

const update = (id, data) => {
  return axios.put(api().COUNSELING_UPDATE + `?id=${id}`, data, { headers: authHeader() });
};

const remove = (id) => {
  return axios.delete(api().COUNSELING_REMOVE + `?id=${id}`, { headers: authHeader() });
};

const counselingService = {
  list,
  detail,
  remove,
  update
}

export default counselingService;
