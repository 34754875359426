import {Button, Card, CardBody, CardHeader, Col, Container, Input, Row} from "reactstrap";
import SimpleHeader from "../../../components/Headers/SimpleHeader";
import donationService, {
    detailFilePreviewState,
    detailFileState,
    thumbnailFilePreviewState,
    thumbnailFileState
} from "./donation.service";
import {useRecoilState, useRecoilValue, useResetRecoilState} from "recoil";
import React, {useEffect, useState} from "react";
import InputFile from "../components/InputFile";
import ReactDatetime from "react-datetime";
import moment from "moment";
import {useHistory} from "react-router-dom";
import AuthService from "../auth/auth.service";

function DonationForm({ match }) {
    const user = typeof window !== 'undefined' ? AuthService.getCurrentUser() : null;
    const history = useHistory();
    const { id } = match.params;
    const thumbnailFile = useRecoilValue(thumbnailFileState);
    const [thumbnailPreviewURL, setThumbnailPreviewURL] = useRecoilState(thumbnailFilePreviewState);
    const thumbnailReset = useResetRecoilState(thumbnailFilePreviewState);

    const detailFile = useRecoilValue(detailFileState);
    const [detailPreviewURL, setDetailPreviewURL] = useRecoilState(detailFilePreviewState);
    const detailReset = useResetRecoilState(detailFilePreviewState);
    const [startDate, setStartDate] = useState(moment().format('YYYY.MM.DD'));
    const [endDate, setEndDate] = useState(moment().format('YYYY.MM.DD'));
    const [companyCode, setCompanyCode] = useState(undefined);
    const [code, setCode] = useState(undefined);
    const [goal, setGoal] = useState(0);
    const [link, setLink] = useState(undefined);
    const [data, setData] = useState(
        {
            sortCode: "",
            company: "",
            category: "아동-청소년",
            title: "",
            goalPrice: 0,
            diff: "걸음",
            companyName: undefined,
        }
    );

    const { sortCode, diff, company, goalPrice, title, category, companyName } = data;
    console.log(data)

    const fetchData = async () => {
        if (id) {
            const res = await donationService.detail(id);
            console.log(res);
            if (res.code === 200) {
                setData(res.charity);
                if (res.charity.thumbnail) {
                    setThumbnailPreviewURL('http://3.35.59.241/img/' + res.charity.thumbnail);
                }
                if (res.charity.image) {
                    setDetailPreviewURL('http://3.35.59.241/img/' + res.charity.image);
                }
            }
            setData({
                company: res.charity.company,
                category: res.charity.category,
                title: res.charity.title,
                startDate: res.charity.startDate,
                endDate: res.charity.endDate,
                goalPrice: res.charity.goalPrice,
                diff: res.charity.diff,
                companyName: res.charity.companyName,
                sortCode: res.charity.sortCode === 99999? 0 : res.charity.sortCode,
            });
            setCompanyCode(res.charity.companyCode)
            setCode(res.charity.code)
            setGoal(res.charity.goal)
            setLink(res.charity.link)
            setStartDate(res.charity.startDate)
            setEndDate(res.charity.endDate)
        }
    };

    useEffect( () => {
        thumbnailReset();
        detailReset();
        fetchData();
    }, []);

    // const myNum = Object.values(data);
    // const myNum2 = myNum.filter(function(item) {
    //     return item !== null && item !== undefined && item !== '';
    // });
    //
    // console.log(myNum2);

    const createOrUpdate = async (e) => {
        if (!company) {
            alert('기부 업체명을 입력해주세요.');
            return;
        }
        if (!title) {
            alert('제목을 입력해주세요.');
            return;
        }
        // if (!goalPrice) {
        //     alert('목표금액을 입력해주세요.');
        //     return;
        // }

        const json = {
            sortCode: Number(sortCode),
            company,
            category,
            title,
            diff,
            startDate,
            endDate,
            goal,
        }

        if(code !== undefined && code !== null) {
            json.code = code
        }
        if(link !== undefined && link !== null) {
            json.link = link
        }
        if(companyCode !== undefined && companyCode !== null) {
            json.companyCode = companyCode
        }
        if (companyName !== undefined && companyName !== null) {
            json.companyName = companyName
        }
        if (goalPrice !== undefined) {
            json.goalPrice = goalPrice
        }
        if(sortCode === 0 || !sortCode) {
            json.sortCode = 99999;
        }

        console.log(json);

        try {
            let res;
            if (id) {
                if (json.goalPrice) {
                    json.goalPrice = String(json.goalPrice);
                }
                res = await donationService.update(id, json);
            } else {
                res = await donationService.register(json);
            }
            console.log(res);
            if (res.code === 200) {
                const charityId = res.charityId || id;
                if (thumbnailFile) {
                    let form = new FormData();
                    console.log('file',thumbnailFile, typeof thumbnailFile);
                    form.append('img', thumbnailFile);
                    await donationService.fileRegister(charityId, form, 'thumbnail');
                }
                if (detailFile) {
                    let form = new FormData();
                    console.log('file',detailFile, typeof detailFile);
                    form.append('img', detailFile);
                    await donationService.fileRegister(charityId, form, 'image');
                }
                alert(`${id ? '수정' : '등록'}되었습니다.`);
                history.goBack();
            }
        } catch (e) {
            console.log(JSON.stringify(e.response?.data?.resultMsg, null, 4));
        }
    }

    const onChange = (e) => {
        let { value, name } = e.target;
        if (
            name === 'goalPrice' ||
            name === 'sortCode' ||
            name === 'goal'
        ) {
            setData({
                ...data,
                [name]: value.replace(/[^0-9]/g, ''),
            })
        } else {
            setData({
                ...data,
                [name]: value
            });
        }
    };

    const handleReactDatetimeChange = (who, date) => {
        if (
            startDate &&
            who === "endDate" &&
            new Date(startDate) > new Date(date.format('YYYY.MM.DD'))
        ) {
            setStartDate(date.format('YYYY.MM.DD'));
            setEndDate(date.format('YYYY.MM.DD'));
        } else if (
            endDate &&
            who === "startDate" &&
            new Date(endDate) < new Date(date.format('YYYY.MM.DD'))
        ) {
            setStartDate(date.format('YYYY.MM.DD'));
            setEndDate(date.format('YYYY.MM.DD'));
        } else {
            if (who === "startDate") {
                setStartDate(date.format('YYYY.MM.DD'));
            } else {
                setEndDate(date.format('YYYY.MM.DD'));
            }
        }
    };

    const remove = () => {
        if (window.confirm('삭제하시겠습니까?')) {
            donationService.remove(id)
                .then(res => res.data)
                .then(res => {
                    alert('삭제되었습니다.')
                    history.goBack();
                })
        };
    };

    return(
        <>
            <SimpleHeader name="Tables" parentName="Tables" />
            <Container className="mt--6" fluid>
                <Row>
                    <div className="col">
                        <Card>
                            <div className="col">
                                <CardHeader>
                                    {id?
                                        <Row>
                                            <h3 className="mt-2">기업부 기부 관리 상세</h3>
                                            {user?.role === 'center' ? null : (
                                              <Col className="text-right">
                                                  <div className="text-right">
                                                      <Button
                                                        color="default"
                                                        type="button"
                                                        onClick={createOrUpdate}
                                                      >
                                                          수정하기
                                                      </Button>
                                                      <Button
                                                        color="danger"
                                                        type="button"
                                                        onClick={remove}
                                                      >
                                                          삭제하기
                                                      </Button>
                                                  </div>
                                              </Col>
                                            )}
                                        </Row>
                                        :
                                        <Row>
                                            <h3 className="mt-2">기업부 기부 관리 등록</h3>
                                            <Col className="text-right">
                                                <Button color="default" type="button" onClick={createOrUpdate}>
                                                    <span className="btn-inner--icon mr-1">
                                                        <i className="fas fa-user-edit" />
                                                    </span>
                                                    등록하기
                                                </Button>
                                            </Col>
                                        </Row>
                                    }
                                </CardHeader>
                            </div>
                            <CardBody>
                                {user?.role === 'center' ? null : (
                                  <>
                                      <Row>
                                          <Col md="auto">
                                              <label
                                                  className="form-control-label"
                                                  htmlFor="example3cols1Input"
                                              >
                                                  우선순위
                                              </label>
                                          </Col>
                                      </Row>
                                      <Row className="mb-4">
                                          <Col md="5">
                                              <Input
                                                  placeholder="우선순위 입력"
                                                  type="text"
                                                  id="sortCode"
                                                  name="sortCode"
                                                  value={sortCode || ''}
                                                  onChange={onChange}
                                              />
                                          </Col>
                                      </Row>
                                      <Row className="">
                                          <Col md="auto">
                                              <label
                                                className="form-control-label"
                                                htmlFor="example3cols1Input"
                                              >
                                                  기업 코드 입력 ( , 콤마로  구분 )
                                              </label>
                                          </Col>
                                      </Row>
                                      <Row>
                                          <Col md="5">
                                              <Input
                                                placeholder="ex) comwelesg,kpbesg,kpbeg,..."
                                                type="textarea"
                                                rows="6"
                                                id="companyCode"
                                                name="companyCode"
                                                value={companyCode || ''}
                                                onChange={(e) => setCompanyCode(e.target.value)}
                                              />
                                          </Col>
                                      </Row>
                                      <Row className="mt-4">
                                          <Col md="auto">
                                              <label
                                                className="form-control-label"
                                                htmlFor="example3cols1Input"
                                              >
                                                  기업명 입력 ( , 콤마로  구분 )
                                              </label>
                                          </Col>
                                      </Row>
                                      <Row>
                                          <Col md="5">
                                              <Input
                                                placeholder="ex) 올바른코드,포아브..."
                                                type="textarea"
                                                rows="6"
                                                id="companyName"
                                                name="companyName"
                                                value={companyName || ''}
                                                onChange={onChange}
                                              />
                                          </Col>
                                      </Row>
                                  </>
                                )}
                                <Row className="mt-4">
                                    <Col md="auto">
                                        <label
                                            className="form-control-label"
                                            htmlFor="example3cols1Input"
                                        >
                                            기부 업체 명
                                        </label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="5">
                                        <Input
                                            placeholder="기부 업체 명 입력"
                                            type="text"
                                            id="company"
                                            name="company"
                                            value={company || ''}
                                            onChange={onChange}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-4">
                                    <Col md="auto">
                                        <label
                                            className="form-control-label"
                                            htmlFor="example3cols1Input"
                                        >
                                            카테고리
                                        </label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="5">
                                        <Input
                                            id="category"
                                            name="category"
                                            type="select"
                                            value={category || ''}
                                            onChange={onChange}
                                        >
                                            <option>아동-청소년</option>
                                            <option>어르신</option>
                                            <option>장애인</option>
                                            <option>다문화</option>
                                            <option>지구촌</option>
                                            <option>가족-여성</option>
                                            <option>시민사회</option>
                                            <option>동물</option>
                                            <option>환경</option>
                                            <option>기타</option>
                                        </Input>
                                    </Col>
                                </Row>
                                <Row className="mt-4">
                                    <Col md="auto">
                                        <label
                                            className="form-control-label"
                                            htmlFor="example3cols1Input"
                                        >
                                            제목
                                        </label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="5">
                                        <Input
                                            placeholder="제목 입력"
                                            type="text"
                                            id="title"
                                            name="title"
                                            value={title || ''}
                                            onChange={onChange}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-4">
                                    <Col md="auto">
                                        <label
                                            className="form-control-label"
                                            htmlFor="example3cols1Input"
                                        >
                                            기부 유형
                                        </label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="5">
                                        <Input
                                            id="diff"
                                            name="diff"
                                            type="select"
                                            value={diff || ''}
                                            onChange={onChange}
                                        >
                                            <option>걸음</option>
                                            <option>시간</option>
                                        </Input>
                                    </Col>
                                </Row>
                                <Row className="mt-4">
                                    <Col md="auto">
                                        <label
                                            className="form-control-label"
                                            htmlFor="example3cols1Input"
                                        >
                                            시작 날짜
                                        </label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="5">
                                        <ReactDatetime
                                            inputProps={{
                                                placeholder: "",
                                            }}
                                            locale={'ko'}
                                            timeFormat={false}
                                            dateFormat={'YYYY.MM.DD'}
                                            value={startDate || ''}
                                            onChange={(e) =>
                                                handleReactDatetimeChange("startDate", e)
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-4">
                                    <Col md="auto">
                                        <label
                                            className="form-control-label"
                                            htmlFor="example3cols1Input"
                                        >
                                            종료 날짜
                                        </label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="5">
                                        <ReactDatetime
                                            inputProps={{
                                                placeholder: "",
                                            }}
                                            locale={'ko'}
                                            timeFormat={false}
                                            dateFormat={'YYYY.MM.DD'}
                                            value={endDate || ''}
                                            onChange={(e) =>
                                                handleReactDatetimeChange("endDate", e)
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-4">
                                    <Col md="auto">
                                        <label
                                            className="form-control-label"
                                            htmlFor="example3cols1Input"
                                        >
                                            목표(시간 또는 걸음수)
                                        </label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="5">
                                        <Input
                                            placeholder="기부 유형 입력"
                                            type="text"
                                            id="goal"
                                            name="goal"
                                            value={goal}
                                            onChange={(e) => setGoal(e.target.value)}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-4">
                                    <Col md="auto">
                                        <label
                                            className="form-control-label"
                                            htmlFor="example3cols1Input"
                                        >
                                            목표 금액
                                        </label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="5">
                                        <Input
                                            placeholder="목표 금액 입력"
                                            type="text"
                                            id="goalPrice"
                                            name="goalPrice"
                                            value={goalPrice || 0}
                                            onChange={onChange}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-4">
                                    <Col md="auto">
                                        <label
                                            className="form-control-label"
                                            htmlFor="example3cols1Input"
                                        >
                                            유튜브 주소
                                        </label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="5">
                                        <Input
                                            placeholder="유튜브 주소 입력"
                                            type="text"
                                            id="code"
                                            name="code"
                                            value={code || ''}
                                            onChange={(e) => setCode(e.target.value)}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-4">
                                    <Col md="auto">
                                        <label
                                            className="form-control-label"
                                            htmlFor="example3cols1Input"
                                        >
                                            관련링크
                                        </label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="5">
                                        <Input
                                            placeholder="관련 링크 입력"
                                            type="text"
                                            id="link"
                                            name="link"
                                            value={link || ''}
                                            onChange={(e) => setLink(e.target.value)}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="mt-4">
                                        <InputFile
                                            title={'관련 이미지 첨부(720px x 755px)'}
                                            fileState={thumbnailFileState}
                                            filePreviewState={thumbnailFilePreviewState}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="mt-4">
                                        <InputFile
                                            title={'상세 내용 이미지 첨부(720px x 세로 자유)'}
                                            fileState={detailFileState}
                                            filePreviewState={detailFilePreviewState}
                                        />
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    )
}

export default DonationForm;
