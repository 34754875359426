/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import { Container, Row, Col } from "reactstrap";
import styled from "styled-components";
import {background} from "../../images";

const Background = styled.img`
    //position: absolute;
    width: 20%;
    //height: 100vh;
    z-index: 0;
`;

function AuthHeader({ title, lead }) {
  return (
    <>
      <div className="header bg-gradient-info py-lg-8 pt-lg-8">
        <Container>
          <div className="header-body text-center">
            <Row className="text-center mb-3 mt--4">
              <Col>
                <Background src={background} />
              </Col>
            </Row>
            <Row className="justify-content-center mb-2">
              <Col className="px-5" lg="6" md="8" xl="5">
                {title ? <p className="text-lead text-white">{title}</p> : null}
                {lead ? <p className="text-lead text-white">{lead}</p> : null}
              </Col>
            </Row>
          </div>
        </Container>
        <div className="separator separator-bottom separator-skew zindex-100">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon className="fill-default" points="2560 0 2560 100 0 100" />
          </svg>
        </div>
      </div>
    </>
  );
}

AuthHeader.propTypes = {
  title: PropTypes.string,
  lead: PropTypes.string,
};

export default AuthHeader;
