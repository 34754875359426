import React, {useRef, useState} from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import AuthHeader from "components/Headers/AuthHeader.js";
import AuthService from "./auth.service"
import NotificationAlert from "react-notification-alert";
import {background} from 'images';
import styled from 'styled-components';

const Background = styled.img`
    position: absolute;
    //width: 100%;
    //height: 100vh;
    z-index: 0;
`;


function Login({ history }) {
  const [focusedEmail, setfocusedEmail] = useState(false);
  const [focusedPassword, setfocusedPassword] = useState(false);
  const [id, setId] = useState('');
  const [pw, setPw] = useState('');
  const notificationAlertRef = useRef(null);

  const isCheck = typeof window !== 'undefined' ? AuthService.getCurrentUser() : null;
  if (isCheck) {
    history.push('/admin/dashboard');
    return null;
  }

  const notify = (type, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            로그인 오류!
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const handleInputId = (e) => {
    setId(e.target.value);
  };

  const handlePassword = (e) => {
    setPw(e.target.value);
  };

  const goToMain = (e) => {
    e.preventDefault();

    AuthService.login(id, pw)
      .then(
        (res) => {
          if (res.code === 200) {
            history.push("/admin/dashboard");
          } else {
            notify('danger', res.resultMsg);
          }
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          notify('danger', resMessage);
        }
      );
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      goToMain(e);
    }
  };

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <AuthHeader
        lead="사회적가치 확산을 위한 솔루션 파트너 포아브입니다."
      />
      <Container className="">
        <Row className="justify-content-center mt--8">
          <Col lg="5">
            <Card className="bg-secondary border-0 mb-0">
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-5">
                  <small>포아브 최고 관리자</small>
                </div>
                <Form role="form">
                  <FormGroup
                    className={classnames("mb-3", {
                      focused: focusedEmail,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="아이디"
                        type="email"
                        onFocus={() => setfocusedEmail(true)}
                        onBlur={() => setfocusedEmail(true)}
                        onChange={handleInputId}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup
                    className={classnames({
                      focused: focusedPassword,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="비밀번호"
                        type="password"
                        onFocus={() => setfocusedPassword(true)}
                        onBlur={() => setfocusedPassword(true)}
                        onChange={handlePassword}
                        onKeyUp={handleKeyPress}
                      />
                    </InputGroup>
                  </FormGroup>
                  <div className="custom-control custom-control-alternative custom-checkbox">
                    <input
                      className="custom-control-input"
                      id=" customCheckLogin"
                      type="checkbox"
                    />
                  </div>
                  <div className="text-center">
                    <Button
                      className="my-1"
                      color="info"
                      type="button"
                      onClick={goToMain}
                    >
                      로그인
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Login;
