import React, {Fragment, useEffect, useState} from "react";

// reactstrap components
import {
    Button,
    Card,
    CardFooter,
    CardHeader,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText, Modal,
    Row,
    Table,
    UncontrolledTooltip,
} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import CounselingService from "../../../services/counseling.service";
import PaginationWrapper from "../components/pagination";
import {numberWithCommas, paginate} from "../../../utils";
import useHistoryState from "../../../common/useHistoryState";
import classnames from "classnames";
import ReactDatetime from "react-datetime";
import moment from "moment";
import {Link} from "react-router-dom";
import companyCharityService from "./service/companyCharity.service";
import {useHistory} from "react-router";
import {shopCategory} from "../shop/service/shop.category";
import AuthService from "../auth/auth.service";
import companyService from "../company/service/company.service";
import companyCenterService from "../companyCenter/service/companyCenter.service";
import volunteerService from "../volunteer/service/volunteer.service";
import challengeService from "../carbon/service/challenge.service";
import QuestionService from "../../../services/questions.service";

function CompanyCharityList({ location }) {
  const history = useHistory();
  const user = typeof window !== 'undefined' ? AuthService.getCurrentUser() : null;
  const center = JSON.parse(localStorage.getItem('user'))?.companyId
  const [pageActive, setPageActive] = useHistoryState(1, 'pageActive');
  const [pageCount, setPageCount] = useHistoryState(1, 'pageCount');
  const [data, setData] = useHistoryState([], 'data');
  const [category, setCategory] = useHistoryState('전체', 'category');
  const [startDate, setStartDate] = useHistoryState(moment().add(-6, "month").format('YYYY-MM-DD'), 'startDate');
  const [endDate, setEndDate] = useHistoryState(moment().format('YYYY-MM-DD'), 'endDate');
  const [searchCurrent, setSearchCurrent] = useHistoryState(false, 'searchCurrent');
  const [search, setSearch] = useHistoryState('', 'search');
  const [companyList, setCompanyList] = useHistoryState([], 'companyList');
  const [companyCenterList, setCompanyCenterList] = useHistoryState([], 'companyCenterList');
  const [companyCenterId, setCompanyCenterId] = useHistoryState('전체', 'companyCenterId');
  const [centerList, setCenterList] = useHistoryState([], 'centerList');
  const [companyId, setCompanyId] = useHistoryState(129, 'companyId');
  const [loginId, setLoginId] = useState('');
  const [dataId, setDataId] = useState([]);
  const [defaultModal, setDefaultModal] = useHistoryState(false, 'defaultModal');

  const render = async () => {
      const option = {
          page: pageActive, startDate, endDate, search, companyId: Number(companyId), centerId: Number(companyCenterId)
      };
      if (user.role === 'center') {
          option.companyId = center;
          // option.centerId = Number(companyCenterId);
          option.centerId = undefined
      }
      if (companyCenterId === '전체') {
          option.centerId = undefined
      }
    const res = await companyCharityService.list(option);
    if (res.code === 200) {
      console.log(res);
      setPageCount(res.zones.length);
      setData(res.zones);
    }
  };

    const centerRender = async () => {
        const option = {
         companyId: Number(companyId)
        };
        const res = await companyCharityService.centerList(option);
        if (res.code === 200) {
            console.log(res);
            setCenterList(res.list);
        }
    };

  useEffect(() => {
    render();
    centerRender();
  }, [category, pageActive, startDate, endDate, search, companyId, center, companyCenterList, companyCenterId]);

    const companyRender = async () => {
        const res = await companyService.list({});
        if (res.code === 200) {
            console.log(res);
            setCompanyList(res.list);
        }
    };

    const companyCenterRender = async () => {
        const option = {
            companyId: Number(companyId)
        };
        if (user.role === 'center') {
            option.companyId = center;
        }
        const res = await companyCharityService.centerList(option);
        if (res.code === 200) {
            console.log(res);
            setCompanyCenterList(res.list);
        }
    };

    useEffect(() => {
        companyRender();
    }, [ ]);

    useEffect(() => {
        companyCenterRender();
    }, [ companyId, center ]);

    const sendRender = async () => {
        const res = await companyCharityService.sendRegister({ centerId: Number(companyCenterId), zoneIds: dataId.map((v) => String(v))});
        if (res.code === 200) {
            console.log(res);
            alert('봉사내역을 전송하였습니다.')
            location.reload();
        }
        dataId.map(async (v) => {
            await companyCharityService.zoneUpdate(v, {check: true});
        })
    };

    const handleReactDatetimeChange = (who, date) => {
        if (
            startDate &&
            who === "endDate" &&
            new Date(startDate) > new Date(date.format('YYYY-MM-DD'))
        ) {
            setStartDate(date.format('YYYY-MM-DD'));
            setEndDate(date.format('YYYY-MM-DD'));
        } else if (
            endDate &&
            who === "startDate" &&
            new Date(endDate) < new Date(date.format('YYYY-MM-DD'))
        ) {
            setStartDate(date.format('YYYY-MM-DD'));
            setEndDate(date.format('YYYY-MM-DD'));
        } else {
            if (who === "startDate") {
                setStartDate(date.format('YYYY-MM-DD'));
            } else {
                setEndDate(date.format('YYYY-MM-DD'));
            }
        }
    };

    const getClassNameReactDatetimeDays = (date) => {
        if (startDate && endDate) {
        }
        if (startDate && endDate && startDate !== endDate) {
            if (
                new Date(endDate) > new Date(date.format('YYYY-MM-DD')) &&
                new Date(startDate) < new Date(date.format('YYYY-MM-DD'))
            ) {
                return " middle-date";
            }
            if (endDate === date.format('YYYY-MM-DD')) {
                return " end-date";
            }
            if (startDate === date.format('YYYY-MM-DD')) {
                return " start-date";
            }
        }
        return "";
    };

    const create = async () => {
        const data = {
            companyId: Number(companyId),
            loginId
        }
        let res = await companyCharityService.companyCenterRegister(data);
        if(res.code === 203) {
            alert(res.resultMsg)
        } else {
            alert('등록되었습니다.')
        }
    }

    const isCheck = (value, type, setType) => {
        const index = type.indexOf(value); // value 값의 위치를 찾음.
        if (index > -1) { //  값이 있는 경우, -1은 값이없음.
            setType([
                ...type.filter(v => v !== value)
            ]);
        } else {
            setType([
                ...type,
                value
            ]);
        }
    };

    // const downExcel = (id) => {
    //     companyCharityService.companyEachExcel(id)
    //         .then((res) => {
    //             fileCreate(res);
    //         });
    // }

    const down = () => {
        const option = {
            page: pageActive, startDate, endDate, companyId: Number(companyId), diff: 'download'
        };
        if (user.role === 'center') {
            option.companyId = center;
        }
        companyCharityService.companyCenterExcel(option)
            .then((res) => {
                fileCreate(res);
            });
    }

    const fileCreate = (res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        const contentDisposition = res.headers['content-disposition']; // 파일 이름
        let fileName = 'report';
        if (contentDisposition) {
            const [ fileNameMatch ] = contentDisposition.split(';').filter(str => str.includes('filename'));
            if (fileNameMatch)
                [ , fileName ] = fileNameMatch.split('=');
        }
        link.href = url;
        link.setAttribute('download', `${fileName}.xlsx`);
        link.style.cssText = 'display:none';
        document.body.appendChild(link);
        link.click();
        link.remove();
    }

    return (
    <>
      <SimpleHeader name="Tables" parentName="Tables" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row className="py-3">
                    <Col xs="11">
                        <Row className="py-3">
                            <Col xs="2">
                                <label className=" form-control-label">
                                    날짜 조회
                                </label>
                                <FormGroup>
                                    <ReactDatetime
                                        inputProps={{
                                            placeholder: "",
                                        }}
                                        value={startDate}
                                        locale={'ko'}
                                        timeFormat={false}
                                        dateFormat={'YYYY-MM-DD'}
                                        onChange={(e) =>
                                            handleReactDatetimeChange("startDate", e)
                                        }
                                        renderDay={(props, currentDate, selectedDate) => {
                                            let classes = props.className;
                                            classes += getClassNameReactDatetimeDays(
                                                currentDate
                                            );
                                            return (
                                                <td {...props} className={classes}>
                                                    {currentDate.date()}
                                                </td>
                                            );
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs="2">
                                <FormGroup>
                                    <label className=" form-control-label py-2">
                                    </label>
                                    <ReactDatetime
                                        inputProps={{
                                            placeholder: "",
                                        }}
                                        value={endDate}
                                        locale={'ko'}
                                        timeFormat={false}
                                        dateFormat={'YYYY-MM-DD'}
                                        onChange={(e) =>
                                            handleReactDatetimeChange("endDate", e)
                                        }
                                        renderDay={(props, currentDate, selectedDate) => {
                                            let classes = props.className;
                                            classes += getClassNameReactDatetimeDays(
                                                currentDate
                                            );
                                            return (
                                                <td {...props} className={classes}>
                                                    {currentDate.date()}
                                                </td>
                                            );
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                            {user?.role !== 'center' &&
                                <>
                                    <Col xs="3">
                                        <label className=" form-control-label py-2">
                                        </label>
                                        <FormGroup>
                                            <InputGroup
                                                className={classnames("input-group-merge", {
                                                    focused: searchCurrent,
                                                })}
                                            >
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="fas fa-search" />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    placeholder="검색"
                                                    type="text"
                                                    value={search}
                                                    onChange={(e) => setSearch(e.target.value)}
                                                    onFocus={(e) => setSearchCurrent(true)}
                                                    onBlur={(e) => setSearchCurrent(false)}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                    <Col xs="auto">
                                        <FormGroup>
                                            <label className=" form-control-label py-2">
                                            </label>
                                            <Input
                                                id="diff"
                                                type="select"
                                                value={companyId}
                                                onChange={(e) => setCompanyId(e.target.value)}
                                                className=""
                                            >
                                                {companyList.map((v, i) => {
                                                    return <option value={v.id}>{v.name}</option>
                                                })}
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                </>
                            }
                        </Row>
                    </Col>
                </Row>
                <Row>
                  {/*<Col>*/}
                    {user.role === 'center'
                      ?
                        <>
                            <Col md="auto">
                                <h3 className="mt-2">봉사활동 실적 관리</h3>
                            </Col>
                            <Col md="2">
                                <Input
                                    id="diff"
                                    type="select"
                                    value={companyCenterId}
                                    onChange={(e) => setCompanyCenterId(e.target.value)}
                                    className=""
                                >
                                    <option>전체</option>
                                    {companyCenterList.map((v) => {
                                        return <option value={v.id}>{v.name}</option>
                                    })}
                                </Input>
                            </Col>
                            <Col md="auto">
                                <Button color="default" onClick={(e) => sendRender()}>
                                    보내기
                                </Button>
                            </Col>
                        </>
                      :
                        <>
                            <Col md="auto">
                                <h3 className="mt-2">기업 센터 봉사 관리</h3>
                            </Col>
                            <Col md="2">
                                <Input
                                    id="diff"
                                    type="select"
                                    value={companyCenterId}
                                    onChange={(e) => setCompanyCenterId(e.target.value)}
                                    className=""
                                >
                                    <option>선택해주세요.</option>
                                    {companyCenterList.map((v) => {
                                        return <option value={v.id}>{v.name}</option>
                                    })}
                                </Input>
                            </Col>
                            <Col md="auto">
                                <Button color="default" onClick={(e) => sendRender()}>
                                    보내기
                                </Button>
                            </Col>
                        </>
                    }
                  {/*</Col>*/}
                  {user?.role === 'center'
                      ?
                      <Col className="text-right">
                          <Button color="primary" onClick={(e) => down()}>
                              엑셀 다운로드
                          </Button>
                      </Col>
                      : (
                    <Col className="text-right">
                        <Button color="primary" onClick={(e) => down()}>
                            엑셀 다운로드
                        </Button>
                        <Button color="default" onClick={(e) => setDefaultModal(true)}>
                            봉사센터 전송 선택
                        </Button>
                    </Col>
                  )}
                </Row>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive striped>
                <thead className="thead-light">
                    <tr>
                      <th className="text-center">보내기</th>
                      <th className="text-center">봉사 날짜</th>
                      <th className="text-center">센터명</th>
                      <th className="text-center">담당 명칭</th>
                      <th className="text-center">봉사활동명</th>
                      <th className="text-center">구역 생성 주소</th>
                      <th className="text-center">총 생성 시간</th>
                      <th className="text-center">총 인원수</th>
                      <th className="text-center">관리</th>
                      <th className="text-center">방송센터 전송 유무</th>
                    </tr>
                </thead>
                <tbody>
                    {data?.map((item, i) => {
                      return [
                        <Fragment key={i}>
                          <tr>
                              <td className="text-center">
                                  <div className="custom-control custom-checkbox">
                                      <input
                                          className="custom-control-input"
                                          id={"table-check-all" + item.id}
                                          type="checkbox"
                                          onClick={() => {
                                              isCheck(item.id, dataId, setDataId)
                                          }}
                                          checked={dataId.indexOf(item.id) > -1}
                                          readOnly
                                      />
                                      <label
                                          className="custom-control-label"
                                          htmlFor={"table-check-all" + item.id}
                                      />
                                  </div>
                              </td>
                              <td className="text-center">
                                {moment(item.createdAt).format('YYYY-MM-DD')}
                            </td>
                            <td className="text-center">
                              {item.centerName}
                            </td>
                            <td className="text-center">
                              {item.groupName}
                            </td>
                            <td className="text-center">
                              {item.zoneName}
                            </td>
                            <td className="text-center">
                              {item.address? item.address:'-'}
                            </td>
                            <td className="text-center">
                              {item.totalServiceTime}
                            </td>
                              <td className="text-center">
                              {item.totalPersonnel} 명
                            </td>
                            <td className="text-center">
                              <Button
                                  className="btn-outline-danger"
                                  // onClick={() => history.push('/admin/charityDetail/' + item.id)}
                                  size="sm"
                              >
                                <span className="btn-inner--text">삭제</span>
                              </Button>
                                <Button
                                  className="btn-outline-default"
                                  onClick={() => history.push('/admin/volunteerDetail/' + item.id)}
                                  size="sm"
                              >
                                <span className="btn-inner--text">상세</span>
                              </Button>
                            </td>
                              <td className="text-center">
                                  {item.isSend === "false"? "X":'O'}
                              </td>
                          </tr>
                        </Fragment>
                      ];
                    })}
                </tbody>
              </Table>

              <CardFooter className="py-4">
                <PaginationWrapper
                  isActive={pageActive}
                  pageNumber={pageCount}
                  callback={(page) => setPageActive(page)}
                  itemPerPage={10}
                />
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
        <Modal
            className="modal-dialog-centered"
            isOpen={defaultModal}
            toggle={() => setDefaultModal(false)}
            fade={false}
        >
            <div className="modal-header">
                <h6 className="modal-title" id="modal-title-default">
                    봉사센터 전송 선택
                </h6>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => {
                        setDefaultModal(false)
                        // setManager('');
                        // setManagerName('');
                        // setUserId('');
                        // setUser({});
                    }}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>
            <div className="modal-body">
                <Row className="mt-3">
                    <Col>
                        <label
                            className="form-control-label mr-5"
                            htmlFor="example3cols1Input"
                        >
                            센터 아이디로 등록하기
                        </label>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Input
                            placeholder=""
                            type="text"
                            name="managerName"
                            value={loginId}
                            onChange={(e) => setLoginId(e.target.value)}
                        />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <label
                            className="form-control-label mr-5"
                            htmlFor="example3cols1Input"
                        >
                            등록된 센터 아이디
                        </label>
                    </Col>
                </Row>
                <Row>
                    {centerList.map((v, i ) => {
                        return [
                            <Col md="auto">
                                <h4>{v?.loginId}</h4>
                            </Col>
                            ]
                    })}
                </Row>
            </div>
            <div className="modal-footer">
                <Button
                    className="ml-auto btn-default"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => create()}
                >
                    등록하기
                </Button>
            </div>
        </Modal>
    </>
  );
}

export default CompanyCharityList;
