import React, {Fragment, useEffect, useState} from "react";

// reactstrap components
import {
    Button,
    Card,
    CardFooter,
    CardHeader,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText, Modal,
    Row,
    Table,
    UncontrolledTooltip,
} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import CounselingService from "../../../services/counseling.service";
import PaginationWrapper from "../components/pagination";
import {clickImg, numberWithCommas, paginate, telWithCommas} from "../../../utils";
import useHistoryState from "../../../common/useHistoryState";
import classnames from "classnames";
import ReactDatetime from "react-datetime";
import moment from "moment";
import {useHistory} from "react-router";
import AuthService from "../auth/auth.service";
import ecoFriendsHistoryService from "./service/ecoFriendsHistory.service";
import dayjs from "dayjs";
import pollutionService from "../pollution/service/pollution.service";
import xlsx from "xlsx";

function EcoFriendsHistoryList() {
    const user = typeof window !== 'undefined' ? AuthService.getCurrentUser() : null;
    const history = useHistory();
    const [pageActive, setPageActive] = useHistoryState(1, 'pageActive');
    const [pageCount, setPageCount] = useHistoryState(1, 'pageCount');
    const [data, setData] = useHistoryState([], 'data');
    const [startDate, setStartDate] = useHistoryState(moment().format('YYYY-MM-01'), 'startDate');
    const [endDate, setEndDate] = useHistoryState(moment().format('YYYY-MM-DD'), 'endDate');
    const [search, setSearch] = useHistoryState('', 'search');
    const [excel, setExcel] = React.useState([]);
    const render = async () => {
        const res = await ecoFriendsHistoryService.list({ page: pageActive, search, limit: 10 });
        if (res.code === 200) {
            console.log(res);
            setPageCount(res.list.count);
            setData(res.list.rows);
            setExcel(res.list.rows.map((v) => {
                return {
                    '고유번호': v.id,
                    '등록일': moment(v.createdAt).format('YYYY-MM-DD HH:mm:ss'),
                    '그룹명': v.user?.ecoName,
                    '넘버링': v.count,
                    '무게(kg)': v.kilogram,
                    '이름': v.user?.name,
                    '전화번호': v.user?.tel,
                }
            }))
        }
    };

    useEffect(() => {
        render();
    }, [pageActive, startDate, endDate, search]);

    const remove = async (id) => {
        if (window.confirm('삭제하시겠습니까?')) {
            let res = await ecoFriendsHistoryService.remove(id);
            console.log(res);
            if (res.code === 200) {
                history.go(0);
            }
        }
    };

    const handleInputChange = (event, index) => {
        const newData = [...data]; // Create a copy of the data array
        newData[index].kilogram = event.target.value; // Update the kilogram value at the specified index
        setData(newData); // Update the state with the new data array
    };

    const saveChange = async (kilogram, index) => {
        try {
            const res = await ecoFriendsHistoryService.update(index, {kilogram: Number(kilogram)});
            if (res.resultMsg === "success") {
                alert('수정되었습니다.');
                render();
            }
        } catch (e) {
            alert(JSON.stringify(e, null, 4));
            alert(e.response?.data?.message);
        }
    };

    const excelDownload = () => {
        const workSheet = xlsx.utils.json_to_sheet(excel);
        workSheet['!autofilter'] = { ref: 'A1:R11' };

        const workBook = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(workBook, workSheet, 'sheet title');
        xlsx.writeFile(workBook, 'report.xlsx');
    };

    return (
        <>
            <SimpleHeader name="Tables" parentName="Tables" />
            <Container className="mt--6" fluid>
                <Row>
                    <div className="col">
                        <Card>
                            <CardHeader className="border-0">
                                {/*<Row className="py-3">*/}
                                {/*    <Col xs="11">*/}
                                {/*        <Form>*/}
                                {/*            <Row>*/}
                                {/*                <Col xs="3">*/}
                                {/*                    <label className=" form-control-label py-2">*/}
                                {/*                    </label>*/}
                                {/*                    <FormGroup>*/}
                                {/*                        <InputGroup*/}
                                {/*                            className={classnames("input-group-merge", {*/}
                                {/*                                focused: searchCurrent,*/}
                                {/*                            })}*/}
                                {/*                        >*/}
                                {/*                            <InputGroupAddon addonType="prepend">*/}
                                {/*                                <InputGroupText>*/}
                                {/*                                    <i className="fas fa-search" />*/}
                                {/*                                </InputGroupText>*/}
                                {/*                            </InputGroupAddon>*/}
                                {/*                            <Input*/}
                                {/*                                placeholder="캠페인명으로 검색"*/}
                                {/*                                type="text"*/}
                                {/*                                value={search}*/}
                                {/*                                onChange={(e) => setSearch(e.target.value)}*/}
                                {/*                                onFocus={(e) => setSearchCurrent(true)}*/}
                                {/*                                onBlur={(e) => setSearchCurrent(false)}*/}
                                {/*                            />*/}
                                {/*                        </InputGroup>*/}
                                {/*                    </FormGroup>*/}
                                {/*                </Col>*/}
                                {/*            </Row>*/}
                                {/*        </Form>*/}
                                {/*    </Col>*/}
                                {/*</Row>*/}
                                <Row>
                                    <Col xs="6">
                                        <h3 className="mt-2">에코프렌즈 관리</h3>
                                    </Col>
                                    {user?.role === 'center' ? null : (
                                        <Col className="text-right">
                                            <Button
                                                color="success"
                                                onClick={() => excelDownload()}
                                            >
                                                엑셀 다운로드
                                            </Button>
                                        </Col>
                                    )}
                                </Row>
                            </CardHeader>

                            <Table className="align-items-center table-flush" responsive striped>
                                <thead className="thead-light">
                                <tr>
                                    <th className="text-center">No.</th>
                                    <th className="text-center">등록일</th>
                                    <th className="text-center">그룹명</th>
                                    <th className="text-center">사진</th>
                                    <th className="text-center">넘버링</th>
                                    <th className="text-center" style={{ width: '8%' }}>무게(kg)</th>
                                    <th className="text-left" style={{ width: '1%' }}/>
                                    <th className="text-center">이름</th>
                                    <th className="text-center">전화번호</th>
                                    <th className="text-center">관리</th>
                                </tr>
                                </thead>
                                <tbody>
                                {data?.map((item, i) => {
                                    return [
                                        <Fragment key={i}>
                                            <tr>
                                                <td className="text-center">
                                                    {item.id}
                                                </td>
                                                <td className="text-center">
                                                    {dayjs(item.createdAt).format('YYYY-MM-DD HH:mm')}
                                                </td>
                                                <td className="text-center">
                                                    {item.user?.ecoName}
                                                </td>
                                                <td className="text-center">
                                                    <img
                                                        src={`https://d2twzv77g3c69u.cloudfront.net/fit-in/1000x700/${item.image?.name}`}
                                                        style={{ maxWidth: '50px', cursor: 'pointer' }}
                                                        onClick={() => clickImg(`https://d2twzv77g3c69u.cloudfront.net/fit-in/1000x700/${item.image?.name}`)}
                                                    />
                                                </td>
                                                <td className="text-center">
                                                    {item.count}
                                                </td>
                                                <td className="text-center">
                                                    <Input
                                                        id="kilogram"
                                                        className="text-center"
                                                        placeholder="입력해주세요"
                                                        name="kilogram"
                                                        type="text"
                                                        bsSize="sm"
                                                        value={item.kilogram || ''}
                                                        onChange={(e) => handleInputChange(e, i)}
                                                    />
                                                </td>
                                                <td className="text-left">
                                                    <Button
                                                        size="sm"
                                                        color="default"
                                                        onClick={() => saveChange(item.kilogram, item.id)}
                                                    >
                                                        저장
                                                    </Button>
                                                </td>
                                                <td className="text-center">
                                                    {item.user.name}
                                                </td>
                                                <td className="text-center">
                                                    {telWithCommas(item.user?.tel)}
                                                </td>
                                                <td className="text-center">
                                                    <Button
                                                        className="btn-outline-warning"
                                                        onClick={() => remove(item.id)}
                                                        size="sm"
                                                    >
                                                        <span className="btn-inner--text">삭제</span>
                                                    </Button>
                                                </td>
                                            </tr>
                                        </Fragment>
                                    ];
                                })}
                                </tbody>
                            </Table>

                            <CardFooter className="py-4">
                                <PaginationWrapper
                                    isActive={pageActive}
                                    pageNumber={pageCount}
                                    callback={(page) => setPageActive(page)}
                                    itemPerPage={10}
                                />
                            </CardFooter>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
}

export default EcoFriendsHistoryList;
