import axios from "axios";
import api from "../../../../config/api.routes";
import authHeader from "../../../../services/auth-header";


const list = (data) => {
  let url = api().apiURL + `/admin/service?`;
  for (let query of Object.keys(data)) {
    if (data[query] === undefined) {
      continue;
    }
    url += `${query}=${data[query]}&`;
  }
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.get(url, { headers: authHeader() })
      .then(res => res.data)
      .then(res => resolve(res))
      .catch(e => reject(e));
  });
};

const detail = (id, data) => {
  let url = api().apiURL + `/admin/service/${id}/undefined?`;
  for (let query of Object.keys(data)) {
    if (data[query] === undefined || data[query] === '') {
      continue;
    }
    url += `${query}=${data[query]}&`;
  }
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.get(url, { headers: authHeader() })
      .then(res => res.data)
      .then(res => resolve(res))
      .catch(e => reject(e));
  });
};

const update = (id, data) => {
  let url = api().apiURL + `/admin/zone/${id}`;
  console.log('수정',url);
  return new Promise((resolve, reject) => {
    axios.put(url, data, { headers: authHeader() })
        .then(res => res.data)
        .then(res => resolve(res))
        .catch(e => reject(e));
  });
};

const updateService = (id, data) => {
  let url = api().apiURL + `/admin/service/history/${id}?confirm=${data}`;
  console.log('수정',url);
  return new Promise((resolve, reject) => {
    axios.put(url, data, { headers: authHeader() })
        .then(res => res.data)
        .then(res => resolve(res))
        .catch(e => reject(e));
  });
};

const removeList = (id) => {
  let url = api().apiURL + `/admin/service/${id}`;
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.delete(url, { headers: authHeader() })
        .then(res => res.data)
        .then(res => resolve(res))
        .catch(e => reject(e));
  });
};

const removeZone = (id) => {
  let url = api().apiURL + `/admin/zone/${id}`;
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.delete(url, { headers: authHeader() })
        .then(res => res.data)
        .then(res => resolve(res))
        .catch(e => reject(e));
  });
};

const remove = (id) => {
  let url = api().apiURL + `/admin/service/history/${id}`;
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.delete(url, { headers: authHeader() })
        .then(res => res.data)
        .then(res => resolve(res))
        .catch(e => reject(e));
  });
};

const excel = (data) => {
  let url = api().apiURL + `/admin/service?`;
  for (let query of Object.keys(data)) {
    if (data[query] === undefined || data[query] === '') {
      continue;
    }
    url += `${query}=${data[query]}&`;
  }
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.get(url, { headers: authHeader(), responseType: 'arraybuffer' })
        .then(res => resolve(res))
        .catch(e => reject(e));
  });
};

const eachExcel = (id) => {
  console.log(id)
  let url = api().apiURL + `/admin/service/${Number(id)}/download`;
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.get(url, { headers: authHeader(), responseType: 'arraybuffer' })
        .then(res => resolve(res))
        .catch(e => reject(e));
  });
};

const volunteerService = {
  list,
  detail,
  update,
  updateService,
  excel,
  removeList,
  removeZone,
  remove,
  eachExcel,
}

export default volunteerService;
