import React, {Fragment, useEffect} from "react";

// reactstrap components
import {
    Button,
    Card,
    CardFooter,
    CardHeader,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText, Modal,
    Row,
    Table,
} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import PaginationWrapper from "../components/pagination";
import {clickImg, paginate, telWithCommas} from "../../../utils";
import useHistoryState from "../../../common/useHistoryState";
import moment from "moment";
import {useHistory} from "react-router";
import ploggingService from "./service/plogging.service";
import xlsx from "xlsx";
import classnames from "classnames";
import ReactDatetime from "react-datetime";
import AuthService from "../auth/auth.service";
import shopService from "../shop/shop.service";

function SharedPloggingCalcMgmtPloggingList({match}) {
    const user = typeof window !== 'undefined' ? AuthService.getCurrentUser() : null;
    const centerPloggingId = JSON.parse(localStorage.getItem('user'))?.centerId;
    const centerPlogging = JSON.parse(localStorage.getItem('user'))?.centerPlogging;
    const companyPloggingId = JSON.parse(localStorage.getItem('user'))?.companyId;
    const companyPlogging = JSON.parse(localStorage.getItem('user'))?.companyPlogging;
    const {centerId} = match.params;
    const {sharingPloggingId} = match.params;
    const {centerName} = match.params;
    const [searchCurrent, setSearchCurrent] = useHistoryState(false, 'searchCurrent');
    const [search, setSearch] = useHistoryState('', 'search');
    const [pageActive, setPageActive] = useHistoryState(1, 'pageActive');
    const [pageCount, setPageCount] = useHistoryState(1, 'pageCount');
    const [data, setData] = useHistoryState([], 'data');
    const [excel, setExcel] = React.useState([]);
    const [dataImage, setDataImage] = useHistoryState([], 'dataImage');
    const [plogging, setPlogging] = useHistoryState([], 'plogging');
    const [address, setAddress] = useHistoryState([], 'address');
    const [diff, setDiff] = useHistoryState('', 'diff');
    const [defaultModal, setDefaultModal] = React.useState(false);
    const [startDate, setStartDate] = useHistoryState(moment().add(-3, 'month').format('YYYY-MM-DD'), 'startDate');
    const [endDate, setEndDate] = useHistoryState(moment().format('YYYY-MM-DD'), 'endDate');
    // console.log(centerPloggingId, companyPloggingId)
    // console.log(centerId, sharingPloggingId)
    // console.log(centerPlogging, companyPlogging)

    /* 정렬 필터 */
    const [sort, setSort] = useHistoryState('봉사일', 'sort');

    const render = () => {
        let option = {startDate, endDate};
        // if (centerId !== ':centerId') {
        //     option.centerId = Number(centerId);
        // } else {
        //     delete option.centerId
        // }
        if (sharingPloggingId !== ':sharingPloggingId') {
            option.sharingPloggingId = Number(sharingPloggingId);
        } else {
            delete option.sharingPloggingId
        }
        // if (centerPloggingId !== undefined) {
        //     option.centerId = Number(centerPloggingId);
        // }
        if (search) {
            option.search = search;
        }
        if (companyPlogging) {
            option.search = search;
            option.companyId = Number(companyPloggingId);
            delete option.centerId
        }
        if (sort) {
            option.sort = sort;
        }
        ploggingService.list(option)
            .then((res) => {
                if (res.code === 200) {
                    console.log(res);
                    setPageCount(res.list.length);
                    setData(paginate(res.list.map((v) => {
                        return {
                            ...v,
                            ploggingData: {
                                can: v.plogging.can,
                                cigarette: v.plogging.cigarette,
                                glass: v.plogging.glass,
                                landfill: v.plogging.landfill,
                                paper: v.plogging.paper,
                                pet: v.plogging.pet,
                                plastic: v.plogging.plastic,
                                styrofoam: v.plogging.styrofoam,
                                vinyl: v.plogging.vinyl,
                            }
                        }
                    }), pageActive, 10));
                    setExcel(res.list.map((v) => {
                        if (centerPlogging) {
                            return {
                                '등록일': moment(v.plogging?.createdAt).format('YYYY-MM-DD HH:mm'),
                                '이름': v.user?.name || '-',
                                '연락처': v.user?.tel || '-',
                                '시작시간': moment(v.plogging?.startTime).format('HH:mm:ss'),
                                '종료시간': moment(v.plogging?.endTime).format('HH:mm:ss'),
                                '봉사시간': v.plogging.time,
                                'GPS주소': v.plogging?.address || '-',
                                'VMS번호': v.user?.vmsNumber || '-',
                                '1365번호': v.user?.number1365 || '-',
                                '활동거리 탄소저감량': v.plogging?.co2 || '-',
                                '쓰레기 탄소저감량': v.plogging?.stepCo2 || '-',
                                '총 탄소저감량': v.plogging?.totalCo2 || '-',
                            }
                        } else {
                            return {
                                '등록일': moment(v.plogging?.createdAt).format('YYYY-MM-DD HH:mm'),
                                '이름': v.user?.name || '-',
                                '연락처': v.user?.tel || '-',
                                '시작시간': moment(v.plogging?.startTime).format('HH:mm:ss'),
                                '종료시간': moment(v.plogging?.endTime).format('HH:mm:ss'),
                                '봉사시간': v.plogging.time,
                                'GPS주소': v.plogging?.address || '-',
                                'VMS번호': v.user?.vmsNumber || '-',
                                '1365번호': v.user?.number1365 || '-',
                                '활동거리 탄소저감량': v.plogging?.co2 || '-',
                                '쓰레기 탄소저감량': v.plogging?.stepCo2 || '-',
                                '총 탄소저감량': v.plogging?.totalCo2 || '-',
                            }
                        }
                    }))
                }
            });
    };

    useEffect(() => {
        render();
    }, [pageActive, search, startDate, endDate]);

    const handleReactDatetimeChange = (who, date) => {
        if (
            startDate &&
            who === "endDate" &&
            new Date(startDate) > new Date(date.format('YYYY-MM-DD'))
        ) {
            setStartDate(date.format('YYYY-MM-DD'));
            setEndDate(date.format('YYYY-MM-DD'));
        } else if (
            endDate &&
            who === "startDate" &&
            new Date(endDate) < new Date(date.format('YYYY-MM-DD'))
        ) {
            setStartDate(date.format('YYYY-MM-DD'));
            setEndDate(date.format('YYYY-MM-DD'));
        } else {
            if (who === "startDate") {
                setStartDate(date.format('YYYY-MM-DD'));
            } else {
                setEndDate(date.format('YYYY-MM-DD'));
            }
        }
    };

    const getClassNameReactDatetimeDays = (date) => {
        if (startDate && endDate) {
        }
        if (startDate && endDate && startDate !== endDate) {
            if (
                new Date(endDate) > new Date(date.format('YYYY-MM-DD')) &&
                new Date(startDate) < new Date(date.format('YYYY-MM-DD'))
            ) {
                return " middle-date";
            }
            if (endDate === date.format('YYYY-MM-DD')) {
                return " end-date";
            }
            if (startDate === date.format('YYYY-MM-DD')) {
                return " start-date";
            }
        }
        return "";
    };

    const remove = (id) => {
        if (window.confirm('삭제하시겠습니까?')) {
            ploggingService.remove(Number(id))
                .then(res => res.data)
                .then(res => {
                    alert('삭제되었습니다.')
                    render();
                })
        }
    };

    const excelDownload = () => {
        const workSheet = xlsx.utils.json_to_sheet(excel);
        workSheet['!autofilter'] = {ref: 'A1:R12'};

        const workBook = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(workBook, workSheet, 'sheet title');
        xlsx.writeFile(workBook, 'report.xlsx');
    };

    const statusChange = async (id, check) => {
        const res = await ploggingService.adminUpdate(id, {check});
        if (res.code === 200) {
            render();
        }
    }


    return (
        <>

            <>
                <SimpleHeader name="Tables" parentName="Tables"/>
                <Container className="mt--6" fluid>
                    <Row>
                        <div className="col">
                            <Card>
                                <CardHeader className="border-0">
                                    <Row className="py-3">
                                        <Col xs="11">
                                            <Form>
                                                <Row>
                                                    <Col md="auto">
                                                        <ReactDatetime
                                                            inputProps={{
                                                                placeholder: "",
                                                            }}
                                                            value={startDate}
                                                            locale={'ko'}
                                                            timeFormat={false}
                                                            dateFormat={'YYYY-MM-DD'}
                                                            onChange={(e) =>
                                                                handleReactDatetimeChange("startDate", e)
                                                            }
                                                            renderDay={(props, currentDate, selectedDate) => {
                                                                let classes = props.className;
                                                                classes += getClassNameReactDatetimeDays(
                                                                    currentDate
                                                                );
                                                                return (
                                                                    <td {...props} className={classes}>
                                                                        {currentDate.date()}
                                                                    </td>
                                                                );
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col md="auto">
                                                        <ReactDatetime
                                                            inputProps={{
                                                                placeholder: "",
                                                            }}
                                                            value={endDate}
                                                            locale={'ko'}
                                                            timeFormat={false}
                                                            dateFormat={'YYYY-MM-DD'}
                                                            onChange={(e) =>
                                                                handleReactDatetimeChange("endDate", e)
                                                            }
                                                            renderDay={(props, currentDate, selectedDate) => {
                                                                let classes = props.className;
                                                                classes += getClassNameReactDatetimeDays(
                                                                    currentDate
                                                                );
                                                                return (
                                                                    <td {...props} className={classes}>
                                                                        {currentDate.date()}
                                                                    </td>
                                                                );
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col xs="3">
                                                        <FormGroup>
                                                            <InputGroup
                                                                className={classnames("input-group-merge", {
                                                                    focused: searchCurrent,
                                                                })}
                                                            >
                                                                <InputGroupAddon addonType="prepend">
                                                                    <InputGroupText>
                                                                        <i className="fas fa-search"/>
                                                                    </InputGroupText>
                                                                </InputGroupAddon>
                                                                <Input
                                                                    placeholder="이름으로 검색"
                                                                    type="text"
                                                                    value={search}
                                                                    onChange={(e) => setSearch(e.target.value)}
                                                                    onFocus={(e) => setSearchCurrent(true)}
                                                                    onBlur={(e) => setSearchCurrent(false)}
                                                                />
                                                            </InputGroup>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </Col>
                                    </Row>
                                    <Row style={{ marginBottom: 20 }}>
                                        <Button
                                            type="button"
                                            style={sort === '봉사일' ?
                                                { backgroundColor: '#3CC900', color: 'white', border: 'none' }
                                                :
                                                { backgroundColor: '#5c5c5c', color: 'white', border: 'none' }
                                            }
                                            onClick={() => {
                                                setSort('봉사일')
                                            }}
                                        >
                                            봉사일 기준 정렬
                                        </Button>
                                        <Button
                                            type="button"
                                            style={sort === '활동자' ?
                                                { backgroundColor: '#3CC900', color: 'white', border: 'none' }
                                                :
                                                { backgroundColor: '#5c5c5c', color: 'white', border: 'none' }
                                            }
                                            onClick={() => {
                                                setSort('활동자')
                                            }}
                                        >
                                            활동자 기준 정렬
                                        </Button>
                                    </Row>
                                    <Row>
                                        <Col xs="6">
                                            <h3 className="mt-2">{centerName}</h3>
                                        </Col>
                                        {/*{user?.role === 'center' ? null : (*/}
                                        <Col className="text-right">
                                            <Button
                                                color="success"
                                                onClick={() => excelDownload()}>
                                                엑셀 다운로드
                                            </Button>
                                        </Col>
                                        {/*)}*/}
                                    </Row>
                                </CardHeader>

                                <Table className="align-items-center table-flush" responsive striped>
                                    <thead className="thead-light">
                                    <tr>
                                        <th className="text-center">등록일</th>
                                        <th className="text-center">이름</th>
                                        <th className="text-center">연락처</th>
                                        <th className="text-center">활동시간</th>
                                        <th className="text-center">봉사시간</th>
                                        <th className="text-center">GPS주소</th>
                                        <th className="text-center">VMS번호</th>
                                        <th className="text-center">1365번호</th>
                                        <th className="text-center">탄소발자국(활동거리)</th>
                                        <th className="text-center">쓰레기
                                            제자원화(탄소발자국)
                                        </th>
                                        <th className="text-center">총 탄소발자국(활동거리
                                            포함)
                                        </th>
                                        <th className="text-center">활동 사진</th>
                                        <th className="text-center">체크</th>
                                        <th className="text-center">관리</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {data?.map((item, i) => {
                                        return [
                                            <Fragment key={i}>
                                                <tr>
                                                    <td className="text-center">
                                                        {moment(
                                                            item.plogging.createdAt).format(
                                                            'YYYY-MM-DD HH:mm')}
                                                    </td>
                                                    <td className="text-center">
                                                        {item.user?.name}
                                                    </td>
                                                    <td className="text-center">
                                                        {telWithCommas(
                                                            item.user?.tel)}
                                                    </td>
                                                    <td className="text-center">
                                                        {item.plogging?.startTime} ~ {item.plogging?.endTime}
                                                    </td>
                                                    <td className="text-center">
                                                        {item.plogging?.time}
                                                    </td>
                                                    <td className="text-center">
                                                        {item?.plogging?.address ?? '-'}
                                                    </td>
                                                    <td className="text-center">
                                                        {item.user?.vmsNumber
                                                            || '-'}
                                                    </td>
                                                    <td className="text-center">
                                                        {item.user?.number1365
                                                            || '-'}
                                                    </td>
                                                    <td className="text-center">
                                                        {item.plogging?.stepCo2?.toFixed(
                                                            2) || 0}kg
                                                    </td>
                                                    <td className="text-center">
                                                        {item.plogging?.co2?.toFixed(
                                                            2) || 0}kg
                                                        &nbsp;&nbsp;&nbsp;
                                                        <Button
                                                            className="px-3 btn-outline-warning"
                                                            size="sm"
                                                            onClick={() => {
                                                                setDefaultModal(
                                                                    true);
                                                                setDiff(
                                                                    '쓰레기 재자원화(탄소발자국)');
                                                                setPlogging(
                                                                    item.plogging)
                                                            }}
                                                        >
                                                            내역
                                                        </Button>
                                                    </td>
                                                    <td className="text-center">
                                                        {item.plogging?.totalCo2?.toFixed(
                                                            2) || 0}kg
                                                    </td>
                                                    <td className="text-center">
                                                        <Button
                                                            className="px-3 btn-outline-warning"
                                                            size="sm"
                                                            onClick={() => {
                                                                setDefaultModal(
                                                                    true);
                                                                setDiff('활동사진');
                                                                setDataImage(
                                                                    item.plogging.ploggingImages);
                                                            }}
                                                        >
                                                            내역
                                                        </Button>
                                                    </td>
                                                    <td className="text-center">
                                                        <label
                                                            className="custom-toggle custom-toggle-success center">
                                                            <input
                                                                checked={item.plogging.check}
                                                                type="checkbox"
                                                                onChange={(e) => statusChange(
                                                                    item.plogging.id,
                                                                    !item.plogging.check)}
                                                            />
                                                            <span
                                                                className="custom-toggle-slider rounded-circle"
                                                                data-label-off="No"
                                                                data-label-on="Yes"
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="text-center">
                                                        <Button
                                                            className="px-3 btn-danger"
                                                            size="sm"
                                                            onClick={() => remove(
                                                                item.id)}
                                                        >
                                                            삭제
                                                        </Button>
                                                    </td>
                                                </tr>
                                            </Fragment>
                                        ];
                                    })}
                                    </tbody>
                                </Table>

                                <CardFooter className="py-4">
                                    <PaginationWrapper
                                        isActive={pageActive}
                                        pageNumber={pageCount}
                                        callback={(page) => setPageActive(page)}
                                        itemPerPage={10}
                                    />
                                </CardFooter>
                            </Card>
                        </div>
                    </Row>
                </Container>
                <Modal
                    className="modal-dialog-centered"
                    isOpen={defaultModal}
                    size="m"
                    // toggle={() => setDefaultModal(false)} // 배경클릭시 창 닫힘
                    fade={false}
                >
                    <div className="modal-header">
                        <h6 className="modal-title mt-2">
                            {diff}
                        </h6>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => {
                                setDefaultModal(false)
                            }}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {diff === 'GPS주소'
                            ?
                            <>
                                {address?.map((v) => {
                                    return <>
                                        <Row>
                                            <Col md="8">
                                                <h4>{v.address}, {v.addressDetail}</h4>
                                            </Col>
                                            <Col>
                                                <h4>{v.time}</h4>
                                            </Col>
                                        </Row>
                                    </>
                                })}

                            </>
                            : diff === '활동사진'
                                ?
                                <>
                                    <Row>
                                        <Col>
                                            <h4>활동사진 첨부</h4>
                                        </Col>
                                    </Row>
                                    <Row>
                                        {dataImage?.map((v) => {
                                            if (v.diff === 'half') {
                                                return <Col md="6">
                                                    <a
                                                        onClick={() => clickImg(`https://d2twzv77g3c69u.cloudfront.net/fit-in/1000x700/${v.name}`)}
                                                        style={{
                                                            cursor: 'pointer'
                                                        }}
                                                    >
                                                        <img
                                                            src={`https://d2twzv77g3c69u.cloudfront.net/fit-in/1000x700/${v.name}`}
                                                            style={{
                                                                width: '100%',
                                                                height: '200px',
                                                                marginBottom: '25px',
                                                                borderRadius: '5px',
                                                                objectFit: 'cover',
                                                            }}
                                                        />
                                                    </a>
                                                </Col>
                                            }
                                        })}
                                    </Row>
                                    <Row>
                                        <Col>
                                            <h4>쓰레기 사진 첨부</h4>
                                        </Col>
                                    </Row>
                                    <Row>
                                        {dataImage?.map((v) => {
                                            if (v.diff === 'trash') {
                                                return <Col md="6">
                                                    <a
                                                        onClick={() => clickImg(`https://d2twzv77g3c69u.cloudfront.net/fit-in/1000x700/${v.name}`)}
                                                        style={{
                                                            cursor: 'pointer'
                                                        }}
                                                    >
                                                        <img
                                                            src={`https://d2twzv77g3c69u.cloudfront.net/fit-in/1000x700/${v.name}`}
                                                            style={{
                                                                width: '100%',
                                                                height: '200px',
                                                                marginBottom: '25px',
                                                                borderRadius: '5px',
                                                                objectFit: 'cover',
                                                            }}
                                                        />
                                                    </a>
                                                </Col>
                                            }
                                        })}
                                    </Row>
                                </>
                                :
                                <>
                                    <Row>
                                        <Col>
                                            <h4 className="text-blue">{plogging?.co2?.toFixed(2) || 0}kg</h4>
                                        </Col>
                                    </Row>
                                    <Table className="align-items-center table-flush" responsive striped>
                                        <thead className="thead-light">
                                        <tr>
                                            <th className="text-center">품목</th>
                                            <th className="text-center">기업명</th>
                                            <th className="text-center">이름</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td className="text-center">
                                                종이류
                                            </td>
                                            <td className="text-center">
                                                {plogging.paper || 0}
                                            </td>
                                            <td className="text-center">
                                                -
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-center">
                                                유리류
                                            </td>
                                            <td className="text-center">
                                                {plogging.glass || 0}
                                            </td>
                                            <td className="text-center">
                                                {plogging.glass ? (plogging.glass * 0.421)?.toFixed(2) : 0}KG
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-center">
                                                캔류
                                            </td>
                                            <td className="text-center">
                                                {plogging.can || 0}
                                            </td>
                                            <td className="text-center">
                                                {plogging.can ? (plogging.can * 0.3)?.toFixed(2) : 0}KG
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-center">
                                                매입/소각류
                                            </td>
                                            <td className="text-center">
                                                {plogging.landfill || 0}
                                            </td>
                                            <td className="text-center">
                                                -
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-center">
                                                스트로폼류
                                            </td>
                                            <td className="text-center">
                                                {plogging.styrofoam || 0}
                                            </td>
                                            <td className="text-center">
                                                -
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-center">
                                                페트류
                                            </td>
                                            <td className="text-center">
                                                {plogging.pet || 0}
                                            </td>
                                            <td className="text-center">
                                                {plogging.pet ? (plogging.pet * 0.011)?.toFixed(2) : 0}KG
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-center">
                                                비닐류
                                            </td>
                                            <td className="text-center">
                                                {plogging.vinyl || 0}
                                            </td>
                                            <td className="text-center">
                                                -
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-center">
                                                플라스틱류
                                            </td>
                                            <td className="text-center">
                                                {plogging.plastic || 0}
                                            </td>
                                            <td className="text-center">
                                                {plogging.plastic ? (plogging.plastic * 0.023)?.toFixed(2) : 0}KG
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-center">
                                                담배꽁초
                                            </td>
                                            <td className="text-center">
                                                {plogging.cigarette || 0}
                                            </td>
                                            <td className="text-center">
                                                -
                                            </td>
                                        </tr>
                                        </tbody>
                                    </Table>
                                </>
                        }
                    </div>
                </Modal>
            </>
        </>
    );
}

export default SharedPloggingCalcMgmtPloggingList;
