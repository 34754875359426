import React, {useEffect, useRef, useState, Fragment} from "react";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label, Media,
  Modal,
  Row, Table,
  UncontrolledTooltip,
} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import {menuList} from "./service/menu.service";
import {v4 as uuidv4} from "uuid";
import {Link} from "react-router-dom";

function ShopMenu({ match, history }) {
  const { id } = match.params;
  const [list, setList] = useState([]);

  const fetchData = async () => {
    if (id) {
      const res = await menuList({ shopId: id });
      console.log(res);
      if (res.code === 200) {
        setList(res.list);
      }
    }
  };

  useEffect( () => {
    fetchData();
  }, []);

  return (
    <>
      <SimpleHeader name="Form elements" parentName="Forms" />
      <Container className="mt--6 m" fluid>
        <Card className="mb-4">
          <CardHeader>
            <Row>
              <Col xs="11">
                <h3 className="mb-0">메뉴 관리</h3>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="8">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    메뉴 등록
                    <p className="text-muted text-sm">
                      대표 메뉴 위주로 올려 가독성을 높이는 게 좋습니다. 최대 10개까지 등록 가능합니다.
                    </p>
                  </label>
                  <Row className="mt--2">
                    <Col md="7">
                      <Button
                        block
                        color="primary"
                        size="md"
                        type="button"
                        onClick={() => history.push('/admin/shopMenuRegister/' + id)}
                      >
                        메뉴 추가
                      </Button>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>

          <Row className="ml-2">
            <Col md="8">
              <Table className="align-items-center table-flush" responsive striped>
                <thead className="thead-light">
                <tr>
                  <th className="text-center">우선순위</th>
                  <th className="text-center">메뉴명</th>
                  <th className="text-center">관리</th>
                </tr>
                </thead>
                <tbody>
                {list?.map((item, i) => {
                  return [
                    <Fragment key={i}>
                      <tr>
                        <td className="text-center">
                          {item.sortCode}
                        </td>
                        <td className="text-center">
                          {item.name}
                        </td>
                        <td className="text-center table-actions">
                          <Link
                            className="table-action"
                            to={{
                              pathname: '/admin/shopMenuDetail',
                              state: {
                                shopMenuId: item.id,
                                shopId: id
                              }
                            }}
                          >
                            <i id={"tooltip" + i} className="fas fa-user-edit" />
                          </Link>
                          <UncontrolledTooltip delay={0} target={"tooltip" + i}>
                            상세 보기
                          </UncontrolledTooltip>
                        </td>
                      </tr>
                    </Fragment>
                  ];
                })}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Card>
      </Container>
    </>
  );
}

export default ShopMenu;
