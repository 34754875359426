import React, {Fragment, useEffect, useState} from "react";

// reactstrap components
import {
    Button,
    Card,
    CardFooter,
    CardHeader,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText, Modal,
    Row,
    Table, UncontrolledTooltip,
} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import PaginationWrapper from "../components/pagination";
import {clickImg, paginate, telWithCommas} from "../../../utils";
import useHistoryState from "../../../common/useHistoryState";
import moment from "moment";
import {useHistory} from "react-router";
import ploggingService from "./service/plogging.service";
import centerService from "../center/service/center.service";
import {Link} from "react-router-dom";
import storeService from "../store/service/store.service";
import ReactDatetime from "react-datetime";
import classnames from "classnames";
import AuthService from "../auth/auth.service";

function PloggingCenter() {
    const user = typeof window !== 'undefined' ? AuthService.getCurrentUser() : null;
    const centerPlogging = JSON.parse(localStorage.getItem('user'))?.centerPlogging;
    const companyPlogging = JSON.parse(localStorage.getItem('user'))?.companyPlogging;
    const history = useHistory();
    const [finalDate, setFinalDate] = useState('');
    const [pageActive, setPageActive] = useHistoryState(1, 'pageActive');
    const [pageCount, setPageCount] = useHistoryState(1, 'pageCount');
    const [data, setData] = useHistoryState([], 'data');
    const [searchCurrent, setSearchCurrent] = useHistoryState(false, 'searchCurrent');
    const [search, setSearch] = useHistoryState('', 'search');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    console.log('123',user)

    const render = () => {
        let option = { diff: '플로깅', startDate, endDate };
        if (search) {
            option.search = search;
        }
        if (pageCount) {
            option.page = pageActive;
        }
        centerService.list(option)
            .then((res) => {
                if (res.code === 200) {
                    console.log(res);
                    setPageCount(res.centers.count);
                    setData(res?.centers?.rows?.map((v) => {
                        return {
                            endDate: moment(v.endDate).format('YYYY-MM-DD'),
                            name: v.name,
                            loginId: v.loginId,
                            isPlogging: v.isPlogging,
                            id: v.id,
                        }
                    }));
                }
            });
    };

    useEffect(() => {
        render();
    }, [ pageActive, pageCount, search, startDate, endDate ]);

    const remove = (id) => {
        if (window.confirm('삭제하시겠습니까?')) {
            ploggingService.remove(Number(id))
                .then(res => res.data)
                .then(res => {
                    alert('삭제되었습니다.')
                    render();
                })
        }
    };

    const handleReactDatetimeChange = (who, date) => {
        if (
            startDate &&
            who === "endDate" &&
            new Date(startDate) > new Date(date.format('YYYY-MM-DD'))
        ) {
            setStartDate(date.format('YYYY-MM-DD'));
            setEndDate(date.format('YYYY-MM-DD'));
        } else if (
            endDate &&
            who === "startDate" &&
            new Date(endDate) < new Date(date.format('YYYY-MM-DD'))
        ) {
            setStartDate(date.format('YYYY-MM-DD'));
            setEndDate(date.format('YYYY-MM-DD'));
        } else {
            if (who === "startDate") {
                setStartDate(date.format('YYYY-MM-DD'));
            } else {
                setEndDate(date.format('YYYY-MM-DD'));
            }
        }
    };

    const getClassNameReactDatetimeDays = (date) => {
        if (startDate && endDate) {
        }
        if (startDate && endDate && startDate !== endDate) {
            if (
                new Date(endDate) > new Date(date.format('YYYY-MM-DD')) &&
                new Date(startDate) < new Date(date.format('YYYY-MM-DD'))
            ) {
                return " middle-date";
            }
            if (endDate === date.format('YYYY-MM-DD')) {
                return " end-date";
            }
            if (startDate === date.format('YYYY-MM-DD')) {
                return " start-date";
            }
        }
        return "";
    };

    const statusChange = async (id, check) => {
        await centerService.update(id, { isPlogging: check });
        render();
    }

    const changeCenter = async (i, id) => {
        await centerService.update(id, { endDate: data[i].endDate });
        render();
        alert('수정되었습니다')
    }

    return (
        <>
            {user.role === 'center'
                ?
                // 센터관리자
                user.companyId && companyPlogging
                    ?
                    <>
                        <>
                            <SimpleHeader name="Tables" parentName="Tables" />
                            <Container className="mt--6" fluid>
                                <Row>
                                    <div className="col">
                                        <Card>
                                            <CardHeader className="border-0">
                                                <Row className="py-3">
                                                    <Col xs="11">
                                                        <Form>
                                                            <Row>
                                                                <Col md="auto">
                                                                    <ReactDatetime
                                                                        inputProps={{
                                                                            placeholder: "",
                                                                        }}
                                                                        value={startDate}
                                                                        locale={'ko'}
                                                                        timeFormat={false}
                                                                        dateFormat={'YYYY-MM-DD'}
                                                                        onChange={(e) =>
                                                                            handleReactDatetimeChange("startDate", e)
                                                                        }
                                                                        renderDay={(props, currentDate, selectedDate) => {
                                                                            let classes = props.className;
                                                                            classes += getClassNameReactDatetimeDays(
                                                                                currentDate
                                                                            );
                                                                            return (
                                                                                <td {...props} className={classes}>
                                                                                    {currentDate.date()}
                                                                                </td>
                                                                            );
                                                                        }}
                                                                    />
                                                                </Col>
                                                                <Col md="auto">
                                                                    <ReactDatetime
                                                                        inputProps={{
                                                                            placeholder: "",
                                                                        }}
                                                                        value={endDate}
                                                                        locale={'ko'}
                                                                        timeFormat={false}
                                                                        dateFormat={'YYYY-MM-DD'}
                                                                        onChange={(e) =>
                                                                            handleReactDatetimeChange("endDate", e)
                                                                        }
                                                                        renderDay={(props, currentDate, selectedDate) => {
                                                                            let classes = props.className;
                                                                            classes += getClassNameReactDatetimeDays(
                                                                                currentDate
                                                                            );
                                                                            return (
                                                                                <td {...props} className={classes}>
                                                                                    {currentDate.date()}
                                                                                </td>
                                                                            );
                                                                        }}
                                                                    />
                                                                </Col>
                                                                <Col md="auto">
                                                                    <Button
                                                                        className="btn-default"
                                                                        onClick={() => {
                                                                            setStartDate('')
                                                                            setEndDate('')
                                                                            setSearch("")
                                                                        }}
                                                                    >
                                                                        <span className="btn-inner-text">초기화</span>
                                                                    </Button>
                                                                </Col>
                                                                <Col xs="3">
                                                                    <FormGroup>
                                                                        <InputGroup
                                                                            className={classnames("input-group-merge", {
                                                                                focused: searchCurrent,
                                                                            })}
                                                                        >
                                                                            <InputGroupAddon addonType="prepend">
                                                                                <InputGroupText>
                                                                                    <i className="fas fa-search" />
                                                                                </InputGroupText>
                                                                            </InputGroupAddon>
                                                                            <Input
                                                                                placeholder="봉사센터명으로 검색"
                                                                                type="text"
                                                                                value={search}
                                                                                onChange={(e) => setSearch(e.target.value)}
                                                                                onFocus={(e) => setSearchCurrent(true)}
                                                                                onBlur={(e) => setSearchCurrent(false)}
                                                                            />
                                                                        </InputGroup>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        </Form>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs="6">
                                                        <h3 className="mt-2">인증 플로깅 센터 리스트</h3>
                                                    </Col>
                                                    {/*{user?.role === 'center' ? null : (*/}
                                                    {/*  <Col className="text-right">*/}
                                                    {/*      <Button color="default" onClick={(e) => history.push('/admin/donationForm')}>*/}
                                                    {/*          등록하기*/}
                                                    {/*      </Button>*/}
                                                    {/*  </Col>*/}
                                                    {/*)}*/}
                                                </Row>
                                            </CardHeader>

                                            <Table className="align-items-center table-flush" responsive striped>
                                                <thead className="thead-light">
                                                <tr>
                                                    <th className="text-center" style={{ width: '20%' }}>센터명</th>
                                                    <th className="text-center" style={{ width: '20%' }}>아이디</th>
                                                    <th className="text-center" style={{ width: '20%' }}>인증플로깅 권한</th>
                                                    <th className="text-center" style={{ width: '10%' }}>이용기간(종료일)</th>
                                                    <th className="text-center" style={{ width: '5%' }}/>
                                                    <th className="text-center">활동이력</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {data?.map((item, i) => {
                                                    return [
                                                        <Fragment key={i}>
                                                            <tr>
                                                                <td className="text-center">
                                                                    {item.name}
                                                                </td>
                                                                <td className="text-center">
                                                                    {item.loginId}
                                                                </td>
                                                                <td className="text-center">
                                                                    <label className="custom-toggle custom-toggle-success center">
                                                                        <input
                                                                            checked={item.isPlogging}
                                                                            type="checkbox"
                                                                            onChange={(e) => statusChange(item.id, !item.isPlogging)}
                                                                        />
                                                                        <span
                                                                            className="custom-toggle-slider rounded-circle"
                                                                            data-label-off="No"
                                                                            data-label-on="Yes"
                                                                        />
                                                                    </label>
                                                                </td>
                                                                <td className="text-center">
                                                                    <Input
                                                                        type="text"
                                                                        value={item.endDate}
                                                                        onChange={(e) => {
                                                                            const copiedItem = data;
                                                                            copiedItem[i].endDate = e.target.value;
                                                                            setData([...data])
                                                                        }}
                                                                    />
                                                                </td>
                                                                <td className="text-left">
                                                                    <Button
                                                                        color="default"
                                                                        onClick={(e) => changeCenter(i, item.id)}
                                                                    >
                                                                        저장
                                                                    </Button>
                                                                </td>
                                                                <td className="text-center">
                                                                    <Button
                                                                        className="btn-outline-warning"
                                                                        onClick={() => history.push('/admin/ploggingList/' + item.id + '/:companyId')}
                                                                        size="sm"
                                                                    >
                                                                        <span className="btn-inner--text">상세</span>
                                                                    </Button>
                                                                </td>
                                                            </tr>
                                                        </Fragment>
                                                    ];
                                                })}
                                                </tbody>
                                            </Table>

                                            <CardFooter className="py-4">
                                                <PaginationWrapper
                                                    isActive={pageActive}
                                                    pageNumber={pageCount}
                                                    callback={(page) => setPageActive(page)}
                                                    itemPerPage={10}
                                                />
                                            </CardFooter>
                                        </Card>
                                    </div>
                                </Row>
                            </Container>
                        </>
                    </>
                    :
                    centerPlogging
                        ?
                        <>
                            <SimpleHeader name="Tables" parentName="Tables" />
                            <Container className="mt--6" fluid>
                                <Row>
                                    <div className="col">
                                        <Card>
                                            <CardHeader className="border-0">
                                                <Row className="py-3">
                                                    <Col xs="11">
                                                        <Form>
                                                            <Row>
                                                                <Col md="auto">
                                                                    <ReactDatetime
                                                                        inputProps={{
                                                                            placeholder: "",
                                                                        }}
                                                                        value={startDate}
                                                                        locale={'ko'}
                                                                        timeFormat={false}
                                                                        dateFormat={'YYYY-MM-DD'}
                                                                        onChange={(e) =>
                                                                            handleReactDatetimeChange("startDate", e)
                                                                        }
                                                                        renderDay={(props, currentDate, selectedDate) => {
                                                                            let classes = props.className;
                                                                            classes += getClassNameReactDatetimeDays(
                                                                                currentDate
                                                                            );
                                                                            return (
                                                                                <td {...props} className={classes}>
                                                                                    {currentDate.date()}
                                                                                </td>
                                                                            );
                                                                        }}
                                                                    />
                                                                </Col>
                                                                <Col md="auto">
                                                                    <ReactDatetime
                                                                        inputProps={{
                                                                            placeholder: "",
                                                                        }}
                                                                        value={endDate}
                                                                        locale={'ko'}
                                                                        timeFormat={false}
                                                                        dateFormat={'YYYY-MM-DD'}
                                                                        onChange={(e) =>
                                                                            handleReactDatetimeChange("endDate", e)
                                                                        }
                                                                        renderDay={(props, currentDate, selectedDate) => {
                                                                            let classes = props.className;
                                                                            classes += getClassNameReactDatetimeDays(
                                                                                currentDate
                                                                            );
                                                                            return (
                                                                                <td {...props} className={classes}>
                                                                                    {currentDate.date()}
                                                                                </td>
                                                                            );
                                                                        }}
                                                                    />
                                                                </Col>
                                                                <Col md="auto">
                                                                    <Button
                                                                        className="btn-default"
                                                                        onClick={() => {
                                                                            setStartDate('')
                                                                            setEndDate('')
                                                                            setSearch("")
                                                                        }}
                                                                    >
                                                                        <span className="btn-inner-text">초기화</span>
                                                                    </Button>
                                                                </Col>
                                                                <Col xs="3">
                                                                    <FormGroup>
                                                                        <InputGroup
                                                                            className={classnames("input-group-merge", {
                                                                                focused: searchCurrent,
                                                                            })}
                                                                        >
                                                                            <InputGroupAddon addonType="prepend">
                                                                                <InputGroupText>
                                                                                    <i className="fas fa-search" />
                                                                                </InputGroupText>
                                                                            </InputGroupAddon>
                                                                            <Input
                                                                                placeholder="봉사센터명으로 검색"
                                                                                type="text"
                                                                                value={search}
                                                                                onChange={(e) => setSearch(e.target.value)}
                                                                                onFocus={(e) => setSearchCurrent(true)}
                                                                                onBlur={(e) => setSearchCurrent(false)}
                                                                            />
                                                                        </InputGroup>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        </Form>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs="6">
                                                        <h3 className="mt-2">인증 플로깅 센터 리스트</h3>
                                                    </Col>
                                                    {/*{user?.role === 'center' ? null : (*/}
                                                    {/*  <Col className="text-right">*/}
                                                    {/*      <Button color="default" onClick={(e) => history.push('/admin/donationForm')}>*/}
                                                    {/*          등록하기*/}
                                                    {/*      </Button>*/}
                                                    {/*  </Col>*/}
                                                    {/*)}*/}
                                                </Row>
                                            </CardHeader>

                                            <Table className="align-items-center table-flush" responsive striped>
                                                <thead className="thead-light">
                                                <tr>
                                                    <th className="text-center" style={{ width: '20%' }}>센터명</th>
                                                    <th className="text-center" style={{ width: '20%' }}>아이디</th>
                                                    <th className="text-center" style={{ width: '20%' }}>인증플로깅 권한</th>
                                                    <th className="text-center" style={{ width: '10%' }}>이용기간(종료일)</th>
                                                    <th className="text-center" style={{ width: '5%' }}/>
                                                    <th className="text-center">활동이력</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {data?.map((item, i) => {
                                                    return [
                                                        <Fragment key={i}>
                                                            <tr>
                                                                <td className="text-center">
                                                                    {item.name}
                                                                </td>
                                                                <td className="text-center">
                                                                    {item.loginId}
                                                                </td>
                                                                <td className="text-center">
                                                                    <label className="custom-toggle custom-toggle-success center">
                                                                        <input
                                                                            checked={item.isPlogging}
                                                                            type="checkbox"
                                                                            onChange={(e) => statusChange(item.id, !item.isPlogging)}
                                                                        />
                                                                        <span
                                                                            className="custom-toggle-slider rounded-circle"
                                                                            data-label-off="No"
                                                                            data-label-on="Yes"
                                                                        />
                                                                    </label>
                                                                </td>
                                                                <td className="text-center">
                                                                    <Input
                                                                        type="text"
                                                                        value={item.endDate}
                                                                        onChange={(e) => {
                                                                            const copiedItem = data;
                                                                            copiedItem[i].endDate = e.target.value;
                                                                            setData([...data])
                                                                        }}
                                                                    />
                                                                </td>
                                                                <td className="text-left">
                                                                    <Button
                                                                        color="default"
                                                                        onClick={(e) => changeCenter(i, item.id)}
                                                                    >
                                                                        저장
                                                                    </Button>
                                                                </td>
                                                                <td className="text-center">
                                                                    <Button
                                                                        className="btn-outline-warning"
                                                                        onClick={() => history.push('/admin/ploggingList/' + item.id + '/:companyId')}
                                                                        size="sm"
                                                                    >
                                                                        <span className="btn-inner--text">상세</span>
                                                                    </Button>
                                                                </td>
                                                            </tr>
                                                        </Fragment>
                                                    ];
                                                })}
                                                </tbody>
                                            </Table>

                                            <CardFooter className="py-4">
                                                <PaginationWrapper
                                                    isActive={pageActive}
                                                    pageNumber={pageCount}
                                                    callback={(page) => setPageActive(page)}
                                                    itemPerPage={10}
                                                />
                                            </CardFooter>
                                        </Card>
                                    </div>
                                </Row>
                            </Container>
                        </>
                        :
                        <>
                            인증 플로깅 비활성화 상태입니다.
                        </>
                :
                <>
                    <SimpleHeader name="Tables" parentName="Tables" />
                    <Container className="mt--6" fluid>
                        <Row>
                            <div className="col">
                                <Card>
                                    <CardHeader className="border-0">
                                        <Row className="py-3">
                                            <Col xs="11">
                                                <Form>
                                                    <Row>
                                                        <Col md="auto">
                                                            <ReactDatetime
                                                                inputProps={{
                                                                    placeholder: "",
                                                                }}
                                                                value={startDate}
                                                                locale={'ko'}
                                                                timeFormat={false}
                                                                dateFormat={'YYYY-MM-DD'}
                                                                onChange={(e) =>
                                                                    handleReactDatetimeChange("startDate", e)
                                                                }
                                                                renderDay={(props, currentDate, selectedDate) => {
                                                                    let classes = props.className;
                                                                    classes += getClassNameReactDatetimeDays(
                                                                        currentDate
                                                                    );
                                                                    return (
                                                                        <td {...props} className={classes}>
                                                                            {currentDate.date()}
                                                                        </td>
                                                                    );
                                                                }}
                                                            />
                                                        </Col>
                                                        <Col md="auto">
                                                            <ReactDatetime
                                                                inputProps={{
                                                                    placeholder: "",
                                                                }}
                                                                value={endDate}
                                                                locale={'ko'}
                                                                timeFormat={false}
                                                                dateFormat={'YYYY-MM-DD'}
                                                                onChange={(e) =>
                                                                    handleReactDatetimeChange("endDate", e)
                                                                }
                                                                renderDay={(props, currentDate, selectedDate) => {
                                                                    let classes = props.className;
                                                                    classes += getClassNameReactDatetimeDays(
                                                                        currentDate
                                                                    );
                                                                    return (
                                                                        <td {...props} className={classes}>
                                                                            {currentDate.date()}
                                                                        </td>
                                                                    );
                                                                }}
                                                            />
                                                        </Col>
                                                        <Col md="auto">
                                                            <Button
                                                                className="btn-default"
                                                                onClick={() => {
                                                                    setStartDate('')
                                                                    setEndDate('')
                                                                    setSearch("")
                                                                }}
                                                            >
                                                                <span className="btn-inner-text">초기화</span>
                                                            </Button>
                                                        </Col>
                                                        <Col xs="3">
                                                            <FormGroup>
                                                                <InputGroup
                                                                    className={classnames("input-group-merge", {
                                                                        focused: searchCurrent,
                                                                    })}
                                                                >
                                                                    <InputGroupAddon addonType="prepend">
                                                                        <InputGroupText>
                                                                            <i className="fas fa-search" />
                                                                        </InputGroupText>
                                                                    </InputGroupAddon>
                                                                    <Input
                                                                        placeholder="봉사센터명으로 검색"
                                                                        type="text"
                                                                        value={search}
                                                                        onChange={(e) => setSearch(e.target.value)}
                                                                        onFocus={(e) => setSearchCurrent(true)}
                                                                        onBlur={(e) => setSearchCurrent(false)}
                                                                    />
                                                                </InputGroup>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="6">
                                                <h3 className="mt-2">인증 플로깅 센터 리스트</h3>
                                            </Col>
                                            {/*{user?.role === 'center' ? null : (*/}
                                            {/*  <Col className="text-right">*/}
                                            {/*      <Button color="default" onClick={(e) => history.push('/admin/donationForm')}>*/}
                                            {/*          등록하기*/}
                                            {/*      </Button>*/}
                                            {/*  </Col>*/}
                                            {/*)}*/}
                                        </Row>
                                    </CardHeader>

                                    <Table className="align-items-center table-flush" responsive striped>
                                        <thead className="thead-light">
                                        <tr>
                                            <th className="text-center" style={{ width: '20%' }}>센터명</th>
                                            <th className="text-center" style={{ width: '20%' }}>아이디</th>
                                            <th className="text-center" style={{ width: '20%' }}>인증플로깅 권한</th>
                                            <th className="text-center" style={{ width: '10%' }}>이용기간(종료일)</th>
                                            <th className="text-center" style={{ width: '5%' }}/>
                                            <th className="text-center">활동이력</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {data?.map((item, i) => {
                                            return [
                                                <Fragment key={i}>
                                                    <tr>
                                                        <td className="text-center">
                                                            {item.name}
                                                        </td>
                                                        <td className="text-center">
                                                            {item.loginId}
                                                        </td>
                                                        <td className="text-center">
                                                            <label className="custom-toggle custom-toggle-success center">
                                                                <input
                                                                    checked={item.isPlogging}
                                                                    type="checkbox"
                                                                    onChange={(e) => statusChange(item.id, !item.isPlogging)}
                                                                />
                                                                <span
                                                                    className="custom-toggle-slider rounded-circle"
                                                                    data-label-off="No"
                                                                    data-label-on="Yes"
                                                                />
                                                            </label>
                                                        </td>
                                                        <td className="text-center">
                                                            <Input
                                                                type="text"
                                                                value={item.endDate}
                                                                onChange={(e) => {
                                                                    const copiedItem = data;
                                                                    copiedItem[i].endDate = e.target.value;
                                                                    setData([...data])
                                                                }}
                                                            />
                                                        </td>
                                                        <td className="text-left">
                                                            <Button
                                                                color="default"
                                                                onClick={(e) => changeCenter(i, item.id)}
                                                            >
                                                                저장
                                                            </Button>
                                                        </td>
                                                        <td className="text-center">
                                                            <Button
                                                                className="btn-outline-warning"
                                                                onClick={() => history.push('/admin/ploggingList/' + item.id + '/:companyId')}
                                                                size="sm"
                                                            >
                                                                <span className="btn-inner--text">상세</span>
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                </Fragment>
                                            ];
                                        })}
                                        </tbody>
                                    </Table>

                                    <CardFooter className="py-4">
                                        <PaginationWrapper
                                            isActive={pageActive}
                                            pageNumber={pageCount}
                                            callback={(page) => setPageActive(page)}
                                            itemPerPage={10}
                                        />
                                    </CardFooter>
                                </Card>
                            </div>
                        </Row>
                    </Container>
                </>
            }

        </>
    );
}

export default PloggingCenter;
