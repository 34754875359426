import React, {Fragment, useEffect} from "react";

// reactstrap components
import {
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import CounselingService from "../../../services/counseling.service";
import PaginationWrapper from "../components/pagination";
import {paginate} from "../../../utils";
import useHistoryState from "../../../common/useHistoryState";
import classnames from "classnames";
import {Link} from "react-router-dom";
import regionAdvertService from "./service/regionAdvert.service";
import {shopCategory} from "../shop/service/shop.category";

function RegionAdvertList() {
  const [pageActive, setPageActive] = useHistoryState(1, 'pageActive');
  const [pageCount, setPageCount] = useHistoryState(1, 'pageCount');
  const [data, setData] = useHistoryState([], 'data');
  const [category, setCategory] = useHistoryState('전체', 'category');
  const [diff, setDiff] = useHistoryState('전체', 'diff');
  const [searchCurrent, setSearchCurrent] = useHistoryState(false, 'searchCurrent');
  const [search, setSearch] = useHistoryState('', 'search');

  const render = async () => {
    const option = {
      category, search
    };
    if (diff !== '전체') {
      option.diff = diff;
    }
    const res = await regionAdvertService.list(option);
    if (res.code === 200) {
      console.log(res);
      setPageCount(res.list.length);
      setData(paginate(res.list, pageActive, 10));
    }
  }

  useEffect(() => {
    render();
  }, [category, pageActive, search, diff]);

  // const statusChange = async (id, status) => {
  //   await regionAdvertService.update(id, {status});
  //   render();
  // }

  return (
    <>
      <SimpleHeader name="Tables" parentName="Tables" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row className="py-3">
                  <Col xs="11">
                    <Form>
                      <Row>
                        <Col xs="3">
                          <label className=" form-control-label py-2">
                          </label>
                          <FormGroup>
                            <InputGroup
                              className={classnames("input-group-merge", {
                                focused: searchCurrent,
                              })}
                            >
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="fas fa-search" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="아이디, 업체명, 주소로 검색"
                                type="text"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                onFocus={(e) => setSearchCurrent(true)}
                                onBlur={(e) => setSearchCurrent(false)}
                              />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col xs="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="exampleFormControlSelect13"
                            >
                              카테고리
                            </label>
                            <Input
                              id="exampleFormControlSelect13"
                              type="select"
                              value={category}
                              onChange={(e) => setCategory(e.target.value)}
                            >
                              <option>전체</option>
                              {shopCategory.map((c, i) => {
                                return [
                                  <option key={i}>{c}</option>
                                ]
                              })}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col xs="2">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="diff"
                            >
                              구분
                            </label>
                            <Input
                              id="diff"
                              type="select"
                              value={diff}
                              onChange={(e) => setDiff(e.target.value)}
                            >
                              <option>전체</option>
                              <option>상점</option>
                              <option>게시글</option>
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">지역 광고 관리</h3>
                  </Col>
                </Row>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive striped>
                <thead className="thead-light">
                <tr>
                  <th className="text-center">광고 등록 날짜</th>
                  <th className="text-center">아이디</th>
                  <th className="text-center">카테고리</th>
                  <th className="text-center">업체명</th>
                  <th className="text-center">구분</th>
                  <th className="text-center">주소</th>
                  <th />
                </tr>
                </thead>
                <tbody>
                  {data?.map((item, i) => {
                  return [
                    <Fragment key={i}>
                      <tr>
                        <td className="text-center">
                          {item.createdAt}
                        </td>
                        <td className="text-center">
                          {item.sns ? item.sns.loginId : item.shop.loginId}
                        </td>
                        <td className="text-center">
                          {item.shop ? item.shop.category : '-'}
                        </td>
                        <td className="text-center">
                          {item.shop ? item.shop.name : '-'}
                        </td>
                        <td className="text-center">
                          {item.shop ? '상점AD' : '게시글AD'}
                        </td>
                        <td className="text-center">
                          {item.address}
                        </td>
                        <td className="table-actions">
                          <Link className="table-action" to={'/admin/regionAdvertDetail/' + item.id}>
                            <i id={"tooltip" + i} className="fas fa-user-edit"  />
                          </Link>
                          <UncontrolledTooltip delay={0} target={"tooltip" + i}>
                            상세 보기
                          </UncontrolledTooltip>
                        </td>
                      </tr>
                    </Fragment>
                  ];
                })}
                </tbody>
              </Table>

              <CardFooter className="py-4">
                <PaginationWrapper
                  isActive={pageActive}
                  pageNumber={pageCount}
                  callback={(page) => setPageActive(page)}
                  itemPerPage={10}
                />
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default RegionAdvertList;
