import React, {Fragment, useEffect, useState} from "react";

// reactstrap components
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import CounselingService from "../../../services/counseling.service";
import PaginationWrapper from "../components/pagination";
import {numberWithCommas, paginate} from "../../../utils";
import useHistoryState from "../../../common/useHistoryState";
import classnames from "classnames";
import ReactDatetime from "react-datetime";
import moment from "moment";
import {Link} from "react-router-dom";
import noticeService from "./service/notice.service";
import {useHistory} from "react-router";
import {shopCategory} from "../shop/service/shop.category";
import challengeService from "../carbon/service/challenge.service";

function NoticeList() {
  const history = useHistory();
  const [pageActive, setPageActive] = useHistoryState(1, 'pageActive');
  const [pageCount, setPageCount] = useHistoryState(1, 'pageCount');
  const [data, setData] = useHistoryState([], 'data');
  const [category, setCategory] = useHistoryState('전체', 'category');
  const [startDate, setStartDate] = useHistoryState(moment().format('YYYY-MM-01'), 'startDate');
  const [endDate, setEndDate] = useHistoryState(moment().format('YYYY-MM-DD'), 'endDate');
  const [searchCurrent, setSearchCurrent] = useHistoryState(false, 'searchCurrent');
  const [search, setSearch] = useHistoryState('', 'search');
  const [english, setEnglish] = useState('한글');

  const render = async () => {
    let option = { };
    if (english === '영문') {
      option.english = true;
    }
    if (search) {
      option.search = search;
    }
    if (pageCount) {
      option.page = pageActive;
    }
    if (startDate) {
      option.startDate = startDate;
    }
    if (endDate) {
      option.endDate = endDate;
    }
    const res = await noticeService.list(option);
    if (res.code === 200) {
      console.log(res);
      setPageCount(res.notices.count);
      setData(res.notices.rows);
    }
  };

  useEffect(() => {
    render();
  }, [category, pageActive, startDate, endDate, search, english]);

  const remove = async (id) => {
    if (window.confirm('삭제하시겠습니까?')) {
      let res = await noticeService.remove(id);
      console.log(res);
      if (res.code === 200) {
        alert('삭제되었습니다.')
        render();
      }
    }
  };

  return (
    <>
      <SimpleHeader name="Tables" parentName="Tables" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col xs="6">
                    <h3 className="mt-2">
                      공지사항
                    </h3>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>
                    <Button
                        type="button"
                        style={english === '한글' ?
                            { backgroundColor: '#3CC900', color: 'white', border: 'none' }
                            :
                            { backgroundColor: '#5c5c5c', color: 'white', border: 'none' }
                        }
                        onClick={() => {
                          setEnglish('한글')
                        }}
                    >
                      한글
                    </Button>
                    <Button
                        type="button"
                        style={english === '영문' ?
                            { backgroundColor: '#3CC900', color: 'white', border: 'none' }
                            :
                            { backgroundColor: '#5c5c5c', color: 'white', border: 'none' }
                        }
                        onClick={() => {
                          setEnglish('영문')
                        }}
                    >
                      영문
                    </Button>
                  </Col>
                  <Col className="text-right">
                    <Button
                        color="default"
                        onClick={(e) => history.push('/admin/noticeForm')}
                    >
                      등록하기
                    </Button>
                  </Col>
                </Row>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive striped>
                <thead className="thead-light">
                <tr>
                  <th className="text-center">랭킹</th>
                  <th className="text-center">제목</th>
                  <th className="text-center">게시날짜</th>
                  <th className="text-center">삭제</th>
                </tr>
                </thead>
                <tbody>
                {data?.map((item, i) => {
                  return [
                    <Fragment key={i}>
                      <tr>
                        <td className="text-center">
                          {item.id}
                        </td>
                        <td className="text-center">
                          {item.title.length > 30 ? item.title.slice(0, 30) + '...' : item.title}
                        </td>
                        <td className="text-center">
                          {item.createdAt}
                        </td>
                        <td className="text-center">
                          <Button
                              className="btn-outline-default"
                              onClick={() => history.push('/admin/noticeDetail/' + item.id)}
                              size="sm"
                          >
                            <span className="btn-inner--text">상세</span>
                          </Button>
                          <Button
                              className="btn-outline-danger"
                              onClick={() => remove(item.id)}
                              size="sm"
                          >
                            <span className="btn-inner--text">삭제</span>
                          </Button>
                        </td>
                      </tr>
                    </Fragment>
                  ];
                })}
                </tbody>
              </Table>

              <CardFooter className="py-4">
                <PaginationWrapper
                  isActive={pageActive}
                  pageNumber={pageCount}
                  callback={(page) => setPageActive(page)}
                  itemPerPage={10}
                />
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default NoticeList;
