import React, {Fragment, useEffect, useState} from "react";

// reactstrap components
import {
    Button,
    Card,
    CardFooter,
    CardHeader,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText, Modal,
    Row,
    Table,
    UncontrolledTooltip,
} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import CounselingService from "../../../services/counseling.service";
import PaginationWrapper from "../components/pagination";
import {numberWithCommas, paginate, telWithCommas} from "../../../utils";
import useHistoryState from "../../../common/useHistoryState";
import classnames from "classnames";
import ReactDatetime from "react-datetime";
import moment from "moment";
import {useHistory} from "react-router";
import AuthService from "../auth/auth.service";
import pollutionService from "./service/pollution.service";
import pollutionHistoryService from "./service/pollutionHistory.service";
import dayjs from "dayjs";
import carbonTabService from "../carbonTab/service/carbonTab.service";
import xlsx from "xlsx";

function PollutionHistoryList({ match }) {
    const { id, title } = match.params
    const user = typeof window !== 'undefined' ? AuthService.getCurrentUser() : null;
    const history = useHistory();
    const [pageActive, setPageActive] = useHistoryState(1, 'pageActive');
    const [pageCount, setPageCount] = useHistoryState(1, 'pageCount');
    const [data, setData] = useHistoryState([], 'data');
    const [startDate, setStartDate] = useHistoryState(moment().add(-3, "month").format('YYYY-MM-01'), 'startDate');
    const [endDate, setEndDate] = useHistoryState(moment().format('YYYY-MM-DD'), 'endDate');
    const [searchCurrent, setSearchCurrent] = useHistoryState(false, 'searchCurrent');
    const [search, setSearch] = useHistoryState('', 'search');
    const [excel, setExcel] = React.useState([]);

    const render = async () => {
        const res = await pollutionHistoryService.list({ page: pageActive, search, limit: 10, pollutionId: id, startDate, endDate });
        if (res.code === 200) {
            console.log(res);
            setPageCount(res.list.length);
            setData(res.list);
            setExcel(res.list.map((v) => {
                return {
                    'NO': v.id || '-',
                    '이름': v.user?.name || '-',
                    '휴대폰번호': v.user?.tel || '-',
                    '활동GPS': v.address,
                    '날씨': v.weather,
                    '풍량/s': v.wind,
                    '산업악취': v.industry,
                    '생활악취': v.life,
                    '기타': v.etc,
                    '측정내역': v.level,
                    '측정일자': dayjs(v?.createdAt).format('YYYY-MM-DD HH:mm'),
                    '악취지속시간': v.time,
                }
            }))
        }
    };

    useEffect(() => {
        render();
    }, [pageActive, startDate, endDate, search]);

    const handleReactDatetimeChange = (who, date) => {
        if (
            startDate &&
            who === "endDate" &&
            new Date(startDate) > new Date(date.format('YYYY-MM-DD'))
        ) {
            setStartDate(date.format('YYYY-MM-DD'));
            setEndDate(date.format('YYYY-MM-DD'));
        } else if (
            endDate &&
            who === "startDate" &&
            new Date(endDate) < new Date(date.format('YYYY-MM-DD'))
        ) {
            setStartDate(date.format('YYYY-MM-DD'));
            setEndDate(date.format('YYYY-MM-DD'));
        } else {
            if (who === "startDate") {
                setStartDate(date.format('YYYY-MM-DD'));
            } else {
                setEndDate(date.format('YYYY-MM-DD'));
            }
        }
    };

    const getClassNameReactDatetimeDays = (date) => {
        if (startDate && endDate) {
        }
        if (startDate && endDate && startDate !== endDate) {
            if (
                new Date(endDate) > new Date(date.format('YYYY-MM-DD')) &&
                new Date(startDate) < new Date(date.format('YYYY-MM-DD'))
            ) {
                return " middle-date";
            }
            if (endDate === date.format('YYYY-MM-DD')) {
                return " end-date";
            }
            if (startDate === date.format('YYYY-MM-DD')) {
                return " start-date";
            }
        }
        return "";
    };

    const remove = (id) => {
        if (window.confirm('삭제하시겠습니까?')) {
            pollutionHistoryService.remove(Number(id))
                .then(res => res.data)
                .then(res => {
                    alert('삭제되었습니다.')
                    render();
                })
        }
    };

    const excelDownload = () => {
        const workSheet = xlsx.utils.json_to_sheet(excel);
        workSheet['!autofilter'] = { ref: 'A1:R11' };

        const workBook = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(workBook, workSheet, 'sheet title');
        xlsx.writeFile(workBook, 'report.xlsx');
    };


    return (
        <>
            <SimpleHeader name="Tables" parentName="Tables" />
            <Container className="mt--6" fluid>
                <Row>
                    <div className="col">
                        <Card>
                            <CardHeader className="border-0">
                                <Row className="py-3">
                                    <Col xs="11">
                                        <Form>
                                            <Row>
                                                <Col xs="2">
                                                    <label className=" form-control-label py-2">
                                                    </label>
                                                    <FormGroup>
                                                        <ReactDatetime
                                                            inputProps={{
                                                                placeholder: "",
                                                            }}
                                                            value={startDate}
                                                            locale={'ko'}
                                                            timeFormat={false}
                                                            dateFormat={'YYYY-MM-DD'}
                                                            onChange={(e) =>
                                                                handleReactDatetimeChange("startDate", e)
                                                            }
                                                            renderDay={(props, currentDate, selectedDate) => {
                                                                let classes = props.className;
                                                                classes += getClassNameReactDatetimeDays(
                                                                    currentDate
                                                                );
                                                                return (
                                                                    <td {...props} className={classes}>
                                                                        {currentDate.date()}
                                                                    </td>
                                                                );
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col xs="2">
                                                    <FormGroup>
                                                        <label className=" form-control-label py-2">
                                                        </label>
                                                        <ReactDatetime
                                                            inputProps={{
                                                                placeholder: "",
                                                            }}
                                                            value={endDate}
                                                            locale={'ko'}
                                                            timeFormat={false}
                                                            dateFormat={'YYYY-MM-DD'}
                                                            onChange={(e) =>
                                                                handleReactDatetimeChange("endDate", e)
                                                            }
                                                            renderDay={(props, currentDate, selectedDate) => {
                                                                let classes = props.className;
                                                                classes += getClassNameReactDatetimeDays(
                                                                    currentDate
                                                                );
                                                                return (
                                                                    <td {...props} className={classes}>
                                                                        {currentDate.date()}
                                                                    </td>
                                                                );
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col xs="3">
                                                    <label className=" form-control-label py-2">
                                                    </label>
                                                    <FormGroup>
                                                        <InputGroup
                                                            className={classnames("input-group-merge", {
                                                                focused: searchCurrent,
                                                            })}
                                                        >
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText>
                                                                    <i className="fas fa-search" />
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input
                                                                placeholder="이름으로 검색"
                                                                type="text"
                                                                value={search}
                                                                onChange={(e) => setSearch(e.target.value)}
                                                                onFocus={(e) => setSearchCurrent(true)}
                                                                onBlur={(e) => setSearchCurrent(false)}
                                                            />
                                                        </InputGroup>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="6">
                                        <h3 className="mt-2">활동명: {title}</h3>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="6">
                                        <h3 className="mt-2">악취오염 캠페인 내역 상세</h3>
                                    </Col>
                                    {/*/!*{user?.role === 'center' ? l : (*!/*/}
                                        <Col className="text-right">
                                            <Button
                                                color="default"
                                                onClick={() => excelDownload()}
                                            >
                                                엑셀 다운로드
                                            </Button>
                                        </Col>
                                    {/*)}*/}
                                </Row>
                            </CardHeader>

                            <Table className="align-items-center table-flush" responsive striped>
                                <thead className="thead-light">
                                <tr>
                                    <th className="text-center">No.</th>
                                    <th className="text-center">이름</th>
                                    <th className="text-center">휴대폰 번호</th>
                                    <th className="text-center">활동 GPS</th>
                                    <th className="text-center">날씨, 풍량</th>
                                    <th className="text-center">악취구분</th>
                                    <th className="text-center">측정내역</th>
                                    <th className="text-center">측정일자</th>
                                    <th className="text-center">지속시간</th>
                                    <th className="text-center">관리</th>
                                </tr>
                                </thead>
                                <tbody>
                                {data?.map((item, i) => {
                                    return [
                                        <Fragment key={i}>
                                            <tr>
                                                <td className="text-center">
                                                    {item.id}
                                                </td>
                                                <td className="text-center">
                                                    {item.user.name}
                                                </td>
                                                <td className="text-center">
                                                    {telWithCommas(item?.user.tel)}
                                                </td>
                                                <td className="text-center">
                                                    {item?.address}
                                                </td>
                                                <td className="text-center">
                                                    {item?.weather}, {item?.wind}/s
                                                </td>
                                                <td className="text-center">
                                                    {item?.industry && '산업: '+ item?.industry}
                                                    {item?.life && '생활: '+ item?.life}
                                                    {item?.etc && '기타: '+ item?.etc}
                                                </td>
                                                <td className="text-center">
                                                    {item?.level}
                                                </td>
                                                <td className="text-center">
                                                    {dayjs(item?.createdAt).format('YYYY-MM-DD HH:mm')}
                                                </td>
                                                <td className="text-center">
                                                    {item?.time}
                                                </td>
                                                <td className="text-center">
                                                    <Button
                                                        className="btn-outline-warning"
                                                        onClick={() => remove(item.id)}
                                                        size="sm"
                                                    >
                                                        <span className="btn-inner--text">삭제</span>
                                                    </Button>
                                                </td>
                                            </tr>
                                        </Fragment>
                                    ];
                                })}
                                </tbody>
                            </Table>

                            <CardFooter className="py-4">
                                <PaginationWrapper
                                    isActive={pageActive}
                                    pageNumber={pageCount}
                                    callback={(page) => setPageActive(page)}
                                    itemPerPage={10}
                                />
                            </CardFooter>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
}

export default PollutionHistoryList;
