import React, {useEffect, useRef, useState, Fragment} from "react";
// reactstrap components
import {
  Button,
  Card,
  CardBody, CardFooter,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Modal,
  Row, Table,
  UncontrolledTooltip,
} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import {useHistory} from "react-router-dom";
import useHistoryState from "../../../common/useHistoryState";
import moment from "moment";
import PaginationWrapper from "../components/pagination";
import ReactDatetime from "react-datetime";
import InputFile from "../components/InputFile";
import {useRecoilState, useRecoilValue, useResetRecoilState} from "recoil";
import companyService from "../company/service/company.service";
import {FormControl, InputGroup} from "react-bootstrap";
import InputFiles from "../components/InputFiles";
import DaumPostcode from "react-daum-postcode";
import companyCenterService from "./service/companyCenter.service";
import AuthService from "../auth/auth.service";

function GroupForm({ match }) {
  const history = useHistory();
  const { id } = match.params;
  const { ids } = match.params;
  const [pageActive, setPageActive] = useHistoryState(1, 'pageActive');
  const [pageCount, setPageCount] = useHistoryState(1, 'pageCount');
  const [groupName, setGroupName] = useState('');
  const [manager, setManager] = useState('');
  const [managerName, setManagerName] = useState('');
  const [managers, setManagers] = useState([]);
  const [user, setUser] = useState({});
  const [userId, setUserId] = useState('');
  const [defaultModal, setDefaultModal] = useHistoryState(false, 'defaultModal');
  console.log(ids)

  const createOrUpdate = async () => {

    const json = {
      groupName,
      centerId: id
    }
    if(ids) {
      delete json.centerId
    }

    console.log('json',json);

    let res;
    try {
      if (ids) {
        res = await companyCenterService.groupUpdate(ids, json);
      } else {
        res = await companyCenterService.groupRegister(json);
      }
      if (res.resultMsg === "success") {
        alert(ids ? '수정되었습니다.' : '등록되었습니다.');
        ids? fetchData():history.goBack();
      }
    } catch (e) {
      // alert(JSON.stringify(e, null, 4));
      alert(e.response?.data?.message);
    }
    console.log('웹', res);
  };

  const create = async () => {
    if(!manager) {
      alert('담당자 직책을 입력해주세요.')
      return;
    }
    if(!userId) {
      alert('이름조회를 해주세요.')
      return;
    }

    const json = {
      userId: Number(userId),
      serviceGroupId: ids,
      name: manager
    }

    console.log('json',json);

    let res;
    try {
        res = await companyCenterService.managerRegister(json);
      if (res.code === 200) {
        setDefaultModal(false);
        setManager('');
        setManagerName('');
        setUserId('');
        setUser({});
        alert('등록되었습니다.');
        fetchData();
      }
    } catch (e) {
      // alert(JSON.stringify(e, null, 4));
      alert(e.response?.data?.message);
    }
    console.log('웹', res);
  };

  const fetchData = async () => {
    const res = await companyCenterService.groupDetail(ids);
    if (res.code === 200) {
      console.log(res);
      setGroupName(res.serviceGroups.groupName)
      setManagers(res.serviceGroups.managers)
    }
  };

  const searchUser = async () => {
    if(!managerName) {
      alert('담당자 이름을 입력해주세요.')
      return;
    }
    const res = await companyCenterService.userSearch(managerName);
    if (res.code === 200) {
      console.log(res);
      setUser(res.users[0])
      setUserId(res.users[0].id)
    }
  };

  useEffect(() => {
    if(ids) {
      fetchData();
    }
  }, []);

  const remove = async (id) => {
    if (window.confirm('삭제하시겠습니까?')) {
      let res = await companyCenterService.removeManager(id);
      console.log(res);
      if (res.code === 200) {
        alert('삭제되었습니다.')
        fetchData();
      }
    }
  };

  return (
    <>
      <SimpleHeader name="Form elements" parentName="Forms" />
      <Container className="mt--6" fluid>
        <Card className="mb-4">
          <CardHeader style={{ display: 'flex', justifyContent: 'space-between' }}>
            {ids?
              <>
                <h3 className="mt-2">봉사 센터 상세</h3>
                <div>
                  <Button
                    color="default"
                    type="button"
                    onClick={createOrUpdate}
                  >
                    수정하기
                  </Button>
                  {/*<Button*/}
                  {/*  color="danger"*/}
                  {/*  type="button"*/}
                  {/*  // onClick={remove}*/}
                  {/*>*/}
                  {/*  삭제하기*/}
                  {/*</Button>*/}
                </div>
              </>
              :
              <>
                <h3 className="mt-2">봉사 센터 등록</h3>
                <div>
                  <Button
                      color="default"
                      type="button"
                      onClick={createOrUpdate}
                  >
                    <span className="btn-inner--icon mr-1">
                        <i className="fas fa-user-edit" />
                    </span>
                    등록하기
                  </Button>
                </div>
              </>
            }
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    단체 등록
                  </label>
                  <Input
                      placeholder=""
                      type="text"
                      name="groupName"
                      value={groupName || ''}
                      onChange={(e) => setGroupName(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            {ids &&
              <Row>
                <div className="col">
                  <Card>
                    <CardHeader className="border-0">
                      <Row>
                        <Col xs="6">
                        </Col>
                        <Col className="text-right">
                          <Button
                              color="default"
                              onClick={(e) => setDefaultModal(true)}
                          >
                            담당자 등록
                          </Button>
                        </Col>
                      </Row>
                    </CardHeader>
                    <Table className="align-items-center table-flush" responsive striped>
                      <thead className="thead-light">
                      <tr>
                        <th className="text-center">담당자 아이디</th>
                        <th className="text-center">담당자 직책</th>
                        <th className="text-center">이름</th>
                        <th className="text-center">생년월일</th>
                        <th className="text-center">전화번호</th>
                        <th className="text-center">관리</th>
                      </tr>
                      </thead>
                      <tbody>
                      {managers?.map((item, i) => {
                        return [
                          <Fragment key={i}>
                            <tr>
                              <td className="text-center">
                                {item.id}
                              </td>
                              <td className="text-center">
                                {item.managerName}
                              </td>
                              <td className="text-center">
                                {item.user.name}
                              </td>
                              <td className="text-center">
                                {item.user.birth}
                              </td>
                              <td className="text-center">
                                {item.user.tel}
                              </td>
                              <td className="text-center">
                                <Button
                                    className="btn-outline-danger"
                                    onClick={() => remove(item.id)}
                                    size="sm"
                                >
                                  <span className="btn-inner--text">삭제</span>
                                </Button>
                              </td>
                            </tr>
                          </Fragment>
                        ];
                      })}
                      </tbody>
                    </Table>
                    <CardFooter className="py-4">
                      <PaginationWrapper
                          isActive={pageActive}
                          pageNumber={pageCount}
                          callback={(page) => setPageActive(page)}
                          itemPerPage={10}
                      />
                    </CardFooter>
                  </Card>
                </div>
              </Row>
            }
          </CardBody>
        </Card>
      </Container>
      <Modal
          className="modal-dialog-centered"
          isOpen={defaultModal}
          toggle={() => setDefaultModal(false)}
          fade={false}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            담당자 등록하기
          </h6>
          <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                setDefaultModal(false)
                setManager('');
                setManagerName('');
                setUserId('');
                setUser({});
              }}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <Col md="12">
              <FormGroup>
                <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                >
                  담당자 직책
                </label>
                <Input
                    placeholder=""
                    type="text"
                    name="manager"
                    value={manager || ''}
                    onChange={(e) => setManager(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="12">
              <FormGroup>
                <label
                    className="form-control-label mr-5"
                    htmlFor="example3cols1Input"
                >
                  담당자 이름
                </label>
                <Input
                    placeholder="*담당자 이름을 정확히 입력해주세요.(공백없이)"
                    type="text"
                    name="managerName"
                    value={managerName || ''}
                    onChange={(e) => setManagerName(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col className="text-right">
                <Button
                    className="btn-outline-primary"
                    data-dismiss="modal"
                    type="button"
                    size="sm"
                    onClick={() => searchUser()}
                >
                  이름 조회
                </Button>
            </Col>
          </Row>
          <Row>
            <Col md="auto">
              <h4>{user?.name}</h4>
            </Col>
            <Col md="auto">
              <h4>{user?.birth}</h4>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <Button
              className="ml-auto btn-default"
              data-dismiss="modal"
              type="button"
              onClick={() => create()}
          >
            등록하기
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default GroupForm;
