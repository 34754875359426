import React, {Fragment, useEffect, useState} from "react";

// reactstrap components
import {
    Button,
    Card,
    CardFooter,
    CardHeader,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText, Modal,
    Row,
    Table, UncontrolledTooltip,
} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import PaginationWrapper from "../components/pagination";
import {paginate, telWithCommas} from "../../../utils";
import useHistoryState from "../../../common/useHistoryState";
import moment from "moment";
import {useHistory} from "react-router";
import staffService from "./service/staff.service";
import classnames from "classnames";
import AuthService from "../auth/auth.service";
import {Link} from "react-router-dom";
import serviceNewsService from "../serviceNews/service/serviceNews.service";

function StaffList() {
    const user = typeof window !== 'undefined' ? AuthService.getCurrentUser() : null;
    const history = useHistory();
    const [pageActive, setPageActive] = useHistoryState(1, 'pageActive');
    const [pageCount, setPageCount] = useHistoryState(1, 'pageCount');
    const [data, setData] = useHistoryState([], 'data');
    const [diff, setDiff] = useHistoryState('', 'diff');
    const [searchCurrent, setSearchCurrent] = useHistoryState(false, 'searchCurrent');
    const [search, setSearch] = useHistoryState('', 'search');
    // console.log('123',user)

    const render = () => {
        let option = { };
        if (search) {
            option.search = search;
        }
        if (pageCount) {
            option.page = pageActive;
        }
        staffService.list({ page: pageActive, search })
            .then((res) => {
                if (res.code === 200) {
                    console.log(res);
                    setPageCount(res.list.count);
                    // setData(paginate(res.centers.rows, pageActive, 11));
                    setData(res.list.rows);
                }
            });
    };

    useEffect(() => {
        render();
    }, [search, pageActive, pageCount]);

    const remove = async (id) => {
        if (window.confirm('삭제하시겠습니까?')) {
            let res = await serviceNewsService.remove(id);
            console.log(res);
            if (res.code === 200) {
                render();
            }
        }
    };

    return (
        <>
            <SimpleHeader name="Tables" parentName="Tables" />
            <Container className="mt--6" fluid>
                <Row>
                    <div className="col">
                        <Card>
                            <CardHeader className="border-0">
                                <Row>
                                    <Col xs="6">
                                        <h3 className="mt-2">
                                            직원 관리
                                        </h3>
                                    </Col>
                                    {user?.role === 'center' ? null : (
                                      <Col className="text-right">
                                          <Button
                                              color="default"
                                              onClick={(e) => history.push('/admin/staffForm')}
                                          >
                                              등록하기
                                          </Button>
                                      </Col>
                                    )}
                                </Row>
                            </CardHeader>

                            <Table className="align-items-center table-flush" responsive striped>
                                <thead className="thead-light">
                                    <tr>
                                        <th className="text-center">번호</th>
                                        <th className="text-center">아이디</th>
                                        <th className="text-center">등록일자</th>
                                        <th className="text-center">관리</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item, i) => {
                                        return [
                                            <Fragment key={i}>
                                                <tr>
                                                    <td className="text-center">
                                                        {item.id}
                                                    </td>
                                                    <td className="text-center">
                                                        {item.loginId}
                                                    </td>
                                                    <td className="text-center">
                                                        {item.createdAt}
                                                    </td>
                                                    <td className="text-center">
                                                        <Button
                                                            className="btn-outline-danger"
                                                            onClick={() => remove()}
                                                            size="sm"
                                                        >
                                                            <span className="btn-inner--text">삭제</span>
                                                        </Button>
                                                        <Button
                                                            className="btn-outline-default"
                                                            onClick={() => history.push('/admin/staffDetail/' + item.id)}
                                                            size="sm"
                                                        >
                                                            <span className="btn-inner--text">상세</span>
                                                        </Button>
                                                    </td>
                                                </tr>
                                            </Fragment>
                                        ];
                                    })}
                                </tbody>
                            </Table>

                            <CardFooter className="py-4">
                                <PaginationWrapper
                                    isActive={pageActive}
                                    pageNumber={pageCount}
                                    callback={(page) => setPageActive(page)}
                                    itemPerPage={10}
                                />
                            </CardFooter>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
}

export default StaffList;
