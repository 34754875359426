//file
import {atom} from "recoil";
import api from "../../../config/api.routes";
import axios from "axios";
import authHeader from "../../../services/auth-header";

export const detailFileState = atom({
    key: 'detailFileState',
    default: '',
});

export const detailFilePreviewState = atom({
    key: 'detailFilePreviewState',
    default: '',
});

export const thumbnailFileState = atom({
    key: 'thumbnailFileState',
    default: '',
});

export const thumbnailFilePreviewState = atom({
    key: 'thumbnailFilePreviewState',
    default: '',
});

const list = (data) => {
    let url = api().apiURL + `/user/undefined?`;
    for (let query of Object.keys(data)) {
        if (data[query] === undefined || data[query] === '') {
            continue;
        }
        url += `${query}=${data[query]}&`;
    }
    console.log(url);
    return new Promise((resolve, reject) => {
        axios.get(url, { headers: authHeader() })
          .then(res => res.data)
          .then(res => resolve(res))
          .catch(e => reject(e));
    });
};

const stepList = (data) => {
    let url = api().apiURL + `/step/list?`;
    for (let query of Object.keys(data)) {
        if (data[query] === undefined || data[query] === '') {
            continue;
        }
        url += `${query}=${data[query]}&`;
    }
    console.log(url);
    return new Promise((resolve, reject) => {
        axios.get(url, { headers: authHeader() })
          .then(res => res.data)
          .then(res => resolve(res))
          .catch(e => reject(e));
    });
};

const stepDetail = (id) => {
    let url = api().apiURL + `/step/detail?id=${id}`;
    console.log(url);
    return new Promise((resolve, reject) => {
        axios.get(url, { headers: authHeader() })
          .then(res => res.data)
          .then(res => resolve(res))
          .catch(e => reject(e));
    });
};

const stepRegister = (data) => {
    let url = api().apiURL + `/step/register`;
    console.log(url,data);
    return new Promise((resolve, reject) => {
        axios.post(url, data, { headers: authHeader() })
          .then(res => res.data)
          .then(res => resolve(res))
          .catch(e => reject(e));
    });
};

const stepUpdate = (id, data) => {
    let url = api().apiURL + `/step/update?id=${id}`;
    console.log('수정',url);
    return new Promise((resolve, reject) => {
        axios.put(url, data, { headers: authHeader() })
          .then(res => res.data)
          .then(res => resolve(res))
          .catch(e => reject(e));
    });
};
const stepRemove = (id) => {
    let url = api().apiURL + `/step/remove?id=${id}`;
    console.log(url);
    return new Promise((resolve, reject) => {
        axios.delete(url, { headers: authHeader() })
          .then(res => res.data)
          .then(res => resolve(res))
          .catch(e => reject(e));
    });
};

const update = (id, data) => {
    let url = api().apiURL + `/admin/user/${id}`;
    console.log('수정',url);
    return new Promise((resolve, reject) => {
        axios.put(url, data, { headers: authHeader() })
            .then(res => res.data)
            .then(res => resolve(res))
            .catch(e => reject(e));
    });
};

const detail = (id) => {
    let url = api().apiURL + `/admin/user/${id}`;
    console.log(url);
    return new Promise((resolve, reject) => {
        axios.get(url, { headers: authHeader() })
          .then(res => res.data)
          .then(res => resolve(res))
          .catch(e => reject(e));
    });
};

const register = (data) => {
    let url = api().apiURL + `/auth/join/temp=true`;
    console.log(url,data);
    return new Promise((resolve, reject) => {
        axios.post(url, data, { headers: authHeader() })
            .then(res => res.data)
            .then(res => resolve(res))
            .catch(e => reject(e));
    });
};

const fileRegister = (id, data, diff) => {
    // diff = thumbnail 또는 image
    let url = api().apiURL + `/charity/fileUpload?charityId=${id}&diff=${diff}`;
    console.log(url);
    return new Promise((resolve, reject) => {
        axios.post(url, data, { headers: authHeader() })
          .then(res => res.data)
          .then(res => resolve(res))
          .catch(e => reject(e));
    });
};

const remove = (id) => {
    let url = api().apiURL + `/charity/delete?charityId=${id}`;
    console.log(url);
    return new Promise((resolve, reject) => {
        axios.delete(url, { headers: authHeader() })
            .then(res => res.data)
            .then(res => resolve(res))
            .catch(e => reject(e));
    });
};

// /charityHistory/detail

const charityHistoryDetail = (data) => {
    let url = api().apiURL + `/charityHistory/detail?`;
    for (let query of Object.keys(data)) {
        if (data[query] === undefined || data[query] === '') {
            continue;
        }
        url += `${query}=${data[query]}&`;
    }
    console.log(url);
    return new Promise((resolve, reject) => {
        axios.get(url, { headers: authHeader() })
          .then(res => res.data)
          .then(res => resolve(res))
          .catch(e => reject(e));
    });
};

const charityHistoryDetailDown = (data) => {
    let url = api().apiURL + `/charityHistory/detail?`;
    for (let query of Object.keys(data)) {
        if (data[query] === undefined || data[query] === '') {
            continue;
        }
        url += `${query}=${data[query]}&`;
    }
    console.log(url);
    return new Promise((resolve, reject) => {
        axios.get(url, { headers: authHeader(), responseType: 'arraybuffer' })
          .then(res => resolve(res))
          .catch(e => reject(e));
    });
};

const userService = {
    list,
    update,
    detail,
    register,
    fileRegister,
    remove,
    charityHistoryDetail,
    charityHistoryDetailDown,
    stepList,
    stepDetail,
    stepRegister,
    stepUpdate,
    stepRemove,
}

export default userService;
