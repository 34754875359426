import React, {Fragment, useEffect, useState} from "react";

// reactstrap components
import {
    Button,
    Card,
    CardFooter,
    CardHeader,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText, Modal,
    Row,
    Table, UncontrolledTooltip,
} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import PaginationWrapper from "../components/pagination";
import {paginate, telWithCommas} from "../../../utils";
import useHistoryState from "../../../common/useHistoryState";
import moment from "moment";
import {useHistory} from "react-router";
import orderService from "./service/order.service";
import classnames from "classnames";
import AuthService from "../auth/auth.service";
import {Link} from "react-router-dom";
import ReactDatetime from "react-datetime";

function OrderList() {
    const user = typeof window !== 'undefined' ? AuthService.getCurrentUser() : null;
    const history = useHistory();
    const [pageActive, setPageActive] = useHistoryState(1, 'pageActive');
    const [pageCount, setPageCount] = useHistoryState(1, 'pageCount');
    const [data, setData] = useHistoryState([], 'data');
    const [diff, setDiff] = useHistoryState('', 'diff');
    const [searchCurrent, setSearchCurrent] = useHistoryState(false, 'searchCurrent');
    const [search, setSearch] = useHistoryState('', 'search');
    const [startDate, setStartDate] = useHistoryState(moment().add(-2, 'years').format('YYYY-MM-01'), 'startDate');
    const [endDate, setEndDate] = useHistoryState(moment().format('YYYY-MM-DD'), 'endDate');
    // console.log('123',user)

    const render = () => {
        let option = { };
        if (search) {
            option.search = search;
        }
        if (pageCount) {
            option.page = pageActive;
        }
        orderService.list({ page: pageActive, search, startDate, endDate })
            .then((res) => {
                if (res.code === 200) {
                    console.log(res);
                    setPageCount(res.list.count);
                    // setData(paginate(res.centers.rows, pageActive, 11));
                    setData(res.list.rows);
                }
            });
    };

    useEffect(() => {
        render();
    }, [search, pageActive, pageCount, startDate, endDate]);

    const handleReactDatetimeChange = (who, date) => {
        if (
            startDate &&
            who === "endDate" &&
            new Date(startDate) > new Date(date.format('YYYY-MM-DD'))
        ) {
            setStartDate(date.format('YYYY-MM-DD'));
            setEndDate(date.format('YYYY-MM-DD'));
        } else if (
            endDate &&
            who === "startDate" &&
            new Date(endDate) < new Date(date.format('YYYY-MM-DD'))
        ) {
            setStartDate(date.format('YYYY-MM-DD'));
            setEndDate(date.format('YYYY-MM-DD'));
        } else {
            if (who === "startDate") {
                setStartDate(date.format('YYYY-MM-DD'));
            } else {
                setEndDate(date.format('YYYY-MM-DD'));
            }
        }
    };

    const getClassNameReactDatetimeDays = (date) => {
        if (startDate && endDate) {
        }
        if (startDate && endDate && startDate !== endDate) {
            if (
                new Date(endDate) > new Date(date.format('YYYY-MM-DD')) &&
                new Date(startDate) < new Date(date.format('YYYY-MM-DD'))
            ) {
                return " middle-date";
            }
            if (endDate === date.format('YYYY-MM-DD')) {
                return " end-date";
            }
            if (startDate === date.format('YYYY-MM-DD')) {
                return " start-date";
            }
        }
        return "";
    };

    return (
        <>
            <SimpleHeader name="Tables" parentName="Tables" />
            <Container className="mt--6" fluid>
                <Row>
                    <div className="col">
                        <Card>
                            <CardHeader className="border-0">
                                <Row className="py-3">
                                    <Col xs="11">
                                        <Form>
                                            <Row>
                                                <Col xs="2">
                                                    <label className=" form-control-label">
                                                        날짜 조회
                                                    </label>
                                                    <FormGroup>
                                                        <ReactDatetime
                                                            inputProps={{
                                                                placeholder: "",
                                                            }}
                                                            value={startDate}
                                                            locale={'ko'}
                                                            timeFormat={false}
                                                            dateFormat={'YYYY-MM-DD'}
                                                            onChange={(e) =>
                                                                handleReactDatetimeChange("startDate", e)
                                                            }
                                                            renderDay={(props, currentDate, selectedDate) => {
                                                                let classes = props.className;
                                                                classes += getClassNameReactDatetimeDays(
                                                                    currentDate
                                                                );
                                                                return (
                                                                    <td {...props} className={classes}>
                                                                        {currentDate.date()}
                                                                    </td>
                                                                );
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col xs="2">
                                                    <FormGroup>
                                                        <label className=" form-control-label py-2">
                                                        </label>
                                                        <ReactDatetime
                                                            inputProps={{
                                                                placeholder: "",
                                                            }}
                                                            value={endDate}
                                                            locale={'ko'}
                                                            timeFormat={false}
                                                            dateFormat={'YYYY-MM-DD'}
                                                            onChange={(e) =>
                                                                handleReactDatetimeChange("endDate", e)
                                                            }
                                                            renderDay={(props, currentDate, selectedDate) => {
                                                                let classes = props.className;
                                                                classes += getClassNameReactDatetimeDays(
                                                                    currentDate
                                                                );
                                                                return (
                                                                    <td {...props} className={classes}>
                                                                        {currentDate.date()}
                                                                    </td>
                                                                );
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="6">
                                        <h3 className="mt-2">
                                            주문 관리
                                        </h3>
                                    </Col>
                                    {/*{user?.role === 'center' ? null : (*/}
                                    {/*  <Col className="text-right">*/}
                                    {/*      <Button*/}
                                    {/*          color="default"*/}
                                    {/*          onClick={(e) => history.push('/admin/centerForm')}*/}
                                    {/*      >*/}
                                    {/*          등록하기*/}
                                    {/*      </Button>*/}
                                    {/*  </Col>*/}
                                    {/*)}*/}
                                </Row>
                            </CardHeader>

                            <Table className="align-items-center table-flush" responsive striped>
                                <thead className="thead-light">
                                    <tr>
                                        <th className="text-center">주문번호</th>
                                        <th className="text-center">업체명</th>
                                        <th className="text-center">결제 수단</th>
                                        <th className="text-center">메뉴명</th>
                                        <th className="text-center">금액</th>
                                        <th className="text-center">상태</th>
                                        <th className="text-center">관리</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item, i) => {
                                        return [
                                            <Fragment key={i}>
                                                <tr>
                                                    <td className="text-center">
                                                        {item.id}
                                                    </td>
                                                    <td className="text-center">
                                                        {item.name}
                                                    </td>
                                                    <td className="text-center">
                                                        {item.payMethod}
                                                    </td>
                                                    <td className="text-center">
                                                        {item.menus}
                                                    </td>
                                                    <td className="text-center">
                                                        {item.totalPrice}
                                                    </td>
                                                    <td className="text-center">
                                                        {item.status}
                                                    </td>
                                                    <td className="text-center">
                                                        <Button
                                                            className="btn-outline-danger"
                                                            onClick={() => history.push('/admin/charityDetail/' + item.id)}
                                                            size="sm"
                                                        >
                                                            <span className="btn-inner--text">삭제</span>
                                                        </Button>
                                                        <Button
                                                            className="btn-outline-default"
                                                            onClick={() => history.push('/admin/charityDetail/' + item.id)}
                                                            size="sm"
                                                        >
                                                            <span className="btn-inner--text">상세</span>
                                                        </Button>
                                                    </td>
                                                </tr>
                                            </Fragment>
                                        ];
                                    })}
                                </tbody>
                            </Table>

                            <CardFooter className="py-4">
                                <PaginationWrapper
                                    isActive={pageActive}
                                    pageNumber={pageCount}
                                    callback={(page) => setPageActive(page)}
                                    itemPerPage={10}
                                />
                            </CardFooter>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
}

export default OrderList;
