import React, {Fragment, useEffect} from "react";

// reactstrap components
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText, Media,
  Row,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import PaginationWrapper from "../components/pagination";
import {clickImg, paginate} from "../../../utils";
import useHistoryState from "../../../common/useHistoryState";
import classnames from "classnames";
import {Link} from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import challengeRankService from "./service/challengeRank.service";
import shopService from "../shop/shop.service";
import ReactDatetime from "react-datetime";
import moment from "moment";
import challengeService from "../carbon/service/challenge.service";

function ChallengeRank({ history }) {
  const [pageActive, setPageActive] = useHistoryState(1, 'pageActive');
  const [pageCount, setPageCount] = useHistoryState(1, 'pageCount');
  const [data, setData] = useHistoryState([], 'data');
  const [startDate, setStartDate] = useHistoryState(moment().format('YYYY-MM-01'), 'startDate');
  const [endDate, setEndDate] = useHistoryState(moment().format('YYYY-MM-DD'), 'endDate');
  const [searchCurrent, setSearchCurrent] = useHistoryState(false, 'searchCurrent');
  const [search, setSearch] = useHistoryState('', 'search');

  const render = async () => {
    const res = await challengeRankService.list({ page: pageActive, startDate, endDate, search });
    if (res.code === 200) {
      console.log(res);
      setPageCount(res.list.count);
      setData(res.list.rows);
    }
  };

  useEffect(() => {
    render();
  }, [ pageActive, startDate, endDate, search ]);

  const handleReactDatetimeChange = (who, date) => {
    if (
        startDate &&
        who === "endDate" &&
        new Date(startDate) > new Date(date.format('YYYY-MM-DD'))
    ) {
      setStartDate(date.format('YYYY-MM-DD'));
      setEndDate(date.format('YYYY-MM-DD'));
    } else if (
        endDate &&
        who === "startDate" &&
        new Date(endDate) < new Date(date.format('YYYY-MM-DD'))
    ) {
      setStartDate(date.format('YYYY-MM-DD'));
      setEndDate(date.format('YYYY-MM-DD'));
    } else {
      if (who === "startDate") {
        setStartDate(date.format('YYYY-MM-DD'));
      } else {
        setEndDate(date.format('YYYY-MM-DD'));
      }
    }
  };

  const getClassNameReactDatetimeDays = (date) => {
    if (startDate && endDate) {
    }
    if (startDate && endDate && startDate !== endDate) {
      if (
          new Date(endDate) > new Date(date.format('YYYY-MM-DD')) &&
          new Date(startDate) < new Date(date.format('YYYY-MM-DD'))
      ) {
        return " middle-date";
      }
      if (endDate === date.format('YYYY-MM-DD')) {
        return " end-date";
      }
      if (startDate === date.format('YYYY-MM-DD')) {
        return " start-date";
      }
    }
    return "";
  };

  return (
    <>
      <SimpleHeader name="Tables" parentName="Tables" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col xs="2">
                    <label className=" form-control-label">
                      날짜 조회
                    </label>
                    <FormGroup>
                      <ReactDatetime
                          inputProps={{
                            placeholder: "",
                          }}
                          value={startDate}
                          locale={'ko'}
                          timeFormat={false}
                          dateFormat={'YYYY-MM-DD'}
                          onChange={(e) =>
                              handleReactDatetimeChange("startDate", e)
                          }
                          renderDay={(props, currentDate, selectedDate) => {
                            let classes = props.className;
                            classes += getClassNameReactDatetimeDays(
                                currentDate
                            );
                            return (
                                <td {...props} className={classes}>
                                  {currentDate.date()}
                                </td>
                            );
                          }}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs="2">
                    <FormGroup>
                      <label className=" form-control-label py-2">
                      </label>
                      <ReactDatetime
                          inputProps={{
                            placeholder: "",
                          }}
                          value={endDate}
                          locale={'ko'}
                          timeFormat={false}
                          dateFormat={'YYYY-MM-DD'}
                          onChange={(e) =>
                              handleReactDatetimeChange("endDate", e)
                          }
                          renderDay={(props, currentDate, selectedDate) => {
                            let classes = props.className;
                            classes += getClassNameReactDatetimeDays(
                                currentDate
                            );
                            return (
                                <td {...props} className={classes}>
                                  {currentDate.date()}
                                </td>
                            );
                          }}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs="3">
                    <label className=" form-control-label py-2">
                    </label>
                    <FormGroup>
                      <InputGroup
                          className={classnames("input-group-merge", {
                            focused: searchCurrent,
                          })}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-search" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                            placeholder="유저 이름, 아이디로 검색"
                            type="text"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            onFocus={(e) => setSearchCurrent(true)}
                            onBlur={(e) => setSearchCurrent(false)}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">
                      챌린지 랭킹 리스트
                    </h3>
                  </Col>
                </Row>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive striped>
                <thead className="thead-light">
                <tr>
                  <th className="text-center">랭킹</th>
                  <th className="text-center">이름</th>
                  <th className="text-center">아이디</th>
                  <th className="text-center">전화번호</th>
                  <th className="text-center">참여수</th>
                </tr>
                </thead>
                <tbody>
                  {data?.map((item, i) => {
                  return [
                    <Fragment key={uuidv4()}>
                      <tr>
                        <td className="text-center">
                          {item.rank}
                        </td>
                        <td className="text-center">
                          {item.userName}
                        </td>
                        <td className="text-center">
                          {item.loginId}
                        </td>
                        <td className="text-center">
                          {item.tel}
                        </td>
                        <td className="text-center">
                          {item.count}
                        </td>
                      </tr>
                    </Fragment>
                  ];
                })}
                </tbody>
              </Table>

              <CardFooter className="py-4">
                <PaginationWrapper
                  isActive={pageActive}
                  pageNumber={pageCount}
                  callback={(page) => setPageActive(page)}
                  itemPerPage={10}
                />
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default ChallengeRank;
