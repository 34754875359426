import React, {Fragment, useEffect, useState} from "react";

// reactstrap components
import {Button, Card, CardFooter, CardHeader, Col, Container, Input, Row, Table,} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import {useHistory} from "react-router-dom";
import useHistoryState from "../../../../common/useHistoryState";
import PaginationWrapper from "../../components/pagination";
import carbonTabService from "../service/carbonTab.service";
import {clickImg} from "../../../../utils";
import challengeService from "../../carbon/service/challenge.service";

function PointSaveList() {
  const router =  useHistory();
  const [pageActive, setPageActive] = useHistoryState(1, 'pageActive');
  const [pageCount, setPageCount] = useHistoryState(1, 'pageCount');
  const [data, setData] = useHistoryState([], 'data');
  const [carbon, setCarbon] = useState('');
  const [point, setPoint] = useState('');
  const [dataId, setDataId] = useState([]);

  const render = async () => {
    const res = await carbonTabService.pointSaveList({ page: pageActive, limit: 15 });
    if (res.code === 200) {
      console.log(res);
      setPageCount(res.list.count);
      setData(res.list.rows);
    }
  };

  useEffect(() => {
    render();
  }, [pageActive]);

  const isCheck = (value, type, setType) => {
    const index = type.indexOf(value); // value 값의 위치를 찾음.
    if (index > -1) { //  값이 있는 경우, -1은 값이없음.
      setType([
        ...type.filter(v => v !== value)
      ]);
    } else {
      setType([
        ...type,
        value
      ]);
    }
  };

  const update = () => {
    const json = {
      point,
      carbon
    }
    let res;
    dataId.map(async (v, i) => {
      res = await carbonTabService.update(v, json);
      if (res.code === 200) {
        alert('적립되었습니다.');
        router.goBack();
      }
    })
    console.log(1)
  };

  return (
    <>
      <SimpleHeader name="Tables" parentName="Tables" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col xs="6">
                    <h4 className="mb-0">환경부와 지자체 탄소포인트 인증</h4>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col className="mt-2 text-right" md="auto">
                    <h4>탄소e음 포인트</h4>
                  </Col>
                  <Col className="text-right" md="1">
                    <Input
                        type="text"
                        value={point}
                        onChange={(e) => setPoint(e.target.value)}
                    />
                  </Col>
                  <Col className="mt-2 text-right" md="auto">
                    <h4>탄소 저감량</h4>
                  </Col>
                  <Col className="text-right" md="1">
                    <Input
                        type="text"
                        value={carbon}
                        onChange={(e) => setCarbon(e.target.value)}
                    />
                  </Col>
                  <Col>
                    <Button
                        className="btn-round btn-icon"
                        color="warning"
                        onClick={() => {
                          update();
                        }}
                    >
                      <span className="btn-inner--text">적립하기</span>
                    </Button>
                  </Col>
                </Row>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive striped>
                <thead className="thead-light">
                  <tr>
                    <th className="text-center">
                      <div className="custom-control custom-checkbox">
                        <input
                            className="custom-control-input"
                            id="table-check-all1"
                            type="checkbox"
                            onClick={() => {
                              setDataId(data.map((v) => {
                                return v.id
                              }))
                            }}
                            checked={dataId.length === 15}
                            readOnly
                        />
                        <label
                            className="custom-control-label"
                            htmlFor="table-check-all1"
                        />
                      </div>
                    </th>
                    <th className="text-center">회원번호</th>
                    <th className="text-center">성명</th>
                    <th className="text-center">등록일</th>
                    <th className="text-center">구분</th>
                    <th className="text-center" style={{ width: '10%' }}>사진1</th>
                    <th className="text-center" style={{ width: '10%' }}>사진2</th>
                    <th className="text-center" style={{ width: '10%' }}>사진3</th>
                    <th className="text-center">탄소e음 포인트</th>
                    <th className="text-center">탄소 저감량</th>
                    {/*<th className="text-center">적립 버튼</th>*/}
                    <th className="text-center">적립 유무</th>
                    <th className="text-center">적립 취소</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((item, i) => {
                    return [
                      <Fragment key={i}>
                        <tr>
                          <td className="text-center">
                            <div className="custom-control custom-checkbox">
                              <input
                                  className="custom-control-input"
                                  id={"table-check-all" + item.id}
                                  type="checkbox"
                                  onClick={() => {
                                    isCheck(item.id, dataId, setDataId)
                                  }}
                                  checked={dataId.indexOf(item.id) > -1}
                                  readOnly
                              />
                              <label
                                  className="custom-control-label"
                                  htmlFor={"table-check-all" + item.id}
                              />
                            </div>
                          </td>
                          <td className="text-center">
                            {item.userId}
                          </td>
                          <td className="text-center">
                            {item.name}
                          </td>
                          <td className="text-center">
                            {item.createdAt}
                          </td>
                          <td className="text-center">
                            {item.diff}
                          </td>
                          <td className="text-center">
                            {item.pointSaveImages[0]?.id
                                ? <img
                                    src={`https://d2twzv77g3c69u.cloudfront.net/fit-in/1000x700/${item.pointSaveImages[0]?.name}`}
                                    style={{ width: '40%', cursor: 'pointer' }}
                                    onClick={() => clickImg(`https://d2twzv77g3c69u.cloudfront.net/fit-in/1000x700/${item.pointSaveImages[0]?.name}`)}
                                />
                                :'-'
                            }
                          </td>
                          <td className="text-center">
                            {item.pointSaveImages[1]?.id
                                ? <img
                                    src={`https://d2twzv77g3c69u.cloudfront.net/fit-in/1000x700/${item.pointSaveImages[1]?.name}`}
                                    style={{ width: '40%', cursor: 'pointer' }}
                                    onClick={() => clickImg(`https://d2twzv77g3c69u.cloudfront.net/fit-in/1000x700/${item.pointSaveImages[1]?.name}`)}
                                />
                                :'-'
                            }
                          </td>
                          <td className="text-center">
                            {item.pointSaveImages[2]?.id
                                ? <img
                                    src={`https://d2twzv77g3c69u.cloudfront.net/fit-in/1000x700/${item.pointSaveImages[2]?.name}`}
                                    style={{ width: '40%', cursor: 'pointer' }}
                                    onClick={() => clickImg(`https://d2twzv77g3c69u.cloudfront.net/fit-in/1000x700/${item.pointSaveImages[2]?.name}`)}
                                />
                                :'-'
                            }
                          </td>
                          <td className="text-center">
                            {item.point? item.point:'-'}
                          </td>
                          <td className="text-center">
                            {item.carbon? item.carbon:'-'}
                          </td>
                          {/*<td className="text-center">*/}
                          {/*  <Button*/}
                          {/*    className="btn-outline-warning"*/}
                          {/*    color="danger"*/}
                          {/*    // onClick={() => setDefaultModal(true)}*/}
                          {/*    size="sm"*/}
                          {/*  >*/}
                          {/*    <span className="btn-inner--text">적립하기</span>*/}
                          {/*  </Button>*/}
                          {/*</td>*/}
                          <td className="text-center">
                            {item.point
                              ? <div className='text-red'>적립완료</div>
                              : <div className=''>적립전</div>
                            }
                          </td>
                              <td className="text-center">
                              {item.point?
                                <Button
                                    className="btn-danger"
                                    color="danger"
                                    // onClick={() => setDefaultModal(true)}
                                    size="sm"
                                >
                                  <span className="btn-inner--text">취소</span>
                                </Button>:'-'
                              }
                              </td>
                        </tr>
                      </Fragment>
                    ];
                  })}
                </tbody>
              </Table>

              <CardFooter className="py-4">
                <PaginationWrapper
                  isActive={pageActive}
                  pageNumber={pageCount}
                  callback={(page) => setPageActive(page)}
                  itemPerPage={10}
                />
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default PointSaveList;
