import React, {Fragment, useEffect} from "react";

// reactstrap components
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Table,
} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import PaginationWrapper from "../components/pagination";
import useHistoryState from "../../../common/useHistoryState";
import classnames from "classnames";
import ReactDatetime from "react-datetime";
import moment from "moment";
import qrCountService from "./service/qrCount.service";

function QrCountList({ match, history }) {
  const [pageActive, setPageActive] = useHistoryState(1, 'pageActive');
  const [pageCount, setPageCount] = useHistoryState(1, 'pageCount');
  const [data, setData] = useHistoryState([], 'data');
  const [category, setCategory] = useHistoryState('전체', 'category');
  const [active, setActive] = useHistoryState('전체', 'active');
  const [startDate, setStartDate] = useHistoryState(moment().add('year', -2).format('YYYY-MM-DD'), 'startDate');
  const [endDate, setEndDate] = useHistoryState(moment().format('YYYY-MM-DD'), 'endDate');
  const [searchCurrent, setSearchCurrent] = useHistoryState(false, 'searchCurrent');
  const [search, setSearch] = useHistoryState('', 'search');
  const { id } = match.params;

  const handleReactDatetimeChange = (who, date) => {
    if (
      startDate &&
      who === "endDate" &&
      new Date(startDate) > new Date(date.format('YYYY-MM-DD'))
    ) {
      setStartDate(date.format('YYYY-MM-DD'));
      setEndDate(date.format('YYYY-MM-DD'));
    } else if (
      endDate &&
      who === "startDate" &&
      new Date(endDate) < new Date(date.format('YYYY-MM-DD'))
    ) {
      setStartDate(date.format('YYYY-MM-DD'));
      setEndDate(date.format('YYYY-MM-DD'));
    } else {
      if (who === "startDate") {
        setStartDate(date.format('YYYY-MM-DD'));
      } else {
        setEndDate(date.format('YYYY-MM-DD'));
      }
    }
  };

  const getClassNameReactDatetimeDays = (date) => {
    if (startDate && endDate) {
    }
    if (startDate && endDate && startDate !== endDate) {
      if (
        new Date(endDate) > new Date(date.format('YYYY-MM-DD')) &&
        new Date(startDate) < new Date(date.format('YYYY-MM-DD'))
      ) {
        return " middle-date";
      }
      if (endDate === date.format('YYYY-MM-DD')) {
        return " end-date";
      }
      if (startDate === date.format('YYYY-MM-DD')) {
        return " start-date";
      }
    }
    return "";
  };

  const render = async () => {
    const res = await qrCountService.list({ page: pageActive, startDate, endDate, search });
    if (res.code === 200) {
      console.log(res);
      setPageCount(res.list.count);
      setData(res.list.rows);
    }
  };

  const statusChange = async (id, active) => {
    await qrCountService.update(id, {active});
    render();
  }

  useEffect(() => {
    render();
  }, [category, pageActive, startDate, endDate, search, active]);

  return (
    <>
      <SimpleHeader name="Tables" parentName="Tables" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row className="py-3">
                  <Col xs="11">
                    <Form>
                      <Row>
                        <Col xs="3">
                          <label className=" form-control-label py-2">
                          </label>
                          <FormGroup>
                            <InputGroup
                              className={classnames("input-group-merge", {
                                focused: searchCurrent,
                              })}
                            >
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="fas fa-search" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="지자체명, 기관명, QR생성명으로 검색"
                                type="text"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                onFocus={(e) => setSearchCurrent(true)}
                                onBlur={(e) => setSearchCurrent(false)}
                              />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col xs="2">
                          <label className=" form-control-label">
                            날짜 조회
                          </label>
                          <FormGroup>
                            <ReactDatetime
                              inputProps={{
                                placeholder: "",
                              }}
                              value={startDate}
                              locale={'ko'}
                              timeFormat={false}
                              dateFormat={'YYYY-MM-DD'}
                              onChange={(e) =>
                                handleReactDatetimeChange("startDate", e)
                              }
                              renderDay={(props, currentDate, selectedDate) => {
                                let classes = props.className;
                                classes += getClassNameReactDatetimeDays(
                                  currentDate
                                );
                                return (
                                  <td {...props} className={classes}>
                                    {currentDate.date()}
                                  </td>
                                );
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs="2">
                          <FormGroup>
                            <label className=" form-control-label py-2">
                            </label>
                            <ReactDatetime
                              inputProps={{
                                placeholder: "",
                              }}
                              value={endDate}
                              locale={'ko'}
                              timeFormat={false}
                              dateFormat={'YYYY-MM-DD'}
                              onChange={(e) =>
                                handleReactDatetimeChange("endDate", e)
                              }
                              renderDay={(props, currentDate, selectedDate) => {
                                let classes = props.className;
                                classes += getClassNameReactDatetimeDays(
                                  currentDate
                                );
                                return (
                                  <td {...props} className={classes}>
                                    {currentDate.date()}
                                  </td>
                                );
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">탄소 적립점 등록, QR 관리</h3>
                  </Col>
                  <Col className="text-right" xs="6">
                    <Button
                      className="btn-round btn-icon"
                      color="default"
                      id="tooltip443412080"
                      onClick={(e) => history.push('/admin/qrCountForm')}
                    >
                      <span className="btn-inner--text">등록하기</span>
                    </Button>
                  </Col>
                </Row>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive striped>
                <thead className="thead-light">
                <tr>
                  <th className="text-center">고유번호</th>
                  <th className="text-center">등록일</th>
                  <th className="text-center">지자체명/기관명</th>
                  <th className="text-center">QR생성명</th>
                  <th className="text-center">적립 포인트</th>
                  <th className="text-center">탄소저감량적립</th>
                  <th className="text-center">활성화</th>
                  <th className="text-center">상세</th>
                </tr>
                </thead>
                <tbody>
                {data?.map((item, i) => {
                  return [
                    <Fragment key={i}>
                      <tr>
                        <td className="text-center">
                          {item.id}
                        </td>
                        <td className="text-center">
                          {item.createdAt}
                        </td>
                        <td className="text-center">
                          {item.name}
                        </td>
                        <td className="text-center">
                          {item.qrName}
                        </td>
                        <td className="text-center">
                          {item.point}
                        </td>
                        <td className="text-center">
                          {item.carbonReduction}
                        </td>
                        <td className="text-center">
                          <label className="custom-toggle custom-toggle-success center">
                            <input
                                checked={item.active}
                                type="checkbox"
                                onChange={(e) => statusChange(item.id, !item.active)}
                            />
                            <span
                                className="custom-toggle-slider rounded-circle"
                                data-label-off="No"
                                data-label-on="Yes"
                            />
                          </label>
                        </td>
                        <td className="text-center">
                          <Button
                              className="btn-outline-default"
                              color="danger"
                              onClick={() => history.push('/admin/qrCountDetail/' + item.id)}
                              size="sm"
                          >
                            <span className="btn-inner--text">상세</span>
                          </Button>
                        </td>
                      </tr>
                    </Fragment>
                  ];
                })}
                </tbody>
              </Table>

              <CardFooter className="py-4">
                <PaginationWrapper
                  isActive={pageActive}
                  pageNumber={pageCount}
                  callback={(page) => setPageActive(page)}
                  itemPerPage={10}
                />
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default QrCountList;
