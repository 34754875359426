import React, {useEffect, useState} from "react";
import {Button, Col, Input, Row} from "reactstrap";

const Choices = ({item, i, handleDeleteItem}) => {
  const [content, setContent] = useState(item.content);
  const [score, setScore] = useState(item.score);

  useEffect( () => {
    async function fetchData() {
      item.content = content;
      item.score = score;
    }
    fetchData();
  }, [content, score, item]);

  return (
    <Row className="py-2">
      <Col md="1">
        <Input
          type="text"
          value={i+1}
          readOnly
        />
      </Col>
      <Col md="5">
        <Input
          type="text"
          value={content}
          onChange={(e) => setContent(() => e.target.value)}
        />
      </Col>
      <Col md="1">
        <Input
          type="number"
          value={score}
          onChange={(e) => setScore(() => Number(e.target.value))}
        />
      </Col>
      <Col md="1" className="py-2">
        <Button
          className="btn-icon"
          color="danger"
          size="sm"
          type="button"
          onClick={handleDeleteItem}
        >
          <span className="btn-inner--icon mr-0">
            <i className="ni ni-basket" />
          </span>
        </Button>
      </Col>
    </Row>
  )
}

export default Choices;
