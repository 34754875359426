import axios from "axios";
import api from "../../../../config/api.routes";
import authHeader from "../../../../services/auth-header";
import {atom} from "recoil";

//file
export const qrDiscountFileState = atom({
  key: 'qrDiscountFileState',
  default: '',
});
export const qrDiscountFilePreviewState = atom({
  key: 'qrDiscountFilePreviewState',
  default: '',
});

//files
export const qrDiscountStoreState = atom({
  key: 'qrDiscountStoreState',
  default: [],
});

export const qrDiscountPreviewState = atom({
  key: 'qrDiscountPreviewState',
  default: [],
});

export const qrDiscountDeletedId = atom({
  key: 'qrDiscountDeletedId',
  default: [],
});

const list = (data) => {
  let url = api().apiURL + `/store/list?`;
  for (let query of Object.keys(data)) {
    if (data[query] === undefined || data[query] === '') {
      continue;
    }
    url += `${query}=${data[query]}&`;
  }
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.get(url, { headers: authHeader() })
        .then(res => res.data)
        .then(res => resolve(res))
        .catch(e => reject(e));
  });
};

const register = (data) => {
  let url = api().apiURL + `/store/register?`;
  console.log('서비스',url,data);
  return new Promise((resolve, reject) => {
    axios.post(url, data, { headers: authHeader() })
        .then(res => res.data)
        .then(res => resolve(res))
        .catch(e => reject(e));
  });
};

const fileRegister = (id, data) => {
  let url = api().apiURL + `/store/fileRegister?storeId=${id}`;
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.post(url, data, { headers: authHeader() })
        .then(res => res.data)
        .then(res => resolve(res))
        .catch(e => reject(e));
  });
};

const detail = (id, data) => {
  let url = api().apiURL + `/store/detail?storeId=${id}`;
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.get(url, { headers: authHeader() })
        .then(res => res.data)
        .then(res => resolve(res))
        .catch(e => reject(e));
  });
};

const update = (id, data) => {
  let url = api().apiURL + `/store/update?storeId=${id}`;
  console.log('수정',url);
  return new Promise((resolve, reject) => {
    axios.put(url, data, { headers: authHeader() })
        .then(res => res.data)
        .then(res => resolve(res))
        .catch(e => reject(e));
  });
};

const status = (id, data) => {
  let url = api().apiURL + `/store/status?storeId=${id}&active=${data.active}`;
  console.log('수정',url);
  return new Promise((resolve, reject) => {
    axios.put(url, data, { headers: authHeader() })
        .then(res => res.data)
        .then(res => resolve(res))
        .catch(e => reject(e));
  });
};

const remove = (id) => {
  let url = api().apiURL + `/store/delete?storeId=${id}`;
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.delete(url, { headers: authHeader() })
        .then(res => res.data)
        .then(res => resolve(res))
        .catch(e => reject(e));
  });
};

const qrDiscountService = {
  list,
  register,
  fileRegister,
  detail,
  update,
  status,
  remove,
}

export default qrDiscountService;
