import React, {Fragment, useEffect} from "react";

// reactstrap components
import {
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import CounselingService from "../../../services/counseling.service";
import PaginationWrapper from "../components/pagination";
import {paginate} from "../../../utils";
import useHistoryState from "../../../common/useHistoryState";
import classnames from "classnames";
import ReactDatetime from "react-datetime";
import moment from "moment";
import {Link} from "react-router-dom";
import boardService from "./board.service";

function BoardList() {
  const [pageActive, setPageActive] = useHistoryState(1, 'pageActive');
  const [pageCount, setPageCount] = useHistoryState(1, 'pageCount');
  const [data, setData] = useHistoryState([], 'data');
  const [category, setCategory] = useHistoryState('전체', 'category');
  const [startDate, setStartDate] = useHistoryState('', 'startDate');
  const [endDate, setEndDate] = useHistoryState('', 'endDate');
  const [searchCurrent, setSearchCurrent] = useHistoryState(false, 'searchCurrent');
  const [search, setSearch] = useHistoryState('', 'search');

  const handleReactDatetimeChange = (who, date) => {
    if (
      startDate &&
      who === "endDate" &&
      new Date(startDate) > new Date(date.format('YYYY-MM-DD'))
    ) {
      setStartDate(date.format('YYYY-MM-DD'));
      setEndDate(date.format('YYYY-MM-DD'));
    } else if (
      endDate &&
      who === "startDate" &&
      new Date(endDate) < new Date(date.format('YYYY-MM-DD'))
    ) {
      setStartDate(date.format('YYYY-MM-DD'));
      setEndDate(date.format('YYYY-MM-DD'));
    } else {
      if (who === "startDate") {
        setStartDate(date.format('YYYY-MM-DD'));
      } else {
        setEndDate(date.format('YYYY-MM-DD'));
      }
    }
  };

  const getClassNameReactDatetimeDays = (date) => {
    if (startDate && endDate) {
    }
    if (startDate && endDate && startDate !== endDate) {
      if (
        new Date(endDate) > new Date(date.format('YYYY-MM-DD')) &&
        new Date(startDate) < new Date(date.format('YYYY-MM-DD'))
      ) {
        return " middle-date";
      }
      if (endDate === date.format('YYYY-MM-DD')) {
        return " end-date";
      }
      if (startDate === date.format('YYYY-MM-DD')) {
        return " start-date";
      }
    }
    return "";
  };

  const render = async () => {
    const option = {
      search, startDate, endDate
    };
    if (category !== '전체') {
      option.category = category;
    }
    const res = await boardService.list(option);
    if (res.code === 200) {
      console.log(res);
      setPageCount(res.list.length);
      setData(paginate(res.list, pageActive, 10));
    }
  }

  useEffect(() => {
    render();
  }, [category, pageActive, startDate, endDate, search]);

  // const statusChange = async (id, status) => {
  //   await CounselingService.update(id, {status});
  //   render();
  // }

  return (
    <>
      <SimpleHeader name="Tables" parentName="Tables" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row className="py-3">
                  <Col xs="11">
                    <Form>
                      <Row>
                        <Col xs="3">
                          <label className=" form-control-label py-2">
                          </label>
                          <FormGroup>
                            <InputGroup
                              className={classnames("input-group-merge", {
                                focused: searchCurrent,
                              })}
                            >
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="fas fa-search" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="유저 이름, 제목으로 검색"
                                type="text"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                onFocus={(e) => setSearchCurrent(true)}
                                onBlur={(e) => setSearchCurrent(false)}
                              />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col xs="2">
                          <label className=" form-control-label">
                            게시 날짜
                          </label>
                          <FormGroup>
                            <ReactDatetime
                              inputProps={{
                                placeholder: "",
                              }}
                              value={startDate}
                              locale={'ko'}
                              timeFormat={false}
                              dateFormat={'YYYY-MM-DD'}
                              onChange={(e) =>
                                handleReactDatetimeChange("startDate", e)
                              }
                              renderDay={(props, currentDate, selectedDate) => {
                                let classes = props.className;
                                classes += getClassNameReactDatetimeDays(
                                  currentDate
                                );
                                return (
                                  <td {...props} className={classes}>
                                    {currentDate.date()}
                                  </td>
                                );
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs="2">
                          <FormGroup>
                            <label className=" form-control-label py-2">
                            </label>
                            <ReactDatetime
                              inputProps={{
                                placeholder: "",
                              }}
                              value={endDate}
                              locale={'ko'}
                              timeFormat={false}
                              dateFormat={'YYYY-MM-DD'}
                              onChange={(e) =>
                                handleReactDatetimeChange("endDate", e)
                              }
                              renderDay={(props, currentDate, selectedDate) => {
                                let classes = props.className;
                                classes += getClassNameReactDatetimeDays(
                                  currentDate
                                );
                                return (
                                  <td {...props} className={classes}>
                                    {currentDate.date()}
                                  </td>
                                );
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs="2">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="exampleFormControlSelect13"
                            >
                              카테고리
                            </label>
                            <Input
                              id="exampleFormControlSelect13"
                              type="select"
                              value={category}
                              onChange={(e) => setCategory(e.target.value)}
                            >
                              <option>전체</option>
                              <option>나눔/교환</option>
                              <option>육아정보</option>
                              <option>맛집추천</option>
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">포아브탭 게시글 관리</h3>
                  </Col>
                </Row>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive striped>
                <thead className="thead-light">
                <tr>
                  <th className="text-center">게시 날짜</th>
                  <th className="text-center">아이디</th>
                  <th className="text-center">유저이름</th>
                  <th className="text-center">카테고리</th>
                  <th className="text-center">제목</th>
                  <th className="text-center">내용</th>
                  <th className="text-center">가격</th>
                  <th className="text-center">좋아요</th>
                  <th className="text-center">댓글</th>
                  <th />
                </tr>
                </thead>
                <tbody>
                {data?.map((item, i) => {
                  return [
                    <Fragment key={i}>
                      <tr>
                        <td className="text-center">
                          {item.createdAt}
                        </td>
                        <td className="text-center">
                          {item.user.loginId}
                        </td>
                        <td className="text-center">
                          {item.user.name}
                        </td>
                        <td className="text-center">
                          {item.category}
                        </td>
                        <td className="text-center">
                          {item.title}
                        </td>
                        <td className="text-center">
                          {item.content.length > 20 ? item.content.slice(0, 20) + '...': item.content}
                        </td>
                        <td className="text-center">
                          {item.price}원
                        </td>
                        <td className="text-center">
                          {item.wishCount}
                        </td>
                        <td className="text-center">
                          {item.commentCount}
                        </td>
                        <td className="table-actions">
                          <Link className="table-action" to={'/admin/snsDetail/' + item.id}>
                            <i id={"tooltip" + i} className="fas fa-user-edit" />
                          </Link>
                          <UncontrolledTooltip delay={0} target={"tooltip" + i}>
                            상세 보기
                          </UncontrolledTooltip>
                        </td>
                      </tr>
                    </Fragment>
                  ];
                })}
                </tbody>
              </Table>

              <CardFooter className="py-4">
                <PaginationWrapper
                  isActive={pageActive}
                  pageNumber={pageCount}
                  callback={(page) => setPageActive(page)}
                  itemPerPage={10}
                />
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default BoardList;
