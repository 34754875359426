//file
import {atom} from "recoil";
import axios from "axios";
import api from "../../../../config/api.routes";
import authHeader from "../../../../services/auth-header";

export const topFileState = atom({
    key: 'topFileState',
    default: '',
});

export const topFilePreviewState = atom({
    key: 'topFilePreviewState',
    default: '',
});

export const livingThumbFileState = atom({
    key: 'livingThumbFileState',
    default: '',
});

export const livingThumbFilePreviewState = atom({
    key: 'livingThumbFilePreviewState',
    default: '',
});

export const livingDetailFileState = atom({
    key: 'livingDetailFileState',
    default: '',
});

export const livingDetailFilePreviewState = atom({
    key: 'livingDetailFilePreviewState',
    default: '',
});

export const companyThumbFileState = atom({
    key: 'companyThumbFileState',
    default: '',
});

export const companyThumbFilePreviewState = atom({
    key: 'companyThumbFilePreviewState',
    default: '',
});

export const companyDetailFileState = atom({
    key: 'companyDetailFileState',
    default: '',
});

export const companyDetailFilePreviewState = atom({
    key: 'companyDetailFilePreviewState',
    default: '',
});

const activityList = (data) => {
    let url = api().apiURL + `/activity/list?`;
    for (let query of Object.keys(data)) {
        if (data[query] === undefined || data[query] === '') {
            continue;
        }
        url += `${query}=${data[query]}&`;
    }
    console.log(url);
    return new Promise((resolve, reject) => {
        axios.get(url, { headers: authHeader() })
          .then(res => res.data)
          .then(res => resolve(res))
          .catch(e => reject(e));
    });
};

const activityCompanyList = (data) => {
    let url = api().apiURL + `/companyIntroduction/list?`;
    for (let query of Object.keys(data)) {
        if (data[query] === undefined || data[query] === '') {
            continue;
        }
        url += `${query}=${data[query]}&`;
    }
    console.log(url);
    return new Promise((resolve, reject) => {
        axios.get(url, { headers: authHeader() })
          .then(res => res.data)
          .then(res => resolve(res))
          .catch(e => reject(e));
    });
};

const pointSaveList = (data) => {
    let url = api().apiURL + `/pointSave/list?`;
    for (let query of Object.keys(data)) {
        if (data[query] === undefined || data[query] === '') {
            continue;
        }
        url += `${query}=${data[query]}&`;
    }
    console.log(url);
    return new Promise((resolve, reject) => {
        axios.get(url, { headers: authHeader() })
          .then(res => res.data)
          .then(res => resolve(res))
          .catch(e => reject(e));
    });
};

const livingList = (data) => {
    let url = api().apiURL + `/carbonLiving/list?`;
    for (let query of Object.keys(data)) {
        if (data[query] === undefined || data[query] === '') {
            continue;
        }
        url += `${query}=${data[query]}&`;
    }
    console.log(url);
    return new Promise((resolve, reject) => {
        axios.get(url, { headers: authHeader() })
          .then(res => res.data)
          .then(res => resolve(res))
          .catch(e => reject(e));
    });
};

const historyList = (data) => {
    let url = api().apiURL + `/carbonPointHistory/admin/list?`;
    for (let query of Object.keys(data)) {
        if (data[query] === undefined || data[query] === '') {
            continue;
        }
        url += `${query}=${data[query]}&`;
    }
    console.log(url);
    return new Promise((resolve, reject) => {
        axios.get(url, { headers: authHeader() })
          .then(res => res.data)
          .then(res => resolve(res))
          .catch(e => reject(e));
    });
};

const detail = () => {
    let url = api().apiURL + `/carbon/detail?`;
    console.log(url);
    return new Promise((resolve, reject) => {
        axios.get(url, { headers: authHeader() })
            .then(res => res.data)
            .then(res => resolve(res))
            .catch(e => reject(e));
    });
};

const activityDetail = (id) => {
    let url = api().apiURL + `/activity/detail?id=${id}`;
    console.log(url);
    return new Promise((resolve, reject) => {
        axios.get(url, { headers: authHeader() })
            .then(res => res.data)
            .then(res => resolve(res))
            .catch(e => reject(e));
    });
};

const update = (id, data) => {
    let url = api().apiURL + `/pointSave/update?id=${id}`;
    console.log('수정',url);
    return new Promise((resolve, reject) => {
        axios.put(url, data, { headers: authHeader() })
            .then(res => res.data)
            .then(res => resolve(res))
            .catch(e => reject(e));
    });
};

const activityUpdate = (id, data) => {
    let url = api().apiURL + `/activity/update?id=${id}`;
    console.log('수정',url);
    return new Promise((resolve, reject) => {
        axios.put(url, data, { headers: authHeader() })
            .then(res => res.data)
            .then(res => resolve(res))
            .catch(e => reject(e));
    });
};

const register = (data) => {
    let url = api().apiURL + `/carbonPointHistory/carbon/register?carbonReduction=${data.kg}`;
    console.log(url,data);
    return new Promise((resolve, reject) => {
        axios.get(url, { headers: authHeader() })
            .then(res => res.data)
            .then(res => resolve(res))
            .catch(e => reject(e));
    });
};

const activityRegister = (data) => {
    let url = api().apiURL + `/activity/register`;
    console.log(url,data);
    return new Promise((resolve, reject) => {
        axios.post(url, data, { headers: authHeader() })
            .then(res => res.data)
            .then(res => resolve(res))
            .catch(e => reject(e));
    });
};

const remove = (id) => {
    let url = api().apiURL + `/carbonPointHistory/remove?id=${id}`;
    console.log(url);
    return new Promise((resolve, reject) => {
        axios.delete(url, { headers: authHeader() })
            .then(res => res.data)
            .then(res => resolve(res))
            .catch(e => reject(e));
    });
};

const activityRemove = (id) => {
    let url = api().apiURL + `/activity/remove?id=${id}`;
    console.log(url);
    return new Promise((resolve, reject) => {
        axios.delete(url, { headers: authHeader() })
            .then(res => res.data)
            .then(res => resolve(res))
            .catch(e => reject(e));
    });
};

const activityFileRegister = (id, data) => {
    console.log(id, data)
    let url = api().apiURL + `/activity/file/register?activityId=${id}`;
    console.log(url);
    return new Promise((resolve, reject) => {
        axios.post(url, data, { headers: authHeader() })
            .then(res => res.data)
            .then(res => resolve(res))
            .catch(e => reject(e));
    });
};

const livingRegister = (data) => {
    let url = api().apiURL + `/carbonLiving/register`;
    console.log(url,data);
    return new Promise((resolve, reject) => {
        axios.post(url, data, { headers: authHeader() })
            .then(res => res.data)
            .then(res => resolve(res))
            .catch(e => reject(e));
    });
};

const livingDetail = (id) => {
    let url = api().apiURL + `/carbonLiving/detail?id=${id}`;
    console.log(url);
    return new Promise((resolve, reject) => {
        axios.get(url, { headers: authHeader() })
            .then(res => res.data)
            .then(res => resolve(res))
            .catch(e => reject(e));
    });
};

const livingUpdate = (id, data) => {
    let url = api().apiURL + `/carbonLiving/update?id=${id}`;
    console.log('수정',url);
    return new Promise((resolve, reject) => {
        axios.put(url, data, { headers: authHeader() })
            .then(res => res.data)
            .then(res => resolve(res))
            .catch(e => reject(e));
    });
};

const livingRemove = (id) => {
    let url = api().apiURL + `/carbonLiving/remove?id=${id}`;
    console.log(url);
    return new Promise((resolve, reject) => {
        axios.delete(url, { headers: authHeader() })
            .then(res => res.data)
            .then(res => resolve(res))
            .catch(e => reject(e));
    });
};

const livingFileRegister = (id, data) => {
    console.log(id, data)
    let url = api().apiURL + `/carbonLiving/file/register?carbonLivingId=${id}`;
    console.log(url);
    return new Promise((resolve, reject) => {
        axios.post(url, data, { headers: authHeader() })
            .then(res => res.data)
            .then(res => resolve(res))
            .catch(e => reject(e));
    });
};

const livingFileRegister2 = (id, data) => {
    console.log(id, data)
    let url = api().apiURL + `/carbonLivingImage/register?carbonLivingId=${id}`;
    console.log(url);
    return new Promise((resolve, reject) => {
        axios.post(url, data, { headers: authHeader() })
            .then(res => res.data)
            .then(res => resolve(res))
            .catch(e => reject(e));
    });
};

const companyRegister = (data) => {
    let url = api().apiURL + `/companyIntroduction/register`;
    console.log(url,data);
    return new Promise((resolve, reject) => {
        axios.post(url, data, { headers: authHeader() })
            .then(res => res.data)
            .then(res => resolve(res))
            .catch(e => reject(e));
    });
};

const companyDetail = (id) => {
    let url = api().apiURL + `/companyIntroduction/detail?id=${id}`;
    console.log(url);
    return new Promise((resolve, reject) => {
        axios.get(url, { headers: authHeader() })
            .then(res => res.data)
            .then(res => resolve(res))
            .catch(e => reject(e));
    });
};

const companyUpdate = (id, data) => {
    let url = api().apiURL + `/companyIntroduction/update?id=${id}`;
    console.log('수정',url);
    return new Promise((resolve, reject) => {
        axios.put(url, data, { headers: authHeader() })
            .then(res => res.data)
            .then(res => resolve(res))
            .catch(e => reject(e));
    });
};

const companyRemove = (id) => {
    let url = api().apiURL + `/companyIntroduction/remove?id=${id}`;
    console.log(url);
    return new Promise((resolve, reject) => {
        axios.delete(url, { headers: authHeader() })
            .then(res => res.data)
            .then(res => resolve(res))
            .catch(e => reject(e));
    });
};

const companyFileRegister = (id, data) => {
    console.log(id, data)
    let url = api().apiURL + `/companyIntroduction/file/register?companyIntroductionId=${id}`;
    console.log(url);
    return new Promise((resolve, reject) => {
        axios.post(url, data, { headers: authHeader() })
            .then(res => res.data)
            .then(res => resolve(res))
            .catch(e => reject(e));
    });
};

const companyFileRegister2 = (id, data) => {
    console.log(id, data)
    let url = api().apiURL + `/companyIntroductionImage/register?companyIntroductionId=${id}`;
    console.log(url);
    return new Promise((resolve, reject) => {
        axios.post(url, data, { headers: authHeader() })
            .then(res => res.data)
            .then(res => resolve(res))
            .catch(e => reject(e));
    });
};

const carbonTabService = {
    activityList,
    activityCompanyList,
    pointSaveList,
    livingList,
    historyList,
    detail,
    activityDetail,
    update,
    activityUpdate,
    register,
    activityRegister,
    remove,
    activityRemove,
    activityFileRegister,

    livingRegister,
    livingDetail,
    livingUpdate,
    livingRemove,
    livingFileRegister,
    livingFileRegister2,

    companyRegister,
    companyDetail,
    companyUpdate,
    companyRemove,
    companyFileRegister,
    companyFileRegister2,
};

export default carbonTabService;
