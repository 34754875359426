import React, {useEffect, useState} from "react";
// reactstrap components
import {Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Input, Row,} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import shopService from "./shop.service";
import ReactDatetime from "react-datetime";
import {numberWithCommas} from "../../../utils";

function ShopCouponFrom({ match, history }) {
  const [loginId, setLoginId] = useState('');
  const data = history.location.state;

  const [inputs, setInputs] = useState({
    shopId: 0,
    point: '',
    name: '',
    count: '',
    endDate: ''
  });

  const { shopId, name, endDate, point, count } = inputs;

  useEffect(() => {
    if (data) {
      setInputs({
        shopId: data.shopId,
        point: data.point,
        name: data.name,
        count: data.count,
        endDate: data.endDate,
      });
      setLoginId(data.loginId);
    }
  }, []);

  const onChange = (e) => {
    let { value, name } = e.target;
    if (name === 'loginId') {
      setLoginId(value);
    } else if (name === 'count' || name === 'point') {
      const onlyNumber = value.replace(/[^0-9]/g, '')
      setInputs({
        ...inputs,
        [name]: numberWithCommas(onlyNumber)
      });
    } else {
      setInputs({
        ...inputs,
        [name]: value
      });
    }
  };

  const submit = async () => {
    if (!shopId || shopId < 1) {
        alert('아이디를 조회해주세요.');
        return;
      }

    if (name.length === 0) {
      alert('쿠폰명을 입력해주세요.');
      return;
    }

    if (point?.length === 0) {
      alert('쿠폰 할인 금액을 입력해주세요.');
      return;
    }

    if (count?.length === 0) {
      alert('발행 개수를 입력해주세요.');
      return;
    }

    if (endDate.length === 0) {
      alert('쿠폰 사용 마감 기간을 선택해주세요.');
      return;
    }

    const data = {
      shopId,
      name,
      endDate,
      point: Number(point.replace(/[^0-9]/g, '')),
      count: Number(count.replace(/[^0-9]/g, ''))
    };

    try {
      let res = await shopService.couponRegister(data);
      console.log(res);
      if (res.code === 200) {
        alert('등록되었습니다.');
        history.goBack();
      } else {
        alert(res.resultMsg);
      }
    } catch (e) {
      alert(e.response?.data?.resultMsg);
    }

  }

  const loginIdCheck = async () => {
    const data = await shopService.loginIdCheck({ loginId });
    if (data.code === 200 && data.user.shop) {
      alert('정상적으로 조회 되었습니다.');
      setInputs({
        ...inputs,
        shopId: data.user.shop.id
      })
    } else {
      alert('상점 유저가 아닙니다.');
    }
  }

  const handleReactDatetimeChange = (who, date) => {
    setInputs({
      ...inputs,
      endDate: date.format('YYYY-MM-DD')
    });
  };

  const remove = async () => {
    if (window.confirm('삭제 하시겠습니까?')) {
      let res = await shopService.couponRemove(data.id);
      console.log(res);
      if (res.code === 200) {
        history.goBack();
      }
    }
  }

  return (
    <>
      <SimpleHeader name="Form elements" parentName="Forms" />
      <Container className="mt--6" fluid>
        <Card className="mb-4">
          <CardHeader>
            <Row>
              <Col xs="11">
                <h3 className="mb-0">업체 쿠폰 상세</h3>
              </Col>
              {
                data ?
                  (
                    <Button
                    className="btn-round btn-icon"
                    color="danger"
                    onClick={remove}
                    size="sm"
                    >
                      <span className="btn-inner--text">삭제하기</span>
                    </Button>
                  ) :
                  <Button
                    className="btn-round btn-icon"
                    color="primary"
                    onClick={submit}
                    size="md"
                  >
                    <span className="btn-inner--text">등록하기</span>
                  </Button>
              }
            </Row>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="titleInput1"
                  >
                    아이디 <span className='text-red'>(필수)</span>
                  </label>
                  <div className="form-inline">
                    <Input
                      id="titleInput1"
                      readOnly={data}
                      placeholder="아이디"
                      name="loginId"
                      type="text"
                      value={loginId || ''}
                      onChange={onChange}
                    />
                    {!data && <Button
                      id="titleInput1"
                      className="ml-2"
                      color="primary"
                      type="button"
                      onClick={() => loginIdCheck()}
                    >
                      조회하기
                    </Button>}
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    쿠폰 할인 금액 <span className='text-red'>(필수)</span>
                    <p className="text-muted text-sm mb--2">탄소이음 차감 포인트 만큼 할인이 가능합니다.</p>
                  </label>
                  <Input
                    placeholder="할인해주실 금액을 숫자만 입력해주세요"
                    readOnly={data}
                    type="text"
                    name="point"
                    value={point || ''}
                    onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    쿠폰 명 <span className='text-red'>(필수)</span>
                  </label>
                  <Input
                    placeholder="ex) 탄소 포인트 할인 쿠폰"
                    readOnly={data}
                    type="text"
                    name="name"
                    value={name || ''}
                    onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    발행 개수 <span className='text-red'>(필수)</span>
                  </label>
                  <Input
                    placeholder="숫자만"
                    readOnly={data}
                    type="text"
                    name="count"
                    value={count || ''}
                    onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    쿠폰 사용 마감 기간 <span className='text-red'>(필수)</span>
                    <p className="text-muted text-sm mb--2">쿠폰을 발행하면 바로 게시가 됩니다. 마감 기간 내 상점에서 쿠폰이 발행됩니다.</p>
                  </label>
                  {
                    data ?
                      (
                        <Input
                          readOnly
                          type="text"
                          value={endDate || ''}
                        />
                      )
                      :
                      (
                        <ReactDatetime
                          inputProps={{
                            placeholder: "",
                            readOnly: true,
                            style: {
                              cursor: 'pointer'
                            },
                          }}
                          value={endDate}
                          locale={'ko'}
                          timeFormat={false}
                          dateFormat={'YYYY-MM-DD'}
                          onChange={(e) =>
                            handleReactDatetimeChange("startDate", e)
                          }
                          renderDay={(props, currentDate, selectedDate) => {
                            let classes = props.className;
                            // classes += getClassNameReactDatetimeDays(
                            //   currentDate
                            // );
                            return (
                              <td {...props} className={classes}>
                                {currentDate.date()}
                              </td>
                            );
                          }}
                        />
                      )
                  }
                </FormGroup>
              </Col>
            </Row>

          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default ShopCouponFrom;
