import React, {Fragment, useEffect, useState} from "react";
// reactstrap components
import {Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Input, Modal, Row,} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import {useHistory} from "react-router-dom";
import useHistoryState from "../../../common/useHistoryState";
import moment from "moment";
import InputFile from "../components/InputFile";
import {FormControl, InputGroup} from "react-bootstrap";
import DaumPostcode from "react-daum-postcode";
import qrCountService, {qrCountFilePreviewState, qrCountFileState} from "./service/qrCount.service";
import {useRecoilState, useRecoilValue, useResetRecoilState} from "recoil";
import advertisementService from "../advertisments/service/advertisement.service";

function QrCountForm({ match }) {
  const history = useHistory();
  const { id } = match.params;
  const [loading, setLoading] = useState(false);
  const [defaultModal, setDefaultModal] = useState(false);

  const file = useRecoilValue(qrCountFileState);
  const [previewThumbURL, setPreviewThumbURL] = useRecoilState(qrCountFilePreviewState);
  const resetFileState = useResetRecoilState(qrCountFileState);
  const resetFilePreview = useResetRecoilState(qrCountFilePreviewState);
  const [deleteId, setDeleteId] = useState('')
  const [data, setData] = useState({
    name: '',
    qrName: '',
    point: '',
    carbonReduction: '',
    active: '',
    address: '',
    addressDetail: '',
    latitude: '',
    longitude: '',
    images:[],
  });
  const {
    name,
    qrName,
    point,
    carbonReduction,
    active,
    address,
    addressDetail,
    latitude,
    longitude,
  } = data;
  console.log(deleteId)

  const reset = () => {
    resetFileState();
    resetFilePreview();
  }

  const onChange = (e) => {
    let { value, name } = e.target;
    if (name === ''
    ) {
      setData({
        ...data,
        [name]: Number(value.replace(/[^0-9]/g, '')),
      })
    } else {
      setData({
        ...data,
        [name]: value
      });
    }
  };

  const fetchData = async () => {
    const res = await qrCountService.detail(id);
    if (res.code === 200) {
      console.log(res);
      setData(res.data)
      setPreviewThumbURL(`https://d2twzv77g3c69u.cloudfront.net/fit-in/1000x700/${res.data?.images[0]?.name}`)
    }
  };

  useEffect(() => {
    if(id) {
      fetchData();
    } else {
      reset();
    }
  }, []);

  const submit = async () => {
    setLoading(true);
    try {
      await createOrUpdate();
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const createOrUpdate = async () => {
    // if (!title) {
    //     alert('제목을 입력해주세요.');
    //     return;
    // }
    // if (!content) {
    //     alert('내용을 입력해주세요.');
    //     return;
    // }

    const json = {
      ...data,
    }

    console.log('json',json);

    let res;
    try {
      if (id) {
        delete json.id
        delete json.createdAt
        delete json.images
        res = await qrCountService.update(id, json);
        if (deleteId) {
          await qrCountService.fileRemove(Number(deleteId));
        }
        if (file) {
          let form = new FormData();
          form.append('image', file);
          await qrCountService.fileRegister(id, form);
        }
      } else {
        delete json.active
        delete json.images
        res = await qrCountService.register(json);
        if (file) {
          let form = new FormData();
          form.append('image', file);
          await qrCountService.fileRegister(res.data.carbonSaveStoreId, form);
        }
      }
      if (res.code === 200) {
        alert(id ? '수정되었습니다.' : '등록되었습니다.');
        history.goBack();
        reset();
      }
    } catch (e) {
      // alert(JSON.stringify(e, null, 4));
      alert(e.response?.data?.message);
    }
    console.log('웹', res);
  };

  const remove = async () => {
    if (window.confirm('삭제하시겠습니까?')) {
      let res = await qrCountService.remove(id);
      console.log(res);
      if (res.code === 200) {
        alert('삭제되었습니다.')
        history.goBack();
      }
    }
  };

  const onChangeOpenPost = () => {
    setDefaultModal(true);
  };

  const onCompletePost = (input) => {
    let address = input.address;
    const geocoder = new window.daum.maps.services.Geocoder();
    geocoder.addressSearch(address, function (result, status) {
      // 정상적으로 검색이 완료됐으면
      if (status === window.daum.maps.services.Status.OK) {
        setData({
          ...data,
          address,
          longitude: result[0].x,
          latitude: result[0].y
        });
      }
    })
    setDefaultModal(false);
  };

  if (loading) return <div>로딩중..</div>;
  return (
    <>
      {defaultModal  ? (
          <Fragment>
            <Modal
                className="modal-dialog-centered"
                isOpen={defaultModal}
                toggle={() => setDefaultModal(() => false)}
                fade={false}
            >
              <DaumPostcode autoClose onComplete={onCompletePost} className="post-code" />
            </Modal>

          </Fragment>
      ) : null}
      <SimpleHeader name="Form elements" parentName="Forms" />
      <Container className="mt--6" fluid>
        <Card className="mb-4">
          <CardHeader style={{ display: 'flex', justifyContent: 'space-between' }}>
            {id?
                <>
                  <h3 className="mt-2">탄소 적립점 등록,QR관리 상세</h3>
                  <div>
                    <Button
                      color="default"
                      type="button"
                      onClick={submit}
                    >
                      수정하기
                    </Button>
                    <Button
                      color="danger"
                      type="button"
                      onClick={remove}
                    >
                      삭제하기
                    </Button>
                  </div>
                </>
                :
                <>
                  <h3 className="mt-2">탄소 적립점 등록,QR관리 등록</h3>
                  <div>
                    <Button
                        color="default"
                        type="button"
                        onClick={submit}
                    >
                      <span className="btn-inner--icon mr-1">
                          <i className="fas fa-user-edit" />
                      </span>
                      등록하기
                    </Button>
                  </div>
                </>
            }
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    지자체명/기관명
                  </label>
                  <Input
                      placeholder="지자체명/기관명을 입력해주세요."
                      type="text"
                      name="name"
                      value={name || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    QR생성명
                  </label>
                  <Input
                      placeholder="QR생성명을 입력해주세요."
                      type="text"
                      name="qrName"
                      value={qrName || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    적립포인트
                  </label>
                  <Input
                      placeholder="적립포인트를 입력해주세요."
                      type="text"
                      name="point"
                      value={point || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    탄소저감량적립
                  </label>
                  <Input
                      placeholder="탄소저감량적립을 입력해주세요."
                      type="text"
                      name="carbonReduction"
                      value={carbonReduction || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    주소 검색
                  </label>
                  <InputGroup className="mb-3">
                    <FormControl
                        placeholder="주소 입력"
                        aria-label="주소 입력"
                        aria-describedby="basic-addon2"
                        // name="address"
                        value={address || ''}
                        // onChange={onChange}
                        readOnly
                    />
                    <Button
                        className="ml-2"
                        variant="outline-secondary"
                        id="button-addon2"
                        color="primary"
                        onClick={() => onChangeOpenPost()}
                    >
                      주소 찾기
                    </Button>
                  </InputGroup>
                  <Input
                      placeholder="세부 주소 입력 ex) OO역 5번출구 도보 4분"
                      type="text"
                      name="addressDetail"
                      value={addressDetail || ''}
                      onChange={onChange}
                  />
                  <Input
                      className="mt-3"
                      placeholder=""
                      type="text"
                      name="latitude"
                      value={latitude || ''}
                      onChange={onChange}
                  />
                  <Input
                      className="mt-3"
                      placeholder=""
                      type="text"
                      name="longitude"
                      value={longitude || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="mt-4">
                <InputFile
                    title={'생성 QR'}
                    fileState={qrCountFileState}
                    filePreviewState={qrCountFilePreviewState}
                />
              </Col>
            </Row>
            <Row>
              {/*<Col className="" md="auto">*/}
                <Button
                    size="sm"
                    className=""
                    color="danger"
                    onClick={() => {
                      setPreviewThumbURL('')
                      setDeleteId(data.images[0].id)
                    }}
                >
                  삭제
                </Button>
              {/*</Col>*/}
            </Row>
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default QrCountForm;
