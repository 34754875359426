import React, {useEffect, useRef, useState, Fragment} from "react";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import {useHistory} from "react-router-dom";
import qnaService from "./service/qna.service";
import challengeService from "../carbon/service/challenge.service";
import useHistoryState from "../../../common/useHistoryState";

function QnaForm({ match }) {
  const history = useHistory();
  const { id } = match.params;
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [english, setEnglish] = useState(false);
  const [categoryList, setCategoryList] = useHistoryState([], 'categoryList');
  const [categoryId, setCategoryId] = useState(0);

  const renderCategory = async () => {
    let option = { };
    const res = await qnaService.categoryList(option);
    if (res.code === 200) {
      console.log(res);
      setCategoryList(res.list);
    }
  };

  useEffect(() => {
    renderCategory();
  }, []);

  const fetchData = async () => {
    const res = await qnaService.detail(id);
    if (res.code === 200) {
      console.log(res);
      setCategoryId(res.data.categoryId);
      setTitle(res.data.title);
      setContent(res.data.content);
    }
  };

  useEffect(() => {
      fetchData();
  }, []);

  const submit = async () => {
    if (!title) {
        alert('제목을 입력해주세요.');
        return;
    }
    if (!content) {
        alert('내용을 입력해주세요.');
        return;
    }

    const json = {
      title,
      content,
      qnaCategoryId: Number(categoryId)
    }


    let res;
    try {
      if (id) {
        res = await qnaService.update(id, json);
      } else {
        res = await qnaService.register(json);
      }
      if (res.code === 200) {
        alert(id ? '수정되었습니다.' : '등록되었습니다.');
        history.goBack();
      }
    } catch (e) {
      // alert(JSON.stringify(e, null, 4));
      alert(e.response?.data?.message);
    }
    console.log('웹', res);
  };

  const remove = async () => {
    if (window.confirm('삭제하시겠습니까?')) {
      let res = await qnaService.remove(id);
      console.log(res);
      if (res.code === 200) {
        alert('삭제되었습니다.')
        history.goBack();
      }
    }
  };

  if (loading) return <div>로딩중..</div>;
  return (
    <>
      <SimpleHeader name="Form elements" parentName="Forms" />
      <Container className="mt--6" fluid>
        <Card className="mb-4">
          <CardHeader style={{ display: 'flex', justifyContent: 'space-between' }}>
            {id?
                <>
                  <h3 className="mt-2">공지사항 상세</h3>
                  <div>
                    <Button
                      color="default"
                      type="button"
                      onClick={submit}
                    >
                      수정하기
                    </Button>
                    <Button
                      color="danger"
                      type="button"
                      onClick={remove}
                    >
                      삭제하기
                    </Button>
                  </div>
                </>
                :
                <>
                  <h3 className="mt-2">공지사항 등록</h3>
                  <div>
                    <Button
                        color="default"
                        type="button"
                        onClick={submit}
                    >
                      <span className="btn-inner--icon mr-1">
                          <i classNam="fas fa-user-edit" />
                      </span>
                      등록하기
                    </Button>
                  </div>
                </>
            }
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="6">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="check1"
                  >
                    카테고리
                  </label>
                  <Input
                      id="exampleFormControlSelect13"
                      type="select"
                      value={categoryId}
                      onChange={(e) => setCategoryId(Number(e.target.value))}
                  >
                    <option>전체</option>
                    {categoryList.map((v, i) => {
                      return [
                        <option key={i} value={v.id}>{v.name}</option>
                      ]
                    })}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    제목
                  </label>
                  <Input
                      placeholder="제목을 입력해주세요."
                      type="text"
                      name="title"
                      value={title || ''}
                      onChange={(e) => setTitle(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    내용
                  </label>
                  <Input
                      placeholder="내용을 입력해주세요."
                      type="textarea"
                      rows="18"
                      name="content"
                      value={content || ''}
                      onChange={(e) => setContent(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default QnaForm;
