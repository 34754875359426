import React, {Fragment, useEffect, useState} from "react";

// reactstrap components
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import CounselingService from "../../../services/counseling.service";
import PaginationWrapper from "../components/pagination";
import {paginate} from "../../../utils";
import useHistoryState from "../../../common/useHistoryState";
import classnames from "classnames";
import ReactDatetime from "react-datetime";
import moment from "moment";
import {Link} from "react-router-dom";
import volunteerService from "./service/volunteer.service";
import {useHistory} from "react-router";
import AuthService from "../auth/auth.service";
import challengeService from "../carbon/service/challenge.service";

function VolunteerList() {
  const history = useHistory();
  const user = typeof window !== 'undefined' ? AuthService.getCurrentUser() : null;
  const center = JSON.parse(localStorage.getItem('user'))?.centerId;
  const [pageActive, setPageActive] = useHistoryState(1, 'pageActive');
  const [pageCount, setPageCount] = useHistoryState(1, 'pageCount');
  const [data, setData] = useHistoryState([], 'data');
  const [category, setCategory] = useHistoryState('전체', 'category');
  const [startDate, setStartDate] = useHistoryState(moment().add(-2, "month").format('YYYY-MM-DD'), 'startDate');
  const [endDate, setEndDate] = useHistoryState(moment().format('YYYY-MM-DD'), 'endDate');
  const [searchCurrent, setSearchCurrent] = useHistoryState(false, 'searchCurrent');
  const [keyword, setKeyword] = useHistoryState("", 'keyword');
  const [isSearch, setIsSearch] = useState(false);

  const render = async () => {
    const option = {
      page: pageActive, startDate, endDate
    };
    if (category !== '전체') {
      option.category = category;
    }
    if (user.role === 'center') {
      // https://api.foav.co.kr/admin/service?keyword=&page=1&startDate=2023-10-01&endDate=2023-10-27
      option.keyword = '';
      option.centerId = center;
    }
    if (keyword) {
      option.keyword = keyword;
    }
    const res = await volunteerService.list(option);
    if (res.code === 200) {
      console.log(res);
      setPageCount(res.count);
      setData(res.zones);
    }
  }


      useEffect(() => {
    render();
  }, [isSearch, pageActive]);

  const handleReactDatetimeChange = (who, date) => {
    if (
      startDate &&
      who === "endDate" &&
      new Date(startDate) > new Date(date.format('YYYY-MM-DD'))
    ) {
      setStartDate(date.format('YYYY-MM-DD'));
      setEndDate(date.format('YYYY-MM-DD'));
    } else if (
      endDate &&
      who === "startDate" &&
      new Date(endDate) < new Date(date.format('YYYY-MM-DD'))
    ) {
      setStartDate(date.format('YYYY-MM-DD'));
      setEndDate(date.format('YYYY-MM-DD'));
    } else {
      if (who === "startDate") {
        setStartDate(date.format('YYYY-MM-DD'));
      } else {
        setEndDate(date.format('YYYY-MM-DD'));
      }
    }
  };

  const getClassNameReactDatetimeDays = (date) => {
    if (startDate && endDate) {
    }
    if (startDate && endDate && startDate !== endDate) {
      if (
        new Date(endDate) > new Date(date.format('YYYY-MM-DD')) &&
        new Date(startDate) < new Date(date.format('YYYY-MM-DD'))
      ) {
        return " middle-date";
      }
      if (endDate === date.format('YYYY-MM-DD')) {
        return " end-date";
      }
      if (startDate === date.format('YYYY-MM-DD')) {
        return " start-date";
      }
    }
    return "";
  };

  const statusChange = async (id, check) => {
    await volunteerService.update(id, {check});
    render();
  };

  const remove = async (id) => {
    if (window.confirm('삭제하시겠습니까?')) {
      let res = await volunteerService.removeZone(id);
      console.log(res);
      if (res.code === 200) {
        render();
      }
    }
  };

  const down = () => {
    volunteerService.excel({ page: pageActive, startDate, endDate, diff: 'download' })
        .then((res) => {
          fileCreate(res);
        });
  };

  const fileCreate = (res) => {
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');
    const contentDisposition = res.headers['content-disposition']; // 파일 이름
    let fileName = 'report';
    if (contentDisposition) {
      const [ fileNameMatch ] = contentDisposition.split(';').filter(str => str.includes('filename'));
      if (fileNameMatch)
        [ , fileName ] = fileNameMatch.split('=');
    }
    link.href = url;
    link.setAttribute('download', `${fileName}.xlsx`);
    link.style.cssText = 'display:none';
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  return (
    <>
      <SimpleHeader name="Tables" parentName="Tables" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row className="py-3">
                  <Col xs="11">
                    <Form>
                      <Row>
                        <Col xs="3">
                          <label className=" form-control-label py-2">
                          </label>
                          <FormGroup>
                            <InputGroup
                              className={classnames("input-group-merge", {
                                focused: searchCurrent,
                              })}
                            >
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="fas fa-search" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="센터명, 담당명칭, 봉사활동 명으로 검색"
                                type="text"
                                value={keyword}
                                onChange={(e) => setKeyword(e.target.value)}
                                onFocus={(e) => setSearchCurrent(true)}
                                onBlur={(e) => setSearchCurrent(false)}
                              />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col xs="2">
                          <label className=" form-control-label">
                            날짜 조회
                          </label>
                          <FormGroup>
                            <ReactDatetime
                              inputProps={{
                                placeholder: "선택해주세요.",
                              }}
                              value={startDate}
                              locale={'ko'}
                              timeFormat={false}
                              dateFormat={'YYYY-MM-DD'}
                              onChange={(e) =>
                                handleReactDatetimeChange("startDate", e)
                              }
                              renderDay={(props, currentDate, selectedDate) => {
                                let classes = props.className;
                                classes += getClassNameReactDatetimeDays(
                                  currentDate
                                );
                                return (
                                  <td {...props} className={classes}>
                                    {currentDate.date()}
                                  </td>
                                );
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs="2">
                          <FormGroup>
                            <label className=" form-control-label py-2">
                            </label>
                            <ReactDatetime
                              inputProps={{
                                placeholder: "선택해주세요.",
                              }}
                              value={endDate}
                              locale={'ko'}
                              timeFormat={false}
                              dateFormat={'YYYY-MM-DD'}
                              onChange={(e) =>
                                handleReactDatetimeChange("endDate", e)
                              }
                              renderDay={(props, currentDate, selectedDate) => {
                                let classes = props.className;
                                classes += getClassNameReactDatetimeDays(
                                  currentDate
                                );
                                return (
                                  <td {...props} className={classes}>
                                    {currentDate.date()}
                                  </td>
                                );
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
                <Row>
                  <Col className="text-center">
                    <Button
                      color="default"
                      size="m"
                      onClick={(e) => {
                        setIsSearch(!isSearch)
                      }}
                    >
                      검색
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">봉사 관리</h3>
                  </Col>
                  <Col className="text-right">
                    <Button color="primary" onClick={(e) => down()}>
                      엑셀 다운로드
                    </Button>
                  </Col>
                </Row>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive striped>
                <thead className="thead-light">
                <tr>
                  <th className="text-center">봉사 날짜</th>
                  <th className="text-center">센터명</th>
                  <th className="text-center">담당명칭</th>
                  <th className="text-center">봉사활동 명</th>
                  <th className="text-center">구역생성주소</th>
                  <th className="text-center">총 생성 시간</th>
                  <th className="text-center">총 인원수</th>
                  <th className="text-center">관리</th>
                  <th className="text-center">봉사센터 체크</th>
                </tr>
                </thead>
                <tbody>
                {data?.map((item, i) => {
                  return [
                    <Fragment key={i}>
                      <tr style={item.centerCompanyId? {backgroundColor: '#fff9e4'}:{}}>
                        <td className="text-center">
                          {moment(item.createdAt).format('YYYY-MM-DD')}
                        </td>
                        <td className="text-center">
                          {item.centerName}
                        </td>
                        <td className="text-center">
                          {item.groupName}
                        </td>
                        <td className="text-center">
                          {item.zoneName}
                        </td>
                        <td className="text-center">
                          {item.address? item.address:'-'}
                        </td>
                        <td className="text-center">
                          {item.totalServiceTime}
                        </td>
                        <td className="text-center">
                          {item.totalPersonnel}명
                        </td>
                        <td className="text-center">
                          <Button
                              className="btn-outline-danger"
                              color="danger"
                              onClick={() => remove(item.id)}
                              size="sm"
                          >
                            <span className="btn-inner--text">삭제</span>
                          </Button>
                          <Button
                              className="btn-outline-default"
                              color="danger"
                              onClick={() => history.push('/admin/volunteerDetail/' + item.id)}
                              size="sm"
                          >
                            <span className="btn-inner--text">상세</span>
                          </Button>
                        </td>
                        <td className="text-center">
                          <label className="custom-toggle custom-toggle-success center">
                            <input
                                checked={item.check}
                                type="checkbox"
                                onChange={(e) => statusChange(item.id, !item.check)}
                            />
                            <span
                                className="custom-toggle-slider rounded-circle"
                                data-label-off="No"
                                data-label-on="Yes"
                            />
                          </label>
                        </td>
                      </tr>
                    </Fragment>
                  ];
                })}
                </tbody>
              </Table>

              <CardFooter className="py-4">
                <PaginationWrapper
                  isActive={pageActive}
                  pageNumber={pageCount}
                  callback={(page) => setPageActive(page)}
                  itemPerPage={10}
                />
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default VolunteerList;
