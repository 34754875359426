import axios from "axios";
import api from "../../../config/api.routes";
import authHeader from "../../../services/auth-header";


const list = (data) => {
  let url = api().apiURL + `/shop/admin/list?`;
  for (let query of Object.keys(data)) {
    if (data[query] === undefined || data[query] === '') {
      continue;
    }
    url += `${query}=${data[query]}&`;
  }
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.get(url, { headers: authHeader() })
      .then(res => res.data)
      .then(res => resolve(res))
      .catch(e => reject(e));
  });
};

const couponList = (data) => {
  let url = api().apiURL + `/coupon/admin/list?`;
  for (let query of Object.keys(data)) {
    if (data[query] === undefined || data[query] === '') {
      continue;
    }
    url += `${query}=${data[query]}&`;
  }
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.get(url, { headers: authHeader() })
      .then(res => res.data)
      .then(res => resolve(res))
      .catch(e => reject(e));
  });
};

const reviewList = (data) => {
  let url = api().apiURL + `/shopReview/admin/list?`;
  for (let query of Object.keys(data)) {
    if (data[query] === undefined || data[query] === '') {
      continue;
    }
    url += `${query}=${data[query]}&`;
  }
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.get(url, { headers: authHeader() })
      .then(res => res.data)
      .then(res => resolve(res))
      .catch(e => reject(e));
  });
};

const update = (id, data) => {
  let url = api().apiURL + `/shop/update?id=${id}`;
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.put(url, data, { headers: authHeader() })
      .then(res => res.data)
      .then(res => resolve(res))
      .catch(e => reject(e));
  });
};

const couponUpdate = (id, data) => {
  let url = api().apiURL + `/coupon/update?id=${id}`;
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.put(url, data, { headers: authHeader() })
      .then(res => res.data)
      .then(res => resolve(res))
      .catch(e => reject(e));
  });
};

const loginIdCheck = (data) => {
  let url = api().apiURL + `/admin/user/detail?`;
  for (let query of Object.keys(data)) {
    if (data[query] === undefined || data[query] === '') {
      continue;
    }
    url += `${query}=${data[query]}&`;
  }
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.get(url, { headers: authHeader() })
      .then(res => res.data)
      .then(res => resolve(res))
      .catch(e => reject(e));
  });
};

const register = (data) => {
  let url = api().apiURL + `/shop/register`;
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.post(url, data, { headers: authHeader() })
      .then(res => res.data)
      .then(res => resolve(res))
      .catch(e => reject(e));
  });
};

const couponRegister = (data) => {
  let url = api().apiURL + `/coupon/register`;
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.post(url, data, { headers: authHeader() })
      .then(res => res.data)
      .then(res => resolve(res))
      .catch(e => reject(e));
  });
};

const couponDetail = (id) => {
  let url = api().apiURL + `/coupon/detail?id=${id}`;
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.get(url, { headers: authHeader() })
      .then(res => res.data)
      .then(res => resolve(res))
      .catch(e => reject(e));
  });
};

const fileRegister = (id, data) => {
  let url = api().apiURL + `/shop/file/register?shopId=${id}`;
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.post(url, data, { headers: authHeader() })
      .then(res => res.data)
      .then(res => resolve(res))
      .catch(e => reject(e));
  });
};

const filesRegister = (id, data) => {
  let url = api().apiURL + `/shopImage/register?shopId=${id}`;
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.post(url, data, { headers: authHeader() })
      .then(res => res.data)
      .then(res => resolve(res))
      .catch(e => reject(e));
  });
};


const filesRemove = (id) => {
  let url = api().apiURL + `/shopImage/remove?id=${id}`;
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.delete(url, { headers: authHeader() })
      .then(res => res.data)
      .then(res => resolve(res))
      .catch(e => reject(e));
  });
}

const detail = (id) => {
  let url = api().apiURL + `/shop/detail?id=${id}`;
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.get(url, { headers: authHeader() })
      .then(res => res.data)
      .then(res => resolve(res))
      .catch(e => reject(e));
  });
};

const reviewDetail = (id) => {
  let url = api().apiURL + `/shopReview/detail?id=${id}`;
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.get(url, { headers: authHeader() })
      .then(res => res.data)
      .then(res => resolve(res))
      .catch(e => reject(e));
  });
};

const remove = (id) => {
  let url = api().apiURL + `/shop/remove?id=${id}`;
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.delete(url, { headers: authHeader() })
      .then(res => res.data)
      .then(res => resolve(res))
      .catch(e => reject(e));
  });
};

const couponRemove = (id) => {
  let url = api().apiURL + `/coupon/remove?id=${id}`;
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.delete(url, { headers: authHeader() })
      .then(res => res.data)
      .then(res => resolve(res))
      .catch(e => reject(e));
  });
};


const shopService = {
  list,
  update,
  couponList,
  couponUpdate,
  reviewList,
  loginIdCheck,
  register,
  fileRegister,
  filesRegister,
  filesRemove,
  detail,
  couponRegister,
  couponDetail,
  reviewDetail,
  remove,
  couponRemove
}

export default shopService;
