import axios from "axios";
import authHeader from "./auth-header";

import api from '../config/api.routes';

const list = (data) => {
  let url = api().PUSH_LIST + `?`;
  for (let query of Object.keys(data)) {
    url += `${query}=${data[query]}&`;
  }
  console.log(url);
  return axios.get(url, { headers: authHeader() });
};

const send = (message) => {
  return axios.get(api().PUSH_SEND + `?message=${message}`, { headers: authHeader() });
};

const pushService = {
  list,
  send
}

export default pushService;
