import React, {useEffect, useRef, useState, Fragment} from "react";
// reactstrap components
import {
  Button,
  Card,
  CardBody, CardFooter,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Modal,
  Row, Table,
  UncontrolledTooltip,
} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import {useHistory} from "react-router-dom";

import useHistoryState from "../../../common/useHistoryState";
import moment from "moment";
import PaginationWrapper from "../components/pagination";
import ReactDatetime from "react-datetime";
import InputFile from "../components/InputFile";
import {useRecoilState, useRecoilValue, useResetRecoilState} from "recoil";
import charityService from "../charity/service/charity.service";
import allianceService, {
  allianceDetailFilePreviewState,
  allianceDetailFileState,
  allianceThumbFilePreviewState,
  allianceThumbFileState
} from "./service/alliance.service";

function AllianceForm({ match }) {
  const history = useHistory();
  const { id } = match.params;
  const [startDate, setStartDate] = useHistoryState(moment().format('YYYY-MM-DD'), 'startDate');
  const [endDate, setEndDate] = useHistoryState(moment().add(1, 'month').format('YYYY-MM-DD'), 'endDate');
  const [pageActive, setPageActive] = useHistoryState(1, 'pageActive');
  const [pageCount, setPageCount] = useHistoryState(1, 'pageCount');
  const [loading, setLoading] = useState(false);
  const [list, setList] = useHistoryState([], 'list');
  const [image, setImage] = useHistoryState([], 'image');
  const thumbFile = useRecoilValue(allianceThumbFileState);
  const [previewThumbURL, setPreviewThumbURL] = useRecoilState(allianceThumbFilePreviewState);
  const detailFile = useRecoilValue(allianceDetailFileState);
  const [previewThumbURL2, setPreviewThumbURL2] = useRecoilState(allianceDetailFilePreviewState);
  const resetThumbFile = useResetRecoilState(allianceThumbFileState);
  const resetDetailFilePreview = useResetRecoilState(allianceThumbFilePreviewState);
  const resetThumbFile2 = useResetRecoilState(allianceDetailFileState);
  const resetDetailFilePreview2 = useResetRecoilState(allianceDetailFilePreviewState);
  const [data, setData] = useState({
    sortCode: '',
    content: '',
    title: '',
  });
  const {
    sortCode,
    content,
    title,
  } = data;

  const reset = () => {
    resetThumbFile();
    resetThumbFile2();
    resetDetailFilePreview()
    resetDetailFilePreview2()
  }

  const onChange = (e) => {
    let { value, name } = e.target;
    if (name === 'sortCode'
    ) {
      setData({
        ...data,
        [name]: Number(value.replace(/[^0-9]/g, '')),
      })
    } else {
      setData({
        ...data,
        [name]: value
      });
    }
  };

  const submit = async () => {
    setLoading(true);
    try {
      await createOrUpdate();
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }

  const createOrUpdate = async () => {
    if (!title) {
        alert('제목을 입력해주세요.');
        return;
    }
    if (!content) {
        alert('내용을 입력해주세요.');
        return;
    }

    const json = {
      ...data,
    }

    console.log('json',json);

    let res;
    try {
      if (id) {
        res = await allianceService.update(id, json);
      } else {
        res = await allianceService.register(json);
      }
      if (res.resultMsg === "success") {
        if (thumbFile) {
          let form = new FormData();
          console.log('file', thumbFile, typeof thumbFile);
          form.append('thumb', thumbFile);
          await allianceService.fileRegister(res.partnership.id, form, 'thumb');
        }
        if (detailFile) {
          let form = new FormData();
          console.log('file', detailFile, typeof detailFile);
          form.append('img', detailFile);
          await allianceService.fileRegister(res.partnership.id, form, 'img');
        }
        alert(id ? '수정되었습니다.' : '등록되었습니다.');
        history.goBack();
        reset();
      }
    } catch (e) {
      // alert(JSON.stringify(e, null, 4));
      alert(e.response?.data?.message);
    }
    console.log('웹', res);
  };

  const fetchData = async () => {
    const res = await allianceService.detail(id);
    if (res.code === 200) {
      console.log(res);
      setData(res.partnership)
      setStartDate(res.partnership.startDate)
      setEndDate(res.partnership.endDate)
      if(res.partnership.thumbnail) {
        setPreviewThumbURL(res.partnership.thumbnail)
      }
      if(res.partnership.image) {
        setPreviewThumbURL2(res.partnership.image)
      }
    }
  };

  useEffect(() => {
    if(id) {
      fetchData();
    } else {
      reset();
    }
  }, []);

  const remove = async () => {
    if (window.confirm('삭제하시겠습니까?')) {
      let res = await allianceService.remove(id);
      console.log(res);
      if (res.code === 200) {
        alert('삭제되었습니다.')
        history.goBack();
      }
    }
  };

  if (loading) return <div>로딩중..</div>;
  return (
    <>
      <SimpleHeader name="Form elements" parentName="Forms" />
      <Container className="mt--6" fluid>
        <Card className="mb-4">
          <CardHeader style={{ display: 'flex', justifyContent: 'space-between' }}>
            {id?
                <>
                  <h3 className="mt-2">법인 제휴 상세</h3>
                  <div>
                    <Button
                      color="default"
                      type="button"
                      onClick={submit}
                    >
                      수정하기
                    </Button>
                    <Button
                      color="danger"
                      type="button"
                      onClick={remove}
                    >
                      삭제하기
                    </Button>
                  </div>
                </>
                :
                <>
                  <h3 className="mt-2">법인 제휴 등록</h3>
                  <div>
                    <Button
                        color="default"
                        type="button"
                        onClick={submit}
                    >
                      <span className="btn-inner--icon mr-1">
                          <i className="fas fa-user-edit" />
                      </span>
                      등록하기
                    </Button>
                  </div>
                </>
            }
          </CardHeader>
          <CardBody>
            <Row>
              <Col>
                <InputFile
                    title={'메인 썸내일 첨부 (최소) 435 x 435px'}
                    fileState={allianceThumbFileState}
                    filePreviewState={allianceThumbFilePreviewState}
                />
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    우선순위
                  </label>
                  <Input
                      placeholder="우선순위를 입력해주세요."
                      type="text"
                      name="sortCode"
                      value={sortCode || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    제목
                  </label>
                  <Input
                      placeholder="제목을 입력해주세요."
                      type="text"
                      name="title"
                      value={title || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    내용
                  </label>
                  <Input
                      placeholder="내용을 입력해주세요."
                      type="textarea"
                      rows="10"
                      name="content"
                      value={content || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <InputFile
                    title={'상세 이미지 첨부 (600 x 600px)'}
                    fileState={allianceDetailFileState}
                    filePreviewState={allianceDetailFilePreviewState}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default AllianceForm;
