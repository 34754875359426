/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Alternative from "views/pages/dashboards/Alternative.js";
import Buttons from "views/pages/components/Buttons.js";
import Calendar from "views/pages/Calendar.js";
import Cards from "views/pages/components/Cards.js";
import Charts from "views/pages/Charts.js";
import Components from "views/pages/forms/Components.js";
import Dashboard from "views/pages/dashboards/Dashboard.js";
import Elements from "views/pages/forms/Elements.js";
import Grid from "views/pages/components/Grid.js";
import Icons from "views/pages/components/Icons.js";
import Lock from "views/pages/examples/Lock.js";
import Login from "views/pages/auth/Login.js";
import Notifications from "views/pages/components/Notifications.js";
import Pricing from "views/pages/examples/Pricing.js";
import Profile from "views/pages/examples/Profile.js";
import ReactBSTables from "views/pages/tables/ReactBSTables.js";
import Register from "views/pages/examples/Register.js";
import RTLSupport from "views/pages/examples/RTLSupport.js";
import Sortable from "views/pages/tables/Sortable.js";
import Tables from "views/pages/tables/Tables.js";
import Timeline from "views/pages/examples/Timeline.js";
import Typography from "views/pages/components/Typography.js";
import Validation from "views/pages/forms/Validation.js";
import Widgets from "views/pages/Widgets.js";
import ChallengeMemDetail from "./views/pages/carbon/challengeMemDetail";
import CompanyDetail from "./views/pages/companyCenter/companyDetail";
import GroupForm from "./views/pages/companyCenter/groupForm";
import VolunteerList from "./views/pages/volunteer/volunteerList";
import VolunteerForm from "./views/pages/volunteer/volunteerForm";
import PloggingList from "./views/pages/plogging/ploggingList";
import PloggingCompany from "./views/pages/plogging/ploggingCompany";
import PloggingCenter from "./views/pages/plogging/ploggingCenter";
import TogetherChallenge from "./views/pages/togetherChallenge/togetherChallenge";
import SharedPloggingCalcMgmt
  from "./views/pages/plogging/sharedPloggingCalcMgmt";
import SharedPloggingCalcMgmtPloggingList
  from "./views/pages/plogging/sharedPloggingCalcMgmtPloggingList";

const company = JSON.parse(localStorage.getItem('user'))

let centerRoutes = [
  {
    path: "/dashboard",
    name: "홈",
    icon: "ni ni-shop mt--1",
    layout: "/admin",
    component: Dashboard,
  },
  {
    path: "/login",
    redirect: true,
    component: Login,
    layout: "/auth",
  },
  {
    path: "/companyCenter/detail/:id",
    name: "봉사센터 관리",
    icon: "ni ni-send text-orange mt--1",
    component: CompanyDetail,
    layout: "/admin",
  },
  {
    path: "/companyCenter/groupDetail/:id/:ids",
    layout: "/admin",
    redirect: true,
    component: GroupForm,
  },
  {
    path: "/companyCenter/groupForm/:id",
    layout: "/admin",
    redirect: true,
    component: GroupForm,
  },
  {
    path: "/volunteerList",
    name: "QR 봉사 관리",
    icon: "ni ni-send text-purple mt--1",
    layout: "/admin",
    component: VolunteerList,
  },
  {
    path: "/sharedPloggingCalcMgmt/:centerId",
    name: "봉사 플로깅 관리",
    icon: "ni ni-send text-green mt--1",
    layout: "/admin",
    component: SharedPloggingCalcMgmt,
  },
  {
    path: "/sharedPloggingCalcMgmtPlogging/:sharingPloggingId/:centerName",
    layout: "/admin",
    redirect: true,
    component: SharedPloggingCalcMgmtPloggingList,
  },
  {
    path: "/volunteerDetail/:id",
    layout: "/admin",
    redirect: true,
    component: VolunteerForm,
  },
  {
    path: "/ploggingList/:centerId/:companyId",
    name: "인증 플로깅 개별 리스트",
    icon: "ni ni-send text-green mt--1",
    layout: "/admin",
    component: PloggingList,
  },
  {
    path: "/challengeTogether",
    name: "함께하는 챌린지 관리",
    icon: "ni ni-send text-yellow mt--1",
    component: TogetherChallenge,
    layout: "/admin",
  },
  // {
  //   path: "/ploggingCompany",
  //   name: "인증 플로깅 기업전달 리스트",
  //   miniName: "플",
  //   layout: "/admin",
  //   component: PloggingCompany,
  // },
  // {
  //   path: "/ploggingCenter/detail/:id/:name",
  //   layout: "/admin",
  //   redirect: true,
  //   component: PloggingCenterDetail,
  // },
  // {
  //   path: "/ploggingCenter",
  //   name: "인증 플로깅 센터전달 리스트",
  //   icon: "ni ni-send text-yellow mt--1",
  //   layout: "/admin",
  //   component: PloggingCenter,
  // },
];

// if (company.isPlogging) {
//   centerRoutes = [
//     ...centerRoutes,
//     {
//       path: "/ploggingList/:centerId/:companyId",
//       name: "인증 플로깅 개별 리스트",
//       miniName: "플",
//       layout: "/admin",
//       component: EcoFriendsHistoryList,
//     },
//     // {
//     //   path: "/ploggingCompany",
//     //   name: "인증 플로깅 기업전달 리스트",
//     //   miniName: "플",
//     //   layout: "/admin",
//     //   component: PloggingCompany,
//     // },
//     // {
//     //   path: "/ploggingCenter/detail/:id/:name",
//     //   layout: "/admin",
//     //   redirect: true,
//     //   component: PloggingCenterDetail,
//     // },
//     {
//       path: "/ploggingCenter",
//       name: "인증 플로깅 센터전달 리스트",
//       miniName: "플",
//       layout: "/admin",
//       component: PloggingCenter,
//     },
//   ]
// }

if (process.env.NODE_ENV === 'development') {
  centerRoutes = [
    ...centerRoutes,
    {
      collapse: true,
      name: "Dashboards",
      icon: "ni ni-shop text-primary",
      state: "dashboardsCollapse",
      views: [
        {
          path: "/dashboard",
          name: "Dashboard",
          miniName: "D",
          component: Dashboard,
          layout: "/admin",
        },
        {
          path: "/alternative-dashboard",
          name: "Alternative",
          miniName: "A",
          component: Alternative,
          layout: "/admin",
        },
      ],
    },
    {
      collapse: true,
      name: "Examples",
      icon: "ni ni-ungroup text-orange",
      state: "examplesCollapse",
      views: [
        {
          path: "/pricing",
          name: "Pricing",
          miniName: "P",
          component: Pricing,
          layout: "/auth",
        },
        {
          path: "/login",
          name: "Login",
          miniName: "L",
          component: Login,
          layout: "/auth",
        },
        {
          path: "/register",
          name: "Register",
          miniName: "R",
          component: Register,
          layout: "/auth",
        },
        {
          path: "/lock",
          name: "Lock",
          miniName: "L",
          component: Lock,
          layout: "/auth",
        },
        {
          path: "/timeline",
          name: "Timeline",
          miniName: "T",
          component: Timeline,
          layout: "/admin",
        },
        {
          path: "/profile",
          name: "Profile",
          miniName: "P",
          component: Profile,
          layout: "/admin",
        },
        {
          path: "/rtl-support",
          name: "RTL Support",
          miniName: "RS",
          component: RTLSupport,
          layout: "/rtl",
        },
      ],
    },
    {
      collapse: true,
      name: "Components",
      icon: "ni ni-ui-04 text-info",
      state: "componentsCollapse",
      views: [
        {
          path: "/buttons",
          name: "Buttons",
          miniName: "B",
          component: Buttons,
          layout: "/admin",
        },
        {
          path: "/cards",
          name: "Cards",
          miniName: "C",
          component: Cards,
          layout: "/admin",
        },
        {
          path: "/grid",
          name: "Grid",
          miniName: "G",
          component: Grid,
          layout: "/admin",
        },
        {
          path: "/notifications",
          name: "Notifications",
          miniName: "N",
          component: Notifications,
          layout: "/admin",
        },
        {
          path: "/icons",
          name: "Icons",
          miniName: "I",
          component: Icons,
          layout: "/admin",
        },
        {
          path: "/typography",
          name: "Typography",
          miniName: "T",
          component: Typography,
          layout: "/admin",
        },
        {
          collapse: true,
          name: "Multi Level",
          miniName: "M",
          state: "multiCollapse",
          views: [
            {
              path: "#pablo",
              name: "Third level menu",
              component: () => {},
              layout: "/",
            },
            {
              path: "#pablo",
              name: "Just another link",
              component: () => {},
              layout: "/",
            },
            {
              path: "#pablo",
              name: "One last link",
              component: () => {},
              layout: "/",
            },
          ],
        },
      ],
    },
    {
      collapse: true,
      name: "Forms",
      icon: "ni ni-single-copy-04 text-pink",
      state: "formsCollapse",
      views: [
        {
          path: "/elements",
          name: "Elements",
          miniName: "E",
          component: Elements,
          layout: "/admin",
        },
        {
          path: "/components",
          name: "Components",
          miniName: "C",
          component: Components,
          layout: "/admin",
        },
        {
          path: "/validation",
          name: "Validation",
          miniName: "V",
          component: Validation,
          layout: "/admin",
        },
      ],
    },
    {
      collapse: true,
      name: "Tables",
      icon: "ni ni-align-left-2 text-default",
      state: "tablesCollapse",
      views: [
        {
          path: "/tables",
          name: "Tables",
          miniName: "T",
          component: Tables,
          layout: "/admin",
        },
        {
          path: "/sortable",
          name: "Sortable",
          miniName: "S",
          component: Sortable,
          layout: "/admin",
        },
        {
          path: "/react-bs-table",
          name: "React BS Tables",
          miniName: "RBT",
          component: ReactBSTables,
          layout: "/admin",
        },
      ],
    },
    {
      collapse: true,
      name: "Maps",
      icon: "ni ni-map-big text-primary",
      state: "mapsCollapse",
      views: [
        {
          path: "/google",
          name: "Google",
          miniName: "G",
          layout: "/admin",
        },
        {
          path: "/vector",
          name: "Vector",
          miniName: "V",
          layout: "/admin",
        },
      ],
    },
    {
      path: "/widgets",
      name: "Widgets",
      icon: "ni ni-archive-2 text-green",
      component: Widgets,
      layout: "/admin",
    },
    {
      path: "/charts",
      name: "Charts",
      icon: "ni ni-chart-pie-35 text-info",
      component: Charts,
      layout: "/admin",
    },
    {
      path: "/calendar",
      name: "Calendar",
      icon: "ni ni-calendar-grid-58 text-red",
      component: Calendar,
      layout: "/admin",
    },
  ]
}

export default centerRoutes;
