import React, {useEffect, useState} from "react";
// reactstrap components
import {Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Input, Row,} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import {useHistory} from "react-router-dom";
import centerService from "./service/center.service";

function CenterForm({ match }) {
  const history = useHistory();
  const { id } = match.params;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    nation: '',
    name: '',
    loginId: '',
    password: '',
    tel: '',
  });
  const {
    nation,
    name,
    loginId,
    password,
    tel,
  } = data;

  const onChange = (e) => {
    let { value, name } = e.target;
    if (name === '') {
    } else {
      setData({
        ...data,
        [name]: value
      });
    }
  };

  const fetchData = async () => {
    setLoading(true);
    if (id) {
      const res = await centerService.detail(id);
      console.log(res);
      if (res.code === 200) {
        setData(res.centers[0]);
      }
    }
    setLoading(false);
  };

  useEffect( () => {
    fetchData();
  }, []);

  const submit = async (e) => {
    setLoading(true);
    try {
      await createOrUpdate(e);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }

  const createOrUpdate = async (e) => {
    if (!name) {
      alert('센터명을 입력해주세요.');
      return;
    }
    if (!loginId) {
      alert('아이디를 입력해주세요.');
      return;
    }
    // if (!password) {
    //   alert('비밀번호를 입력해주세요.');
    //   return;
    // }
    if (!tel) {
      alert('전화번호를 입력해주세요.');
      return;
    }

    const json = {
      ...data,
    };
    try {
      let res;
      if (id) {
        res = await centerService.update(id, json);
      } else {
        res = await centerService.register(json);
      }
      console.log(res);
      if (res.code === 201) {
        alert(`${id ? '수정' : '등록'}되었습니다.`);
        history.goBack();
      }
    } catch (e) {
      alert(e.response?.data?.resultMsg);
    }
  }

  const remove = async () => {
    if (window.confirm('삭제하시겠습니까?')) {
      let res = await centerService.remove(id);
      console.log(res);
      if (res.code === 200) {
        history.goBack();
      }
    }
  }

  if (loading) return <div>로딩중..</div>;
  return (
    <>
      <SimpleHeader name="Form elements" parentName="Forms" />
      <Container className="mt--6" fluid>
        <Card className="mb-4">
          <CardHeader style={{ display: 'flex', justifyContent: 'space-between' }}>
            {id?
                <>
                  <h3 className="mt-2">센터 상세</h3>
                  <div>
                    <Button
                        color="default"
                        type="button"
                        onClick={submit}
                    >
                      수정하기
                    </Button>
                    <Button
                        color="danger"
                        type="button"
                        onClick={remove}
                    >
                      삭제하기
                    </Button>
                  </div>
                </>
                :
                <>
                  <h3 className="mt-2">센터 등록</h3>
                  <div>
                    <Button color="default" type="button"
                            onClick={submit}
                    >
                      <span className="btn-inner--icon mr-1">
                          <i className="fas fa-user-edit" />
                      </span>
                      등록하기
                    </Button>
                  </div>
                </>
            }
          </CardHeader>
          <CardBody>
            {!id &&
              <Row>
                <Col md="5">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                    >
                      국가
                    </label>
                    <Input
                      placeholder="국가를 입력해주세요."
                      type="text"
                      name="nation"
                      value={nation || ''}
                      onChange={onChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
            }
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    센터명
                  </label>
                  <Input
                      placeholder="* 센터명은 2 ~ 20자 입니다."
                      type="text"
                      name="name"
                      value={name || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    아이디
                  </label>
                  <Input
                      placeholder="* 아이디는 7 ~ 20자, 영문 또는 영문(소문자) + 숫자 입니다."
                      type="text"
                      name="loginId"
                      value={loginId || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    비밀번호
                  </label>
                  <Input
                      placeholder="* 비밀번호는 8 ~ 13자, 영문 또는 영문 + 숫자 + 특수문자($@$!%*#?&.-) 입니다."
                      type="password"
                      name="password"
                      value={password || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    전화번호
                  </label>
                  <Input
                      placeholder="* -(하이픈) 없이 번호만."
                      type="text"
                      name="tel"
                      value={tel || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default CenterForm;
