import React, {useEffect, useRef, useState} from "react";
import {Button, FormGroup,} from "reactstrap";
import {useRecoilState, useSetRecoilState} from "recoil";
import {filePreviewState, fileState} from "../service/menu.service";

function InputFile({ match, history }) {
  const setFile = useSetRecoilState(fileState);
  const [previewURL, setPreviewURL] = useRecoilState(filePreviewState);
  const fileRef = useRef();

  const handleFileOnChange = (event) => {
    //파일 불러오기
    event.preventDefault();
    let file = event.target.files[0];
    let reader = new FileReader();

    reader.onloadend = (e) => {
      setFile(file);
      setPreviewURL(reader.result);
    };
    if (file) reader.readAsDataURL(file);
  };

  const handleFileButtonClick = (e) => {
    //버튼 대신 클릭하기
    e.preventDefault();
    fileRef.current.click(); // file 불러오는 버튼을 대신 클릭함
  };

  const clickImg = (imgsrc,pageName) =>{
    // alert(imgsrc);
    var imageWin = new Image();
    imageWin = window.open("", "", "width=800px, height=800px");
    imageWin.document.write("<html><body style='margin:0'>");
    imageWin.document.write("<img src='" + imgsrc + "' border=0 width=\"100%\" height=\"100%\">");
    imageWin.document.write("</body><html>");
    imageWin.document.title = pageName;
  }

  return (
    <>
      <FormGroup>
        <label
          className="form-control-label"
          htmlFor="example4cols2Input"
        >
          메뉴 사진 <span className='text-green'>메뉴 사진 1장만 첨부 가능</span>
        </label>
        <input
          ref={fileRef}
          hidden={true}
          id='file'
          type='file'
          onChange={handleFileOnChange}
        />
      </FormGroup>
      <div className="avatar-group">
        {previewURL && (
          <a
            className="avatar avatar-xl rounded"
            onClick={() => clickImg(previewURL, ' ')}
            style={{
              cursor: 'pointer'
            }}
          >
            <img src={previewURL}/>
          </a>
        )}
        <Button
          className="ml-2 mt--4"
          color="primary"
          size="md"
          type="button"
          onClick={handleFileButtonClick}
        >
          첨부하기
        </Button>
      </div>
    </>
  );
}

export default InputFile;
