//file
import {atom} from "recoil";
import axios from "axios";
import api from "../../../../config/api.routes";
import authHeader from "../../../../services/auth-header";

export const newsState = atom({
    key: 'newsState',
    default: '',
});

export const newsPreviewState = atom({
    key: 'newsPreviewState',
    default: '',
});

export const detailState = atom({
    key: 'detailState',
    default: '',
});

export const detailPreviewState = atom({
    key: 'detailPreviewState',
    default: '',
});

const list = (data) => {
    let url = api().apiURL + `/serviceNews?`;
    for (let query of Object.keys(data)) {
        if (data[query] === undefined || data[query] === '') {
            continue;
        }
        url += `${query}=${data[query]}&`;
    }
    console.log(url);
    return new Promise((resolve, reject) => {
        axios.get(url, { headers: authHeader() })
          .then(res => res.data)
          .then(res => resolve(res))
          .catch(e => reject(e));
    });
};

const fileRegister = (id, data, diff) => {
    // diff = thumb 또는 image
    let url = api().apiURL + `/serviceNews/${id}/${diff}`;
    console.log(url);
    return new Promise((resolve, reject) => {
        axios.post(url, data, { headers: authHeader() })
            .then(res => res.data)
            .then(res => resolve(res))
            .catch(e => reject(e));
    });
};

const detail = (id) => {
    let url = api().apiURL + `/serviceNews/${id}`;
    console.log(url);
    return new Promise((resolve, reject) => {
        axios.get(url, { headers: authHeader() })
            .then(res => res.data)
            .then(res => resolve(res))
            .catch(e => reject(e));
    });
};

const update = (id, data) => {
    let url = api().apiURL + `/serviceNews/${id}`;
    console.log('수정',url);
    return new Promise((resolve, reject) => {
        axios.put(url, data, { headers: authHeader() })
            .then(res => res.data)
            .then(res => resolve(res))
            .catch(e => reject(e));
    });
};

const register = (data) => {
    let url = api().apiURL + `/serviceNews`;
    console.log(url,data);
    return new Promise((resolve, reject) => {
        axios.post(url, data, { headers: authHeader() })
            .then(res => res.data)
            .then(res => resolve(res))
            .catch(e => reject(e));
    });
};

const remove = (id) => {
    let url = api().apiURL + `/serviceNews/${id}`;
    console.log(url);
    return new Promise((resolve, reject) => {
        axios.delete(url, { headers: authHeader() })
            .then(res => res.data)
            .then(res => resolve(res))
            .catch(e => reject(e));
    });
};


const serviceNewsService = {
    list,
    fileRegister,
    detail,
    update,
    register,
    remove,
}

export default serviceNewsService;
