import React, {useEffect, useState} from "react";
// reactstrap components
import {Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Input, Row,} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import {useHistory} from "react-router-dom";
import useHistoryState from "../../../common/useHistoryState";
import moment from "moment";
import ReactDatetime from "react-datetime";
import companyService from "../company/service/company.service";
import pollutionService from "./service/pollution.service";
import dayjs from "dayjs";

function PollutionForm({ match }) {
  const { id } = match.params;
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useHistoryState(moment().format('YYYY-MM-01'), 'startDate');
  const [endDate, setEndDate] = useHistoryState(moment().format('YYYY-MM-DD'), 'endDate');
  const [data, setData] = useState({
    title: '',
    code: '',
    companyCode: '',
    mission: '',
    link: '',
  });

  const {
    title,
    code,
    companyCode,
    mission,
    link,
  } = data;
  console.log(data)

  const handleReactDatetimeChange = (who, date) => {
    if (
        startDate &&
        who === "endDate" &&
        new Date(startDate) > new Date(date.format('YYYY-MM-DD'))
    ) {
      setStartDate(date.format('YYYY-MM-DD'));
      setEndDate(date.format('YYYY-MM-DD'));
    } else if (
        endDate &&
        who === "startDate" &&
        new Date(endDate) < new Date(date.format('YYYY-MM-DD'))
    ) {
      setStartDate(date.format('YYYY-MM-DD'));
      setEndDate(date.format('YYYY-MM-DD'));
    } else {
      if (who === "startDate") {
        setStartDate(date.format('YYYY-MM-DD'));
      } else {
        setEndDate(date.format('YYYY-MM-DD'));
      }
    }
  };

  const getClassNameReactDatetimeDays = (date) => {
    if (startDate && endDate) {
    }
    if (startDate && endDate && startDate !== endDate) {
      if (
          new Date(endDate) > new Date(date.format('YYYY-MM-DD')) &&
          new Date(startDate) < new Date(date.format('YYYY-MM-DD'))
      ) {
        return " middle-date";
      }
      if (endDate === date.format('YYYY-MM-DD')) {
        return " end-date";
      }
      if (startDate === date.format('YYYY-MM-DD')) {
        return " start-date";
      }
    }
    return "";
  };

  const onChange = (e) => {
    let { value, name } = e.target;
    if (name === 'managerTel'
    ) {
      setData({
        ...data,
        [name]: Number(value.replace(/[^0-9]/g, '')),
      })
    } else {
      setData({
        ...data,
        [name]: value
      });
    }
  };

  const submit = async () => {
    setLoading(true);
    try {
      await createOrUpdate();
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }

  const createOrUpdate = async () => {
    if (!code) {
      return alert('활동코드를 입력해주세요.');
    }
    if (!companyCode) {
      return alert('기업코드를 입력해주세요.');
    }
    if (!title) {
      return alert('활동명을 입력해주세요.');
    }
    if (!startDate || !endDate) {
      return alert('기간을 입력해주세요.');
    }
    if (!mission) {
      return alert('활동미션을 입력해주세요.');
    }

    const json = {
      ...data,
      startDate,
      endDate,
    }

    if(id) {
      delete json.id;
      delete json.active;
      delete json.createdAt;
      delete json.updatedAt;
      delete json.deletedAt;
    }

    if(json.link === "" || json.link === null) {
      delete json.link;
    }

    console.log('json',json);

    let res;
    try {
      if (id) {
        res = await pollutionService.update(id, json);
      } else {
        res = await pollutionService.register(json);
      }
      if (res.resultMsg === "success") {
        alert(id ? '수정되었습니다.' : '등록되었습니다.');
        history.goBack();
      }
    } catch (e) {
      alert(JSON.stringify(e, null, 4));
      alert(e.response?.data?.message);
    }
    console.log('웹', res);
  };

  const fetchData = async () => {
    const res = await pollutionService.detail(id);
    if (res.code === 200) {
      console.log(res);
      setData(res.data)
      setStartDate(dayjs(res.data.startDate).format('YYYY-MM-DD'))
      setEndDate(dayjs(res.data.endDate).format('YYYY-MM-DD'))
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const remove = async () => {
    if (window.confirm('삭제하시겠습니까?')) {
      let res = await pollutionService.remove(id);
      console.log(res);
      if (res.code === 200) {
        history.goBack();
      }
    }
  };

  if (loading) return <div>로딩중..</div>;
  return (
    <>
      <SimpleHeader name="Form elements" parentName="Forms" />
      <Container className="mt--6" fluid>
        <Card className="mb-4">
          <CardHeader style={{ display: 'flex', justifyContent: 'space-between' }}>
            {id?
                <>
                  <h3 className="mt-2">악취오염 캠페인 상세</h3>
                  <div>
                    <Button
                      color="default"
                      type="button"
                      onClick={submit}
                    >
                      수정하기
                    </Button>
                    <Button
                      color="danger"
                      type="button"
                      onClick={remove}
                    >
                      삭제하기
                    </Button>
                  </div>
                </>
                :
                <>
                  <h3 className="mt-2">악취오염 캠페인 등록</h3>
                  <div>
                    <Button
                        color="default"
                        type="button"
                        onClick={submit}
                    >
                      <span className="btn-inner--icon mr-1">
                          <i className="fas fa-user-edit" />
                      </span>
                      등록하기
                    </Button>
                  </div>
                </>
            }
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="2">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    활동코드
                  </label>
                  <Input
                      placeholder="활동코드를 입력해주세요."
                      type="text"
                      name="code"
                      value={code || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
              <Col md="2">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    관리자 공유 기업코드
                  </label>
                  <Input
                      placeholder="기업코드를 입력해주세요."
                      type="text"
                      name="companyCode"
                      value={companyCode || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    활동명
                  </label>
                  <Input
                      placeholder="활동명을 입력해주세요."
                      type="text"
                      name="title"
                      value={title || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="3"  className="form-control-label">
                기업 기간
              </Col>
            </Row>
            <Row className="mt-2">
              <Col md="2">
              <ReactDatetime
                  inputProps={{
                    placeholder: "",
                  }}
                  value={startDate}
                  locale={'ko'}
                  timeFormat={false}
                  dateFormat={'YYYY-MM-DD'}
                  onChange={(e) =>
                      handleReactDatetimeChange("startDate", e)
                  }
                  renderDay={(props, currentDate, selectedDate) => {
                    let classes = props.className;
                    classes += getClassNameReactDatetimeDays(
                        currentDate
                    );
                    return (
                        <td {...props} className={classes}>
                          {currentDate.date()}
                        </td>
                    );
                  }}
              />
              </Col>
              <Col md="2">
              <ReactDatetime
                  inputProps={{
                    placeholder: "",
                  }}
                  value={endDate}
                  locale={'ko'}
                  timeFormat={false}
                  dateFormat={'YYYY-MM-DD'}
                  onChange={(e) =>
                      handleReactDatetimeChange("endDate", e)
                  }
                  renderDay={(props, currentDate, selectedDate) => {
                    let classes = props.className;
                    classes += getClassNameReactDatetimeDays(
                        currentDate
                    );
                    return (
                        <td {...props} className={classes}>
                          {currentDate.date()}
                        </td>
                    );
                  }}
              />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md="4">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    미션내용
                  </label>
                  <Input
                      placeholder="미션내용을 입력해주세요."
                      type="textarea"
                      rows="10"
                      name="mission"
                      value={mission || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    미션 링크 (선택사항)
                  </label>
                  <Input
                      placeholder="미션 링크를 입력해주세요."
                      type="text"
                      name="link"
                      value={link || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default PollutionForm;
