import React, {Fragment, useEffect} from "react";

// reactstrap components
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import CounselingService from "../../../services/counseling.service";
import PaginationWrapper from "../components/pagination";
import {numberWithCommas, paginate} from "../../../utils";
import useHistoryState from "../../../common/useHistoryState";
import classnames from "classnames";
import ReactDatetime from "react-datetime";
import moment from "moment";
import {Link} from "react-router-dom";
import reviewService from "./service/review.service";
import {useHistory} from "react-router";
import {shopCategory} from "../shop/service/shop.category";
import allianceService from "../alliance/service/alliance.service";

function ReviewList() {
  const history = useHistory();
  const [pageActive, setPageActive] = useHistoryState(1, 'pageActive');
  const [pageCount, setPageCount] = useHistoryState(1, 'pageCount');
  const [data, setData] = useHistoryState([], 'data');
  const [category, setCategory] = useHistoryState('전체', 'category');
  const [startDate, setStartDate] = useHistoryState(moment().format('YYYY-MM-01'), 'startDate');
  const [endDate, setEndDate] = useHistoryState(moment().format('YYYY-MM-DD'), 'endDate');
  const [searchCurrent, setSearchCurrent] = useHistoryState(false, 'searchCurrent');
  const [search, setSearch] = useHistoryState('', 'search');

  const render = async () => {
    const res = await reviewService.list({ page: pageActive });
    if (res.code === 200) {
      console.log(res);
      setPageCount(res.reviewList.count);
      setData(res.reviewList.rows);
    }
  };

  useEffect(() => {
    render();
  }, [category, pageActive, startDate, endDate, search]);

  const remove = async (id) => {
    if (window.confirm('삭제하시겠습니까?')) {
      let res = await reviewService.remove(id);
      console.log(res);
      if (res.code === 200) {
        render();
      }
    }
  };

  return (
    <>
      <SimpleHeader name="Tables" parentName="Tables" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col xs="6">
                    <h3 className="mt-2">
                      업체 리뷰 관리
                    </h3>
                  </Col>
                </Row>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive striped>
                <thead className="thead-light">
                <tr>
                  <th className="text-center">날짜날짜</th>
                  <th className="text-center">사용자명</th>
                  <th className="text-center">업체명</th>
                  <th className="text-center">내용</th>
                  <th className="text-center">업체만족도</th>
                  <th className="text-center">관리</th>
                </tr>
                </thead>
                <tbody>
                {data?.map((item, i) => {
                  return [
                    <Fragment key={i}>
                      <tr>
                        <td className="text-center">
                          {item.createdAt}
                        </td>
                        <td className="text-center">
                          {item.userName? item.userName:'-'}
                        </td>
                        <td className="text-center">
                          {item.company? item.company:'-'}
                        </td>
                        <td className="text-center">
                          {item.content.length > 30 ? item.content.slice(0, 30) + '...' : item.content}
                        </td>
                        <td className="text-center">
                          {item.rate}
                        </td>
                        <td className="text-center">
                          <Button
                              className="btn-outline-danger"
                              onClick={() => remove()}
                              size="sm"
                          >
                            <span className="btn-inner--text">삭제</span>
                          </Button>
                        </td>
                      </tr>
                    </Fragment>
                  ];
                })}
                </tbody>
              </Table>

              <CardFooter className="py-4">
                <PaginationWrapper
                  isActive={pageActive}
                  pageNumber={pageCount}
                  callback={(page) => setPageActive(page)}
                  itemPerPage={10}
                />
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default ReviewList;
