import axios from "axios";
import api from "../../../config/api.routes";
import authHeader from "../../../services/auth-header";


const list = (data) => {
  let url = api().apiURL + `/market/list?`;
  for (let query of Object.keys(data)) {
    if (data[query] === undefined || data[query] === '') {
      continue;
    }
    url += `${query}=${data[query]}&`;
  }
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.get(url, { headers: authHeader() })
      .then(res => res.data)
      .then(res => resolve(res))
      .catch(e => reject(e));
  });
};

const update = (id, data) => {
  let url = api().apiURL + `/market/update?id=${id}`;
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.put(url, data, { headers: authHeader() })
      .then(res => res.data)
      .then(res => resolve(res))
      .catch(e => reject(e));
  });
};

const register = (data) => {
  let url = api().apiURL + `/market/register`;
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.post(url, data, { headers: authHeader() })
      .then(res => res.data)
      .then(res => resolve(res))
      .catch(e => reject(e));
  });
};

const fileRegister = (id, data) => {
  let url = api().apiURL + `/market/file/register?marketId=${id}`;
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.post(url, data, { headers: authHeader() })
      .then(res => res.data)
      .then(res => resolve(res))
      .catch(e => reject(e));
  });
};

const detail = (id) => {
  let url = api().apiURL + `/market/detail?id=${id}`;
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.get(url, { headers: authHeader() })
      .then(res => res.data)
      .then(res => resolve(res))
      .catch(e => reject(e));
  });
};

const remove = (id) => {
  let url = api().apiURL + `/market/remove?id=${id}`;
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.delete(url, { headers: authHeader() })
      .then(res => res.data)
      .then(res => resolve(res))
      .catch(e => reject(e));
  });
};


const marketService = {
  list,
  update,
  register,
  fileRegister,
  detail,
  remove,
}

export default marketService;
