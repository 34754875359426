import React, {Fragment, useEffect} from "react";

// reactstrap components
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import CounselingService from "../../../services/counseling.service";
import PaginationWrapper from "../components/pagination";
import {numberWithCommas, paginate} from "../../../utils";
import useHistoryState from "../../../common/useHistoryState";
import classnames from "classnames";
import ReactDatetime from "react-datetime";
import moment from "moment";
import {Link} from "react-router-dom";
import charityService from "./service/charity.service";
import {useHistory} from "react-router";

function CharityList() {
  const history = useHistory();
  const [pageActive, setPageActive] = useHistoryState(1, 'pageActive');
  const [pageCount, setPageCount] = useHistoryState(1, 'pageCount');
  const [data, setData] = useHistoryState([], 'data');
  const [category, setCategory] = useHistoryState('전체', 'category');
  const [startDate, setStartDate] = useHistoryState('', 'startDate');
  const [endDate, setEndDate] = useHistoryState('', 'endDate');
  const [searchCurrent, setSearchCurrent] = useHistoryState(false, 'searchCurrent');
  const [search, setSearch] = useHistoryState('', 'search');

  const render = async () => {
    const res = await charityService.list({ page: pageActive, diff: 'all' });
    if (res.code === 200) {
      console.log(res);
      setPageCount(res.charityList.count);
      setData(res.charityList.rows);
    }
  }

  useEffect(() => {
    render();
  }, [category, pageActive, startDate, endDate, search]);

  const statusChange = async (id, {active, isAd}) => {
    await charityService.update(id, {active, isAd});
    render();
  }

  return (
    <>
      <SimpleHeader name="Tables" parentName="Tables" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col xs="6">
                    <h3 className="mt-2">기부 관리</h3>
                  </Col>
                  {/*{user?.role === 'center' ? null : (*/}
                      <Col className="text-right">
                        <Button
                            color="default"
                            onClick={(e) => history.push('/admin/charityForm')}
                        >
                          등록하기
                        </Button>
                      </Col>
                  {/* )}*/}
                </Row>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive striped>
                <thead className="thead-light">
                <tr>
                  <th className="text-center">우선순위</th>
                  <th className="text-center">기부업체명</th>
                  <th className="text-center">제목</th>
                  <th className="text-center">시작날짜</th>
                  <th className="text-center">종료날짜</th>
                  <th className="text-center">기부유형</th>
                  <th className="text-center">기부목표</th>
                  <th className="text-center">기부상황</th>
                  <th className="text-center">기부상세</th>
                  <th className="text-center">활성화</th>
                  <th className="text-center">광고제거</th>
                  <th className="text-center">관리</th>
                </tr>
                </thead>
                <tbody>
                {data?.map((item, i) => {
                  return [
                    <Fragment key={i}>
                      <tr>
                        <td className="text-center">
                          {item.sortCode === 99999 ? '-' : item.sortCode}
                        </td>
                        <td className="text-center">
                          {item.company}
                        </td>
                        <td className="text-center">
                          {item.title}
                        </td>
                        <td className="text-center">
                          {item.startDate}
                        </td>
                        <td className="text-center">
                          {item.endDate}
                        </td>
                        <td className="text-center">
                          {item.diff}
                        </td>
                        <td className="text-center">
                          {numberWithCommas(item.goal)}
                        </td>
                        <td className="text-center">
                          {numberWithCommas(item.current)}
                        </td>
                        <td className="text-center">
                          <Button
                              className="btn-outline-warning"
                              color="danger"
                              onClick={() => history.push(
                                  '/admin/charityHistory/' + item.id)}
                              size="sm"
                          >
                            <span className="btn-inner--text">보기</span>
                          </Button>
                        </td>
                        <td className="text-center">
                          <label
                              className="custom-toggle custom-toggle-success center">
                            <input
                                checked={item.active}
                                type="checkbox"
                                onChange={(e) => statusChange(item.id,
                                    {active: !item.active, isAd: item.isAd})}
                            />
                            <span
                                className="custom-toggle-slider rounded-circle"
                                data-label-off="No"
                                data-label-on="Yes"
                            />
                          </label>
                        </td>
                        <td className="text-center">
                          <label
                              className="custom-toggle custom-toggle-success center">
                            <input
                                checked={item.isAd}
                                type="checkbox"
                                onChange={(e) => statusChange(item.id,
                                    {active: item.active, isAd: !item.isAd})}
                            />
                            <span
                                className="custom-toggle-slider rounded-circle"
                                data-label-off="No"
                                data-label-on="Yes"
                            />
                          </label>
                        </td>
                        <td className="text-center">
                          <Button
                              className="btn-outline-danger"
                              color="danger"
                              // onClick={() => setDefaultModal(true)}
                              size="sm"
                          >
                            <span className="btn-inner--text">삭제</span>
                          </Button>
                          <Button
                              className="btn-outline-default"
                              color="danger"
                              onClick={() => history.push(
                                  '/admin/charityDetail/' + item.id)}
                              size="sm"
                          >
                            <span className="btn-inner--text">상세</span>
                          </Button>
                        </td>
                      </tr>
                    </Fragment>
                  ];
                })}
                </tbody>
              </Table>

              <CardFooter className="py-4">
                <PaginationWrapper
                    isActive={pageActive}
                    pageNumber={pageCount}
                    callback={(page) => setPageActive(page)}
                    itemPerPage={10}
                />
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default CharityList;
