import axios from 'axios';

import api from '../../../config/api.routes';

const login = (loginId, password) => {
  return axios
    .post(api().LOGIN, {
      loginId,
      password,
    })
    .then((res) => {
      if (res.data.code === 200) {
        if (res.data.token) {
          localStorage.setItem("user", JSON.stringify(res.data));
        }
      }
      return res.data;
    });
};

const logout = () => {
  localStorage.removeItem("user");
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

// axios.interceptors.response.use(function (response) {
//   // Any status code that lie within the range of 2xx cause this function to trigger
//   // Do something with response data
//   return response;
// }, function (error) {
//   // Any status codes that falls outside the range of 2xx cause this function to trigger
//   // Do something with response error
//   if (error.message === 'Request failed with status code 400') {
//     localStorage.removeItem("user")
//     window.location.reload();
//   }
//   return Promise.reject(error);
// });

const authService = {
  login,
  logout,
  getCurrentUser,
}

export default authService;
