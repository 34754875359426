import React, {useEffect, useState} from "react";
// reactstrap components
import {Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Input, Row,} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import {useHistory} from "react-router-dom";
import companyGroupService, {groupThumbFilePreviewState, groupThumbFileState} from "./service/companyGroup.service";
import useHistoryState from "../../../common/useHistoryState";
import moment from "moment";
import ReactDatetime from "react-datetime";
import companyService from "../company/service/company.service";
import InputFile from "../components/InputFile";
import {charityFilePreviewState, charityFileState} from "../charity/service/charity.service";
import livingService, {livingThumbFilePreviewState, livingThumbFileState} from "../carbonTab/service/carbonTab.service";
import {useRecoilState, useRecoilValue, useResetRecoilState} from "recoil";

function CompanyGroupForm({ match }) {
  const { id } = match.params;
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [isCheck, setIsCheck] = useState(false);
  const [exName, setExName] = useState('');
  const thumbFile = useRecoilValue(groupThumbFileState);
  const [previewThumbURL, setPreviewThumbURL] = useRecoilState(groupThumbFilePreviewState);
  const resetThumbFile = useResetRecoilState(groupThumbFileState);
  const resetPreviewThumbFile = useResetRecoilState(groupThumbFilePreviewState);
  const [exCompany, setExCompany] = useState();
  const [startDate, setStartDate] = useHistoryState(moment().format('YYYY-MM-01'), 'startDate');
  const [endDate, setEndDate] = useHistoryState(moment().format('YYYY-MM-DD'), 'endDate');
  const [companyList, setCompanyList] = useState([]);
  const [list, setList] = useState([]);
  const [data, setData] = useState({
    companyId: '',
    name: '',
    code: '',
    steps: '',
    active: '',
  });

  const {
    companyId,
    name,
    code,
    steps,
    active,
  } = data;
  console.log(thumbFile)

  const reset = () => {
    resetThumbFile();
    resetPreviewThumbFile();
  }

  const handleReactDatetimeChange = (who, date) => {
    if (
        startDate &&
        who === "endDate" &&
        new Date(startDate) > new Date(date.format('YYYY-MM-DD'))
    ) {
      setStartDate(date.format('YYYY-MM-DD'));
      setEndDate(date.format('YYYY-MM-DD'));
    } else if (
        endDate &&
        who === "startDate" &&
        new Date(endDate) < new Date(date.format('YYYY-MM-DD'))
    ) {
      setStartDate(date.format('YYYY-MM-DD'));
      setEndDate(date.format('YYYY-MM-DD'));
    } else {
      if (who === "startDate") {
        setStartDate(date.format('YYYY-MM-DD'));
      } else {
        setEndDate(date.format('YYYY-MM-DD'));
      }
    }
  };

  const getClassNameReactDatetimeDays = (date) => {
    if (startDate && endDate) {
    }
    if (startDate && endDate && startDate !== endDate) {
      if (
          new Date(endDate) > new Date(date.format('YYYY-MM-DD')) &&
          new Date(startDate) < new Date(date.format('YYYY-MM-DD'))
      ) {
        return " middle-date";
      }
      if (endDate === date.format('YYYY-MM-DD')) {
        return " end-date";
      }
      if (startDate === date.format('YYYY-MM-DD')) {
        return " start-date";
      }
    }
    return "";
  };

  const onChange = (e) => {
    let { value, name } = e.target;
    if (name === 'companyId' || name === steps
    ) {
      setData({
        ...data,
        [name]: Number(value.replace(/[^0-9]/g, '')),
      })
    } else {
      setData({
        ...data,
        [name]: value
      });
    }
  };

  const render = async () => {
    const res = await companyGroupService.list({  });
    if (res.code === 200) {
      console.log(res);
      setList(res.list);
    }
  };

  useEffect(() => {
    render();
  }, []);

  const submit = async () => {
    setLoading(true);
    try {
      await createOrUpdate();
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }

  const createOrUpdate = async () => {
    if (!name) {
      return alert('기업명을 입력해주세요.');
    }
    if(!isCheck) {
      return alert('중복체크를 해주세요.')
    }
    if (name !== exName) {
      return alert('중복체크를 다시 해주세요.');
    }
    if (!code) {
      return alert('기업코드를 입력해주세요');
    }
    if (!steps) {
      return alert('개별 목표걸음을 입력해주세요.');
    }

    const json = {
      name,
      code: code.replace(/ /g, ''),
      steps: Number(steps),
      startDate,
      endDate,
    }
    if(id) {
      json.active = active
    }

    console.log('json',json);

    let res;
    try {
      if (id) {
        res = await companyGroupService.update(id, json);
      } else {
        res = await companyGroupService.register(json);
      }
      if (res.resultMsg === "success") {
        if (thumbFile) {
          if(id) {
            let form = new FormData();
            console.log('image', thumbFile, typeof thumbFile);
            form.append('image', thumbFile);
            await companyGroupService.fileRegister(id, form);
          } else {
            let form = new FormData();
            console.log('image', thumbFile, typeof thumbFile);
            form.append('image', thumbFile);
            await companyGroupService.fileRegister(res.data.companyGroupId, form);
          }
        }
        alert(id ? '수정되었습니다.' : '등록되었습니다.');
        history.goBack();
        reset();
      }
    } catch (e) {
      alert(JSON.stringify(e, null, 4));
      alert(e.response?.data?.message);
    }
    console.log('웹', res);
  };

  const fetchData = async () => {
    const res = await companyGroupService.detail(id);
    if (res.code === 200) {
      console.log(res);
      setData(res.data)
      setStartDate(res.data.startDate)
      setEndDate(res.data.endDate)
      setExName(res.data.name)
      setIsCheck(true)
      if(res.data.thumbnail) {
        setPreviewThumbURL(res.data.thumbnail)
      }
    }
  };

  useEffect(() => {
    if(id) {
      fetchData();
    } else {
      reset();
    }
  }, []);
  console.log(exCompany)

  const searchName = async () => {
    const check = list.filter(value => value.name === name);
    if(check.length > 0) {
      setIsCheck(false);
      alert('이미 등록된 기업입니다.')
    } else {
      setIsCheck(true);
      setExName(name);
      alert('등록 가능합니다.')
    }
  };

  const remove = async () => {
    if (window.confirm('삭제하시겠습니까?')) {
      let res = await companyGroupService.remove(id);
      console.log(res);
      if (res.code === 200) {
        history.goBack();
      }
    }
  };

  const imageRemove = async () => {
    if (window.confirm('썸네일을 삭제하시겠습니까?')) {
      let res = await companyGroupService.fileRemove(id);
      console.log(res);
      if (res.code === 200) {
        history.go(0);
      }
    }
  };

  if (loading) return <div>로딩중..</div>;
  return (
    <>
      <SimpleHeader name="Form elements" parentName="Forms" />
      <Container className="mt--6" fluid>
        <Card className="mb-4">
          <CardHeader style={{ display: 'flex', justifyContent: 'space-between' }}>
            {id?
                <>
                  <h3 className="mt-2">참여그룹 걷기 현황 상세</h3>
                  <div>
                    <Button
                      color="default"
                      type="button"
                      onClick={submit}
                    >
                      수정하기
                    </Button>
                    <Button
                      color="danger"
                      type="button"
                      onClick={remove}
                    >
                      삭제하기
                    </Button>
                  </div>
                </>
                :
                <>
                  <h3 className="mt-2">참여그룹 걷기 현황 등록</h3>
                  <div>
                    <Button
                        color="default"
                        type="button"
                        onClick={submit}
                    >
                      <span className="btn-inner--icon mr-1">
                          <i className="fas fa-user-edit" />
                      </span>
                      등록하기
                    </Button>
                  </div>
                </>
            }
          </CardHeader>
          <CardBody>
            {id &&
            <>
              <Row>
                <Col md="auto">
                    <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                    >
                      활성화/비활성화
                    </label>
                </Col>
              </Row>
              <Row className="mb-4">
                <Col md="auto">
                    <label className="custom-toggle custom-toggle-success center">
                      <input
                          checked={active}
                          type="checkbox"
                          onChange={(e) => setData({ ...data, active: !active })}
                      />
                      <span
                          className="custom-toggle-slider rounded-circle"
                          data-label-off="No"
                          data-label-on="Yes"
                      />
                    </label>
                </Col>
              </Row>
            </>
            }
            <Row>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    기업명 검색
                  </label>
                  <Input
                    className="mt--2"
                    placeholder="기업명을 입력해주세요."
                    type="text"
                    name="name"
                    value={name || ''}
                    onChange={onChange}
                  />
                </FormGroup>
              </Col>
              <Col>
                <Button
                    className="mt-4"
                    color="default"
                    type="button"
                    onClick={searchName}
                    size=""
                >
                  중복검사
                </Button>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    참여기업 코드 명
                  </label>
                  <Input
                      placeholder="콤마로 구분해주세요. "
                      type="text"
                      name="code"
                      value={code || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    개별 목표걸음
                  </label>
                  <Input
                      placeholder="숫자만 입력해주세요."
                      type="text"
                      name="steps"
                      value={steps || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="3"  className="form-control-label">
                기간 설정
              </Col>
            </Row>
            <Row className="mt-2">
              <Col md="2">
              <ReactDatetime
                  inputProps={{
                    placeholder: "",
                  }}
                  value={startDate}
                  locale={'ko'}
                  timeFormat={false}
                  dateFormat={'YYYY-MM-DD'}
                  onChange={(e) =>
                      handleReactDatetimeChange("startDate", e)
                  }
                  renderDay={(props, currentDate, selectedDate) => {
                    let classes = props.className;
                    classes += getClassNameReactDatetimeDays(
                        currentDate
                    );
                    return (
                        <td {...props} className={classes}>
                          {currentDate.date()}
                        </td>
                    );
                  }}
              />
              </Col>
              <Col md="2">
              <ReactDatetime
                  inputProps={{
                    placeholder: "",
                  }}
                  value={endDate}
                  locale={'ko'}
                  timeFormat={false}
                  dateFormat={'YYYY-MM-DD'}
                  onChange={(e) =>
                      handleReactDatetimeChange("endDate", e)
                  }
                  renderDay={(props, currentDate, selectedDate) => {
                    let classes = props.className;
                    classes += getClassNameReactDatetimeDays(
                        currentDate
                    );
                    return (
                        <td {...props} className={classes}>
                          {currentDate.date()}
                        </td>
                    );
                  }}
              />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md="auto">
                <InputFile
                    title={'대표 이미지 첨부'}
                    fileState={groupThumbFileState}
                    filePreviewState={groupThumbFilePreviewState}
                />
              </Col>
              {previewThumbURL &&
                <Col>
                  <Button
                      className="btn-danger"
                      onClick={() => imageRemove()}
                  >
                    삭제
                  </Button>
                </Col>
              }
            </Row>
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default CompanyGroupForm;
