import React, {Fragment, useEffect} from "react";

// reactstrap components
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText, Media,
  Row,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import PaginationWrapper from "../components/pagination";
import {clickImg, paginate} from "../../../utils";
import useHistoryState from "../../../common/useHistoryState";
import classnames from "classnames";
import {Link} from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import togetherChallengeService from "./service/togetherChallenge.service";
import shopService from "../shop/shop.service";
import AuthService from "../auth/auth.service";

function TogetherChallenge({ match, history }) {
  const { id } = match.params;
  const user = typeof window !== 'undefined' ? AuthService.getCurrentUser() : null;
  const centerPloggingId = JSON.parse(localStorage.getItem('user'))?.centerId;
  const company = JSON.parse(localStorage.getItem('user'))?.companyId
  const [pageActive, setPageActive] = useHistoryState(1, 'pageActive');
  const [pageCount, setPageCount] = useHistoryState(1, 'pageCount');
  const [data, setData] = useHistoryState([], 'data');

  console.log(pageActive, pageCount)

  const render = async () => {
    let option = {};
    if(user.role === 'center') {
      option.companyId = Number(company)
    }
    if (centerPloggingId !== undefined) {
      option.centerId = Number(centerPloggingId);
    }
    try {
      const res = await togetherChallengeService.list(option);
      console.log(res);
      if (res.code === 200) {
        setPageCount(res.list.length);
        setData(paginate(res.list, pageActive, 10));
      }
    } catch (e) {
      alert(e.response?.data?.resultMsg);
    }
  };

  useEffect(() => {
    render();
  }, [ pageActive, pageCount ]);

  const statusChange = async (id, active) => {
    const res = await togetherChallengeService.update(id, {active});
    if (res.code === 200) {
      render();
    }
  }

  return (
    <>
      <SimpleHeader name="Tables" parentName="Tables" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">
                      함께하는 챌린지 관리
                    </h3>
                  </Col>
                  {centerPloggingId === undefined &&
                      <Col className="text-right" xs="6">
                        <Button
                            className="btn-round btn-icon"
                            color="default"
                            id="tooltip443412080"
                            onClick={(e) => history.push('/admin/togetherChallengeForm')}
                        >
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-user-edit" />
                  </span>
                          <span className="btn-inner--text">등록하기</span>
                        </Button>
                      </Col>
                  }
                </Row>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive striped>
                <thead className="thead-light">
                <tr>
                  <th className="text-center">No.</th>
                  <th className="text-center">챌린지명</th>
                  <th className="text-center">시작날짜</th>
                  <th className="text-center">종료날짜</th>
                  <th className="text-center">참여건</th>
                  <th className="text-center">댓글수</th>
                  <th className="text-center">활성화</th>
                  <th className="text-center">인증리스트</th>
                  {centerPloggingId === undefined &&
                    <th className="text-center">상세</th>
                  }
                </tr>
                </thead>
                <tbody>
                  {data?.map((item, i) => {
                  return [
                    <Fragment key={uuidv4()}>
                      <tr>
                        <td className="text-center">
                          {item.id}
                        </td>
                        <td className="text-center">
                          {item.title}
                        </td>
                        <td className="text-center">
                          {item.startDate}
                        </td>
                        <td className="text-center">
                          {item.endDate}
                        </td>
                        <td className="text-center">
                          {item.join}
                        </td>
                        <td className="text-center">
                          {item.challengeUserCommentCount}
                        </td>
                        <td className="text-center">
                          <label className="custom-toggle custom-toggle-success center">
                            <input
                                checked={item.active}
                                type="checkbox"
                                onChange={(e) => statusChange(item.id, !item.active)}
                            />
                            <span
                                className="custom-toggle-slider rounded-circle"
                                data-label-off="No"
                                data-label-on="Yes"
                            />
                          </label>
                        </td>
                        <td className="text-center">
                          <Button
                              className="btn-outline-default"
                              color="danger"
                              onClick={() => history.push('/admin/certificateList/' + item.id)}
                              size="sm"
                          >
                            <span className="btn-inner--text">상세</span>
                          </Button>
                        </td>
                        {centerPloggingId === undefined &&
                          <td className="text-center">
                            <Button
                                className="btn-outline-default"
                                color="danger"
                                onClick={() => history.push('/admin/togetherChallengeDetail/' + item.id)}
                                size="sm"
                            >
                              <span className="btn-inner--text">상세</span>
                            </Button>
                          </td>
                        }
                      </tr>
                    </Fragment>
                  ];
                })}
                </tbody>
              </Table>

              <CardFooter className="py-4">
                <PaginationWrapper
                  isActive={pageActive}
                  pageNumber={pageCount}
                  callback={(page) => setPageActive(page)}
                  itemPerPage={10}
                />
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default TogetherChallenge;
