import React, {Fragment, useEffect} from "react";

// reactstrap components
import {
    Button,
    Card,
    CardFooter,
    CardHeader,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row,
    Table,
    UncontrolledTooltip,
} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import PaginationWrapper from "../components/pagination";
import {telWithCommas} from "../../../utils";
import useHistoryState from "../../../common/useHistoryState";
import {useHistory} from "react-router";
import centerService from "./service/center.service";
import classnames from "classnames";
import AuthService from "../auth/auth.service";
import {Link} from "react-router-dom";

function CenterList() {
    const user = typeof window !== 'undefined' ? AuthService.getCurrentUser() : null;
    const history = useHistory();
    const [pageActive, setPageActive] = useHistoryState(1, 'pageActive');
    const [pageCount, setPageCount] = useHistoryState(1, 'pageCount');
    const [data, setData] = useHistoryState([], 'data');
    const [diff, setDiff] = useHistoryState('', 'diff');
    const [searchCurrent, setSearchCurrent] = useHistoryState(false, 'searchCurrent');
    const [search, setSearch] = useHistoryState('', 'search');
    // console.log('123',user)

    const render = () => {
        let option = { };
        if (search) {
            option.search = search;
        }
        if (pageCount) {
            option.page = pageActive;
        }
        centerService.list(option)
            .then((res) => {
                if (res.code === 200) {
                    console.log(res);
                    setPageCount(res.centers.count);
                    // setData(paginate(res.centers.rows, pageActive, 11));
                    setData(res.centers.rows);
                }
            });
    };

    useEffect(() => {
        render();
    }, [search, pageActive, pageCount]);

    return (
        <>
            <SimpleHeader name="Tables" parentName="Tables" />
            <Container className="mt--6" fluid>
                <Row>
                    <div className="col">
                        <Card>
                            <CardHeader className="border-0">
                                <Row className="py-3">
                                    <Col xs="11">
                                        <Form>
                                            <Row>
                                                <Col xs="3">
                                                    <label className=" form-control-label py-2">
                                                    </label>
                                                    <FormGroup>
                                                        <InputGroup
                                                            className={classnames("input-group-merge", {
                                                                focused: searchCurrent,
                                                            })}
                                                        >
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText>
                                                                    <i className="fas fa-search" />
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input
                                                                placeholder="센터명으로 검색"
                                                                type="text"
                                                                value={search}
                                                                onChange={(e) => setSearch(e.target.value)}
                                                                onFocus={(e) => setSearchCurrent(true)}
                                                                onBlur={(e) => setSearchCurrent(false)}
                                                            />
                                                        </InputGroup>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="6">
                                        <h3 className="mt-2">봉사센터 관리</h3>
                                    </Col>
                                    {user?.role === 'center' ? null : (
                                      <Col className="text-right">
                                          <Button
                                              color="default"
                                              onClick={(e) => history.push('/admin/centerForm')}
                                          >
                                              등록하기
                                          </Button>
                                      </Col>
                                    )}
                                </Row>
                            </CardHeader>

                            <Table className="align-items-center table-flush" responsive striped>
                                <thead className="thead-light">
                                    <tr>
                                        <th className="text-center">국가</th>
                                        <th className="text-center">센터명</th>
                                        <th className="text-center">아이디</th>
                                        <th className="text-center">전화번호</th>
                                        {/*<th className="text-center">상세</th>*/}
                                        <th className="text-center">관리</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item, i) => {
                                        return [
                                            <Fragment key={i}>
                                                <tr>
                                                    <td className="text-center">
                                                        {item.nation}
                                                    </td>
                                                    <td className="text-center">
                                                        {item.name}
                                                    </td>
                                                    <td className="text-center">
                                                        {item.loginId}
                                                    </td>
                                                    <td className="text-center">
                                                        {telWithCommas(item.tel)}
                                                    </td>
                                                    {/*<td className="text-center">*/}
                                                    {/*    <Button*/}
                                                    {/*        className="btn-outline-default"*/}
                                                    {/*        onClick={() => history.push('/admin/staffDetail/' + item.id)}*/}
                                                    {/*        size="sm"*/}
                                                    {/*    >*/}
                                                    {/*        <span className="btn-inner--text">상세</span>*/}
                                                    {/*    </Button>*/}
                                                    {/*</td>*/}
                                                    <td className="text-center">
                                                        <Link
                                                            className="text-center"
                                                            to={'/admin/centerDetail/' + item.id}
                                                        >
                                                            <i id={"tooltip" + i} className="fas fa-user-edit" />
                                                        </Link>
                                                        <UncontrolledTooltip
                                                            delay={0}
                                                            target={"tooltip" + i}
                                                        >
                                                            상세 보기
                                                        </UncontrolledTooltip>
                                                    </td>
                                                </tr>
                                            </Fragment>
                                        ];
                                    })}
                                </tbody>
                            </Table>

                            <CardFooter className="py-4">
                                <PaginationWrapper
                                    isActive={pageActive}
                                    pageNumber={pageCount}
                                    callback={(page) => setPageActive(page)}
                                    itemPerPage={10}
                                />
                            </CardFooter>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
}

export default CenterList;
