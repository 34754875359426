import React, {useEffect, useRef, useState, Fragment} from "react";
// reactstrap components
import {
  Button,
  Card,
  CardBody, CardFooter,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Modal,
  Row, Table,
  UncontrolledTooltip,
} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import {useHistory} from "react-router-dom";
import useHistoryState from "../../../common/useHistoryState";
import moment from "moment";
import PaginationWrapper from "../components/pagination";
import ReactDatetime from "react-datetime";
import InputFile from "../components/InputFile";
import {useRecoilState, useRecoilValue, useResetRecoilState} from "recoil";
import companyService from "../company/service/company.service";
import {FormControl, InputGroup} from "react-bootstrap";
import InputFiles from "../components/InputFiles";
import DaumPostcode from "react-daum-postcode";
import companyCenterService from "./service/companyCenter.service";

function CompanyCenterForm({ match }) {
  const history = useHistory();
  const { id } = match.params;
  const [loading, setLoading] = useState(false);
  const [checkList, setCheckList] = useState([]);
  const [checkOneList, setCheckOneList] = useState([]);
  const [companyList, setCompanyList] = useHistoryState([], 'companyList');
  const [companyId, setCompanyId] = useState(117);
  const [data, setData] = useState({
    loginId: '',
    password: '',
    name: '',
    tel: '',
    nation: '',
  });
  const {
    loginId,
    password,
    name,
    tel,
    nation,
  } = data;

  const checks = ['미연결 기업']
  console.log('123',checkList)

  const reset = () => {
    // resetThumbFile();
    // resetDetailFile();
    // resetThumbFilePreview();
    // resetDetailFilePreview()
  }

  const onChange = (e) => {
    let { value, name } = e.target;
    if (name === 'managerTel'
    ) {
      setData({
        ...data,
        [name]: Number(value.replace(/[^0-9]/g, '')),
      })
    } else {
      setData({
        ...data,
        [name]: value
      });
    }
  };

  const companyRender = async () => {
    const res = await companyService.list({});
    if (res.code === 200) {
      console.log(res);
      setCompanyList(res.list);
    }
  };

  useEffect(() => {
    companyRender();
    if(id) {
      // fetchData();
    } else {
      reset();
    }
  }, []);

  useEffect(() => {
    if (checkOneList[0]) {
      setCheckList(companyList.filter((v) => v.centerId === null))
    } else {
      setCheckList(companyList)
    }
  }, [checkOneList]);

  const submit = async () => {
    setLoading(true);
    try {
      await createOrUpdate();
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }

  const createOrUpdate = async () => {
    if (!nation) {
        alert('국가를 입력해주세요.');
        return;
    }
    if (!name) {
        alert('센터명을 입력해주세요.');
        return;
    }
    if (!loginId) {
        alert('아이디를 입력해주세요.');
        return;
    }
    if (!password) {
        alert('비밀번호를 입력해주세요.');
        return;
    }
    if (password.length < 8) {
        alert('비밀번호는 8자리 이상 입력해주세요.');
        return;
    }
    if (!tel) {
        alert('전화번호를 입력해주세요.');
        return;
    }
    if (tel.length < 8) {
      alert('전화번호는 8자리 이상 입력해주세요.');
      return;
    }

    const json = {
      ...data,
      companyId: Number(companyId),
    }

    console.log('json',json);

    let res;
    try {
      if (id) {
        res = await companyCenterService.update(id, json);
      } else {
        res = await companyCenterService.register(json);
      }
      if (res.resultMsg === "success") {
        alert(id ? '수정되었습니다.' : '등록되었습니다.');
        history.goBack();
        reset();
      } else {
        alert(res.resultMsg)
      }
    } catch (e) {
      // alert(JSON.stringify(e, null, 4));
      // alert(e.resultMsg);
    }
    console.log('웹', res);
  };

  const remove = async () => {
    if (window.confirm('삭제하시겠습니까?')) {
      let res = await companyCenterService.remove(id);
      console.log(res);
      if (res.code === 200) {
        history.goBack();
      }
    }
  };

  const isCheckOne = (value, type, setType) => {
    const index = type.indexOf(value); // value 값의 위치를 찾음.
    if (index > -1) { //  값이 있는 경우, -1은 값이없음.
      setType([
        ...type.filter(v => v !== value)
      ]);
    } else {
      setType([
        value
      ]);
    }
  };

  if (loading) return <div>로딩중..</div>;
  return (
    <>
      <SimpleHeader name="Form elements" parentName="Forms" />
      <Container className="mt--6" fluid>
        <Card className="mb-4">
          <CardHeader style={{ display: 'flex', justifyContent: 'space-between' }}>
            {id?
                <>
                  <h3 className="mt-2">기업 센터 상세</h3>
                  <div>
                    <Button
                      color="default"
                      type="button"
                      onClick={submit}
                    >
                      수정하기
                    </Button>
                    <Button
                      color="danger"
                      type="button"
                      onClick={remove}
                    >
                      삭제하기
                    </Button>
                  </div>
                </>
                :
                <>
                  <h3 className="mt-2">기업 센터 등록</h3>
                  <div>
                    <Button
                        color="default"
                        type="button"
                        onClick={submit}
                    >
                      <span className="btn-inner--icon mr-1">
                          <i className="fas fa-user-edit" />
                      </span>
                      등록하기
                    </Button>
                  </div>
                </>
            }
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    국가
                  </label>
                  <Input
                      placeholder="국가를 입력해주세요."
                      type="text"
                      name="nation"
                      value={nation || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    기업 센터명
                  </label>
                  <Input
                      placeholder="*기업센터명은 2~20자 입니다."
                      type="text"
                      name="name"
                      value={name || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    아이디
                  </label>
                  <Input
                      placeholder="* 아이디는 7 ~ 20자, 영문 또는 영문(소문자) + 숫자 입니다."
                      type="text"
                      name="loginId"
                      value={loginId || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    비밀번호
                  </label>
                  <Input
                      placeholder="* 비밀번호는 8 ~ 13자, 영문 또는 영문 + 숫자 + 특수문자($@$!%*#?&.-) 입니다."
                      type="text"
                      name="password"
                      value={password || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    비밀번호 확인
                  </label>
                  <Input
                      placeholder="비밀번호을 다시 입력해주세요."
                      type="text"
                      name="password"
                      value={password || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    전화번호
                  </label>
                  <Input
                      placeholder="* -(하이픈) 없이 번호만"
                      type="text"
                      name="tel"
                      value={tel || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    기업선택
                  </label>
                  {/*<Row>*/}
                    {checks.map((v, i) => {
                      return <Col md="auto" key={i}>
                        <div className="custom-control custom-checkbox mb-3">
                          <input
                              className="custom-control-input"
                              id={"customCheck" + i}
                              type="checkbox"
                              value={v}
                              onClick={() => {
                                isCheckOne(v, checkOneList, setCheckOneList)
                              }}
                              checked={checkOneList.indexOf(v) > -1}
                              readOnly
                          />
                          <label
                              className="custom-control-label"
                              htmlFor={"customCheck" + i}
                          >
                            {v}
                          </label>
                        </div>
                      </Col>
                    })}
                  {/*</Row>*/}
                  <Input
                      type="select"
                      name="companyId"
                      value={companyId || ''}
                      onChange={(e) => setCompanyId(e.target.value)}
                  >
                    {checkList.map((v, i) => {
                      return <option value={v.id}>{v.name}</option>
                    })}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default CompanyCenterForm;
