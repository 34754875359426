//file
import {atom} from "recoil";
import axios from "axios";
import api from "../../../../config/api.routes";
import authHeader from "../../../../services/auth-header";

export const togetherThumbFileState = atom({
    key: 'togetherThumbFileState',
    default: '',
});

export const togetherThumbFilePreviewState = atom({
    key: 'togetherThumbFilePreviewState',
    default: '',
});

export const togetherBannerFileState = atom({
    key: 'togetherBannerFileState',
    default: '',
});

export const togetherBannerFilePreviewState = atom({
    key: 'togetherBannerFilePreviewState',
    default: '',
});

export const togetherBannerDetailFileState = atom({
    key: 'togetherBannerDetailFileState',
    default: '',
});

export const togetherBannerDetailFilePreviewState = atom({
    key: 'togetherBannerDetailFilePreviewState',
    default: '',
});

const companyList = (data) => {
    let url = api().apiURL + `/company/list?`;
    for (let query of Object.keys(data)) {
        if (data[query] === undefined || data[query] === '') {
            continue;
        }
        url += `${query}=${data[query]}&`;
    }
    console.log(url);
    return new Promise((resolve, reject) => {
        axios.get(url, { headers: authHeader() })
            .then(res => res.data)
            .then(res => resolve(res))
            .catch(e => reject(e));
    });
};

const list = (data) => {
    let url = api().apiURL + `/challenge/list?`;
    for (let query of Object.keys(data)) {
        if (data[query] === undefined || data[query] === '') {
            continue;
        }
        url += `${query}=${data[query]}&`;
    }
    console.log(url);
    return new Promise((resolve, reject) => {
        axios.get(url, { headers: authHeader() })
            .then(res => res.data)
            .then(res => resolve(res))
            .catch(e => reject(e));
    });
};

const certificateList = (data) => {
    let url = api().apiURL + `/challengeUser/list?`;
    for (let query of Object.keys(data)) {
        if (data[query] === undefined || data[query] === '') {
            continue;
        }
        url += `${query}=${data[query]}&`;
    }
    console.log(url);
    return new Promise((resolve, reject) => {
        axios.get(url, { headers: authHeader() })
            .then(res => res.data)
            .then(res => resolve(res))
            .catch(e => reject(e));
    });
};

const update = (id, data) => {
    console.log(id, data)
    let url = api().apiURL + `/challenge/update?id=${id}`;
    console.log('수정',url);
    return new Promise((resolve, reject) => {
        axios.put(url, data, { headers: authHeader() })
            .then(res => res.data)
            .then(res => resolve(res))
            .catch(e => reject(e));
    });
};

const detail = (id) => {
    let url = api().apiURL + `/challenge/detail?id=${id}`;
    console.log(url);
    return new Promise((resolve, reject) => {
        axios.get(url, { headers: authHeader() })
            .then(res => res.data)
            .then(res => resolve(res))
            .catch(e => reject(e));
    });
};

const register = (data) => {
    let url = api().apiURL + `/challenge/register`;
    console.log(url,data);
    return new Promise((resolve, reject) => {
        axios.post(url, data, { headers: authHeader() })
            .then(res => res.data)
            .then(res => resolve(res))
            .catch(e => reject(e));
    });
};

const fileRegister = (id, data) => {
    // diff = thumbnail 또는 image
    let url = api().apiURL + `/challenge/file/register?challengeId=${id}`;
    console.log(url);
    return new Promise((resolve, reject) => {
        axios.post(url, data, { headers: authHeader() })
            .then(res => res.data)
            .then(res => resolve(res))
            .catch(e => reject(e));
    });
};

const bannerRegister = (id, data) => {
    // diff = thumbnail 또는 image
    let url = api().apiURL + `/challenge/file/register?diff=banner&challengeId=${id}`;
    console.log(url);
    return new Promise((resolve, reject) => {
        axios.post(url, data, { headers: authHeader() })
            .then(res => res.data)
            .then(res => resolve(res))
            .catch(e => reject(e));
    });
};

const bannerDetailRegister = (id, data) => {
    // diff = thumbnail 또는 image
    let url = api().apiURL + `/challenge/file/register?diff=bannerDetail&challengeId=${id}`;
    console.log(url);
    return new Promise((resolve, reject) => {
        axios.post(url, data, { headers: authHeader() })
            .then(res => res.data)
            .then(res => resolve(res))
            .catch(e => reject(e));
    });
};

const remove = (id) => {
    let url = api().apiURL + `/challenge/remove?id=${id}`;
    console.log(url);
    return new Promise((resolve, reject) => {
        axios.delete(url, { headers: authHeader() })
            .then(res => res.data)
            .then(res => resolve(res))
            .catch(e => reject(e));
    });
};

const charityHistoryDetail = (data) => {
    let url = api().apiURL + `/charityHistory/detail?`;
    for (let query of Object.keys(data)) {
        if (data[query] === undefined || data[query] === '') {
            continue;
        }
        url += `${query}=${data[query]}&`;
    }
    console.log(url);
    return new Promise((resolve, reject) => {
        axios.get(url, { headers: authHeader() })
            .then(res => res.data)
            .then(res => resolve(res))
            .catch(e => reject(e));
    });
};

const charityHistoryDetailDown = (data) => {
    let url = api().apiURL + `/charityHistory/detail?`;
    for (let query of Object.keys(data)) {
        if (data[query] === undefined || data[query] === '') {
            continue;
        }
        url += `${query}=${data[query]}&`;
    }
    console.log(url);
    return new Promise((resolve, reject) => {
        axios.get(url, { headers: authHeader(), responseType: 'arraybuffer' })
            .then(res => resolve(res))
            .catch(e => reject(e));
    });
};

const togetherChallengeService = {
    companyList,
    list,
    certificateList,
    update,
    detail,
    register,
    fileRegister,
    bannerRegister,
    bannerDetailRegister,
    remove,
    charityHistoryDetail,
    charityHistoryDetailDown
}

export default togetherChallengeService;
