import React, {useEffect, useRef, useState, Fragment} from "react";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Modal,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import {useHistory} from "react-router-dom";
import userService from "./user.service";

function UserForm({ match }) {
  const history = useHistory();
  const { id } = match.params;
  const [loading, setLoading] = useState(false);
  const [checkPassword, setCheckPassword] = useState('');
  const [data, setData] = useState({
    birth: '',
    name: '',
    loginId: '',
    password: '',
    tel: '',
    gender: '',
    email: '',
    number1365: '',
    staffNumber: '',
    image1365: '',
  });
  const {
    birth,
    name,
    loginId,
    password,
    tel,
    gender,
    email,
    number1365,
    staffNumber,
    image1365,
  } = data;
  console.log(data.staffNumber)

  const onChange = (e) => {
    let { value, name } = e.target;
    if (name === '') {
    } else {
      setData({
        ...data,
        [name]: value
      });
    }
  };

  const fetchData = async () => {
    setLoading(true);
    if (id) {
      const res = await userService.detail(id);
      console.log(res);
      if (res.code === 200) {
        setData(res.user);
      }
    }
    setLoading(false);
  };

  useEffect( () => {
    fetchData();
  }, []);

  const submit = async (e) => {
    setLoading(true);
    try {
      await createOrUpdate(e);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const createOrUpdate = async (e) => {
    if (!loginId) {
      alert('아이디를 입력해주세요.');
      return;
    }
    if(!id) {
      if (password !== checkPassword || !password || !checkPassword) {
        alert('비밀번호를 확인해주세요.');
        return;
      }
    }
    if (!name) {
      alert('이름을 입력해주세요.');
      return;
    }
    if (!tel) {
      alert('전화번호를 입력해주세요.');
      return;
    }

    const json = {
      ...data,
    };
    if(id) {
      delete json.loginId;
      delete json.password;
      delete json.email;
      delete json.gender;
      delete json.image1365;
      delete json.number1365;
      // delete json.staffNumber;
      delete json.id;
    } else {
      delete json.number1365;
      delete json.staffNumber;
    }

    if(number1365) {
      json.number1365 = number1365
    }
    if(!staffNumber) {
      json.staffNumber = ''
    }

    console.log(json)
    let res;
    if (id) {
      // delete json.staffNumber;
      res = await userService.update(id, json);
    } else {
      res = await userService.register(json);
    }
    console.log(res);
    if (res.code === 200) {
      alert(`${id ? '수정' : '등록'}되었습니다.`);
      history.goBack();
    }
  }

  const remove = async () => {
    if (window.confirm('삭제하시겠습니까?')) {
      let res = await userService.remove(id);
      console.log(res);
      if (res.code === 200) {
        history.goBack();
      }
    }
  };

  if (loading) return <div>로딩중..</div>;
  return (
    <>
      <SimpleHeader name="Form elements" parentName="Forms" />
      <Container className="mt--6" fluid>
        <Card className="mb-4">
          <CardHeader style={{ display: 'flex', justifyContent: 'space-between' }}>
            {id?
                <>
                  <h3 className="mt-2">유저 상세</h3>
                  <div>
                    <Button
                        color="default"
                        type="button"
                        onClick={submit}
                    >
                      수정하기
                    </Button>
                    <Button
                        color="danger"
                        type="button"
                        onClick={remove}
                    >
                      삭제하기
                    </Button>
                  </div>
                </>
                :
                <>
                  <h3 className="mt-2">유저 등록</h3>
                  <div>
                    <Button color="default" type="button"
                            onClick={submit}
                    >
                      <span className="btn-inner--icon mr-1">
                          <i className="fas fa-user-edit" />
                      </span>
                      등록하기
                    </Button>
                  </div>
                </>
            }
          </CardHeader>
          <CardBody>
            {!id &&
                <>
                  <Row>
                    <Col md="5">
                      <FormGroup>
                        <label
                            className="form-control-label"
                            htmlFor="example3cols1Input"
                        >
                          아이디
                        </label>
                        <Input
                            placeholder="* 아이디는 7 ~ 20자, 영문 또는 영문(소문자) + 숫자 입니다."
                            type="text"
                            name="loginId"
                            value={loginId || ''}
                            onChange={onChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="5">
                      <FormGroup>
                        <label
                            className="form-control-label"
                            htmlFor="example3cols1Input"
                        >
                          비밀번호
                        </label>
                        <Input
                            placeholder="* 비밀번호는 8 ~ 13자, 영문 또는 영문 + 숫자 + 특수문자($@$!%*#?&.-) 입니다."
                            type="password"
                            name="checkPassword"
                            value={checkPassword || ''}
                            onChange={(e) => setCheckPassword(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="5">
                      <FormGroup>
                        <label
                            className="form-control-label"
                            htmlFor="example3cols1Input"
                        >
                          비밀번호 확인
                        </label>
                        <Input
                            placeholder="* 비밀번호를 한번 더 입력해주세요."
                            type="password"
                            name="password"
                            value={password || ''}
                            onChange={onChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </>
            }
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    이름
                  </label>
                  <Input
                      placeholder="이름을 입력해주세요."
                      type="name"
                      name="name"
                      value={name || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    생년월일
                  </label>
                  <Input
                      placeholder="ex)20001010"
                      type="birth"
                      name="birth"
                      value={birth || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <FormGroup>
                  <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                  >
                    전화번호
                  </label>
                  <Input
                      placeholder="* -(하이픈) 없이 번호만."
                      type="text"
                      name="tel"
                      value={tel || ''}
                      onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            {id?
                <>
                  <Row>
                    <Col md="5">
                      <FormGroup>
                        <label
                            className="form-control-label"
                            htmlFor="example3cols1Input"
                        >
                          1365 회원번호
                        </label>
                        <Input
                            placeholder="1365 회원번호를 입력해주세요."
                            type="text"
                            name="number1365"
                            value={number1365 || ''}
                            onChange={onChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="5">
                      <FormGroup>
                        <label
                            className="form-control-label"
                            htmlFor="example3cols1Input"
                        >
                          직번
                        </label>
                        <Input
                            placeholder="직번을 입력해주세요."
                            type="text"
                            name="staffNumber"
                            value={staffNumber || ''}
                            onChange={onChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </>
                :
                <>
                  <Row>
                    <Col md="5">
                      <FormGroup>
                        <label
                            className="form-control-label"
                            htmlFor="example3cols1Input"
                        >
                          이메일
                        </label>
                        <Input
                            placeholder="이메일을 입력해주세요."
                            type="text"
                            name="email"
                            value={email || ''}
                            onChange={onChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="5">
                      <FormGroup>
                        <label
                            className="form-control-label"
                            htmlFor="example3cols1Input"
                        >
                          성별
                        </label>
                        <Input
                            type="select"
                            name="gender"
                            value={gender || ''}
                            onChange={(e) => setData({...data, gender: e.target.value})}
                        >
                          <option>전체</option>
                          <option value="M">남자</option>
                          <option value="F">여자</option>
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                </>
            }
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default UserForm;
