import React, {Fragment, useEffect} from "react";

// reactstrap components
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText, Media,
  Row,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
// layout for this page
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import PaginationWrapper from "../components/pagination";
import {clickImg, numberWithCommas, paginate} from "../../../utils";
import useHistoryState from "../../../common/useHistoryState";
import classnames from "classnames";
import {Link} from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import {marketCategory} from "./market.const";
import marketService from "./market.service";
import dayjs from "dayjs";

function MarketList({ history }) {
  const [pageActive, setPageActive] = useHistoryState(1, 'pageActive');
  const [pageCount, setPageCount] = useHistoryState(1, 'pageCount');
  const [data, setData] = useHistoryState([], 'data');

  const [category, setCategory] = useHistoryState('전체', 'category');
  const [active, setActive] = useHistoryState('전체', 'active');
  const [registerType, setRegisterType] = useHistoryState('전체', 'registerType');
  const [search, setSearch] = useHistoryState('', 'search');

  console.log(pageActive, pageCount)

  const render = async () => {
    try {
      const options = {
        category: category === '전체' ? undefined : category,
      }
      const res = await marketService.list(options);
      console.log('로컬 이음 마켓 관리', res);
      if (res.code === 200) {
        setPageCount(res.list.length);
        setData(paginate(res.list, pageActive, 10));
      }
    } catch (e) {
      alert(e.response?.data?.resultMsg);
    }
  }

  useEffect(() => {
    render();
  }, [category, search, active, registerType, pageActive, pageCount]);

  const statusChange = async (id, active) => {
    const res = await marketService.update(id, {active});
    if (res.code === 200) {
      render();
    }
  }

  return (
    <>
      <SimpleHeader name="Tables" parentName="Tables" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row className="py-3">
                  <Col xs="11">
                    <Form>
                      <Row>
                        <Col xs="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="exampleFormControlSelect13"
                            >
                              카테고리
                            </label>
                            <Input
                              id="exampleFormControlSelect13"
                              type="select"
                              value={category}
                              onChange={(e) => setCategory(e.target.value)}
                            >
                              <option>전체</option>
                              {marketCategory.map((c, i) => {
                                return [
                                  <option key={i}>{c}</option>
                                ]
                              })}
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">로컬 이음 마켓 관리</h3>
                  </Col>
                  <Col className="text-right" xs="6">
                    <Button
                      className="btn-round btn-icon"
                      color="default"
                      id="tooltip443412080"
                      onClick={(e) => history.push('/admin/marketRegister')}
                      size="sm"
                    >
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-user-edit" />
                  </span>
                      <span className="btn-inner--text">등록하기</span>
                    </Button>
                  </Col>
                </Row>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive striped>
                <thead className="thead-light">
                <tr>
                  <th className="text-center">등록일</th>
                  <th className="text-center">카테고리</th>
                  <th className="text-center">상품명</th>
                  <th className="text-center">한줄소개</th>
                  <th className="text-center">판매금액</th>
                  <th className="text-center">상품표준가격</th>
                  <th className="text-center">사진</th>
                  <th className="text-center">링크주소</th>
                  <th>관리</th>
                  <th />
                </tr>
                </thead>
                <tbody>
                  {data?.map((item, i) => {
                  return [
                    <Fragment key={uuidv4()}>
                      <tr>
                        <td className="text-center">
                          {dayjs(item.createdAt).format('YYYY-MM-DD')}
                        </td>
                        <td className="text-center">
                          {item.category}
                        </td>
                        <td className="text-center">
                          {item.name}
                        </td>
                        <td className="text-center">
                          {item.intro}
                        </td>
                        <td className="text-center">
                          {numberWithCommas(item.price)}
                        </td>
                        <td className="text-center">
                          {numberWithCommas(item.realPrice)}
                        </td>
                        <td className="text-center">
                          <a
                              className="avatar rounded-circle"
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                          >
                            <img
                                alt="..."
                                style={{height: 50}}
                                onClick={() => clickImg(item.thumbnail)}
                                src={item.thumbnail}
                            />
                          </a>
                        </td>
                        <td className="text-center">
                          {item.link}
                        </td>
                        {/*<td className="text-center">*/}
                        {/*  <label className="custom-toggle custom-toggle-success center">*/}
                        {/*    <input*/}
                        {/*      checked={item.active}*/}
                        {/*      type="checkbox"*/}
                        {/*      onChange={(e) => statusChange(item.id, !item.active)}*/}
                        {/*    />*/}
                        {/*    <span*/}
                        {/*      className="custom-toggle-slider rounded-circle"*/}
                        {/*      data-label-off="No"*/}
                        {/*      data-label-on="Yes"*/}
                        {/*    />*/}
                        {/*  </label>*/}
                        {/*</td>*/}
                        <td className="table-actions">
                          <Link className="table-action" to={'/admin/marketsDetail/' + item.id}>
                            <i id={"tooltip" + i} className="fas fa-user-edit" />
                          </Link>
                          <UncontrolledTooltip delay={0} target={"tooltip" + i}>
                            상세 보기
                          </UncontrolledTooltip>
                        </td>
                      </tr>
                    </Fragment>
                  ];
                  })}
                </tbody>
              </Table>

              <CardFooter className="py-4">
                <PaginationWrapper
                  isActive={pageActive}
                  pageNumber={pageCount}
                  callback={(page) => setPageActive(page)}
                  itemPerPage={10}
                />
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default MarketList;
