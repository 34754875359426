import axios from "axios";
import api from "../../../../config/api.routes";
import authHeader from "../../../../services/auth-header";
import {atom} from "recoil";

//file
export const charityFileState = atom({
  key: 'charityFileState',
  default: '',
});

export const charityFilePreviewState = atom({
  key: 'charityFilePreviewState',
  default: '',
});

export const charityDetailFileState = atom({
  key: 'charityDetailFileState',
  default: '',
});

export const charityDetailFilePreviewState = atom({
  key: 'charityDetailFilePreviewState',
  default: '',
});

const list = (data) => {
  let url = api().apiURL + `/company/rank/list?`;
  for (let query of Object.keys(data)) {
    if (data[query] === undefined || data[query] === '') {
      continue;
    }
    url += `${query}=${data[query]}&`;
  }
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.get(url, { headers: authHeader() })
      .then(res => res.data)
      .then(res => resolve(res))
      .catch(e => reject(e));
  });
};

// const register = (data) => {
//   let url = api().apiURL + `/charity/register?`;
//   console.log('서비스',url,data);
//   return new Promise((resolve, reject) => {
//     axios.post(url, data, { headers: authHeader() })
//         .then(res => res.data)
//         .then(res => resolve(res))
//         .catch(e => reject(e));
//   });
// };
//
// const fileRegister = (id, data, diff) => {
//   let url = api().apiURL + `/v1/charity/fileUpload?charityId=${id}&diff=${diff}`;
//   console.log(url);
//   return new Promise((resolve, reject) => {
//     axios.post(url, data, { headers: authHeader() })
//         .then(res => res.data)
//         .then(res => resolve(res))
//         .catch(e => reject(e));
//   });
// };

const detail = (data) => {
  let url = api().apiURL + `/company/rank/detail?`;
  for (let query of Object.keys(data)) {
    if (data[query] === undefined || data[query] === '' || data[query] === '전체') {
      continue;
    }
    url += `${query}=${data[query]}&`;
  }
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.get(url, { headers: authHeader() })
        .then(res => res.data)
        .then(res => resolve(res))
        .catch(e => reject(e));
  });
};

const update = (id, data) => {
  let url = api().apiURL + `/company/${id}`;
  console.log('수정',url);
  return new Promise((resolve, reject) => {
    axios.put(url, data, { headers: authHeader() })
        .then(res => res.data)
        .then(res => resolve(res))
        .catch(e => reject(e));
  });
};

const updateService = (id, data) => {
  let url = api().apiURL + `/admin/service/history/${id}?confirm=${data}`;
  console.log('수정',url);
  return new Promise((resolve, reject) => {
    axios.put(url, data, { headers: authHeader() })
        .then(res => res.data)
        .then(res => resolve(res))
        .catch(e => reject(e));
  });
};

const remove = (id) => {
  let url = api().apiURL + `/company/kick?userId=${id}`;
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.delete(url, { headers: authHeader() })
        .then(res => res.data)
        .then(res => resolve(res))
        .catch(e => reject(e));
  });
};

const excelDown = (data) => {
  let url = api().apiURL + `/company/rank/detail?`;
  for (let query of Object.keys(data)) {
    if (data[query] === undefined || data[query] === '') {
      continue;
    }
    url += `${query}=${data[query]}&`;
  }
  console.log(url);
  return new Promise((resolve, reject) => {
    axios.get(url, { headers: authHeader(), responseType: 'arraybuffer' })
        .then(res => resolve(res))
        .catch(e => reject(e));
  });
};

const companyRankService = {
  list,
  // register,
  // fileRegister,
  detail,
  update,
  updateService,
  remove,
  excelDown,
}

export default companyRankService;
